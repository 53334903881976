import TuneIcon from "@mui/icons-material/TuneRounded";
import { Badge, Box, Grid } from "@mui/joy";
import Button from "@mui/joy/Button";
import DialogContent from "@mui/joy/DialogContent";
import DialogTitle from "@mui/joy/DialogTitle";
import Divider from "@mui/joy/Divider";
import Drawer from "@mui/joy/Drawer";
import FormControl from "@mui/joy/FormControl";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import dayjs from "dayjs";
import _ from "lodash";
import * as React from "react";
import BaseDatePicker from "./BaseDatePicker";
import BaseInput from "./BaseInput";
import BaseSelect from "./BaseSelect";
import BaseTagsInput from "./BaseTagsInput";

const TextFilter = ({
  label,
  inputLabel,
  value,
  onChange,
  onKeyUp,
  placeholder,
  lg,
}: {
  label?: string;
  inputLabel?: string;
  value: string;
  onChange: (value: string) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: string;
  lg?: number;
}) => {
  return (
    <Grid xs={12} lg={lg}>
      {!!label && (
        <Typography level="title-md" mb={1}>
          {label}
        </Typography>
      )}
      <FormControl>
        <BaseInput
          label={''}
          value={value || ""}
          onChange={(e) => onChange(e.target.value)}
          placeholder={placeholder}
          onKeyUp={onKeyUp}
        />
      </FormControl>
    </Grid>
  );
};

const MultipleSelectFilter = ({
  label,
  placeholder,
  options,
  value,
  onChange,
  lg,
}: {
  label?: string;
  placeholder?: string;
  options: { value: any; label: string }[];
  value: string[];
  onChange: (value: any[]) => void;
  lg?: number;
}) => {
  return (
    <Grid xs={12} lg={lg}>
      <Typography
        level="title-md"
      >
        {label}
      </Typography>
      <FormControl>
        <BaseTagsInput value={value}
          placeholder={placeholder}
          options={options}
          onChange={(e) => {
            onChange(e)
          }}
        ></BaseTagsInput>
      </FormControl>
    </Grid>
  );
};

const DropdownSelectFilter = ({
  label,
  inputLabel,
  placeholder,
  options,
  value,
  onChange,
  lg,
}: {
  label?: string;
  inputLabel?: string;
  placeholder?: string;
  options: { value: any; label: string }[];
  value: string;
  onChange: (value: any) => void;
  lg?: number;
}) => {
  return (
    <Grid xs={12} lg={lg}>
      <Typography level="title-md" mb={1}>
        {label}
      </Typography>
      <FormControl
      >
        <BaseSelect
          label={''}
          placeholder={placeholder}
          options={options}
          value={value}
          onChange={onChange}
        />
      </FormControl>
    </Grid>
  );
};

const DateRangeFilter = ({
  label,
  value,
  onChange,
  lg,
}: {
  label?: string;
  value: { from: string; to: string };
  onChange: (value: { from?: string; to?: string }) => void;
  lg?: number;
}) => {
  return (
    <Grid xs={12} lg={lg}>
      <Typography level="title-md">
        {label}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 1,
        }}
      >
        <FormControl>
          <BaseDatePicker
            label="From date"
            value={value.from}
            onChange={(date) => {
              onChange({
                ...value,
                from: dayjs(date).isValid() ? dayjs(date).startOf("day").format("YYYY-MM-DD") : undefined,
              });
            }}
          />
        </FormControl>
        <FormControl>
          <BaseDatePicker
            label="To date"
            value={value.to}
            onChange={(date) => {
              onChange({
                ...value,
                to: dayjs(date).isValid() ? dayjs(date).endOf("day").format("YYYY-MM-DD") : undefined,
              });
            }}
          />
        </FormControl>
      </Box>
    </Grid>
  );
};

export interface IFilterItem {
  label: string;
  type: "text" | "texts" | "multiple-select" | "date" | "select" | "dropdown-select"
  placeholder?: string;
  options?: { value: any; label: any }[];
  key: string;
  keys?: string[];
}

export default function FilterDrawer({
  title,
  filters,
  value,
  onChange,
  defaultValue,
}: {
  title?: string;
  filters: IFilterItem[];
  value: any;
  onChange: (value: any) => void;
  defaultValue?: any;
}) {
  const [open, setOpen] = React.useState(false);

  const [localValue, setLocalValue] = React.useState({ ...value });

  React.useEffect(() => {
    setLocalValue({ ...value });
  }, [value, open]);

  const handleClearFilters = () => {
    if (defaultValue) {
      setLocalValue(_.cloneDeep(defaultValue));
      return;
    }

    setLocalValue({});
  };

  const handleApplyFilter = (newValue: any) => {
    console.log("Apply filter", newValue);
    onChange(newValue);
    setOpen(false);
  };

  const applyDebounce = React.useCallback(
    _.debounce((nextValue) => handleApplyFilter(nextValue), 0),
    []
  );

  const badgeCount = Object.values(localValue).filter(
    (v) => !_.isEmpty(v)
  ).length;

  const renderFilter = (filter?: IFilterItem) => {
    if (!filter) return null;

    const filterLabel = filter.label;
    if (filter.type === "text" || filter.type === "texts") {
      return (
        <TextFilter
          key={filter.key}
          label={filterLabel}
          inputLabel={filter.placeholder}
          value={localValue[filter.key]}
          placeholder={filter.placeholder}
          onChange={(v) => {
            const newValue = { ...localValue, [filter.key]: v };
            setLocalValue(newValue);
          }}
          lg={12}
        />
      );
    } else if (filter.type === "multiple-select") {
      return (
        <MultipleSelectFilter
          key={filter.key}
          label={filterLabel}
          placeholder={filter.placeholder}
          options={filter.options!}
          value={localValue[filter.key] || []}
          onChange={(v) => {
            setLocalValue({ ...localValue, [filter.key]: v });
          }}
          lg={12}
        />
      );
    } else if (filter.type === 'dropdown-select') {
      return <DropdownSelectFilter
        key={filter.key}
        label={filterLabel}
        inputLabel={filter.placeholder}
        placeholder={filter.placeholder}
        options={filter.options!}
        value={localValue[filter.key]}
        onChange={(v) => {
          const newValue = { ...localValue, [filter.key]: v };
          setLocalValue(newValue);
        }}
        lg={12}
      />
    } else if (filter.type === "date") {
      return (
        <DateRangeFilter
          key={filter.key}
          label={filterLabel}
          value={
            localValue[filter.key] || {
              from: null,
              to: null,
            }
          }
          onChange={(v) => {
            setLocalValue({ ...localValue, [filter.key]: v });
          }}
          lg={12}
        />
      );
    }
    return null;
  };


  return (
    <React.Fragment>
      <Badge badgeContent={badgeCount} size="sm">
        <Button
          variant="outlined"
          color="neutral"
          size="sm"
          startDecorator={<TuneIcon fontSize='small' />}
          onClick={() => setOpen(true)}
        >
          Change filters
        </Button>
      </Badge>

      <Drawer
        size='md'
        variant="plain"
        anchor={"right"}
        open={open}
        onClose={() => setOpen(false)}
        slotProps={{
          content: {
            sx: {
              bgcolor: 'transparent',
              p: { md: 3, sm: 0 },
              boxShadow: 'none',
            },
          },
        }}
      >
        <Sheet
          sx={{
            borderRadius: 'md',
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            height: '100%',
          }}
        >
          <DialogTitle>{title || "Filters"}</DialogTitle>
          <ModalClose />
          <Divider sx={{ mt: "auto" }} />
          <DialogContent sx={{ gap: 2, overflowX: 'hidden', overflowY: 'auto', }}>
            <Grid container spacing={2}>
              {filters.map((v) => renderFilter(v))}
            </Grid>
          </DialogContent>

          <Divider sx={{ mt: "auto" }} />
          <Stack
            direction="row"
            justifyContent="flex-end"
            useFlexGap
            spacing={1}
          >
            <Button
              variant="outlined"
              color="neutral"
              onClick={handleClearFilters}
            >
              Clear
            </Button>
            <Button onClick={() => handleApplyFilter(localValue)}>Procces</Button>
          </Stack>
        </Sheet>
      </Drawer>
    </React.Fragment>
  );
}

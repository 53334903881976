import CommonList from "components/CommonList";
import PageWrapper from "components/PageWrapper";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { getListWarehouse } from "redux-store/reducers/warehouse";
import ROUTERS from "routers/constants";

export default function WarehouseListPage() {
  const warehouseList = useAppSelector((state) => state.warehouse.list);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleGetListWarehouse = () => {
    dispatch(getListWarehouse({}));
  };
  useEffect(() => {
    handleGetListWarehouse();
  }, []);
  return (
    <PageWrapper
      pageName="Warehouses"
      breadcrumb={[
        {
          label: "Warehouse",
        },
      ]}
    >
      <CommonList
        data={warehouseList}
        columns={[
          {
            key: "name",
            label: "Name",
            render: (row) => <b>{row.name}</b>,
          },
          {
            key: "address",
            label: "Address",
          },
          {
            width: 100,
            key: "state",
            label: "State",
          },
          {
            width: 100,
            key: "country",
            label: "Country",
          },
        ]}
        searchKeys={["name", "address"]}
        sortKeys={["name"]}
        filterStatus={{
          statusKey: "",
          data: [],
        }}
        onRowClick={(row) =>
          navigate(`${ROUTERS.WAREHOUSE_DETAIL.replace(":id", row.id as any)}`)
        }
      />
    </PageWrapper>
  );
}

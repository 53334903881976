export interface IArea {
  id?: number;
  name: string;
  type: AreaTypeEnum;
  warehouse_id: number;
  status?: string;
  parent_id?: number;
}

export interface IWarehouse {
  id?: number;
  name: string;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  country: string;
  areas: IArea[];
}

export enum AreaTypeEnum {
  AISLE = "aisle",
  LEVEL = "level",
  BIN = "bin",
}

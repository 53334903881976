import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import { useAppDispatch } from "redux-store";

import { HelpOutlineOutlined } from "@mui/icons-material";
import { Box } from "@mui/joy";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";

import Grid from "@mui/joy/Grid";
import { useState } from "react";
import { useAppSelector } from "redux-store";
import { TProductItem } from "types/product";

import { unwrapResult } from "@reduxjs/toolkit";
import BaseNumberInput from "components/BaseNumberInput";
import BaseSelect from "components/BaseSelect";
import { getRealItemsBySerialNumber } from "redux-store/reducers/return";
import { IReturnItem } from "types/return";
import notification from "utils/notification";

type TBasicModal = {
  isOpen: boolean;
  onCloseModal: () => void;
  hasSerialNumber: boolean;
  onCreated: (items: IReturnItem) => void;
  onItemsCreated: (items: IReturnItem[]) => void;
  editedItem?: IReturnItem;
};

export default function ModalAddNewReturnItem({
  isOpen,
  onCloseModal,
  hasSerialNumber,
  onCreated,
  onItemsCreated,
  editedItem,
}: TBasicModal) {
  const dispatch = useAppDispatch();
  const productItems =
    useAppSelector((state) => state.product.productItems) || [];

  const [itemId, setItemId] = useState(editedItem?.item_id || "");
  const [serialNumbers, setSerialNumbers] = useState("");
  const [loadingSerialNumber, setLoadingSerialNumber] = useState(false);
  const [quantity, setQuantity] = useState(editedItem?.quantity || 1);

  const options =
    productItems?.map?.((item: TProductItem) => ({
      label: item.code,
      value: item.id,
    })) || [];

  const handleAddNewSerialNumber = () => {
    setLoadingSerialNumber(true);
    dispatch(
      getRealItemsBySerialNumber({
        serial_numbers: serialNumbers
          .split(",")
          .map((v) => v.trim().toUpperCase()),
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setLoadingSerialNumber(false);
        if (response.apiStatus) {
          if (response.data?.length === 0) {
            notification.error("No ttems found");
            return;
          } else {
            notification.success(`Found ${response.data.length} items`);
          }
          onItemsCreated(
            response.data.map((v) => ({
              so_number: v.sale_order?.so_number,
              customer_id: v.sale_order?.customer.id,
              item_id: v.item?.id,
              item_real_id: v.id,
              quantity: 1,
              item: v.item,
              customer: v.sale_order?.customer,
              item_real: {
                serial_number: v.serial_number,
              },
            }))
          );
          onCloseModal();
        } else {
          notification.error("Get real items by serial number fail");
        }
      });
  };

  const onCreateItem = () => {
    onCreated({
      so_number: undefined,
      customer_id: undefined,
      item_id: Number(itemId),
      item_real_id: undefined,
      quantity,
      item: productItems.find((item) => item.id === itemId),
    });
    onCloseModal();
  };

  return hasSerialNumber ? (
    <BaseModal
      title="Add Items"
      isOpen={isOpen}
      onClose={onCloseModal}
      actions={
        <Button
          disabled={!serialNumbers}
          loading={loadingSerialNumber}
          color="primary"
          size="sm"
          onClick={handleAddNewSerialNumber}
        >
          Add
        </Button>
      }
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            mb: 2,
          }}
        >
          <HelpOutlineOutlined fontSize="small" />
          Input multiple Serial Numbers by comma
        </Box>
        <FormControl>
          <BaseInput
            value={serialNumbers}
            onChange={(e) => setSerialNumbers(e.target.value)}
            label="Serial Numbers"
            placeholder="Ex: Z123456XE,Z123456XE,Z123456XE"
          />
        </FormControl>
      </Box>
    </BaseModal>
  ) : (
    <BaseModal
      title="Select Item"
      isOpen={isOpen}
      onClose={onCloseModal}
      actions={<Button onClick={onCreateItem}>Add</Button>}
    >
      <Grid container spacing={2}>
        <Grid sm={12}>
          <FormControl>
            <BaseSelect
              label={"Item"}
              options={options}
              value={itemId}
              onChange={setItemId}
            />
          </FormControl>
        </Grid>
        <Grid sm={3}>
          <FormControl>
            <BaseNumberInput
              label="Quantity"
              value={quantity}
              onChange={(e) => setQuantity(e)}
              min={1}
            />
          </FormControl>
        </Grid>
      </Grid>
    </BaseModal>
  );
}

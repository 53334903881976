import { ClearOutlined } from "@mui/icons-material";
import { Box, CircularProgress, Grid, IconButton, LinearProgress, Table } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { ChipStatus } from "components/ChipStatus";
import { set } from "lodash";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import { setBreadcrumb } from "redux-store/reducers/common";
import {
  itemPalletPackingV2,
  palletDeleteSerialNumber,
  palletGetSerialNumber,
} from "redux-store/reducers/warehouse";
import notification from "utils/notification";
let scanningTextPallet = "";
export default function PalletPackingPage() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState<any[]>([]);
  const [snMap, setSnMap] = useState<any>({});
  const [palletData, setPalletData] = useState<any>({
    pallet_number: "",
  });
  const scanModeRef = React.createRef<HTMLDivElement>();
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          label: "Pallet Scan",
        },
      ])
    );
    scanningTextPallet = "";
  }, []);

  const fetchSN = (e: any) => {
    const item_id = e.item.item_id;
    dispatch(
      palletGetSerialNumber({
        pallet_id: palletData.id,
        item_id: item_id,
      })
    )
      .unwrap()
      .then(({ response }) => {
        if (response.apiStatus) {
          const data = response.data;
          setSnMap({
            ...snMap,
            [palletData.id + '_' + item_id]: data,
          });
        } else {
          notification.error(response?.message);
        }
      });
  };

  const handleDeleteSN = (e: any, payload: any) => {
    dispatch(palletDeleteSerialNumber(payload))
      .unwrap()
      .then(({ response }) => {
        if (response.apiStatus) {
          dispatch(itemPalletPackingV2({
            text: palletData.pallet_number,
          }))
            .then(unwrapResult)
            .then(({ response }) => {
              setLoading(false);
              if (response.apiStatus) {
                const data = response.data;
                if (data?.pallet_number) {
                  setPalletData(data);
                }
              } else {
                return notification.error(response.message);
              }
            });
          fetchSN(e);
        } else {
          notification.error(response.message);
        }
      });
  };

  const handleViewSerial = (e: any) => {
    const item_id = palletData.id + '_' + e.item.item_id;

    if (expanded.includes(item_id)) {
      setExpanded([...expanded.filter((e) => e !== item_id)]);
    } else {
      fetchSN(e);
      setExpanded([...expanded, item_id]);
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (loading) {
      scanningTextPallet = "";
      return;
    }
    // Assuming your scanner adds a special character before/after the scanned code
    // You may need to adjust this based on your scanner's behavior
    if (e.key === "Enter") {
      if (!scanningTextPallet) return;
      setTimeout(() => {
        // call api
        const objectFind: any = {
          text: scanningTextPallet,
        };
        if (palletData && palletData.id) {
          objectFind.pallet_id = palletData.id;
        }
        setLoading(true);
        dispatch(itemPalletPackingV2(objectFind))
          .then(unwrapResult)
          .then(({ response }) => {
            setLoading(false);
            scanningTextPallet = "";
            if (response.apiStatus) {
              const data = response.data;
              if (data?.pallet_number) {
                setPalletData(data);
              }

              if (data?.item_id) {
                fetchSN({
                  item: {
                    item_id: data.item_id
                  }
                });
              }
            } else {
              return notification.error(response.message);
            }
          });
      }, 0);
    } else {
      scanningTextPallet += e.key.replace(/^Shift/, "");
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", handleKeyDown);
    return () => {
      document.removeEventListener("keypress", handleKeyDown);
    };
  }, [handleKeyDown, loading]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          sm={12}
          xs={12}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              scanModeRef.current?.focus();
            }
          }}
        >
          {loading ? (
            <LinearProgress thickness={1} />
          ) : (
            <div style={{ height: "1px", width: "100%" }}></div>
          )}

          {palletData?.pallet_number ? (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: "0.5rem",
                }}
              >
                <Box
                  sx={{
                    fontSize: "13px",
                  }}
                >
                  Pallet Number:{" "}
                  <strong style={{ marginRight: "10px" }}>
                    {" "}
                    {palletData.pallet_number}
                  </strong>
                  <ChipStatus status={palletData.status}></ChipStatus>
                </Box>
              </Box>
              <Table borderAxis="both">
                <thead>
                  <tr>
                    <th
                      style={{
                        paddingTop: 0,
                        width: "140px",
                      }}
                    >
                      Item
                    </th>
                    <th>Qty</th>
                  </tr>
                </thead>
                <tbody>
                  {(palletData?.result || []).map((e: any) => {
                    return (
                      <tr
                        key={`${e.item.code}`}
                        onClick={() => handleViewSerial(e)}
                      >
                        <td>
                          <strong> {e.item.code}</strong>
                        </td>
                        <td>
                          {!expanded.includes(palletData.id + '_' + e.item.item_id) ? (
                            <Box>
                              <strong> {e.quantity}</strong> {e.item.unit}
                            </Box>
                          ) : (
                            <Box sx={{
                              display: "flex",
                              flexDirection: "column",
                            }}>
                              {!snMap[
                                palletData.id + '_' + e.item.item_id
                              ] && <CircularProgress size='sm' />}
                              {snMap[
                                palletData.id + '_' + e.item.item_id
                              ]?.map((v: any) => (
                                <Box
                                  key={v.id}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  {v.serial_number}
                                    <ClearOutlined
                                    fontSize="small"
                                    onClick={(ev) =>
                                    {
                                      ev.preventDefault();
                                      ev.stopPropagation();
                                      handleDeleteSN(e, {
                                        id: v.id,
                                        item_id: e.item.item_id,
                                        pallet_id: palletData.id,
                                      })
                                    }
                                    } />
                                </Box>
                              ))}
                            </Box>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : (
            <>Please scan a pallet.</>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

// ABACO-240627-004

import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  getDetailInvoiceApi,
  listInvoiceApi,
} from "services/customer-portal/customer-invoice.service";
import { IInvoice } from "types/invoice";

const TYPE = "CUSTOMER_INVOICE";

export interface IInvoiceState {
  list: IInvoice[];
}

const initialState: IInvoiceState = {
  list: [],
};

export const getListInvoice = createCallApiAsyncAction(
  TYPE + "/getListInvoice",
  listInvoiceApi
);

export const getDetailInvoice = createCallApiAsyncAction(
  TYPE + "/getDetailInvoice",
  getDetailInvoiceApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListInvoice,
      stateKey: "list",
    });
  },
  reducers: {},
});

export default slice.reducer;

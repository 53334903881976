import { Box } from "@mui/joy";
import Button from "@mui/joy/Button";
import { unwrapResult } from "@reduxjs/toolkit";

import CommonList from "components/CommonList";
import PageWrapper from "components/PageWrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { getListProduct, getListProductExport } from "redux-store/reducers/product";
import ROUTERS from "routers/constants";
import { exportProductItems, formatDate } from "utils/common";
import notification from "utils/notification";

export default function ProductListPage() {
  let productList = useAppSelector((state) => state.product.list);
  productList = productList.map(e => {
    return {
      ...e,
      code: `${e.items?.map(i => { return i.code }).join(' | ')}`
    }
  })
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loading, setLoading] =
    useState<boolean>(false);

  const handleGetListProduct = () => {
    dispatch(getListProduct({}));
  };
  useEffect(() => {
    handleGetListProduct();
  }, []);

  const handleExportProduct = () => {
    setLoading(true)
    dispatch(
      getListProductExport({})
    )
      .then(unwrapResult)
      .then(({ response }) => {
        console.log(response.data)
        if (response.data && response.data.length) {
          // export
          exportProductItems(response.data)
        } else {
          notification.error("Export fail")
        }
        setLoading(false)

      });

  };

  return (
    <PageWrapper
      pageAction={
        <Box
          sx={{
            display: "flex",
            gap: 1,
          }}
        >
          <Button
            loading={loading}
            size="sm"
            variant="plain"
            onClick={handleExportProduct}
          >
            Export
          </Button>

          <Button
            color="primary"
            size="sm"
            onClick={() => navigate(ROUTERS.PRODUCT_IMPORT)}
          >
            Import
          </Button>
          <Button
            color="primary"
            size="sm"
            onClick={() => navigate(ROUTERS.PRODUCT_NEW)}
          >
            Create
          </Button>
        </Box>
      }
      pageName="Products"
      breadcrumb={[
        {
          label: "Products",
        },
      ]}
    >
      <CommonList
        data={productList}
        columns={[
          {
            key: "name",
            label: "Name",
            render: (row) => <><strong>{row.name}</strong> <br /> <small>{row?.code}</small></>,
          },
          {
            key: "items",
            label: "Variants",
            width: 100,
            render: (row) => row.items?.length,
          },
          {
            key: "status",
            label: "Status",
            width: 100,
          },
          {
            key: "created_at",
            label: "Created At",
            width: 120,
            render: (row) => formatDate(row.created_at),
          },
        ]}
        searchKeys={["name", "code"]}
        sortKeys={["created_at", "name"]}
        filterStatus={{
          statusKey: "status",
          data: [
            { label: "Active", value: "Active" },
            { label: "Inactive", value: "Inactive" },
          ],
        }}
        onRowClick={(row) =>
          navigate(`${ROUTERS.PRODUCT_DETAIL.replace(":id", row.id)}`)
        }
      />
    </PageWrapper>
  );
}

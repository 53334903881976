import Typography from "@mui/joy/Typography";

import { Box, Stack, Table } from "@mui/joy";
import { InvoiceStatusEnum } from "app-constants";
import _ from "lodash";
import { IInvoice } from "types/invoice";
import {
  calcGrantTotal,
  showCurrency,
  showProductItemName,
} from "utils/common";
import PaymentHistoryList from "./PaymentHistoryList";

export default function InvoiceTableItem({
  invoiceDetail,
}: {
  invoiceDetail: IInvoice;
}) {
  // {
  //   id?: any;
  //   invoice_id?: any;
  //   amount?: number;
  //   payment_date?: string;
  //   payment_transaction?: string;
  //   payment_method?: string;
  //   receive_bank?: string;
  //   note?: string;
  //   created_at?: string;
  // }
  const payments = invoiceDetail?.invoice?.payments || [];

  const grandTotal = calcGrantTotal(invoiceDetail as any);
  const paidAmount = _.sumBy(payments, (v) => Number(v.amount));
  const unpaidAmount = grandTotal - paidAmount;

  return (
    <Stack spacing={2}>
      <Table variant={"outlined"}>
        <thead>
          <tr>
            <th>Item Name</th>
            <th style={{ width: "110px", textAlign: "right" }}>Qty</th>
            <th style={{ textAlign: "right" }}> Unit Cost </th>
            <th style={{ textAlign: "right" }}> Discount </th>
            <th style={{ textAlign: "right" }}> Total </th>
          </tr>
        </thead>
        <tbody>
          {invoiceDetail?.sales_order_items?.map((item) => {
            const product = item.item;
            return (
              <tr key={item.item_id || product?.id}>
                <td>{showProductItemName(product, true)}</td>
                <td style={{ textAlign: "right" }}>
                  {item.quantity} {product?.unit}
                </td>
                <td style={{ textAlign: "right" }}>
                  {showCurrency(item.cost)}{" "}
                </td>
                <td style={{ textAlign: "right" }}>
                  {showCurrency(item.discount)}{" "}
                </td>
                <td style={{ textAlign: "right" }}>
                  {showCurrency(Number(item.total) - Number(item.discount))}{" "}
                </td>

              </tr>
            );
          })}
        </tbody>
      </Table>
      <Box
        sx={{
          display: "flex",
          gap: 1,
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box sx={{ flex: 1 }}>
          {!!invoiceDetail?.invoice?.status &&
            [InvoiceStatusEnum.PARTIALLY_PAID, InvoiceStatusEnum.PAID].includes(
              invoiceDetail?.invoice?.status as any
            ) && <PaymentHistoryList invoiceDetail={invoiceDetail} />}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Box mr={6}>
            <Typography fontSize="sm" fontWeight={600} textAlign="right">
              Subtotal
            </Typography>
            <Typography fontSize="sm" textAlign="right">
              Tax
            </Typography>
            <Typography fontSize="sm" textAlign="right">
              Shipping
            </Typography>

            {invoiceDetail.is_pallet && (
              <Typography fontSize="sm" textAlign="right">
                Pallet fee
              </Typography>
            )}

            <Typography fontSize="sm" textAlign="right">
              Total Discount
            </Typography>

            <Typography fontSize="sm" fontWeight={600} textAlign="right">
              Total
            </Typography>

            <Typography fontSize="sm" color="success" textAlign="right">
              Paid
            </Typography>
            {!!unpaidAmount && (
              <Typography fontSize="sm" color="danger" textAlign="right">
                Unpaid
              </Typography>
            )}
          </Box>
          <Box>
            <Typography fontSize="sm" textAlign="right">
              {showCurrency(invoiceDetail?.total)}
            </Typography>
            <Typography fontSize="sm" textAlign="right">
              {showCurrency(invoiceDetail?.tax_cost)}
            </Typography>
            <Typography fontSize="sm" textAlign="right">
              {showCurrency(invoiceDetail?.shipping_cost)}
            </Typography>

            {invoiceDetail.is_pallet && (
              <Typography fontSize="sm" textAlign="right">
                {showCurrency(invoiceDetail?.pallet_cost)}
              </Typography>
            )}

            <Typography fontSize="sm" textAlign="right">
              {showCurrency(invoiceDetail?.discount_item)}
            </Typography>

            <Typography fontSize="sm" fontWeight={600} textAlign="right">
              {invoiceDetail
                ? showCurrency(invoiceDetail.invoice?.total)
                : ""}
            </Typography>

            <Typography fontSize="sm" color="success" textAlign="right">
              {invoiceDetail ? showCurrency(paidAmount) : ""}
            </Typography>
            {!!unpaidAmount && (
              <Typography fontSize="sm" color="danger" textAlign="right">
                {invoiceDetail ? showCurrency(unpaidAmount) : ""}
              </Typography>
            )}
          </Box>


        </Box>
      </Box>
    </Stack>
  );
}

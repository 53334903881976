import { Box, Typography } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import { getCustomerPODepositList } from "redux-store/reducers/customer-portal-so";
import { IDepositsItem } from "types/invoice";
import { ISalesOrder } from "types/sales-order";
import { formatDate, showCurrency } from "utils/common";

export default function DepositList({
  salesOrderDetail,
}: {
  salesOrderDetail: ISalesOrder;
}) {
  const dispatch = useAppDispatch();
  const [depositList, setDepositList] = useState<IDepositsItem[]>([]);
  const fetchList = () => {
    if (!salesOrderDetail.id) return;
    dispatch(
      getCustomerPODepositList({
        so_id: salesOrderDetail.id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        let newData: any = [];
        if (response.apiStatus) {
          newData = response.data || [];
        }

        newData.finishLoading = true;

        setDepositList(newData);
      });
  };

  useEffect(() => {
    fetchList();
  }, [salesOrderDetail]);
  return (
    <Box>
      <CommonList
        data={depositList}
        columns={[
          {
            label: "Status",
            key: "status",
            width: 120,
            render: (item) => <ChipStatus status={item.status} />,
          },
          {
            label: "Amount",
            key: "amount",
            width: 120,
            render: (item) => showCurrency(item.amount),
          },
          {
            label: "Info",
            key: "info",
            hide: !!depositList.find((item) => item.status === "Pending"),
            render: (item) => (
              <>
                <Box>
                  <Typography>Method: {item.deposit_method}</Typography>
                  <Typography>Receive Bank: {item.receive_bank}</Typography>
                  <Typography>Date: {formatDate(item.deposit_date)}</Typography>
                  <Typography>
                    Transaction: {item.deposit_transaction}
                  </Typography>
                </Box>
              </>
            ),
          },
          {
            label: "Note",
            key: "note",
          },
        ]}
        sortKeys={["amount"]}
      />
    </Box>
  );
}

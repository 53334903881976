import PageWrapper from "components/PageWrapper";
import ImportProduct from "pages/product/components/ImportProduct";
import ROUTERS from "routers/constants";

export default function ProductImportPage() {
  return (
    <>
      <PageWrapper
        pageName="Import Product"
        breadcrumb={[
          {
            label: "Products",
            link: ROUTERS.PRODUCT,
          },
          {
            label: "Import",
          },
        ]}
      >
        <ImportProduct />
      </PageWrapper>
    </>
  );
}

import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";

import { Typography } from "@mui/joy";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import PageWrapper from "components/PageWrapper";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  getListCustomerAddress,
  getListCustomerSaleOrder,
} from "redux-store/reducers/customer-portal-so";
import { getCustomerProductItems } from "redux-store/reducers/product";
import { ROUTERS_CUSTOMER } from "routers/constants";
import { formatDate, showCurrency } from "utils/common";

export default function PurchaseListPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const purchaseList =
    useAppSelector((state) => state.customerPortalSO.list) || [];

  const handleGetListPurchase = () => {
    dispatch(getListCustomerSaleOrder({}));
  };

  useEffect(() => {
    handleGetListPurchase();
    dispatch(getCustomerProductItems({}));
    dispatch(getListCustomerAddress({}));
  }, []);

  return (
    <Box sx={{ mt: "24px" }}>
      <PageWrapper
        pageName="Orders"
        pageAction={
          <Button
            size="sm"
            color="primary"
            onClick={() => navigate(ROUTERS_CUSTOMER.PURCHASE_ORDER_NEW)}
          >
            Create
          </Button>
        }
      >
        <CommonList
          data={purchaseList}
          columns={[
            {
              key: "so_number",
              label: "#SO",
              width: 80,
            },
            {
              key: "name",
              label: "Name",
            },
            {
              key: "total",
              label: "Total",
              width: 140,
              render: (row) => (
                <Typography>
                  {row.total ? showCurrency(row.total) : "-"}
                </Typography>
              ),
            },
            {
              key: "status",
              label: "Status",
              width: 150,
              render: (row) => <ChipStatus status={row.status} />,
            },
            {
              key: "created_at",
              label: "Created At",
              width: 120,
              render: (row) => (
                <Typography>{formatDate(row.created_at)}</Typography>
              ),
            },
          ]}
          searchKeys={["so_number", "name", "note"]}
          sortKeys={["created_at", "so_number", "name", "total"]}
          filterStatus={{
            statusKey: "status",
            data: [
              { label: "Active", value: "Active" },
              { label: "Inactive", value: "Inactive" },
              { label: "Draft", value: "Draft" },
            ],
          }}
          onRowClick={(row) =>
            navigate(
              `${ROUTERS_CUSTOMER.PURCHASE_ORDER_DETAIL.replace(
                ":id",
                row.id as any
              )}`
            )
          }
        />
      </PageWrapper>
    </Box>
  );
}

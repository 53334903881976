import { AsyncThunk } from "@reduxjs/toolkit";

export const addListBuilderCase = ({
  builder,
  fetchAction,
  stateKey,
}: {
  builder: any;
  fetchAction: AsyncThunk<any, any, any>;
  stateKey: string;
}) => {
  builder.addCase(fetchAction.fulfilled, (state: any, action: any) => {
    state[stateKey] = action.payload?.response?.apiStatus
      ? action.payload?.response?.data || []
      : [];
    state[stateKey].finishLoading = true;
  });
};

import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import { TProductItem } from "types/product";

import { unwrapResult } from "@reduxjs/toolkit";
import BaseCurrencyInput from "components/BaseCurrencyInput";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import BaseNumberInput from "components/BaseNumberInput";
import BaseSelect from "components/BaseSelect";
import { getItemInStock } from "redux-store/reducers/product";
import { ICustomerDropdown } from "types/customer-portal-so";
import { ISalesOrder, ISalesOrderItem } from "types/sales-order";
import { showCurrency } from "utils/common";
import notification from "utils/notification";

type TBasicModal = {
  isOpen: boolean;
  itemSelected?: ISalesOrderItem;
  onCloseModal: () => void;
  onCreateItem: (item: ISalesOrderItem) => void;
  customer?: ICustomerDropdown;
  items?: ISalesOrderItem[];
  salesOrderDetail?: ISalesOrder;
};

export default function ModalAddNewSOItem({
  isOpen,
  itemSelected,
  onCloseModal,
  onCreateItem,
  customer,
  items,
  salesOrderDetail,
}: TBasicModal) {
  const dispatch = useAppDispatch();
  const productItems =
    useAppSelector((state) => state.product.productItems) || [];

  const [lineItemId, setLineItemId] = useState("");
  const [itemId, setItemId] = useState("");

  const [quantity, setQuantity] = useState(1);
  const [discount, setDiscount] = useState(0);
  const [percent, setPercent] = useState(0);
  const [cost, setCost] = useState("");
  const [note, setNote] = useState("");
  const [inStockNumber, setInStockNumber] = useState(0);

  const [loadStockNumber, setLoadStockNumber] = useState(false);


  const options =
    productItems?.map?.((item: TProductItem) => ({
      label: item.code,
      value: item.id,
    })) || [];

  const productItemSelected = productItems.find?.(
    (item) => item.id === +itemId
  );

  const onResetState = () => {
    setLineItemId("");
    setItemId("");
    setQuantity(1);
    setDiscount(0);
    setPercent(0);
    setCost("");
    setNote("");
  };

  const onClose = () => {
    onResetState();
    onCloseModal();
  };

  const onCreate = () => {
    if (!itemId || !quantity || Number(discount) > Number(cost) * Number(quantity)) return notification.error("Data Error");

    onCreateItem({
      id: lineItemId,
      item_id: itemId ? Number(itemId) : 0,
      quantity: Number(quantity),
      cost: Number(cost),
      percent: Number(percent),
      discount: Number(discount),
      note,
      item: productItemSelected as any,
    });

    onResetState();
  };


  useEffect(() => {
    if (itemSelected) {
      setLineItemId(itemSelected?.id);
      setItemId(itemSelected?.item?.id?.toString() || "");
      setQuantity(itemSelected.quantity || 1);
      setDiscount(itemSelected.discount || 0);
      setCost(itemSelected.cost?.toString() || "");
      setNote(itemSelected?.note || "");
      setPercent(itemSelected.percent || 0)
    } else {
      setPercent(0)
    }
  }, [itemSelected]);

  useEffect(() => {
    const t = Number(cost) * Number(quantity)
    setDiscount((percent || 0) ? t * percent / 100 : 0);
  }, [percent, quantity]);

  useEffect(() => {
    if (productItemSelected) {
      const existItem = items?.find(
        (item) => item.item?.id === +productItemSelected.id
      );
      if (existItem) {
        setLineItemId(existItem?.id);
        setQuantity(existItem.quantity || 1);
        setDiscount(existItem.discount || 0);
        setPercent(existItem.percent || 0);
        setNote(existItem?.note || "");
      } else {
        setLineItemId("");
        setQuantity(1);
        setDiscount(0);
        setPercent(0);
        setNote("");
      }
      if (customer?.price_level === "Special") {
        setCost(productItemSelected?.special_price?.toString() || "");
      } else if (customer?.price_level === "Regular") {
        setCost(productItemSelected?.regular_price?.toString() || "");
      } else {
        setCost(productItemSelected?.price?.toString() || "");
      }
      setLoadStockNumber(true)
      dispatch(
        getItemInStock({
          item_id: productItemSelected?.id,
          so_id: salesOrderDetail?.id,
        })
      )
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.apiStatus) setInStockNumber(response.data.count);
          setLoadStockNumber(false)
        });
    }
  }, [productItemSelected]);

  return (
    <BaseModal
      title="Select Item"
      isOpen={isOpen}
      onClose={onClose}
      width={600}
      actions={
        <Button onClick={onCreate}
          disabled={!quantity || loadStockNumber}
        >
          {itemSelected?.id ? "Update" : "Add"}
        </Button>
      }
    >
      <Grid container spacing={2}>
        <Grid sm={12}>
          <FormControl>
            <BaseSelect
              label={"Item"}
              options={options}
              value={itemId}
              onChange={setItemId}
            />
          </FormControl>
        </Grid>
        <Grid
          sm={3}
          sx={{
            alignItems: "center",
            display: "flex"
          }}
        >
          <FormControl>
            <BaseNumberInput
              sx={{
                width: 100,
              }}
              min={1}
              label="Quantity"
              value={quantity}
              onChange={(e) => setQuantity(e)}
            />
          </FormControl>

        </Grid>
        <Grid
          sm={3}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControl>
            <BaseCurrencyInput
              max={100}
              label="Discount %"
              is_number={true}
              value={percent}
              onChange={(e) => setPercent(Number(e))}
            />
          </FormControl>
        </Grid>
        <Grid
          sm={3}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControl>
            <Typography><small>Unit Cost</small> <br /> <b>{showCurrency(+cost)}</b></Typography>
          </FormControl>
        </Grid>
        <Grid
          sm={3}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <FormControl>
            <Typography><small>Total</small> <br /> <strong> {showCurrency((+quantity * +cost) - discount)}</strong></Typography>
          </FormControl>
        </Grid>
        <Grid sm={12}>
          <FormControl>
            <BaseInput
              label="Note"
              multiline
              minRows={2}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </FormControl>
        </Grid>

        {itemId && <Typography
          level="body-sm"
          sx={{ ml: 1, flex: 1 }}
          color={
            inStockNumber >= Number(quantity || 0) ? "success" : "neutral"
          }
        >
          {inStockNumber} available
        </Typography>}
      </Grid>
    </BaseModal >
  );
}

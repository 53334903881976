import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";

import {
  acceptFreightQuoteApi,
  cancelFreightQuoteApi,
  createCustomerFreightQuoteApi,
  getDetailCustomerFreightQuoteApi,
  getListCustomerFreightQuoteApi,
} from "services/customer-portal/customer-portal-freight-quote.service";

import { IFreightQuote } from "types/freight-quote";

const TYPE = "CUSTOMER_PORTAL_FREIGHT_QUOTE";

export interface ICustomerPortalFreightQuoteState {
  list?: IFreightQuote[];
}

const initialState: ICustomerPortalFreightQuoteState = {
  list: [],
};

export const createCustomerFreightQuote = createCallApiAsyncAction(
  TYPE + "/createCustomerPurchase",
  createCustomerFreightQuoteApi
);

export const getListCustomerFreightQuote = createCallApiAsyncAction(
  TYPE + "/getListCustomerFreightQuote",
  getListCustomerFreightQuoteApi
);

export const getDetailCustomerFreightQuote = createCallApiAsyncAction(
  TYPE + "/getDetailCustomerFreightQuote",
  getDetailCustomerFreightQuoteApi
);

export const cancelFreightQuote = createCallApiAsyncAction(
  TYPE + "/cancelFreightQuote",
  cancelFreightQuoteApi
);

export const acceptFreightQuote = createCallApiAsyncAction(
  TYPE + "/acceptFreightQuote",
  acceptFreightQuoteApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListCustomerFreightQuote,
      stateKey: "list",
    });
  },
  reducers: {},
});

export default slice.reducer;

import { IFreightQuote } from "types/freight-quote";
import { Request } from "utils/request";

export const getListFreightQuoteApi = () => {
  return Request.call<{ data: IFreightQuote[] }>({
    url: "/freight-quote/list",
    method: "GET",
  });
};

export const getDetailFreightQuoteApi = (payload: { id: any }) => {
  return Request.call<{ data: IFreightQuote }>({
    url: "/freight-quote/detail/" + payload.id,
    method: "GET",
  });
};

export const updateFreightQuoteApi = (payload: {
  shipping_cost: number;
  id: any;
}) => {
  return Request.call<{ data: IFreightQuote }>({
    url: "/freight-quote/update/",
    method: "PUT",
    data: payload,
  });
};

export const updateCarrierFreightQuoteApi = (payload: {
  carrier_code: string;
  carrier_name: string;
  carrier_cost: number;
  id: any;
}) => {
  return Request.call<{ data: IFreightQuote }>({
    url: "/freight-quote/carrier/update",
    method: "PUT",
    data: payload,
  });
};

export const createFreightQuoteApi = (payload: {
  so_id: any;
  package_id: any;
}) => {
  return Request.call<{ data: IFreightQuote }>({
    url: "/freight-quote/package/create",
    method: "PUT",
    data: payload,
  });
};

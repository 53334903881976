import { Box, Button, FormControl, Grid, Radio, RadioGroup, Switch } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { DELIVERY_METHOD, SHIPPING_SERVICES } from "app-constants";
import BaseCurrencyInput from "components/BaseCurrencyInput";
import BaseDatePicker from "components/BaseDatePicker";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import BaseSelect from "components/BaseSelect";
import CardWithLabel from "components/CardWithLabel";
import SelectOrAddNew from "components/SelectOrAddNew";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import { getShipCarrier, updateSOShipSection } from "redux-store/reducers/sales-order";
import { ISalesOrder } from "types/sales-order";
import notification from "utils/notification";

type TEditShipSection = {
  isOpen: boolean;
  onUpdateSuccess: () => void;
  onClose: () => void;
  so: ISalesOrder;
  inPPSPage?: boolean;
};

const EditShipSection = ({
  isOpen,
  onUpdateSuccess,
  onClose,
  so,
  inPPSPage,
}: TEditShipSection) => {
  const [shipCarrierList, setShipCarrierList] = useState<any[]>([]);

  const [salesOrderDetail, setSalesOrderDetail] = useState<ISalesOrder>({ ...so });
  const dispatch = useAppDispatch();

  const onCreate = () => {
    const newSaveObj: any = {
      is_pallet: false,
      pallet_cost: 0,
      shipping_cost: 0,
      tracking_number: "",
      ship_date: salesOrderDetail.ship_date,
      delivery_method: salesOrderDetail.delivery_method,
      delivery_company: "",
      shipping_service: "",
      note: salesOrderDetail.note,
    }

    if (["UPS", "LTL"].includes(salesOrderDetail.delivery_method)) {
      newSaveObj.tracking_number = salesOrderDetail.tracking_number
      newSaveObj.shipping_cost = salesOrderDetail.shipping_cost
      if (salesOrderDetail.delivery_method === "UPS") {
        newSaveObj.shipping_service = salesOrderDetail.shipping_service
      }
      if (salesOrderDetail.delivery_method === "LTL") {
        newSaveObj.delivery_company = salesOrderDetail.delivery_company
        newSaveObj.is_pallet = salesOrderDetail.is_pallet
        newSaveObj.pallet_cost = salesOrderDetail.pallet_cost
      }
    }
    // call api
    console.log(newSaveObj)
    dispatch(
      updateSOShipSection({
        id: salesOrderDetail?.id,
        ...newSaveObj,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success("Update success");
          onClose()
          onUpdateSuccess();
        } else {
          notification.error("Update failed");
        }
      });

  };

  useEffect(() => {
    dispatch(getShipCarrier({})).then(unwrapResult).then(({ response }) => {
      if (response.apiStatus) {
        setShipCarrierList(_.map(response.data || [], v => ({ title: v.name })));
      }
    });
  }, []);

  useEffect(() => {
    setSalesOrderDetail({ ...salesOrderDetail, pallet_cost: salesOrderDetail.is_pallet ? 25 : 0 })
  }, [salesOrderDetail.is_pallet]);

  return (
    <BaseModal
      title="Edit Ship Section"
      isOpen={isOpen}
      onClose={onClose}
      width={650}
      actions={
        <Button onClick={onCreate}>
          Update
        </Button>
      }
    >
      <Grid container spacing={2}>
        <Grid sm={12}>
          <CardWithLabel label="Ship Method" style={{
            padding: "18px",
            width: "100%",
            marginTop: "-8px"
          }}>
            <RadioGroup
              aria-label="Type"
              name="type"
              defaultValue={DELIVERY_METHOD[0]}
              value={salesOrderDetail.delivery_method}
              onChange={(e) => setSalesOrderDetail({ ...salesOrderDetail, delivery_method: e.target.value as any })}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {DELIVERY_METHOD.map((item) => (
                  <Box key={item}>
                    <Radio
                      value={item}
                      label={item}
                      sx={{ flexGrow: 1 }}
                      slotProps={{
                        action: ({ checked }) => ({
                          sx: (theme) => ({
                            ...(checked && {
                              inset: -1,
                              fontWeight: 500
                            }),
                          }),
                        }),
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </RadioGroup>
          </CardWithLabel>
        </Grid>
        {/* ups vs ltl */}
        {
          ["UPS", "LTL"].includes(salesOrderDetail.delivery_method) && <>
            <Grid xs={12} sm={6}>
              {salesOrderDetail.delivery_method === "UPS" && <FormControl>
                <BaseSelect
                  label="Shipping Service"
                  options={SHIPPING_SERVICES}
                  value={salesOrderDetail.shipping_service}
                  onChange={(e) => setSalesOrderDetail({ ...salesOrderDetail, shipping_service: e })}
                />
              </FormControl>}

              {salesOrderDetail.delivery_method === "LTL" && <FormControl>
                <SelectOrAddNew
                  label="Logistics Compamy"
                  value={salesOrderDetail.delivery_company} options={shipCarrierList}
                  onChange={(e) => setSalesOrderDetail({ ...salesOrderDetail, delivery_company: e })}
                />
              </FormControl>
              }
            </Grid>
            {!inPPSPage && <Grid xs={12} sm={6}>
              <FormControl>
                <BaseCurrencyInput
                  label="Freight Cost"
                  value={salesOrderDetail.shipping_cost}
                  onChange={(e: any) => setSalesOrderDetail({ ...salesOrderDetail, shipping_cost: e })}
                />
              </FormControl>
            </Grid>}

            {!inPPSPage && <Grid xs={12} sm={6}>
              <FormControl>
                <BaseInput
                  label="Tracking number"
                  value={salesOrderDetail.tracking_number}
                  onChange={(e: any) => setSalesOrderDetail({ ...salesOrderDetail, tracking_number: e.target.value })}
                />
              </FormControl>
            </Grid>}

            {salesOrderDetail.delivery_method === "LTL" && <Grid xs={12} sm={3}>
              <FormControl>
                <Switch
                  checked={salesOrderDetail.is_pallet}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSalesOrderDetail({ ...salesOrderDetail, is_pallet: event.target.checked })}
                  endDecorator={"Pallet?"}
                />
              </FormControl>
            </Grid>}

          </>
        }

        {/* alway show */}
        {!inPPSPage && <Grid sm={12}>
          <FormControl>
            <BaseInput
              label={"Note"}
              placeholder="Write some things"
              multiline
              minRows={2}
              value={salesOrderDetail.note}
              onChange={(e) => setSalesOrderDetail({ ...salesOrderDetail, note: e.target.value as any })}
            />
          </FormControl>
        </Grid>}

        {!inPPSPage && <Grid sm={12}>
          <FormControl>
            <BaseDatePicker
              label="Shipped Date"
              value={salesOrderDetail.ship_date}
              onChange={(e) => setSalesOrderDetail({ ...salesOrderDetail, ship_date: e })}
            />
          </FormControl>
        </Grid>}
      </Grid>
    </BaseModal>

  );
};

export default EditShipSection;

import { IInvoice, IPaymentItem } from "types/invoice";
import { Request } from "utils/request";

export const listInvoiceApi = (payload: {
  customer_id?: any;
}) => {
  return Request.call<{ data: IInvoice[] }>({
    url: "/sales-order/list/invoice" + (payload.customer_id ? `?customer_id=${payload.customer_id}` : ""),
    method: "GET",
  });
};

export const getDetailInvoiceApi = (payload: { id: any }) => {
  return Request.call<{ data: IInvoice }>({
    url: "/sales-order/list/invoice/" + payload.id,
    method: "GET",
  });
};

export const createInvoicePaymentApi = (payload: IPaymentItem) => {
  return Request.call<{ data: IPaymentItem }>({
    url: "/sales-order/invoice/payment/create/",
    method: "PUT",
    data: payload,
  });
};

export const remindInvoicePaymentApi = (payload: { invoice_id: any }) => {
  return Request.call<{ data: IInvoice }>({
    url: "/sales-order/invoice/payment/reminder/",
    method: "PUT",
    data: payload,
  });
};


export const syncInvoiceQBOApi = (payload: { invoice_id: any }) => {
  return Request.call<{ data: IInvoice }>({
    url: "/sales-order/invoice/sync-qbo",
    method: "PUT",
    data: payload,
  });
};




import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";

import { Add, ModeEdit } from "@mui/icons-material";
import {
  Avatar,
  Divider,
  FormLabel,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import BaseSelect from "components/BaseSelect";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import PageWrapper from "components/PageWrapper";
import { customerUpdateProfile, fetchMe } from "redux-store/reducers/auth";
import {
  createUpdateBranch,
  listBranch,
} from "redux-store/reducers/customer-portal-so";
import { IBranch } from "types/customer";
import notification from "utils/notification";
import AddressesListPage from "../addresses";
import ContactListPage from "../contact";

const CustomerHome = () => {
  const { user } = useAppSelector((state) => state.auth);

  const [isEdit, setIsEdit] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [company, setCompany] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [bio, setBio] = useState("");
  const [photo] = useState("");
  const [branchList, setBranchList] = useState<IBranch[]>([]);
  const [newBranch, setNewBranch] = useState<IBranch | null>(null);
  const [loadingBranch, setLoadingBranch] = useState(false);

  const dispatch = useAppDispatch();

  const onResetValue = () => {
    setFirstName(user?.first_name || "");
    setLastName(user?.last_name || "");
    setPhone(user?.phone || "");
    setEmail(user?.email || "");
    setCompany(user?.company || "");
    setBio(user?.bio || "");
  };

  const onToggleEdit = () => setIsEdit(!isEdit);

  const onSave = () => {
    const data = {
      first_name: company,
      last_name: "",
      phone,
      email,
      bio,
      photo,
      company,
    };

    dispatch(customerUpdateProfile(data))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success("Saved success");
          dispatch(fetchMe({ isCustomer: true }));
        } else {
          notification.error("Saved failed");
        }
      });
    onToggleEdit();
  };

  useEffect(() => {
    if (user) {
      onResetValue();
      getBranchList();
    }
  }, [user]);

  const getBranchList = () => {
    dispatch(listBranch({}))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          setBranchList(response.data || []);
        }
      });
  };

  const handleSaveBranch = () => {
    if (newBranch) {
      setLoadingBranch(true);
      dispatch(createUpdateBranch(newBranch))
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.apiStatus) {
            notification.success("Saved success");
            setNewBranch(null);
            getBranchList();
          } else {
            notification.error("Saved failed");
          }
          setLoadingBranch(false);
        });
    }
  };

  return (
    <>
      <Box sx={{ mt: "24px" }}>
        <PageWrapper
          pageName={"Company Information"}
          pageAction={
            <Box>
              <Button
                size="sm"
                sx={{ ml: 1 }}
                onClick={isEdit ? onSave : onToggleEdit}
              >
                Save
              </Button>
            </Box>
          }
        >
          <Box
            sx={{
              display: "flex",
              my: 1,
              gap: 2,
              flexDirection: {
                xs: "column",
                sm: "row",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <AspectRatio
                ratio="1"
                sx={{ width: 120, height: 120, borderRadius: "100%" }}
              >
                <Avatar variant="outlined" size="sm" />
              </AspectRatio>
            </Box>
            <Box sx={{ py: 1 }}>
              <Grid container spacing={3}>
                <Grid sm={12} xs={12}>
                  <FormControl>
                    <BaseInput
                      size="small"
                      label="Company"
                      value={company}
                      onChange={(e) => setCompany(e.target.value)}
                      InputProps={{
                        readOnly: !isEdit,
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid sm={6} xs={12}>
                  <FormControl>
                    <BaseInput
                      size="small"
                      label="Phone number"
                      InputProps={{
                        readOnly: !isEdit,
                      }}
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid sm={6} xs={12}>
                  <FormControl>
                    <BaseInput
                      size="small"
                      type="email"
                      label="Email"
                      InputProps={{
                        readOnly: !isEdit,
                      }}
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {!user?.parent_customer_id ? (
            <>
              <Divider sx={{ mt: 2 }} />
              <Grid container>
                <Grid xs={12} sm={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-end",
                      mb: 1,
                      mt: 2,
                    }}
                  >
                    <FormLabel>Branchs</FormLabel>
                    <IconButton
                      size="sm"
                      onClick={() => {
                        setNewBranch({
                          company: "",
                          password: "",
                          phone: "",
                          email: "",
                        });
                      }}
                      variant="outlined"
                      color="primary"
                    >
                      <Add />
                    </IconButton>
                  </Box>

                  <CommonList
                    pageSize={100}
                    noLoading={true}
                    data={branchList}
                    size="sm"
                    columns={[
                      {
                        key: "company",
                        label: "Name",
                        render: (row) => (
                          <Box>
                            <Typography level="title-md">
                              {row.first_name}
                            </Typography>
                            <Typography>
                              Distributor ID: {row.cus_number}
                            </Typography>
                            <Typography>Phone: {row.phone}</Typography>
                          </Box>
                        ),
                      },
                      {
                        key: "email",
                        label: "Email",
                      },
                      {
                        key: "status",
                        label: "Status",
                        width: 90,
                        render: (row) => <ChipStatus status={row.status} />,
                      },
                      {
                        key: "actions",
                        label: "",
                        width: 50,
                        render: (item) => (
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                            }}
                          >
                            <Tooltip title="Edit" arrow>
                              <ModeEdit
                                fontSize="small"
                                sx={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setNewBranch({
                                    id: item.id,
                                    company: item.company,
                                    status: item.status,
                                    phone: item.phone,
                                    password: item.password,
                                    email: item.email,
                                  });
                                }}
                              />
                            </Tooltip>
                          </Box>
                        ),
                      },
                    ]}
                    searchKeys={[]}
                    filterStatus={{
                      statusKey: "",
                      data: [],
                    }}
                    sortKeys={["status", "phone", "email", "name"]}
                  />
                </Grid>
              </Grid>

              <BaseModal
                title={newBranch?.id ? "Edit Branch" : "New Branch"}
                isOpen={!!newBranch}
                onClose={() => setNewBranch(null)}
                actions={
                  <Button
                    loading={loadingBranch}
                    onClick={() => handleSaveBranch()}
                  >
                    Save
                  </Button>
                }
              >
                <Grid container spacing={2}>
                  <Grid sm={12} xs={12}>
                    <FormControl>
                      <BaseInput
                        label="Name"
                        value={newBranch?.company}
                        onChange={(e) =>
                          setNewBranch({
                            ...newBranch,
                            company: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid sm={12} xs={12}>
                    <FormControl>
                      <BaseInput
                        label="Phone"
                        value={newBranch?.phone}
                        onChange={(e) =>
                          setNewBranch({ ...newBranch, phone: e.target.value })
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid sm={12} xs={12}>
                    <FormControl>
                      <BaseInput
                        label="Email"
                        value={newBranch?.email}
                        onChange={(e) =>
                          setNewBranch({ ...newBranch, email: e.target.value })
                        }
                      />
                    </FormControl>
                  </Grid>

                  <Grid sm={12} xs={12}>
                    <FormControl>
                      <BaseInput
                        label="Password"
                        value={newBranch?.password}
                        onChange={(e) =>
                          setNewBranch({
                            ...newBranch,
                            password: e.target.value,
                          })
                        }
                      />
                    </FormControl>
                  </Grid>

                  {newBranch?.id ? (
                    <Grid sm={12} xs={12}>
                      <FormControl>
                        <BaseSelect
                          label="Status"
                          value={newBranch?.status}
                          options={[
                            {
                              label: "Active",
                              value: "Active",
                            },
                            {
                              label: "Inactive",
                              value: "Inactive",
                            },
                          ]}
                          onChange={(e) =>
                            setNewBranch({ ...newBranch, status: e })
                          }
                        />
                      </FormControl>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </BaseModal>
            </>
          ) : (
            ""
          )}

          <AddressesListPage />
          <Divider sx={{ mt: 2 }} />
          <ContactListPage />
        </PageWrapper>
      </Box>
    </>
  );
};

export default CustomerHome;

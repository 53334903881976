import { persistReducer } from 'redux-persist'
import { encryptTransform } from 'redux-persist-transform-encrypt'
import storage from 'redux-persist/lib/storage'

export const persistEncryptReducer = (key, reducer, whitelist) => {
  return persistReducer(
    {
      transforms: [
        encryptTransform({
          secretKey: '5TOPMAIO',
          onError: function (error) {
            console.error('encrypt error', error)
          },
        }),
      ],
      key,
      version: 1,
      storage,
      whitelist,
    },
    reducer,
  )
}

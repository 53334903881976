import Box from "@mui/joy/Box";
import FormControl from "@mui/joy/FormControl";
import Sheet, { sheetClasses } from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import _ from "lodash";

import { KeyboardArrowRight } from "@mui/icons-material";
import {
  CircularProgress,
  Grid,
  List,
  ListDivider,
  ListItem,
  ListItemButton,
  ListItemContent,
  Stack,
  Tab,
  tabClasses,
  TabList,
  Tabs
} from "@mui/joy";
import { SOStatusEnum } from "app-constants";
import { useEffect, useMemo, useState } from "react";
import BaseInput from "./BaseInput";
import { ChipStatus } from "./ChipStatus";

type Order = "asc" | "desc";

const getSortListInCurrentPage = ({
  data,
  page,
  pageSize,
  orderKey,
  order,
}: {
  data: any[];
  page: number;
  pageSize: number;
  orderKey: string;
  order: Order;
}) => {
  const sortedList = orderKey ? _.orderBy(data, [orderKey], [order]) : data;
  if (!sortedList?.length) return [];
  if (sortedList.length <= pageSize) return sortedList;
  return sortedList?.slice((page - 1) * pageSize, page * pageSize);
};

const applyFilterToList = ({
  data,
  selectedStatus,
  statusKey,
  searchText,
  searchKeys,
}: {
  data: any[];
  selectedStatus: string;
  statusKey: string;
  searchText: string;
  searchKeys: string[];
}) => {
  if (!data?.length) return [];
  return _.filter(data, (item) => {
    if (selectedStatus) {
      if (selectedStatus !== item[statusKey]) return false;
    }
    if (searchText) {
      if (
        !searchKeys.some((key) =>
          item[key]?.toLowerCase().includes(searchText.toLowerCase())
        )
      )
        return false;
    }
    return true;
  });
};

interface IProps<T> {
  data: T[];
  noLoading?: boolean;
  columns: {
    key: string;
    label: string;
    hide?: boolean;
    rawValue?: (item: T) => any;
    render?: (item: T) => React.ReactNode;
    width?: number | string;
  }[];
  searchKeys: string[];
  sortKeys: string[];
  filterStatus: {
    statusKey: string;
    data: {
      label: string;
      value: string;
    }[];
  };
  pageSize?: number;
  onRowClick?: (item: T) => void;
  size?: "sm" | "md";
  tableActions?: React.ReactNode;
  tabName: string;
  setTabName: (value: any) => void;
}
export default function PickingList<T>({
  data: _data,
  noLoading = false,
  columns,
  searchKeys,
  sortKeys,
  filterStatus,
  pageSize = 12,
  onRowClick,
  tabName,
  setTabName,
  tableActions,
}: IProps<T>) {
  const tabItems = [{
    label: "Pick",
    value: SOStatusEnum.READY_TO_PICK
  }, {
    label: "Pack",
    value: SOStatusEnum.READY_TO_PACK
  }, {
    label: "Ship",
    value: SOStatusEnum.READY_TO_SHIP
  }]


  const data = useMemo(() => {
    return _data?.map((item) => {
      const newItem = { ...item };
      columns.forEach((column) => {
        if (column.rawValue) {
          // @ts-ignore
          newItem[column.key] = column.rawValue(item);
        }
      });
      return newItem;
    });
  }, [_data, columns]);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState<{
    order: Order;
    key: string;
  }>({
    key: sortKeys[0] ?? "",
    order: "desc",
  });
  const [searchText, setSearchText] = useState("");
  const { statusKey, data: statusData } = filterStatus;
  const [selectedStatus, setSelectedStatus] = useState<string>("");
  const filteredRows = useMemo(() => {
    return applyFilterToList({
      data,
      selectedStatus,
      statusKey,
      searchText,
      searchKeys,
    });
  }, [data, selectedStatus, statusKey, searchText, searchKeys]);
  const displayRows = useMemo(() => {
    return getSortListInCurrentPage({
      data: filteredRows,
      page,
      pageSize,
      orderKey: order.key,
      order: order.order,
    });
  }, [filteredRows, page, pageSize, order.key, order.order]);

  useEffect(() => {
    setPage(1);
  }, [searchText, selectedStatus]);

  const searchTextPlaceholder = `Search by ${searchKeys
    .map((key) => columns.find((column) => column.key === key)?.label || key)
    .join(", ")}`;


  const shouldShowLoading =
    !noLoading && !(_data as any)?.finishLoading && !_data?.length;
  const loadingComponent = (
    <Box
      style={{
        textAlign: "center",
        padding: "16px 0",
      }}
    >
      <CircularProgress color="neutral" />
    </Box>
  );

  const emptyComponent = (
    <Box
      style={{
        textAlign: "center",
        padding: "16px 0",
      }}
    >
      <Typography>No data</Typography>
    </Box>
  );
  return (
    <>
      <Sheet sx={{ m: -1, p: 1 }}>
        <Tabs
          defaultValue={tabName}
          value={tabName}
          onChange={(e, value) => setTabName(value)}
          sx={{
            bgcolor: "transparent",
          }}
        >
          <TabList
            tabFlex={1}
            size="sm"
            disableUnderline
            sx={(theme) => ({
              p: 1,
              borderRadius: 16,
              width: "100%",
              mx: 'auto',
              boxShadow: theme.shadow.sm,
              [`& .${tabClasses.root}`]: {
                py: 1,
                flex: 1,
                transition: '0.3s',
                fontWeight: 'md',
                [`&:not(.${tabClasses.selected}):not(:hover)`]: {
                  opacity: 0.7,
                },
              },
            })}
          >
            {tabItems.map((item) => (
              <Tab disableIndicator value={item.value} key={item.value}>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <b>{item.label}</b>
                    {/* <Chip
                      size="sm"
                      sx={{ ml: 1 }}
                      variant="soft"
                      color={"primary"}
                    >
                      14
                    </Chip> */}
                  </Box>
                </Box>
              </Tab>
            ))}
          </TabList>
        </Tabs>

        <Grid
          container
          spacing={2}
          sx={{
            my: 1,
          }}
        >
          <Grid sm={12} xs={12}>
            <FormControl sx={{ flex: 1 }} size="sm">
              <BaseInput
                label={searchTextPlaceholder}
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            </FormControl>
          </Grid>
        </Grid>


        <List
          sx={(theme) => ({
            "& ul": {
              "--List-gap": "0px",
              bgcolor: "background.surface",
              '& > li:first-child > [role="button"]': {
                borderTopRightRadius: "var(--List-radius)",
                borderTopLeftRadius: "var(--List-radius)",
              },
              '& > li:last-child > [role="button"]': {
                borderBottomRightRadius: "var(--List-radius)",
                borderBottomLeftRadius: "var(--List-radius)",
              },
            },
            "--List-radius": "8px",
            "--List-gap": "1rem",
            "--ListDivider-gap": "0px",
            "--ListItem-paddingY": "0.5rem",
            // override global variant tokens
            [theme.getColorSchemeSelector("light")]: {
              "--joy-palette-divider": "rgba(0 0 0 / 0.08)",
            },
          })}
        >
          <ListItem nested>
            <List
              sx={{
                overflow: "auto",
                [`& .${sheetClasses.root}`]: {
                  p: 0.5,
                  lineHeight: 0,
                  borderRadius: "sm",
                },
              }}
            >
              {shouldShowLoading
                ? loadingComponent
                : displayRows?.length
                  ? displayRows?.map((row: any, index: number) => (
                    <>
                      {!!index && <ListDivider />}
                      <ListItem>
                        <ListItemButton
                          key={row.id}
                          onClick={() => onRowClick?.(row)}
                        >
                          <ListItemContent>
                            <Stack>
                              <Typography level="body-md">
                                No: <b>#{row.so_number}</b>
                              </Typography>
                              <Box
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Typography level="body-sm">
                                  Customer: {row.customer.first_name}{" "}
                                  {row.customer.last_name}
                                </Typography>
                              </Box>
                            </Stack>
                          </ListItemContent>
                          <Typography
                            textColor="text.tertiary"
                            sx={{ mr: "calc(-1 * var(--ListItem-gap))" }}
                          >
                            <ChipStatus status={row.status} size="small" />
                          </Typography>
                          <KeyboardArrowRight fontSize="small" />
                        </ListItemButton>
                      </ListItem>
                    </>
                  ))
                  : emptyComponent}
            </List>
          </ListItem>
        </List>
      </Sheet>
    </>
  );
}

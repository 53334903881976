import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Grid, IconButton } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import { useState } from "react";
import { IProductCustomField } from "types/product";

type IProps = {
  isOpen: boolean;
  customFields: IProductCustomField[];
  onChange: (value: IProductCustomField[]) => void;
  onClose: () => void;
};

const ModalUpdateCustomFields = ({
  isOpen,
  customFields,
  onChange,
  onClose,
}: IProps) => {
  const [items, setItems] = useState(customFields);

  const onSave = () => {
    onChange(items);
    onClose();
  };

  const handleAddNewOption = () => {
    const newItems = [...items];
    newItems.push({ name: "", value: "" });
    setItems(newItems);
  };
  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onClose}
      title="Custom Fields"
      actions={
        <Button
          onClick={onSave}
          disabled={items.some((item) => item.name === "" || item.value === "")}
        >
          Save
        </Button>
      }
      width={600}
    >
      <Grid container spacing={2}>
        {items.map((variant, index) => (
          <Grid sm={12} key={index}>
            <Grid container spacing={2}>
              <Grid sm={4}>
                <FormControl>
                  <BaseInput
                    label="Name"
                    value={variant.name}
                    onChange={(e) => {
                      const newItems = [...items];
                      newItems[index].name = e.target.value;
                      setItems(newItems);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid sm>
                <FormControl>
                  <BaseInput
                    label={"Value"}
                    value={variant.value}
                    onChange={(e) => {
                      const newItems = [...items];
                      newItems[index].value = e.target.value;
                      setItems(newItems);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid sx={{ display: "flex", alignItems: "center" }}>
                <IconButton
                  variant="outlined"
                  size="sm"
                  disabled={items.length <= 1}
                  onClick={() => {
                    if (items.length <= 1) return;
                    const newItems = [...items];
                    newItems.splice(index, 1);
                    setItems(newItems);
                  }}
                >
                  <RemoveIcon />
                </IconButton>
                <Box sx={{ marginLeft: 1, width: 40 }}>
                  {index === items.length - 1 ? (
                    <IconButton
                      color="primary"
                      size="sm"
                      onClick={handleAddNewOption}
                      variant="outlined"
                    >
                      <AddIcon />
                    </IconButton>
                  ) : null}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </BaseModal>
  );
};

export default ModalUpdateCustomFields;

import { extendTheme } from "@mui/joy";
import { createTheme as materialCreateTheme } from "@mui/material/styles";

const theme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          "50": "#fff7ed",
          "100": "#ffedd5",
          "200": "#fed7aa",
          "300": "#fdba74",
          "400": "#fb923c",
          "500": "#f97316",
          "600": "#ea580c",
          "700": "#c2410c",
          "800": "#9a3412",
          "900": "#7c2d12",
        },
      },
    },
    dark: {
      palette: {
        primary: {
          "50": "#fff7ed",
          "100": "#ffedd5",
          "200": "#fed7aa",
          "300": "#fdba74",
          "400": "#fb923c",
          "500": "#f97316",
          "600": "#ea580c",
          "700": "#c2410c",
          "800": "#9a3412",
          "900": "#7c2d12",
        },
      },
    },
  },
});

export const materialTheme = materialCreateTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: "#f97316",
        },
      },
    },
    dark: {
      palette: {
        primary: {
          main: "#f97316",
        },
      },
    },
  },
});

export default theme;

import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import { useNavigate, useParams } from "react-router-dom";
import notification from "utils/notification";

import { Grid } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import BaseCheckbox from "components/BaseCheckbox";
import BaseCurrencyInput from "components/BaseCurrencyInput";
import BaseDatePicker from "components/BaseDatePicker";
import BaseInput from "components/BaseInput";
import BaseNumberInput from "components/BaseNumberInput";
import PageWrapper from "components/PageWrapper";
import { DetailSkeleton } from "components/SkeletonComponents";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import {
  getDetailPromotion,
  updatePromotion,
} from "redux-store/reducers/promotion";
import ROUTERS from "routers/constants";
import { IPromotion } from "types/promotion";

export default function PromotionDetailPage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const id = useParams()?.id || detailId;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [promotionDetail, setPromotionDetail] = useState<
    IPromotion | undefined
  >();
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = () => {
    setIsLoading(true);

    dispatch(
      updatePromotion({
        ...promotionDetail,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setIsLoading(false);
        if (response.data?.id) {
          notification.success("Update promotion success");
          fetchDetail();
        } else {
          const err: any = { ...response.data }
          notification.error(err.message || "Update promotion failed");
        }
      });
  };

  const fetchDetail = () => {
    if (!id) return;
    dispatch(
      getDetailPromotion({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        const promotion = response.data;

        if (response.data?.id) {
          setPromotionDetail(promotion);
        }
      });
  };
  useEffect(() => {
    fetchDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <PageWrapper
        pageName="Detail Promotion"
        breadcrumb={[
          {
            label: "Promotion",
            link: ROUTERS.PROMOTION,
          },
          {
            label: "Detail",
          },
        ]}
        pageAction={
          <Box>
            <Button
              color="primary"
              size="sm"
              variant="plain"
              onClick={() => navigate(ROUTERS.PROMOTION)}
              disabled={isLoading}
              sx={{ mr: 1 }}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              size="sm"
              onClick={handleSave}
              loading={isLoading}
            >
              Save
            </Button>
          </Box>
        }
        skeletonLoading={!promotionDetail ? <DetailSkeleton /> : undefined}
      >
        {!!promotionDetail && (
          <Box sx={{ py: 1 }}>
            <Grid container spacing={3}>
              <Grid sm={6}>
                <FormControl>
                  <BaseInput
                    label="Code"
                    value={promotionDetail.code}
                    onChange={(e) =>
                      setPromotionDetail({
                        ...promotionDetail,
                        code: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid sm={6}>
                <FormControl>
                  <BaseInput
                    label="Name"
                    value={promotionDetail.name}
                    onChange={(e) =>
                      setPromotionDetail({
                        ...promotionDetail,
                        name: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid sm={6}>
                <FormControl>
                  <BaseDatePicker
                    label="Start Date"
                    value={promotionDetail.start_date}
                    onChange={(e) =>
                      setPromotionDetail({
                        ...promotionDetail,
                        start_date: e || "",
                      })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid sm={6}>
                <FormControl>
                  <BaseDatePicker
                    label="End Date"
                    value={promotionDetail.end_date}
                    onChange={(e) =>
                      setPromotionDetail({
                        ...promotionDetail,
                        end_date: e || "",
                      })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid sm={6}>
                <Grid container spacing={2}>
                  <Grid
                    sm={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: 56,
                    }}
                  >
                    <FormControl>
                      <BaseCheckbox
                        label="Is Percentage"
                        checked={promotionDetail.is_percentage}
                        onChange={(e) =>
                          setPromotionDetail({
                            ...promotionDetail,
                            is_percentage: e.target.checked,
                          })
                        }
                      />
                    </FormControl>
                  </Grid>
                  {promotionDetail.is_percentage ? (
                    <Grid sm>
                      <FormControl>
                        <BaseNumberInput
                          label="Discount Percentage"
                          value={promotionDetail.percentage}
                          onChange={(num) =>
                            setPromotionDetail({
                              ...promotionDetail,
                              percentage: num,
                            })
                          }
                          InputProps={{
                            endAdornment: "%",
                          }}
                          max={100}
                          min={0}
                        />
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid sm>
                      <FormControl>
                        <BaseCurrencyInput
                          label="Discount Amount"
                          value={promotionDetail.amount}
                          onChange={(num) =>
                            setPromotionDetail({
                              ...promotionDetail,
                              amount: Number(num),
                            })
                          }
                        />
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Grid>

              <Grid sm={6}>
                <Grid container spacing={2}>
                  <Grid
                    sm={2}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: 56,
                    }}
                  >
                    <FormControl>
                      <BaseCheckbox
                        label="Is Limited"
                        checked={promotionDetail.count !== -1}
                        onChange={(e) =>
                          setPromotionDetail({
                            ...promotionDetail,
                            count: e.target.checked ? 0 : -1,
                          })
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid sm>
                    {promotionDetail.count !== -1 && (
                      <FormControl>
                        <BaseNumberInput
                          label="Count"
                          value={promotionDetail.count}
                          onChange={(num) =>
                            setPromotionDetail({
                              ...promotionDetail,
                              count: num,
                            })
                          }
                        />
                      </FormControl>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
      </PageWrapper>
    </>
  );
}


import PageWrapper from "components/PageWrapper";
import { useEffect } from "react";
import { useAppDispatch } from "redux-store";
import { getListEmployee } from "redux-store/reducers/employee";
import { getListApprovalConfig } from "redux-store/reducers/settings";
import ApprovalConfigs from "./components/ApprovalConfigs";

export default function SettingsPage() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getListApprovalConfig({}))
    dispatch(getListEmployee({}))
  }, [])
  return (
    <>
      <PageWrapper
        pageName={'Settings'}
        breadcrumb={[
          {
            label: "Settings",
          },
        ]}
        // skeletonLoading={!settingsDetail ? <DetailSkeleton /> : undefined}

        tabs={[
          {
            label: "General",
            content: <div>Up coming</div>
          },
          {
            label: "Approval",
            content: <ApprovalConfigs />
          },
          {
            label: "Email Config",
            content: <div>Up coming</div>
          },

        ]}
      >
      </PageWrapper>
    </>
  );
}

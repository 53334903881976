import { ModeEdit } from "@mui/icons-material";
import { Card, Grid, Stack, Typography } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { RETURN_OPTIONS } from "app-constants";
import InlineEditModal from "components/InlineEditModal";
import { useAppDispatch } from "redux-store";
import { updateReturn } from "redux-store/reducers/return";
import { IReturn } from "types/return";
import { showCurrency } from "utils/common";
import notification from "utils/notification";
import ReturnTableItems from "./ReturnTableItems";

export default function DetailContent({
  returnDetail,
  onUpdateSuccess,
}: {
  returnDetail: IReturn;
  onUpdateSuccess: () => void;
}) {
  const customer = returnDetail.return_items?.[0].customer;
  const soNumber = returnDetail.return_items?.[0].so_number;
  const dispatch = useAppDispatch();

  const handleUpdate = (newData: any, closeEdit: any) => {
    dispatch(
      updateReturn({
        id: returnDetail?.id,
        ...newData,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success("Update success");
          onUpdateSuccess();
          closeEdit();
        } else {
          notification.error("Update failed");
        }
      });
  };

  const showActions =
    returnDetail?.status && !["Completed"].includes(returnDetail?.status);

  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        <Card
          color="neutral"
          invertedColors={false}
          orientation="vertical"
          size="sm"
          variant="outlined"
        >
          <Grid container spacing={2}>
            <Grid sm xs={12}>
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {customer?.first_name}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                <b>ID:</b> {customer?.cus_number}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                Has Serial Number:{" "}
                {returnDetail.is_serial_number ? "Yes" : "No"}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                SO#: {soNumber || "-"}
              </Typography>
            </Grid>
            <Grid sm xs={12}>
              <Typography
                sx={{ fontSize: 14, display: "flex", alignItems: "center" }}
              >
                Refund Amount:{" "}
                {returnDetail.refund_amount
                  ? showCurrency(returnDetail.refund_amount)
                  : "-"}{" "}
                {showActions && (
                  <InlineEditModal
                    label="Return Amount"
                    placement="left-start"
                    saveText="Save"
                    type="currency"
                    onSave={(newData, closeEdit) => {
                      handleUpdate(
                        {
                          refund_amount: newData,
                        },
                        closeEdit
                      );
                    }}
                  >
                    <ModeEdit fontSize="small" sx={{ top: 5 }} />
                  </InlineEditModal>
                )}
              </Typography>
              <Typography
                sx={{ fontSize: 14, display: "flex", alignItems: "center" }}
              >
                Return Reason: {returnDetail.return_reason || "-"}{" "}
                {showActions && (
                  <InlineEditModal
                    label="Return Reason"
                    placement="left-start"
                    width={200}
                    saveText="Save"
                    type="select"
                    options={RETURN_OPTIONS}
                    onSave={(newData, closeEdit) => {
                      handleUpdate(
                        {
                          return_reason: newData,
                        },
                        closeEdit
                      );
                    }}
                  >
                    <ModeEdit fontSize="small" sx={{ top: 5 }} />
                  </InlineEditModal>
                )}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Stack>

      {returnDetail?.return_items && (
        <ReturnTableItems
          listItems={returnDetail.return_items}
          hasSerialNumber={returnDetail.is_serial_number}
        />
      )}
    </Stack>
  );
}

import { CheckCircleOutline } from "@mui/icons-material";
import { useTheme } from "@mui/joy";
import { Chip } from "@mui/material";
import { SOStatusEnum } from "app-constants";

export const ChipStatus = ({
  status,
  size = "small",
  color,
  backgroundColor,
  onClick,
}: {
  status: string | any;
  size?: "small" | "medium";
  color?: string;
  backgroundColor?: string;
  onClick?: () => void;
}) => {
  const theme = useTheme();
  const colorMap: any = {
    Rejected: theme.palette.danger,
    Approved: theme.palette.success,
    Pending: theme.palette.warning,
    "Ready to Pick": theme.palette.success,
    "Ready to Ship": theme.palette.success,
    [SOStatusEnum.READY_TO_PACK]: theme.palette.success,
    Delivered: theme.palette.success,
    Completed: theme.palette.success,
    Started: theme.palette.success,
    Shipped: theme.palette.success,
    Draft: theme.palette.neutral,
    Deleted: theme.palette.neutral,
    Awaiting: theme.palette.neutral,
    "Pending Approval": theme.palette.warning,
    "Awaiting Customer": theme.palette.warning,
    "Customer Approved": theme.palette.success,
    "Customer Rejected": theme.palette.warning,
    "Reviewing Cost": theme.palette.neutral,
    "In Stock": theme.palette.success,
    "On Hold": theme.palette.warning,

    "In Transit": theme.palette.neutral,

    "Awaiting Manufacturing": theme.palette.warning,

    Cancelled: theme.palette.neutral,

    Paid: theme.palette.success,
    "Partially Paid": theme.palette.warning,
    Unpaid: theme.palette.danger,
    "Manager Approved": theme.palette.success,
    "Processor Approved": theme.palette.success,
    "Manufacturing Approved": theme.palette.success,
    "Accounting Approved": theme.palette.success,
    "Manufacturing in Process": theme.palette.warning,
    "Awaiting for Ship": theme.palette.danger,
    "Shipping to Warehouse": theme.palette.success,
    "Fully Received": theme.palette.success,
    "Partially Received": theme.palette.warning,
    "Partially Shipped": theme.palette.warning,
    "Pending Validation": theme.palette.neutral,

    Manufacturing: theme.palette.warning,

    Packing: theme.palette.warning,
    Picking: theme.palette.warning,
    Picked: theme.palette.success,
    Packed: theme.palette.success,

    Active: theme.palette.success,

    Inactive: theme.palette.neutral,

    Used: theme.palette.warning,
    Submitted: theme.palette.neutral,
    "Cost Provided": theme.palette.success,
    Received: theme.palette.success,
    Created: theme.palette.neutral,

    // 'xxxx': {
    //     500: color,
    //     300: backgroundColor,
    // }
  };
  const selectedColor = colorMap[status] || {
    500: color,
    100: backgroundColor,
  };
  return (
    <Chip
      onClick={onClick}
      sx={{
        color: color || selectedColor?.[500],
        backgroundColor: "transparent",
        border: `1px solid ${color || selectedColor?.[300]}`,
      }}
      size={size}
      label={status}
    />
  );
};

export const ChipBackOrder = () => {
  return (
    <Chip
      variant="outlined"
      color="primary"
      size="small"
      // startDecorator={<CheckCircleOutline />}
      icon={<CheckCircleOutline />}
      label="Back Order"
    />
  );
};

import Typography from "@mui/joy/Typography";
import { SOStatusEnum } from "app-constants";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import CustomerView from "components/CustomerView";
import PageWrapper from "components/PageWrapper";
import { ViewDetailModalWrapper } from "components/ViewDetailModal";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { getListSalesOrder } from "redux-store/reducers/sales-order";
import ROUTERS from "routers/constants";
import { formatDate, showCurrency } from "utils/common";

export default function SalesOrderListBackorderPage() {
  const salesOrderList = useAppSelector((state) => state.salesOrder.list);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleGetListSalesOrder = () => {
    dispatch(getListSalesOrder({ backorder: "yes" }));
  };
  useEffect(() => {
    handleGetListSalesOrder();
  }, []);
  return (
    <>
      <PageWrapper
        pageName="Backorders"
        breadcrumb={[
          {
            label: "Backorder",
          },
        ]}
      >
        <CommonList
          data={salesOrderList}
          columns={[
            {
              key: "so_number",
              label: "#",
              width: 80,
              render: (row) => <b>{row.so_number}</b>,
            },
            {
              key: "customerName",
              label: "Customer",
              render: (row) => <CustomerView customer={row.customer as any} />,
              rawValue: (row) =>
                `${row.customer?.first_name} ${row.customer?.last_name} ${row.customer?.email} ${row.customer?.cus_number}`,
            },
            {
              key: "created_at",
              label: "Created At",
              width: 130,
              render: (row) => (
                <Typography>{formatDate(row.created_at)}</Typography>
              ),
            },
            {
              key: "created_at",
              label: "Shipped Date",
              width: 140,
              render: (row) => (
                <Typography>{row.ship_date ? formatDate(row.ship_date) : '-'}</Typography>
              ),
            },
            {
              key: "total",
              label: "Total",
              width: 140,
              render: (row) => (
                <Typography>{showCurrency(row.total)}</Typography>
              ),
            },
            {
              key: "status",
              label: "Status",
              width: 200,
              render: (row) => <ChipStatus status={row.status} size="small" />,
            },

          ]}
          searchKeys={["so_number", "customerName", "total"]}
          sortKeys={["created_at", "so_number", "total"]}
          filterStatus={{
            statusKey: "status",
            data: [
              { label: "Reviewing Cost", value: "Reviewing Cost" },
              { label: SOStatusEnum.AWAITING_MANUFACTURING, value: SOStatusEnum.AWAITING_MANUFACTURING },
            ],
          }}
          onRowClick={(row) =>
            navigate(
              `${ROUTERS.SALES_ORDER_DETAIL.replace(":id", row.id as any)}`
            )
          }
        />
      </PageWrapper>
      <ViewDetailModalWrapper />
    </>
  );
}

import {
  LogoutOutlined,
  PersonOutline,
  PolicyOutlined,
  PriceChangeOutlined,
  RequestQuoteOutlined,
  ShoppingBasketOutlined,
} from "@mui/icons-material";
import { AspectRatio, IconButton, Stack } from "@mui/joy";
import Box from "@mui/joy/Box";
import CssBaseline from "@mui/joy/CssBaseline";
import Tab, { tabClasses } from "@mui/joy/Tab";
import TabList from "@mui/joy/TabList";
import Tabs from "@mui/joy/Tabs";
import Typography from "@mui/joy/Typography";
import { CssVarsProvider } from "@mui/joy/styles";
import ColorSchemeToggle from "components/ColorSchemeToggle";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { setSession } from "redux-store/reducers/auth";
import { ROUTERS_CUSTOMER } from "routers/constants";

const tabItems = [
  {
    icon: <ShoppingBasketOutlined />,
    label: "Order",
    link: ROUTERS_CUSTOMER.PURCHASE_ORDER,
  },
  {
    icon: <RequestQuoteOutlined />,
    label: "Freight Quote",
    link: ROUTERS_CUSTOMER.FREIGHT_QUOTE,
  },
  {
    icon: <PriceChangeOutlined />,
    label: "Invoice",
    link: ROUTERS_CUSTOMER.INVOICE,
  },
  // {
  //   label: "Support Case",
  //   link: ROUTERS_CUSTOMER.SUPPORT_CASE,
  // },
  {
    icon: <PersonOutline />,
    label: "Profile",
    link: ROUTERS_CUSTOMER.MY_PROFILE,
  },
  {
    icon: <PolicyOutlined />,
    label: "Policy",
    link: ROUTERS_CUSTOMER.POLICY,
  },
];

export default function JoyOrderDashboardTemplate() {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const { pathname } = useLocation();
  const handleLogout = () => {
    dispatch(setSession(null));
  };
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Box sx={{ display: "flex", minHeight: "100dvh" }}>
        <Box
          component="main"
          className="MainContent"
          sx={{
            pb: { xs: 2, sm: 2, md: 3 },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
            overflow: "auto",
          }}
        >
          <Box sx={{ flex: 1, width: "100%" }}>
            <Box
              sx={{
                position: "sticky",
                top: { sm: 0 },
                bgcolor: "background.body",
                zIndex: 10,
                borderBottomWidth: "1px",
                borderBottomStyle: "solid",
                borderBottomColor: "divider",
              }}
            >
              <Box sx={{ maxWidth: 800, m: "auto" }}>
                <Box
                  sx={{
                    px: { xs: 2, md: 6 },
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    my: 2,
                  }}
                >
                  <AspectRatio
                    ratio="1"
                    sx={{
                      width: 70,
                    }}
                  >
                    <img src="/LOGO-Web.png" alt="Abaco" />
                  </AspectRatio>

                  <Stack>
                    <Typography
                      component="h5"
                      sx={{
                        mb: 0,
                        fontSize: {
                          xs: "1.2rem",
                          sm: "1.5rem",
                        },
                      }}
                    >
                      Abaco Portal
                    </Typography>
                    <Typography
                      level="body-sm"
                      sx={{
                        mt: 0,
                      }}
                    >
                      Your Gateway to Seamless Interaction and Effortless
                      Collaboration
                    </Typography>

                    <Typography color="success">
                      Verified Distributor : <strong>{user?.cus_number}</strong>
                    </Typography>
                  </Stack>

                  <Box
                    sx={{
                      ml: "auto",
                      display: "flex",
                      gap: 1,
                    }}
                  >
                    <ColorSchemeToggle />

                    <IconButton
                      size="sm"
                      color="neutral"
                      onClick={handleLogout}
                    >
                      <LogoutOutlined fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
                <Tabs
                  defaultValue={pathname}
                  sx={{
                    bgcolor: "transparent",
                  }}
                >
                  <TabList
                    tabFlex={1}
                    size="sm"
                    disableUnderline
                    sx={{
                      px: { xs: 2, md: 6 },
                      justifyContent: {
                        xs: "space-around",
                        sm: "flex-start",
                      },
                      [`&& .${tabClasses.root}`]: {
                        fontWeight: "600",
                        flex: "initial",
                        color: "text.tertiary",
                        [`&.${tabClasses.selected}`]: {
                          bgcolor: "transparent",
                          color: "text.primary",
                          "&::after": {
                            height: "2px",
                            bgcolor: "primary.400",
                          },
                        },
                      },
                      maxWidth: {
                        xs: "100%",
                        sm: "auto",
                      },
                      overflow: "auto",
                      scrollSnapType: "x mandatory",
                      "&::-webkit-scrollbar": { display: "none" },
                    }}
                  >
                    {tabItems.map((item) => (
                      <Link to={item.link} style={{ textDecoration: "none" }}>
                        <Tab value={item.link} key={item.link}>
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: {
                                  xs: "block",
                                  sm: "none",
                                },
                              }}
                            >
                              {item.icon}
                            </Box>
                            <Box
                              sx={{
                                display: {
                                  xs: "none",
                                  sm: "block",
                                },
                              }}
                            >
                              {item.label}
                            </Box>
                          </Box>
                        </Tab>
                      </Link>
                    ))}
                  </TabList>
                </Tabs>
              </Box>
            </Box>
            <Box sx={{ px: { xs: 2, md: 6 }, maxWidth: 800, m: "auto" }}>
              <Outlet />
            </Box>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-xs" textAlign="center">
              © Abaco {new Date().getFullYear()} -{" "}
              {process.env.REACT_APP_PUBLIC_VERSION}
            </Typography>
          </Box>
        </Box>
      </Box>
    </CssVarsProvider>
  );
}

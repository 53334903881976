import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import notification from "utils/notification";

import { Grid, Switch } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { PRICE_LEVEL } from "app-constants";
import BaseInput from "components/BaseInput";
import BaseNumberInput from "components/BaseNumberInput";
import BaseSelect from "components/BaseSelect";
import ConfirmUtils from "components/ConfirmUtils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux-store";
import { deleteCustomer, updateCustomer } from "redux-store/reducers/customer";
import ROUTERS from "routers/constants";
import { ICustomer } from "types/customer";
import { getPermission } from "utils/roles";
export default function DetailContent({
  customerDetailProp,
  onUpdateSuccess,
}: {
  customerDetailProp: ICustomer;
  onUpdateSuccess: () => void;
}) {
  const dispatch = useAppDispatch();
  const [customerDetail, setCustomerDetail] =
    useState<ICustomer>(customerDetailProp);
  const [checked, setChecked] = useState<boolean>(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCustomerDetail(customerDetailProp);
    setChecked(customerDetailProp.status === "Active")
  }, [customerDetailProp]);

  const onClick = () => {
    if (!getPermission('ALLOW_UPDATE_CUSTOMER')) {
      notification.error("You don't have permission to perform this action");
      return;
    }

    if (!customerDetail) return;
    setIsLoading(true);

    dispatch(
      updateCustomer({
        id: customerDetail.id,
        first_name: customerDetail.first_name,
        last_name: customerDetail.last_name,
        email: customerDetail.email,
        phone: customerDetail.phone,
        password: customerDetail.password || "",
        due_date_number: customerDetail.due_date_number,
        price_level: customerDetail.price_level,
        status: checked ? "Active" : "Inactive",
        company: customerDetail.company,
        ups_acc: customerDetail.ups_acc,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setIsLoading(false);

        if (response.data?.id) {
          notification.success("Update customer success");
          onUpdateSuccess();
        } else {
          notification.error("Update customer failed");
        }
      });
  };

  const onClickDelete = async () => {
    if (!customerDetail) return;

    if (!(await ConfirmUtils.sure(
      "Important Confirm",
      `This action will hard delete this customer from system. Are you sure you want to perform this action?`
    ))) return;

    setIsLoading(true);

    dispatch(
      deleteCustomer({
        customer_id: customerDetail.id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setIsLoading(false);
        if (response.data?.id) {
          notification.success("Delete customer success");
          onUpdateSuccess();
          navigate(ROUTERS.CUSTOMER)
        } else {
          notification.error("This customer is in use");
        }
      });
  };

  return (
    <Grid
      container
      spacing={2}
      sx={{
        py: 2,
      }}
    >
      <Grid xs={12} sx={{ width: 120 }}>
        <Avatar
          variant="outlined"
          size="sm"
          sx={{
            height: 100,
            width: 100,
          }}
        />
      </Grid>
      <Grid container spacing={2} xs={12} sm>

        <Grid xs={12} sm={6}>
          <FormControl>
            <BaseInput
              label="Display Name *"
              placeholder="Company name or Person name"
              value={customerDetail.company}
              onChange={(e) =>
                setCustomerDetail({
                  ...customerDetail,
                  company: e.target.value,
                  first_name: e.target.value,
                  last_name: "",
                })
              }
            />
          </FormControl>
        </Grid>

        <Grid xs={6} sm={6}>
          <BaseSelect
            label={"Price Level"}
            options={PRICE_LEVEL}
            value={customerDetail.price_level}
            onChange={(e) =>
              setCustomerDetail({
                ...customerDetail,
                price_level: e,
              })
            }
          />
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <BaseInput
              label="Phone"
              value={customerDetail.phone}
              onChange={(e) =>
                setCustomerDetail({
                  ...customerDetail,
                  phone: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <BaseInput
              label="Email"
              value={customerDetail.email}
              onChange={(e) =>
                setCustomerDetail({
                  ...customerDetail,
                  email: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid xs={12} sm={6}>
          <FormControl>
            <BaseInput
              label="Password (Optional)"
              type="password"
              value={customerDetail.password}
              onChange={(e) =>
                setCustomerDetail({
                  ...customerDetail,
                  password: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>

        <Grid xs={6} sm={3}>
          <FormControl>
            <BaseNumberInput
              label="Due date after"
              value={customerDetail.due_date_number}
              onChange={(e) =>
                setCustomerDetail({
                  ...customerDetail,
                  due_date_number: e,
                })
              }
            />
          </FormControl>
        </Grid>


        <Grid xs={12} sm={3}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Switch
            checked={checked}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setChecked(event.target.checked)
            }
            color={checked ? "primary" : "neutral"}
            variant={checked ? "solid" : "outlined"}
            endDecorator={checked ? "Active" : "Inactive"}
            slotProps={{
              endDecorator: {
                sx: {
                  minWidth: 24,
                },
              },
            }}
          />
        </Grid>

        <Grid xs={12} sm={6}>
          <FormControl>
            <BaseInput
              label="UPS Account"
              value={customerDetail.ups_acc}
              onChange={(e) =>
                setCustomerDetail({
                  ...customerDetail,
                  ups_acc: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>
      </Grid>

      <Grid xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
          }}
        >
          {getPermission('ALLOW_DELETE_CUSTOMER') && <Button
            color="danger"
            size="sm"
            onClick={onClickDelete}
            sx={{ mr: 1 }}
            loading={isLoading}
          >
            Delete
          </Button>}

          <Button
            color="primary"
            size="sm"
            onClick={onClick}
            loading={isLoading}
          >
            Save
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import { persistEncryptReducer } from "redux-store/redux-utils";
import {
  createProductApi,
  createProductItemApi,
  getCustomerProductItemsApi,
  getDetailProductApi,
  getItemInStockApi,
  getListProductExportApi,
  getProductItemsApi,
  importProductApi,
  listProductApi,
} from "services/product.service";
import {
  IProductWithId,
  TCustomerProductItemList,
  TProductItemList,
} from "types/product";

const TYPE = "PRODUCT";

export interface IProductState {
  list: IProductWithId[];
  productItems: TProductItemList;
  customerProductItems: TCustomerProductItemList;
}

const initialState: IProductState = {
  list: [],
  productItems: [],
  customerProductItems: [],
};

export const createProduct = createCallApiAsyncAction(
  TYPE + "/createProduct",
  createProductApi
);

export const importProduct = createCallApiAsyncAction(
  TYPE + "/importProduct",
  importProductApi
);

export const getListProduct = createCallApiAsyncAction(
  TYPE + "/getListProduct",
  listProductApi
);

export const getListProductExport = createCallApiAsyncAction(
  TYPE + "/getListProductExport",
  getListProductExportApi
);


export const getDetailProduct = createCallApiAsyncAction(
  TYPE + "/getDetailProduct",
  getDetailProductApi
);

export const createProductItem = createCallApiAsyncAction(
  TYPE + "/createProductItem",
  createProductItemApi
);

export const getProductItems = createCallApiAsyncAction(
  TYPE + "/createProductItem",
  getProductItemsApi
);

export const getItemInStock = createCallApiAsyncAction(
  TYPE + "/getItemInStock",
  getItemInStockApi
);

export const getCustomerProductItems = createCallApiAsyncAction(
  TYPE + "/getCustomerProductItems",
  getCustomerProductItemsApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListProduct,
      stateKey: "list",
    });

    addListBuilderCase({
      builder,
      fetchAction: getProductItems,
      stateKey: "productItems",
    });

    addListBuilderCase({
      builder,
      fetchAction: getCustomerProductItems,
      stateKey: "customerProductItems",
    });
  },
  reducers: {},
});

export default persistEncryptReducer(TYPE, slice.reducer, [
  "productItems",
  "customerProductItems",
]) as typeof slice.reducer;

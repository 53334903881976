import { HelpOutlineOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Stack,
  Table,
  Typography
} from "@mui/joy";
import { Tooltip } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { POStatusEnum } from "app-constants";
import { ChipStatus } from "components/ChipStatus";
import ConfirmUtils from "components/ConfirmUtils";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  approveManufacturing,
  getApprovalsManufacturing,
} from "redux-store/reducers/manufacturing";
import { IPurchaseOrder, IPurchaseOrderApproval } from "types/purchase";
import { formatDate } from "utils/common";
import notification from "utils/notification";
import { RolesEnum } from "utils/roles";

export default function ProductionQueue({
  manufacturing,
  onApproveSuccess,
  viewMode
}: {
  manufacturing: IPurchaseOrder;
  onApproveSuccess: () => void;
  viewMode?: string;
}) {
  const dispatch = useAppDispatch();

  const [isActionLoading, setIsActionLoading] = useState("");
  const [productActionRows, setProductActionRows] = useState<
    IPurchaseOrderApproval[]
  >([
    {
      workflow: "Start Production",
      status_after_approve: "Started",
      icon: require("assets/images/pre-production.png"),
      status: POStatusEnum.MANAGER_APPROVED,
      label: "Start Production",
      roles: [
        RolesEnum.VietnamFactory,
        RolesEnum.CEO,
        RolesEnum.COO,
        RolesEnum.Director,
        RolesEnum.GeneralManager,
        RolesEnum.Administrator,
      ],
    },
    {
      workflow: "Complete Production",
      status_after_approve: "Completed",
      icon: require("assets/images/welder.png"),
      status: POStatusEnum.MANUFACTURING_IN_PROCESS,
      label: "Complete Production",
      roles: [
        RolesEnum.VietnamFactory,
        RolesEnum.CEO,
        RolesEnum.COO,
        RolesEnum.Director,
        RolesEnum.GeneralManager,
        RolesEnum.Administrator,
      ],
    },
    {
      workflow: "Complete Shipping",
      status_after_approve: "Shipped",
      icon: require("assets/images/package.png"),
      status: POStatusEnum.AWAITING_SHIP,
      label: "Complete Shipping",
      roles: [
        RolesEnum.VietnamPlanning,
        RolesEnum.CEO,
        RolesEnum.COO,
        RolesEnum.Director,
        RolesEnum.GeneralManager,
        RolesEnum.Administrator,
      ],
    },
  ]);
  const roleName = useAppSelector((state) => state.auth.user?.roleName);

  const fetchApprovalList = () => {
    if (!manufacturing.id) return;
    dispatch(
      getApprovalsManufacturing({
        po_id: manufacturing.id as any,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setProductActionRows([
          ...productActionRows.map((v) => {
            const item = response.data?.find?.(
              (v2) => v2.workflow === v.workflow
            );
            if (item) {
              return {
                ...v,
                ...item,
              };
            }
            return v;
          }),
        ]);
      });
  };
  useEffect(() => {
    if (manufacturing.id) {
      fetchApprovalList();
    }
  }, [manufacturing]);

  // useListenEmitEvent({
  //   event: AppEvent.types.PO_STATUS_CHANGE,
  //   matchCondition: (payload) =>
  //     manufacturing?.id && payload.id == manufacturing.id,
  //   handle: () => {
  //     fetchApprovalList();
  //   },
  // });

  const handleAction = async ({
    workflow,
    status_after_approve,
  }: IPurchaseOrderApproval) => {
    if (!(await ConfirmUtils.sure())) return;

    setIsActionLoading("loading");

    const saveObj: any = {
      po_id: manufacturing.id as any,
      workflow,
      status: status_after_approve,
    };

    // if (workflow === "Complete Shipping") {
    //   saveObj["eta"] = openCompleteShippingInfo?.eta;
    //   saveObj["etd"] = openCompleteShippingInfo?.etd;
    //   saveObj["shipping_cost"] = openCompleteShippingInfo?.shipping_cost;
    //   saveObj["container_number"] = openCompleteShippingInfo?.container_number;
    //   saveObj["seal_number"] = openCompleteShippingInfo?.seal_number;
    //   saveObj["custom_tax"] = openCompleteShippingInfo?.custom_tax;
    // }

    dispatch(approveManufacturing(saveObj))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.po_id) {
          notification.success("Action success");
          fetchApprovalList();
          onApproveSuccess();
        } else {
          notification.error("Action failed");
        }
      })
      .finally(() => {
        setIsActionLoading("");
      });
  };

  const {
    workflow: activeWorkflow,
    status_after_approve: activeStatusAfterApprove,
    label: labelActive,
    roles: activeRoles,
  } = productActionRows.find(
    (v) => (!v.id || !v.approval_status) && v.status === manufacturing.status
  ) || {};

  const allowAccess = activeRoles?.includes(roleName as RolesEnum) && viewMode !== "warehouse";

  return (
    <Stack spacing={1}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {allowAccess && !!activeWorkflow && (
          <>
            <Button
              sx={{ mr: 1.5 }}
              size="sm"
              variant="outlined"
              loading={!!isActionLoading}
              onClick={() => {
                handleAction({
                  workflow: activeWorkflow,
                  status_after_approve: activeStatusAfterApprove,
                });
              }}
            >
              {labelActive}
            </Button>
            <Tooltip
              arrow
              title={`By clicking the button, you play a crucial
              role in maintaining our production efficiency and compliance
              standards.`}
            >
              <HelpOutlineOutlined fontSize="small" />
            </Tooltip>
          </>
        )}
      </Box>

      <Box>
        <Table
          variant={"outlined"}
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
          }}
        >
          <thead>
            <tr>
              <th>Stage</th>
              <th style={{ width: "150px" }}>Person</th>
              <th style={{ width: "140px" }}>Date</th>
              <th style={{ width: "100px" }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {productActionRows?.map((row) => (
              <tr key={row.workflow}>
                <td>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      alt="icon"
                      style={{ height: "auto", width: "38px", padding: "2px" }}
                      src={row.icon}
                    />
                    <Typography sx={{ fontWeight: 600, ml: 2 }}>
                      {row.workflow}
                    </Typography>
                  </Box>
                </td>
                <td>
                  {!row.user?.name && "-"}
                  <Typography sx={{ fontWeight: 400 }}>
                    {row.user?.name}
                  </Typography>
                  <small>{row.user?.role?.name}</small>
                </td>

                <td>
                  {row.id && row.approval_status
                    ? formatDate(row.updated_at, true)
                    : "-"}
                </td>

                <td>
                  <ChipStatus
                    status={
                      row.id && row.approval_status
                        ? row.approval_status
                        : "Awaiting"
                    }
                    size="small"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Box>

    </Stack>
  );
}

import { HelpOutlineOutlined } from "@mui/icons-material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton, { listItemButtonClasses } from "@mui/joy/ListItemButton";
import Typography from "@mui/joy/Typography";
import { Tooltip } from "@mui/material";
import * as React from "react";
import { IInvoice } from "types/invoice";
import { formatDate, showCurrency } from "utils/common";

export default function PaymentHistoryList({
  invoiceDetail,
}: {
  invoiceDetail: IInvoice;
}) {
  const paymentHistory = invoiceDetail?.invoice?.payments || [];
  const [openIds, setOpenIds] = React.useState<string[]>([]);

  if (!paymentHistory.length) return null;

  return (
    <Box
      sx={{
        pl: "10px",
      }}
    >
      <List
        size="sm"
        sx={(theme) => ({
          "--List-insetStart": "32px",
          "--ListItem-paddingY": "0px",
          "--ListItem-paddingRight": "16px",
          "--ListItem-paddingLeft": "21px",
          "--ListItem-startActionWidth": "0px",
          "--ListItem-startActionTranslateX": "-50%",

          [`& .${listItemButtonClasses.root}`]: {
            borderLeftColor: "divider",
          },
          [`& .${listItemButtonClasses.root}.${listItemButtonClasses.selected}`]:
            {
              borderLeftColor: "currentColor",
            },
          '& [class*="startAction"]': {
            color: "var(--joy-palette-text-tertiary)",
          },
        })}
      >
        <ListItem nested>
          <ListItem
            component="div"
            startAction={
              <Tooltip title="Payment history records your past payments for sales orders, reflecting whether payments were made on time or delayed. It provides a snapshot for assessing your financial reliability in sales transactions.">
                <HelpOutlineOutlined sx={{ ml: 1 }} fontSize="small" />
              </Tooltip>
            }
          >
            <Typography
              level="body-md"
              sx={{ fontWeight: 500, display: "flex", alignItems: "center" }}
            >
              Payment History
            </Typography>
          </ListItem>
        </ListItem>
        {paymentHistory.map((payment, index) => (
          <ListItem
            key={payment.id}
            nested
            startAction={
              <IconButton
                variant="plain"
                size="sm"
                color="neutral"
                onClick={() =>
                  setOpenIds((ids) => {
                    if (ids.includes(payment.id)) {
                      return ids.filter((id) => id !== payment.id);
                    }
                    return [...ids, payment.id];
                  })
                }
              >
                <KeyboardArrowDown
                  sx={{
                    transform: openIds.includes(payment.id)
                      ? "initial"
                      : "rotate(-90deg)",
                  }}
                />
              </IconButton>
            }
          >
            <ListItem>
              <Typography
                level="inherit"
                sx={{
                  fontWeight: openIds.includes(payment.id) ? "bold" : undefined,
                  color: openIds.includes(payment.id)
                    ? "text.primary"
                    : "inherit",
                }}
              >
                Payment at {formatDate(payment.payment_date)}
              </Typography>
            </ListItem>
            {openIds.includes(payment.id) && (
              <List>
                <ListItem>
                  <ListItemButton>
                    Amount: {showCurrency(payment.amount)}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>
                    Method: {payment.payment_method || "-"}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>
                    Transaction: {payment.payment_transaction || "-"}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>
                    Bank: {payment.receive_bank || "-"}
                  </ListItemButton>
                </ListItem>
                <ListItem>
                  <ListItemButton>Note: {payment.note || "-"}</ListItemButton>
                </ListItem>
              </List>
            )}
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import { persistEncryptReducer } from "redux-store/redux-utils";
import {
  createDocumentFromSourceIdApi,
  deleteDocumentApi,
  downloadDocumentApi,
  getDocumentFromSourceIdApi,
} from "services/common.service";

export type IAppLang = "en" | "vi";

export interface ICommonState {
  lang: IAppLang;
  ready: boolean;
  loading: boolean;
  isOpenMenu: boolean;
  breadcrumb:
    | {
        label: string;
        link?: string;
      }[]
    | null;
  triggerReload?: number;
  searchFilter: {
    SO_LIST: any;
    PO_LIST: any;
  }
}

const TYPE = "COMMON";

export const getDocumentFromSourceId = createCallApiAsyncAction(
  TYPE + "/getDocumentFromSourceId",
  getDocumentFromSourceIdApi
);

export const deleteDocument = createCallApiAsyncAction(
  TYPE + "/deleteDocument",
  deleteDocumentApi
);

export const downloadDocument = createCallApiAsyncAction(
  TYPE + "/downloadDocument",
  downloadDocumentApi
);

export const createDocumentFromSourceId = createCallApiAsyncAction(
  TYPE + "/createDocumentFromSourceId",
  createDocumentFromSourceIdApi
);

const initialState: ICommonState = {
  lang: "en",
  ready: false,
  isOpenMenu: false,
  loading: false,
  breadcrumb: null,
  triggerReload: 0,
  searchFilter: {
    SO_LIST: {},
    PO_LIST: {},
  }
};

const slice = createSlice({
  name: TYPE,
  initialState,
  reducers: {
    setBreadcrumb: (
      state,
      action: PayloadAction<ICommonState["breadcrumb"]>
    ) => {
      state.breadcrumb = action.payload;
    },

    changeLang: (state, action: PayloadAction<IAppLang>) => {
      state.lang = action.payload;
    },
    changeLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setReady: (state, action: PayloadAction<boolean>) => {
      state.ready = action.payload;
    },
    setMenuState: (state, action: PayloadAction<boolean>) => {
      state.isOpenMenu = action.payload;
    },
    reloadApp: (state) => {
      state.triggerReload = Date.now();
    },
    savePageFilter: (state, action: PayloadAction<{
      name: 'SO_LIST' | 'PO_LIST',
      data: any
    }>) => {
      state.searchFilter[action.payload.name] = action.payload.data
    }
  },
});

export const {
  changeLang,
  setReady,
  changeLoading,
  setBreadcrumb,
  setMenuState,
  reloadApp,
  savePageFilter,
} = slice.actions;

export default persistEncryptReducer(TYPE, slice.reducer, [
  "isOpenMenu",
]) as typeof slice.reducer;

import { Button, Typography } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import CommonList from "components/CommonList";
import PageWrapper from "components/PageWrapper";
import _ from "lodash";
import ContainerModal from "pages/manufacturing/components/ContainerModal";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  createContainer,
  listContainer
} from "redux-store/reducers/purchase";
import { IContainer, IPurchaseOrder } from "types/purchase";
import { formatDate, showCurrency } from "utils/common";
import notification from "utils/notification";

export default function ContainerListPage() {
  const containerList = useAppSelector((state) => state.purchase.containerList);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const containerDef: IContainer = {
    eta: "",
    etd: "",
    shipping_cost: 0,
    container_number: "",
    seal_number: "",
    custom_tax: 0,
  }
  const [completeShippingInfo, setCompleteShippingInfo] =
    useState<IContainer>(containerDef);

  const [poList, setPOList] = useState<{
    [key: string]: IPurchaseOrder[];
  }>({});
  const dispatch = useAppDispatch();
  const handleGetListContainerList = () => {
    dispatch(listContainer({}));
  };

  useEffect(() => {
    handleGetListContainerList();
  }, []);

  const handleCreateContainer = (data: any) => {
    setIsLoading(true)

    dispatch(createContainer(_.pick(data, [
      'id',
      'eta',
      'etd',
      'shipping_cost',
      'container_number',
      'seal_number',
      'custom_tax',
    ])))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success("Action success");
          handleGetListContainerList();
          setIsOpen(false)
        } else {
          notification.error("Action fail");
        }
        setIsLoading(false)
      });
  };

  const close = () => {
    setIsOpen(false)
  };


  return (
    <>
      <PageWrapper
        pageName="Containers"
        pageAction={
          <Button onClick={() => {
            setCompleteShippingInfo(containerDef)
            setIsOpen(true)
          }
          }
            size="sm">
            Create
          </Button>
        }
        breadcrumb={[
          {
            label: "Containers",
          },
        ]}
      >
        <CommonList
          data={containerList}
          onRowClick={(r) => {
            setCompleteShippingInfo(r)
            setIsOpen(true)
          }}
          columns={[
            {
              key: "container_number",
              label: "Container Number",
            },
            {
              key: "seal_number",
              label: "Seal Number",
            },
            {
              key: "eta",
              label: "ETA",
              render: (row) => <Typography>{formatDate(row.eta)}</Typography>,
            },
            {
              key: "etd",
              label: "ETD",
              render: (row) => <Typography>{formatDate(row.etd)}</Typography>,
            },
            {
              key: "shipping_cost",
              label: "Cost",
              render: (row) => <Typography>{showCurrency(row.shipping_cost)}</Typography>,
            },
            {
              key: "shipping_cost",
              label: "Custom Tax",
              render: (row) => <Typography>{showCurrency(row.custom_tax)}</Typography>,
            },
          ]}
          searchKeys={[]}
          sortKeys={["id", 'container_number', 'eta', 'etd']}
        />
      </PageWrapper>

      <ContainerModal data={completeShippingInfo} isOpen={isOpen} isLoading={isLoading} onClose={close} onSave={handleCreateContainer}></ContainerModal>
    </>
  );
}

import { NotificationsOutlined, Refresh } from "@mui/icons-material";
import MenuIcon from "@mui/icons-material/Menu";
import { Badge, Box } from "@mui/joy";
import Breadcrumbs from "@mui/joy/Breadcrumbs";
import IconButton from "@mui/joy/IconButton";
import Link from "@mui/joy/Link";
import Sheet from "@mui/joy/Sheet";
import Typography from "@mui/joy/Typography";
import { toggleSidebar } from "utils/ui-uitils";

import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";

import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { reloadApp } from "redux-store/reducers/common";

export default function Header() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const breadcrumb = useAppSelector((state) => state.common.breadcrumb);
  return (
    <Sheet
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "52px",
        zIndex: 10,
        p: 2,
        gap: 1,
        borderBottom: "1px solid",
        borderColor: "background.level1",
        boxShadow: "sm",

        pl: {
          xs: "16px",
          md: "calc(var(--SideNavigation-slideIn, 0) * 240px + 16px)",
        },
        transition: "padding 0.4s",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <IconButton
          onClick={() => toggleSidebar()}
          variant="plain"
          color="neutral"
          size="sm"
        >
          <MenuIcon />
        </IconButton>

        {!!breadcrumb?.length && (
          <Box sx={{ display: "flex", alignItems: "center" }} key="breadcrumb">
            <Breadcrumbs
              size="sm"
              aria-label="breadcrumbs"
              separator={<ChevronRightRoundedIcon fontSize="small" />}
              sx={{ pl: 0 }}
            >
              {breadcrumb?.map((item, index) =>
                index === breadcrumb.length - 1 || !item.link ? (
                  <Typography
                    color="primary"
                    fontWeight={500}
                    fontSize={12}
                    key={item.label}
                  >
                    {item.label}
                  </Typography>
                ) : (
                  <Link
                    key={item.label}
                    underline="hover"
                    color="neutral"
                    fontSize={12}
                    fontWeight={500}
                    href={item.link}
                    onClick={(e) => {
                      e.preventDefault();
                      navigate(item.link || "");
                    }}
                  >
                    {item.label}
                  </Link>
                )
              )}
            </Breadcrumbs>
          </Box>
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <IconButton
          onClick={() => {
            dispatch(reloadApp());
          }}
        >
          <Refresh fontSize="small" />
        </IconButton>
        <Badge badgeContent={0} max={99} badgeInset="6px 6px 0 0 0">
          <IconButton>
            <NotificationsOutlined fontSize="small" />
          </IconButton>
        </Badge>
      </Box>
    </Sheet>
  );
}

import { Add } from "@mui/icons-material";
import { ListItemDecorator } from "@mui/joy";
import { Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
import { tabUUID } from "utils/app-events";
import BaseInput from "./BaseInput";

type TBaseSelect = {
  label?: string;
  options: {
    label: string;
    value: string | number;
    isAddNew?: boolean | undefined;
  }[];
  value: any;
  disabled?: boolean;
  onChange: (val: any) => void;
  allowAddNew?: boolean;
};

export const ADD_NEW_VALUE = "add-new" + tabUUID;

const addNewOptions: {
  label: string;
  value: string | number;
  isAddNew?: boolean | undefined;
}[] = [
    {
      isAddNew: true,
      label: "Add new address",
      value: ADD_NEW_VALUE,
    },
  ];

const BaseSelectWithAdd = ({
  label,
  options,
  value,
  onChange,
  disabled,
  allowAddNew,
}: TBaseSelect) => {
  const [localValue, setLocalValue] = useState<any>(
    options?.find((item: any) => `${item.value}` === `${value}`) || null
  );

  const handleChange = (_: React.SyntheticEvent | null, newValue: any) => {
    onChange(newValue?.value);
  };

  useEffect(() => {
    setLocalValue(
      options?.find((item: any) => `${item.value}` === `${value}`) || null
    );
  }, [value, options]);
  return (
    <Autocomplete
      defaultValue={localValue}
      value={localValue}
      options={allowAddNew ? addNewOptions.concat(options) : options}
      onChange={handleChange}
      getOptionLabel={(option) => option.label}
      getOptionKey={(option) => option.value}
      renderInput={(params) => <BaseInput {...params} label={label} />}
      disabled={!!disabled}
      renderOption={(props, option) => (
        <li {...props}>
          {option.isAddNew ? (
            <ListItemDecorator>
              <Add fontSize="small" /> <span style={{ fontSize: "13px", color: "gray" }}>{option.label}</span>
            </ListItemDecorator>
          ) : (
            option.label
          )}
        </li>
      )}
    />
  );
};

export default BaseSelectWithAdd;

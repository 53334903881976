import { Box, Grid, LinearProgress } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import CardWithLabel from "components/CardWithLabel";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import { setBreadcrumb } from "redux-store/reducers/common";
import {
  scanItemAll
} from "redux-store/reducers/warehouse";
import notification from "utils/notification";
let scanningText = "";
export default function ItemScanPage() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [displayData, setDisplayData] = useState<string>(``);
  const scanModeRef = React.createRef<HTMLDivElement>();
  useEffect(() => {
    dispatch(
      setBreadcrumb([
        {
          label: "Item Scan",
        },
      ])
    );
    scanningText = "";
  }, []);




  const handleKeyDown = (e: KeyboardEvent) => {
    if (loading) {
      scanningText = "";
      return;
    }
    // Assuming your scanner adds a special character before/after the scanned code
    // You may need to adjust this based on your scanner's behavior
    if (e.key === "Enter") {
      if (!scanningText) return;
      setTimeout(() => {
        // call api
        const objectFind: any = {
          text: scanningText,
        };

        setLoading(true);
        dispatch(scanItemAll(objectFind))
          .then(unwrapResult)
          .then(({ response }) => {
            setLoading(false);
            scanningText = "";

            setDisplayData(response.data.html || '')
            if (!response.apiStatus) {
              return notification.error(response.message);
            }
          });
      }, 0);
    } else {
      scanningText += e.key.replace(/^Shift/, "");
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", handleKeyDown);
    return () => {
      document.removeEventListener("keypress", handleKeyDown);
    };
  }, [handleKeyDown, loading]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          sm={12}
          xs={12}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              scanModeRef.current?.focus();
            }
          }}
        >
          {loading ? (
            <LinearProgress thickness={1} />
          ) : (
            <div style={{ height: "1px", width: "100%" }}></div>
          )}

          {displayData ? (
            <CardWithLabel label="Content">
              <div
                dangerouslySetInnerHTML={{
                  __html: displayData
                }}
                style={{
                  whiteSpace: 'pre'
                }}
              ></div>
            </CardWithLabel>
          ) : (
            <>Please scan a serial number.</>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

// ABACO-240627-004

import { Grid } from "@mui/joy";
import AspectRatio from "@mui/joy/AspectRatio";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import { unwrapResult } from "@reduxjs/toolkit";
import BaseInput from "components/BaseInput";
import PageWrapper from "components/PageWrapper";
import { DetailSkeleton } from "components/SkeletonComponents";
import { useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import { updatePassword } from "redux-store/reducers/employee";
import notification from "utils/notification";

export default function ProfilePage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const dispatch = useAppDispatch();
  const profileInfo = useAppSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const onSave = () => {
    if (!profileInfo) return;
    if (!password.newPassword || !password.confirmPassword) {
      notification.error("Please fill in all fields");
      return;
    }

    if (password.newPassword.length < 6) {
      notification.error("Password must be at least 6 characters");
      return;
    }

    if (password.newPassword !== password.confirmPassword) {
      notification.error("Password and confirm password not match");
      return;
    }

    setIsLoading(true);
    dispatch(
      updatePassword({
        password: password.newPassword,
        re_password: password.confirmPassword,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setIsLoading(false);

        if (response.data?.id) {
          notification.success("Update password success");
          setPassword({ newPassword: "", confirmPassword: "" });
        } else {
          notification.error("Update password failed");
        }
      });
  };

  return (
    <>
      <PageWrapper
        pageName={profileInfo?.name}
        breadcrumb={[
          {
            label: "My Profile",
          },
        ]}
        pageAction={
          <Box>
            <Button
              color="primary"
              size="sm"
              onClick={onSave}
              loading={isLoading}
            >
              Save
            </Button>
          </Box>
        }
        skeletonLoading={!profileInfo ? <DetailSkeleton /> : undefined}
      >
        {!!profileInfo && (
          <Box
            sx={{
              display: "flex",
              py: 1,
            }}
          >
            <AspectRatio
              ratio="1"
              maxHeight={200}
              sx={{ flex: 1, minWidth: 100, borderRadius: "100%", mr: 3 }}
            >
              <Avatar variant="outlined" size="sm" />
            </AspectRatio>
            <Grid container spacing={3}>
              <Grid sm={6}>
                <FormControl>
                  <BaseInput
                    disabled
                    label="Username"
                    value={profileInfo.username}
                  />
                </FormControl>
              </Grid>
              <Grid sm={6}>
                <FormControl>
                  <BaseInput
                    disabled
                    label="Role"
                    value={
                      profileInfo.roles?.length
                        ? profileInfo.roles[0]?.name
                        : ""
                    }
                  />
                </FormControl>
              </Grid>

              <Grid sm={6}>
                <FormControl>
                  <BaseInput disabled label="Email" value={profileInfo.email} />
                </FormControl>
              </Grid>
              <Grid sm={6}>
                <FormControl>
                  <BaseInput disabled label="Phone" value={profileInfo.phone} />
                </FormControl>
              </Grid>

              <Grid sm={6}>
                <FormControl>
                  <BaseInput
                    label="Password"
                    type="password"
                    value={password.newPassword}
                    onChange={(e) =>
                      setPassword({ ...password, newPassword: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid sm={6}>
                <FormControl>
                  <BaseInput
                    label="Confirm Password"
                    type="password"
                    value={password.confirmPassword}
                    onChange={(e) =>
                      setPassword({
                        ...password,
                        confirmPassword: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        )}
      </PageWrapper>
    </>
  );
}

import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";

import CommonList from "components/CommonList";
import PageWrapper from "components/PageWrapper";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { getListEmployee } from "redux-store/reducers/employee";
import ROUTERS from "routers/constants";
import { formatDate } from "utils/common";

export default function EmployeeListPage() {
  const employeeList = useAppSelector((state) => state.employee.list);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleGetListEmployee = () => {
    dispatch(getListEmployee({}));
  };
  useEffect(() => {
    handleGetListEmployee();
  }, []);
  return (
    <PageWrapper
      pageAction={
        <Button color="primary" size="sm" onClick={() => navigate(ROUTERS.EMPLOYEE_NEW)}>
          Create
        </Button>
      }
      pageName="Employees"
      breadcrumb={[
        {
          label: "Employee",
        },
      ]}
    >
      <CommonList
        data={employeeList}
        columns={[
          {
            key: "name",
            label: "Name",
            render: (row) => <b>{row.name}</b>,
          },
          {
            key: "email",
            label: "Email",
            width: 280,
          },
          {
            key: "phone",
            label: "Phone",
          },
          {
            key: "status",
            label: "Status",
          },
          {
            key: "created_at",
            label: "Created At",
            width: 123,
            render: (row) => (
              <Typography>
                {formatDate(row.created_at)}
              </Typography>
            ),
          },
        ]}
        searchKeys={["name", "email", "phone"]}
        sortKeys={["created_at", "email", "name"]}
        filterStatus={{
          statusKey: "status",
          data: [
            {
              label: "Active",
              value: "Active",
            },
            {
              label: "Inactive",
              value: "Inactive",
            },
          ],
        }}
        onRowClick={(row) =>
          navigate(`${ROUTERS.EMPLOYEE_DETAIL.replace(":id", row.id as any)}`)
        }
      />
    </PageWrapper>
  );
}

import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";

import { Box, Radio, Stack, Table } from "@mui/joy";
import { DeliveryTypeEnum } from "app-constants";
import BaseModal from "components/BaseModal";
import { useState } from "react";
import { TSalesOrderItemNotStock } from "types/customer-portal-so";

type TModalNotStock = {
  itemsOutStock: TSalesOrderItemNotStock[];
  onRemove: () => void;
  onContinue: (type: DeliveryTypeEnum) => void;
  onClose: () => void;
};

const ModalNotStock = ({
  onRemove,
  onContinue,
  onClose,
  itemsOutStock,
}: TModalNotStock) => {
  const [deliveryType, setDeliveryType] = useState(DeliveryTypeEnum.FULL);
  return (
    <BaseModal
      title="Back Order Confirmation"
      onClose={onClose}
      isOpen={true}
      actions={
        <>
          <Button color="primary" variant="plain" onClick={onRemove}>
            Remove Out Stock Item
          </Button>
          <Button sx={{ ml: 1 }} onClick={() => onContinue(deliveryType)}>
            Continue Process
          </Button>
        </>
      }
    >
      <Stack>
        <Typography sx={{ mb: 1.5 }}>
          Just a quick update on your order: one or more items are out of stock. You can choose:
        </Typography>
        <Typography level="body-sm" sx={{ mb: 1 }}>
          <Radio
            label={DeliveryTypeEnum.FULL}
            checked={deliveryType === DeliveryTypeEnum.FULL}
            onChange={() => setDeliveryType(DeliveryTypeEnum.FULL)}
            value={DeliveryTypeEnum.FULL}
            name="radio-buttons"
            slotProps={{ input: { "aria-label": DeliveryTypeEnum.FULL } }}
          /> We'll wait until everything is in stock and ship your order together.
        </Typography>

        <Typography level="body-sm" sx={{ mb: 1 }}>
          <Radio
            label={DeliveryTypeEnum.PARTIAL}
            checked={deliveryType === DeliveryTypeEnum.PARTIAL}
            onChange={() => setDeliveryType(DeliveryTypeEnum.PARTIAL)}
            value={DeliveryTypeEnum.PARTIAL}
            name="radio-buttons"
            slotProps={{ input: { "aria-label": DeliveryTypeEnum.PARTIAL } }}
          /> We'll send what's available now, and the rest will follow when ready.
        </Typography>

        <Box style={{ height: "270px", overflow: "auto" }}>
          <Table variant={"outlined"} size="sm">
            <thead>
              <tr>
                <th>Out stock item</th>
                <th
                  style={{
                    width: 80,
                  }}
                >
                  Order Qty
                </th>
                <th
                  style={{
                    width: 80,
                  }}
                >
                  In stock
                </th>
              </tr>
            </thead>
            <tbody>
              {itemsOutStock?.map((row) => (
                <tr key={row.id}>
                  <td><b>{row?.item?.code}</b></td>
                  <td>{row.quantity_order}</td>
                  <td>{row.instock}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>


      </Stack>
    </BaseModal>
  );
};

export default ModalNotStock;

import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import Typography from "@mui/joy/Typography";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux-store";

import { Grid } from "@mui/joy";
import BaseModal from "components/BaseModal";
import BaseSelect from "components/BaseSelect";

type TModalChangeWarehouse = {
  isOpen: boolean;
  itemId: string | number;
  setItemId: (value: string | number) => void;
  onCloseModal: () => void;
};

const ModalChangeWarehouse = ({
  isOpen,
  itemId,
  setItemId,
  onCloseModal,
}: TModalChangeWarehouse) => {
  const [itemSelected, setItemSelected] = useState(itemId);

  const warehouseList = useAppSelector((state) => state.purchase.warehouseList);
  const options = warehouseList.map((item) => ({
    label: item?.name,
    value: item.id,
  }));

  const warehouseSelected = warehouseList.find((item) => item.id === itemId);

  const onClickUpdate = () => {
    if (itemSelected) setItemId(itemSelected);
    onCloseModal();
  };

  useEffect(() => {
    setItemSelected(itemId);
  }, [itemId]);

  return (
    <BaseModal
      isOpen={isOpen}
      onClose={onCloseModal}
      title="Change"
      actions={<Button onClick={onClickUpdate}>Ok</Button>}
    >
      <Grid container spacing={2}>
        <Grid sm={12}>
          <FormControl>
            <BaseSelect
              label="Warehouse"
              options={options}
              value={itemId}
              onChange={setItemSelected}
            />
          </FormControl>
        </Grid>
        <Grid sm={12}>
          <Box>
            <Typography>
              <b>A</b>: {warehouseSelected?.address}
            </Typography>
            <Typography>
              {(warehouseSelected?.city || "N/A") +
                ", " +
                (warehouseSelected?.state || "N/A") +
                ", " +
                (warehouseSelected?.country || "N/A") +
                ", " +
                (warehouseSelected?.zip_code || "N/A")}
            </Typography>
            <Typography>
              <b>P</b>: {warehouseSelected?.phone}
            </Typography>
            <Typography>
              <b>M</b>: {warehouseSelected?.email}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </BaseModal>
  );
};

export default ModalChangeWarehouse;

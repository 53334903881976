import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Table from "@mui/joy/Table";
import Typography from "@mui/joy/Typography";

import { useAppSelector } from "redux-store";

import { ClearOutlined, ModeEdit } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { ISalesOrderItem } from "types/sales-order";
import {
  calcGrantTotal,
  showCurrency,
  showProductItemName,
} from "utils/common";

type TSalesOrderTableItem = {
  listItems: ISalesOrderItem[];
  onEditItem?: (item: ISalesOrderItem) => () => void;
  onRemoveItem?: (id?: number) => (event: any) => void;
  salesOrderDetail: {
    total: number;
    tax_cost: number;
    shipping_cost: number;
    discount_item: number;
    pallet_cost?: number;
  };
};

const SOTableItems = ({
  listItems,
  onEditItem,
  onRemoveItem,
  salesOrderDetail,
}: TSalesOrderTableItem) => {
  const productItems =
    useAppSelector((state) => state.product.productItems) || [];

  const getId = (item: ISalesOrderItem) => item.item_id || item?.item?.id;

  return (
    <Stack spacing={2}>
      <Table variant={"outlined"}>
        <thead>
          <tr>
            <th>Item Name</th>
            <th style={{ width: "100px", textAlign: "right" }}>Qty</th>
            <th
              style={{
                textAlign: "right",
              }}
            >
              Unit Cost
            </th>
            <th
              style={{
                textAlign: "right",
              }}
            >
              Discount
            </th>
            <th
              style={{
                textAlign: "right",
              }}
            >
              Total
            </th>
            {!!onRemoveItem && <th style={{ width: "65px" }}></th>}
          </tr>
        </thead>
        <tbody>
          {listItems.map((item) => {
            const product = productItems.find?.(
              (product) => product.id === getId(item)
            );

            return (
              <tr key={getId(item)}>
                <td>{showProductItemName(product, true)}</td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {item.quantity || "-"}
                </td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {showCurrency(item.cost || 0) || "-"}
                </td>

                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {showCurrency(item.discount || 0) || "-"}
                </td>
                <td
                  style={{
                    textAlign: "right",
                  }}
                >
                  {showCurrency((item?.quantity || 0) * (item?.cost || 0) - Number(item.discount)) ||
                    "-"}
                </td>
                {!!onRemoveItem && (
                  <td>
                    <Box
                      sx={{
                        display: "flex",
                        gap: 1,
                      }}
                    >
                      <Tooltip title="Edit" arrow>
                        <ModeEdit
                          sx={{ cursor: "pointer" }}
                          fontSize="small"
                          onClick={onEditItem?.(item)}
                        />
                      </Tooltip>
                      <Tooltip title="Remove" arrow>
                        <ClearOutlined
                          sx={{ cursor: "pointer" }}
                          fontSize="small"
                          onClick={onRemoveItem?.(item.item?.id)}
                        />
                      </Tooltip>
                    </Box>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </Table>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flex: 1 }}></Box>
        <Box mr={6}>
          <Typography fontSize="sm" fontWeight={600} textAlign="right">
            Subtotal
          </Typography>
          <Typography fontSize="sm" textAlign="right">
            Tax
          </Typography>
          <Typography fontSize="sm" textAlign="right">
            Shipping
          </Typography>
          <Typography fontSize="sm" textAlign="right">
            Pallet charge
          </Typography>
          <Typography fontSize="sm" textAlign="right">
            Total discount
          </Typography>
          <Typography fontSize="sm" fontWeight={600} textAlign="right">
            Total
          </Typography>
        </Box>
        <Box>
          <Typography fontSize="sm" textAlign="right">
            {showCurrency(salesOrderDetail?.total)}
          </Typography>
          <Typography fontSize="sm" textAlign="right">
            {showCurrency(salesOrderDetail?.tax_cost)}
          </Typography>
          <Typography fontSize="sm" textAlign="right">
            {showCurrency(salesOrderDetail?.shipping_cost)}
          </Typography>
          <Typography fontSize="sm" textAlign="right">
            {showCurrency(salesOrderDetail?.pallet_cost)}
          </Typography>
          <Typography fontSize="sm" textAlign="right">
            {showCurrency(salesOrderDetail?.discount_item)}
          </Typography>

          <Typography fontSize="sm" fontWeight={600} textAlign="right">
            {showCurrency(calcGrantTotal(salesOrderDetail))}
          </Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default SOTableItems;

import axios from "axios";
import slugify from "slugify";
import { IDocument, IProgressData } from "types/common";
import { Request } from "utils/request";

export const getDocumentFromSourceIdApi = (payload: { source_id: any }) => {
  return Request.call<{ data: IDocument[] }>({
    url: "/user/file/list",
    method: "PUT",
    data: {
      source_id: payload.source_id?.split("_")[1],
      type: payload.source_id?.split("_")[0],
    },
  });
};

export const deleteDocumentApi = (payload: { id: any }) => {
  return Request.call<{ data: IDocument[] }>({
    url: "/user/file/delete",
    method: "PUT",
    data: payload,
  });
};

export const downloadDocumentApi = (payload: { url: any }) => {
  return Request.call<{ data: string }>({
    url: "/user/file/download",
    method: "PUT",
    data: payload,
  });
};

const signFileApi = (payload: { key: string; content_type: string }) => {
  return Request.call<{ data: string }>({
    url: "/user/r2",
    method: "PUT",
    data: payload,
  });
};

const uploadFileBinary = async (
  url: string,
  file: File,
  progressCb: (p: number) => void
) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = () => {
      let arrayBuffer = reader.result;
      if (arrayBuffer) {
        let binary = new Uint8Array(arrayBuffer as ArrayBuffer);
        axios
          .request({
            method: "put",
            maxBodyLength: Infinity,
            url,
            headers: {
              "Content-Type": file.type,
            },
            data: binary,
            onUploadProgress: (progressEvent) => {
              if (progressEvent.total) {
                const percentCompleted = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total
                );
                progressCb(percentCompleted);
              }
            },
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }
    };
    reader.onerror = (error) => reject(error);
  });
};

type IProgressCallback = (data: IProgressData[], finish?: boolean) => void;
export const createDocumentFromSourceIdApi = async (payload: {
  source_id: any;
  files: File[];
  progressCallback?: IProgressCallback;
}) => {
  const progressRs: IProgressData[] = payload.files.map((file, idx) => ({
    fileIdx: idx,
    fileName: file.name,
    progress: 0,
    url: "",
    isLoading: false,
    isError: false,
    fileSize: file.size,
  }));

  payload.progressCallback?.(progressRs);

  for (let i = 0; i < payload.files.length; i++) {
    const file = payload.files[i];
    const progressData = progressRs[i];
    progressData.isLoading = true;

    const fileSignRs = await signFileApi({
      key: slugify(file.name, {
        remove: /[*_+~.()'"!:@]/g,
      }),
      content_type: file.type,
    });
    if (fileSignRs.apiStatus) {
      try {
        await uploadFileBinary(fileSignRs.data, file, (p) => {
          progressData.progress = Math.min(p, 95);
          payload.progressCallback?.(progressRs);
        });

        progressData.url = fileSignRs.data?.split("?")[0];

        const saveFileRs = await Request.call<IDocument>({
          url: "/user/file",
          method: "PUT",
          data: {
            url: progressData.url,
            file_name: file.name,
            source_id: payload.source_id?.split("_")[1],
            type: payload.source_id?.split("_")[0],
            file_type: file.type,
            size: file.size,
          },
        });

        if (saveFileRs.apiStatus) {
          progressData.isLoading = false;
          progressData.progress = 100;
          payload.progressCallback?.(progressRs);
        } else {
          progressData.isError = true;
          progressData.isLoading = false;
          payload.progressCallback?.(progressRs);
        }
      } catch (error) {
        progressData.isError = true;
        progressData.isLoading = false;
        payload.progressCallback?.(progressRs);
      }
    }
  }
  payload.progressCallback?.(progressRs, true);
};

import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  addOldSerialItemApi,
  getDetailInventoryItemApi,
  listInventoryItemApi,
  reStockItemApi,
  relocateItemApi,
  removeStockItemApi,
  updateInventoryItemApi,
  updateInventoryItemPriceApi,
} from "services/inventory.service";
import { IInventoryItem } from "types/inventory";

const TYPE = "INVENTORY";

export interface IInventoryState {
  allItems: IInventoryItem[];
  listItems: IInventoryItem[];
  listParts: IInventoryItem[];
  listFset: IInventoryItem[];
  listRset: IInventoryItem[];
  totalMoney: number;
}

const initialState: IInventoryState = {
  allItems: [],
  listItems: [],
  listParts: [],
  listFset: [],
  listRset: [],
  totalMoney: 0,
};

export const getListInventoryItem = createCallApiAsyncAction(
  TYPE + "/getListInventoryItem",
  listInventoryItemApi
);

export const getDetailInventoryItem = createCallApiAsyncAction(
  TYPE + "/getDetailInventoryItem",
  getDetailInventoryItemApi
);

export const relocateItem = createCallApiAsyncAction(
  TYPE + "/relocateItem",
  relocateItemApi
);

export const removeStockItem = createCallApiAsyncAction(
  TYPE + "/removeStockItem",
  removeStockItemApi
);

export const addOldSerialItem = createCallApiAsyncAction(
  TYPE + "/addOldSerialItem",
  addOldSerialItemApi
);

export const updateInventoryItem = createCallApiAsyncAction(
  TYPE + "/updateInventoryItem",
  updateInventoryItemApi
);


export const updateInventoryItemPrice = createCallApiAsyncAction(
  TYPE + "/updateInventoryItemPrice",
  updateInventoryItemPriceApi
);

export const reStockItem = createCallApiAsyncAction(
  TYPE + "/reStockItem",
  reStockItemApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getListInventoryItem.fulfilled, (state, action) => {
      state.listItems = action.payload?.response?.apiStatus
        ? action.payload?.response?.data.items || []
        : [];

      state.listParts = action.payload?.response?.apiStatus
        ? action.payload?.response?.data.parts || []
        : [];

      state.listFset = action.payload?.response?.apiStatus
        ? action.payload?.response?.data.fSetItems || []
        : [];

      state.listRset = action.payload?.response?.apiStatus
        ? action.payload?.response?.data.rSetItems || []
        : [];

      if (action.payload?.response?.apiStatus) {
        state.allItems = [...state.listItems, ...state.listParts, ...state.listFset, ...state.listRset]
        // sum money by qty * cost
        state.totalMoney = state.allItems.reduce(
          (total: number, item) => {
            return total + Number(item.quantity) * item.cost;
          },
          0
        );
      }

      (state.listItems as any).finishLoading = true;
      (state.listParts as any).finishLoading = true;
      (state.listRset as any).finishLoading = true;
      (state.listFset as any).finishLoading = true;

    });
  },
  reducers: {
    updateInventoryItemLocal: (state, action: PayloadAction<any>) => {
      const index = state.allItems.findIndex(item => item.id === action.payload.id);
      if (index !== -1) {
        state.allItems[index] = action.payload;
      }
    },
  },
});
export const { updateInventoryItemLocal } = slice.actions;
export default slice.reducer;

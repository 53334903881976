import { ICustomer } from "types/customer";
import {
  ICustomerAddress,
  ICustomerContact,
  ICustomerDropdown,
} from "types/customer-portal-so";
import { Request } from "utils/request";

export const listCustomerApi = () => {
  return Request.call<{ data: ICustomer[] }>({
    url: "/customer/list",
    method: "GET",
  });
};

export const createCustomerApi = (payload: ICustomer) => {
  return Request.call<{ data: ICustomer }>({
    url: "/customer/create",
    method: "PUT",
    data: payload,
  });
};

export const updateCustomerApi = (payload: ICustomer) => {
  return Request.call<{ data: ICustomer }>({
    url: "/customer/update",
    method: "PUT",
    data: payload,
  });
};

export const deleteCustomerApi = (payload: { customer_id: number }) => {
  return Request.call<{ data: any }>({
    url: "/customer/delete",
    method: "PUT",
    data: payload,
  });
};

export const getDetailCustomerApi = (payload: { id: any }) => {
  return Request.call<{ data: ICustomer }>({
    url: "/customer/detail/" + payload.id,
    method: "GET",
  });
};

export const getDetailCustomerDropdownApi = () => {
  return Request.call<{ data: ICustomerDropdown[] }>({
    url: "/customer/list/dropdown",
    method: "GET",
  });
};

export const getCustomerAddressListApi = (payload: { customer_id: any }) => {
  return Request.call<{ data: ICustomerAddress[] }>({
    url: "/customer/address/list",
    method: "PUT",
    data: payload,
  });
};

// contact
export const getListCustomerContactApi = (payload: { customer_id: any }) => {
  return Request.call<{ data: ICustomerContact[] }>({
    url: "/customer/contact/list",
    method: "PUT",
    data: payload,
  });
};

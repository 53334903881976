import Typography from "@mui/joy/Typography";

import { Card, Divider, Grid, Stack } from "@mui/joy";
import InvoiceTableItem from "pages/invoice/components/InvoiceTableItem";
import { IInvoice } from "types/invoice";
import { formatDate } from "utils/common";

export default function DetailContent({
  invoiceDetail,
}: {
  invoiceDetail: IInvoice;
}) {
  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        <Card
          color="neutral"
          invertedColors={false}
          orientation="vertical"
          size="sm"
          variant="outlined"
        >
          <Grid container spacing={2}>
            <Grid xs={12} sm={4}>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Customer
              </Typography>

              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {invoiceDetail?.customer?.first_name}{" "}
                {invoiceDetail?.customer?.last_name}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                <b>P</b>: {invoiceDetail?.customer?.phone || "-"}
              </Typography>
              <Typography sx={{ fontSize: 14, mb: 1 }}>
                <b>M</b>: {invoiceDetail?.customer?.email || "-"}
              </Typography>
              <Divider
                sx={{
                  mt: 2,
                  display: {
                    xs: "block",
                    sm: "none",
                  },
                }}
              />
            </Grid>

            <Grid xs={12} sm={4}>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Bill To
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {invoiceDetail?.billing_address.name}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                {invoiceDetail?.billing_address.address}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                {invoiceDetail?.billing_address.city},{" "}
                {invoiceDetail?.billing_address.state}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {invoiceDetail?.billing_address.zip_code}{" "}
                {invoiceDetail?.billing_address.country}
              </Typography>
              <Divider
                sx={{
                  mt: 2,
                  display: {
                    xs: "block",
                    sm: "none",
                  },
                }}
              />
            </Grid>

            <Grid xs={12} sm={4}>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Sent Date
              </Typography>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Due Date
              </Typography>
              <Typography sx={{ mb: 1, fontWeight: 600 }}>
                {formatDate(invoiceDetail?.invoice?.due_date)}
              </Typography>

              <Typography sx={{ mb: 1, fontWeight: 600 }}>
                {formatDate(invoiceDetail?.invoice?.created_at)}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Stack>

      <InvoiceTableItem invoiceDetail={invoiceDetail} />
    </Stack>
  );
}

import { Button, FormControl, Grid } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import AddressModal from "components/AddressModal";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import { getCustomerAddressList } from "redux-store/reducers/customer";
import { createCustomerAddress, updateCustomerAddress } from "redux-store/reducers/customer-portal-so";
import { updateSOAddressSection } from "redux-store/reducers/sales-order";
import { ICustomerAddress } from "types/customer-portal-so";
import { ISalesOrder } from "types/sales-order";
import { parseAddress } from "utils/common";
import notification from "utils/notification";

type TEditAddressSection = {
  isOpen: boolean;
  onUpdateSuccess: () => void;
  onClose: () => void;
  so: ISalesOrder;
};

const EditAddressSection = ({
  isOpen,
  onUpdateSuccess,
  onClose,
  so,
}: TEditAddressSection) => {
  const [isOpenAddAdress, setIsOpenAddAdress] = useState<ICustomerAddress>();
  const [shippingId, setShippingId] = useState(so.shipping_address_id);
  const [billingId, setBillingId] = useState(so.billing_address_id);
  const [cusName, setCusName] = useState(so.name);
  const [loading, setLoading] = useState(false);
  const [addressListCustomer, setAddressListCustomer] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);

  useEffect(() => {
    dispatch(
      getCustomerAddressList({
        customer_id: so.customer_id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {

        if (response.data) {
          setAddressListCustomer((response.data?.map((item) => ({
            label: parseAddress(item),
            value: item?.id,
            type: item.type,
          })) as any) || []);
        }
      });
  }, [so])

  const dispatch = useAppDispatch();

  const onUpdate = () => {
    setLoading(true);
    dispatch(updateSOAddressSection({
      id: so.id,
      // billing_address_id: billingId,
      // shipping_address_id: shippingId,
      name: cusName
    })).then(unwrapResult).then(({ response }) => {
      setLoading(false);

      if (response.apiStatus) {
        notification.success("Update success");
        onUpdateSuccess();
        onClose();
      } else {
        notification.error("Update failed");
      }
    });

  };


  return (
    <BaseModal
      title="Edit"
      isOpen={isOpen}
      onClose={onClose}
      width={480}
      actions={
        <Button onClick={onUpdate} loading={loading}>
          Update
        </Button>
      }
    >
      <Grid container spacing={2}>
        {/* <Grid xs={12}>
          <FormControl>
            <BaseSelectWithAdd
              label="Shipping address"
              options={
                addressListCustomer?.filter(
                  (v: any) => v.type === "ship"
                ) || []
              }
              value={shippingId}
              allowAddNew={!!so?.customer_id}
              onChange={(v) => {
                if (v === ADD_NEW_VALUE) {
                  setShippingId(0);
                  setIsOpenAddAdress({
                    phone: "",
                    type: "ship",
                  });
                } else {
                  setShippingId(v);
                }
              }}
            />
          </FormControl>
        </Grid>

        <Grid xs={12}>
          <FormControl>
            <BaseSelectWithAdd
              label="Billing addrees"
              options={
                addressListCustomer?.filter(
                  (v: any) => v.type === "bill"
                ) || []
              }
              value={billingId}
              allowAddNew={!!so?.customer_id}
              onChange={(v) => {
                if (v === ADD_NEW_VALUE) {
                  setBillingId(0);
                  setIsOpenAddAdress({
                    phone: "",
                    type: "bill",
                  });
                } else {
                  setBillingId(v);
                }
              }}
            />
          </FormControl>
        </Grid> */}

        <Grid xs={12}>
          <FormControl>
            <BaseInput
              label="Customer PO"
              value={cusName}
              onChange={(v) => {
                setCusName(v.target.value);
              }}
            />
          </FormControl>
        </Grid>
      </Grid>

      {
        !!isOpenAddAdress && (
          <AddressModal
            isOpen={!!isOpenAddAdress}
            onClose={() => setIsOpenAddAdress(undefined)}
            onSave={(data) => {
              if (!so?.customer_id) return;

              const addJob = {
                ...data,
                customer_id: so?.customer_id,
                fromAdmin: true,
              };
              dispatch(
                data?.id
                  ? updateCustomerAddress(addJob)
                  : createCustomerAddress(addJob)
              )
                .then(unwrapResult)
                .then(({ response }) => {
                  if (response.apiStatus) {
                    notification.success("Create success");
                    const newAddressUpdate = response.data;
                    const newList = [
                      newAddressUpdate,
                    ].map((item) => ({
                      label: parseAddress(item),
                      value: item?.id,
                      type: item.type,
                    })) as any;

                    setAddressListCustomer(
                      [...addressListCustomer, ...newList]
                    )


                    if (newAddressUpdate.type === "ship") {
                      setShippingId(newAddressUpdate.id);
                    }
                    if (newAddressUpdate.type === "bill") {
                      setBillingId(newAddressUpdate.id);
                    }
                    setIsOpenAddAdress(undefined);
                  } else {
                    notification.error("Create failed");
                  }
                });
            }}
            selectedAddress={isOpenAddAdress}
          />
        )
      }
    </BaseModal>

  );
};

export default EditAddressSection;

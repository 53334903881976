import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  getApprovalConfigApi,
  updateApprovalConfigApi,
} from "services/settings.service";
import { IApprovalConfigs } from "types/settings";

const TYPE = "SETTINGS";

const initialState: {
  approvalConfigs: IApprovalConfigs[];
} = {
  approvalConfigs: [],
};

export const getListApprovalConfig = createCallApiAsyncAction(
  TYPE + "/getListWarehouse",
  getApprovalConfigApi
);

export const updateApprovalConfig = createCallApiAsyncAction(
  TYPE + "/updateApprovalConfig",
  updateApprovalConfigApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListApprovalConfig,
      stateKey: "approvalConfigs",
    });
  },
  reducers: {},
});

export default slice.reducer;

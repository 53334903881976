import { Autocomplete } from "@mui/material";
import { useEffect, useState } from "react";
import BaseInput from "./BaseInput";

type TBaseSelect = {
  label?: string;
  options: {
    label: string;
    value: string | number;
  }[];
  value: any;
  disabled?: boolean;
  onChange: (val: any) => void;
  placeholder?: string;
};

const BaseSelect = ({
  label,
  options,
  value,
  onChange,
  disabled,
  placeholder,
}: TBaseSelect) => {
  const [localValue, setLocalValue] = useState<any>(
    options?.find((item: any) => `${item.value}` === `${value}`) || null
  );

  const handleChange = (_: React.SyntheticEvent | null, newValue: any) => {
    onChange(newValue?.value);
  };

  useEffect(() => {
    setLocalValue(
      options?.find((item: any) => `${item.value}` === `${value}`) || null
    );
  }, [value, options]);
  return (
    <Autocomplete
      defaultValue={localValue}
      value={localValue}
      options={options}
      onChange={handleChange}
      getOptionLabel={(option) => option.label}
      getOptionKey={(option) => option.value}
      renderInput={(params) => <BaseInput {...params} label={label} placeholder={placeholder} />}
      disabled={!!disabled}
    />
  );
};

export default BaseSelect;

import AddIcon from "@mui/icons-material/Add";
import { FormControl, Grid, IconButton, Radio, RadioGroup, Stack, Switch } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import { unwrapResult } from "@reduxjs/toolkit";
import { DELIVERY_METHOD, PRICE_LEVEL } from "app-constants";
import BaseInput from "components/BaseInput";
import BaseSelect from "components/BaseSelect";
import PageWrapper from "components/PageWrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import ROUTERS from "routers/constants";
import {
  ICustomerAddress,
  ICustomerDropdown,
  TSalesOrderItemNotStock,
} from "types/customer-portal-so";
import { ISalesOrderItem } from "types/sales-order";
import ModalAddNewSOItem from "./components/ModalAddNew";
import ModalNotStock from "./components/ModalNotStock";

import {
  DeliveryTypeEnum,
  SHIPPING_SERVICES
} from "app-constants";
import AddressModal from "components/AddressModal";
import BaseCurrencyInput from "components/BaseCurrencyInput";
import BaseModal from "components/BaseModal";
import BaseNumberInput from "components/BaseNumberInput";
import BaseSelectWithAdd, { ADD_NEW_VALUE } from "components/BaseSelectWithAdd";
import CardWithLabel from "components/CardWithLabel";
import ConfirmUtils from "components/ConfirmUtils";
import SelectOrAddNew from "components/SelectOrAddNew";
import _ from "lodash";
import { createCustomer, getDetailCustomerDropdown } from "redux-store/reducers/customer";
import {
  createCustomerAddress,
  updateCustomerAddress,
} from "redux-store/reducers/customer-portal-so";
import { getProductItems } from "redux-store/reducers/product";
import { createSalesOrder, getShipCarrier } from "redux-store/reducers/sales-order";
import {
  calculateDiscountItems,
  calculateTotalItems,
  parseAddress,
  showCustomerName
} from "utils/common";
import notification from "utils/notification";
import SOTableItems from "./components/SOTableItems";

export default function SalesOrderNewPage() {
  const [loading, setLoading] = useState(false);
  const [openCustomer, setOpenCustomer] = useState(false);
  const [newCustomer, setNewCustomer] = useState<any>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const customerDropdown = useAppSelector(
    (state) => state.customer.customerDropdown
  );
  const [isOpenAddAdress, setIsOpenAddAdress] = useState<ICustomerAddress>();
  const [addressListCustomer, setAddressListCustomer] = useState<
    {
      label: string;
      value: number;
    }[]
  >([]);
  const [shipCarrierList, setShipCarrierList] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState<ISalesOrderItem>();
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomerDropdown>();
  const [listItems, setListItems] = useState<ISalesOrderItem[]>([]);
  const [shippingId, setShippingId] = useState("");
  const [billingId, setBillingId] = useState("");
  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("Will Call");
  const [deliveryCompany, setDeliveryCompany] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [shippingService, setShippingService] = useState("");
  const [shippingCost, setShippingCost] = useState(0);
  const [palletCost, setPalletCost] = useState(0);
  const [isPallet, setIsPallet] = useState(false);
  const [itemsOutStock, setItemsOutStock] = useState<
    TSalesOrderItemNotStock[] | null
  >(null);

  useEffect(() => {
    dispatch(getDetailCustomerDropdown({}));
    dispatch(getProductItems({}));
    dispatch(getShipCarrier({})).then(unwrapResult).then(({ response }) => {
      if (response.apiStatus) {
        setShipCarrierList(_.map(response.data || [], v => ({ title: v.name })));
      }
    });
  }, []);

  const onToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const onCloseModal = () => {
    setIsOpen(false);
    setItemSelected(undefined);
  };

  const handleCreateNewCustomer = () => {
    setLoading(true);
    dispatch(createCustomer(newCustomer))
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        if (response.data?.id) {
          setOpenCustomer(false)
          dispatch(getDetailCustomerDropdown({}));
          notification.success("Create customer success");
          setSelectedCustomer(response.data)
        } else {
          const err: any = { ...response.data }
          notification.error(err.message || "Create customer failed");
        }
      });
  };
  const handleParsetAdd = () => {
    if (selectedCustomer) {
      setAddressListCustomer([]);
      setAddressListCustomer(
        (selectedCustomer.customer_address?.map((item) => ({
          label: parseAddress(item),
          value: item?.id,
          type: item.type,
        })) as any) || []
      );

      if (selectedCustomer.customer_address?.length) {
        setShippingId(
          selectedCustomer.customer_address.find((v) => v.type === "ship")
            ?.id || ""
        );
        setBillingId(
          selectedCustomer.customer_address.find((v) => v.type === "bill")
            ?.id || ""
        );
      } else {
        setShippingId("");
        setBillingId("");
      }
    } else {
      setAddressListCustomer([]);
      setShippingId("");
      setBillingId("");
    }
  }
  useEffect(() => {
    handleParsetAdd()
  }, [selectedCustomer?.id]);

  const onCreateItem = (item: ISalesOrderItem) => {
    onToggleOpen();
    const isExist = listItems.find((i) => i.item_id === item.item_id);
    if (isExist) {
      setListItems(
        listItems.map((i) => (i.item_id === item.item_id ? item : i))
      );
    } else {
      setListItems([...listItems, item]);
    }
    setItemSelected(undefined);
  };

  const onEditItem = (itemSelected: ISalesOrderItem) => () => {
    setItemSelected(itemSelected);
    onToggleOpen();
  };

  const onRemoveItem = (id?: number) => (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    ConfirmUtils.sure().then((isOk) => {
      if (!isOk) return;
      setListItems(listItems.filter((item) => item?.item?.id !== id));
    });
  };

  useEffect(() => {
    setShippingCost(0)
    setTrackingNumber("")
    setDeliveryCompany("")
    setShippingService("")
    setIsPallet(false)
  }, [deliveryMethod]);

  useEffect(() => {
    setPalletCost(isPallet ? 25 : 0)
  }, [isPallet]);

  const handleCreateNewSO =
    (isBackOrder?: boolean, deliveryType?: DeliveryTypeEnum) => () => {
      const dataCreate = {
        name,
        tax_cost: 0,
        shipping_cost: shippingCost,
        total: 0,
        note,
        is_pallet: !!isPallet,
        pallet_cost: !!isPallet ? palletCost || 0 : 0,
        sales_order_items: listItems.map((item) => ({
          item_id: item.item_id,
          quantity: item.quantity,
          discount: item.discount,
          percent: item.percent,
          cost: 0,
          tax_cost: 0,
          shipping_cost: 0,
          other_cost: 0,
          total: 0,
          note: item.note,
        })),
        shipping_address_id: +shippingId,
        billing_address_id: +billingId,
        delivery_company: deliveryCompany || "",
        tracking_number: trackingNumber || "",
        delivery_method: deliveryMethod || "",
        delivery_type: deliveryType || DeliveryTypeEnum.FULL,
        is_back_order: isBackOrder ? "yes" : "no",
        ship_date: "",
        customer_id: selectedCustomer?.id,
        shipping_service: shippingService,
      };

      setItemsOutStock(null);
      setLoading(true);
      dispatch(createSalesOrder(dataCreate))
        .then(unwrapResult)
        .then(({ response }) => {
          setLoading(false);
          const data = response.data || {};
          if (data?.id) {
            notification.success("Create order success");
            if (data?.id && !data?.item_not_stock?.length) {
              if (dataCreate.delivery_type === DeliveryTypeEnum.FULL && dataCreate.is_back_order === 'yes') {
                navigate(ROUTERS.SALES_ORDER_BACKORDER);
              } else {
                navigate(ROUTERS.SALES_ORDER);
              }
            }
          } else if (data?.item_not_stock?.length) {
            setItemsOutStock(
              data?.item_not_stock?.map((item: any) => {
                return {
                  ...item,
                  item: listItems.find((i: any) => i.item_id === item.id)?.item,
                };
              })
            );
          } else {
            const err: any = { ...data };
            notification.error(err.message || "Create order fail");
          }
        });
    };

  const handleRemoveOutStock = () => {
    const newListItems = listItems
      .map((item) => {
        const foundOutStock = itemsOutStock?.find((i) => i.id === item.item_id);
        if (foundOutStock) {
          if (foundOutStock.instock === 0) {
            return null;
          } else {
            return {
              ...item,
              quantity: foundOutStock.instock,
            };
          }
        } else {
          return item;
        }
      })
      .filter(Boolean) as ISalesOrderItem[];

    setListItems(newListItems);
    setItemsOutStock(null);
  };

  const disabledSave =
    !selectedCustomer?.id || listItems.length === 0 || !name || !deliveryMethod;
  return (
    <>
      <PageWrapper
        pageAction={
          <Box>
            <Button
              sx={{ marginRight: 1 }}
              variant="plain"
              color="neutral"
              size="sm"
              onClick={() => navigate(ROUTERS.SALES_ORDER)}
              disabled={loading}
            >
              Cancel
            </Button>

            <Button
              sx={{ marginRight: 1 }}
              variant="plain"
              size="sm"
              disabled={loading}
              onClick={() => {
                setNewCustomer({
                  password: "",
                  first_name: "",
                  last_name: "",
                  email: "",
                  phone: "",
                  company: "",
                  price_level: PRICE_LEVEL[1].value,
                  due_date_number: 30,
                })
                setOpenCustomer(true)
              }}
            >
              Add Customer
            </Button>

            <Button
              color="primary"
              size="sm"
              onClick={handleCreateNewSO()}
              loading={loading}
              disabled={disabledSave}
            >
              Save
            </Button>

          </Box >
        }
        pageName="Add Sales Order"
        breadcrumb={
          [
            {
              label: "Sales Orders",
              link: ROUTERS.SALES_ORDER,
            },
            {
              label: "New",
            },
          ]}
      >
        <Stack spacing={2} sx={{ pt: 1 }}>
          <Grid container spacing={2}>

            {/* customer section */}
            <Grid sm={4}>
              <FormControl>
                <BaseSelect
                  label="Select customer"
                  options={customerDropdown?.map((item) => ({
                    label: showCustomerName(item),
                    value: item.id,
                  }))}
                  value={selectedCustomer?.id}
                  onChange={(val) => {
                    setSelectedCustomer(
                      customerDropdown?.find((item) => item.id === val)
                    );
                  }}
                />
              </FormControl>
            </Grid>

            <Grid sm={4}>
              <FormControl>
                <BaseSelectWithAdd
                  label="Shipping address"
                  options={
                    addressListCustomer?.filter(
                      (v: any) => v.type === "ship"
                    ) || []
                  }
                  value={shippingId}
                  allowAddNew={!!selectedCustomer?.id}
                  onChange={(v) => {
                    if (v === ADD_NEW_VALUE) {
                      setShippingId("");
                      setIsOpenAddAdress({
                        phone: "",
                        type: "ship",
                      });
                    } else {
                      setShippingId(v);
                    }
                  }}
                />
              </FormControl>
            </Grid>
            <Grid sm={4}>
              <FormControl>
                <BaseSelectWithAdd
                  label="Billing addrees"
                  options={
                    addressListCustomer?.filter(
                      (v: any) => v.type === "bill"
                    ) || []
                  }
                  value={billingId}
                  allowAddNew={!!selectedCustomer?.id}
                  onChange={(v) => {
                    if (v === ADD_NEW_VALUE) {
                      setBillingId("");
                      setIsOpenAddAdress({
                        phone: "",
                        type: "bill",
                      });
                    } else {
                      setBillingId(v);
                    }
                  }}
                />
              </FormControl>
            </Grid>
            {/* end customer section */}

            <Grid xs={12} sm={12} container spacing={2} sx={{ alignItems: "center" }}>
              <Grid sm={6}>
                <CardWithLabel label="Ship Method" style={{
                  padding: "18px",
                  width: "100%",
                  marginTop: "-8px"
                }}>
                  <RadioGroup
                    aria-label="Type"
                    name="type"
                    defaultValue={DELIVERY_METHOD[0]}
                    value={deliveryMethod}
                    onChange={(e) => setDeliveryMethod(e.target.value as any)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      {DELIVERY_METHOD.map((item) => (
                        <Box key={item}>
                          <Radio
                            value={item}
                            label={item}
                            sx={{ flexGrow: 1 }}
                            slotProps={{
                              action: ({ checked }) => ({
                                sx: (theme) => ({
                                  ...(checked && {
                                    inset: -1,
                                    fontWeight: 500
                                  }),
                                }),
                              }),
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </RadioGroup>
                </CardWithLabel>
              </Grid>
              {/* alway show */}
              <Grid sm={6}>
                <FormControl>
                  <BaseInput
                    label={"Note"}
                    placeholder="Write some things"
                    multiline
                    minRows={2}
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid xs={12} sm={12} container spacing={2} sx={{ alignItems: "center" }}>
              {/* ups vs ltl */}
              {
                ["UPS", "LTL"].includes(deliveryMethod) && <>
                  <Grid xs={12} sm={3}>
                    {deliveryMethod === "UPS" && <FormControl>
                      <BaseSelect
                        label="Shipping Service"
                        options={SHIPPING_SERVICES}
                        value={shippingService}
                        onChange={(e: any) => setShippingService(e)}
                      />
                    </FormControl>}

                    {deliveryMethod === "LTL" && <FormControl>
                      <SelectOrAddNew
                        label="Logistics Compamy"
                        value={deliveryCompany} options={shipCarrierList}
                        onChange={(v) => setDeliveryCompany(v)}
                      />
                    </FormControl>
                    }
                  </Grid>
                  <Grid xs={12} sm={3}>
                    <FormControl>
                      <BaseCurrencyInput
                        label="Freight Cost"
                        value={shippingCost}
                        onChange={(e: any) => setShippingCost(e)}
                      />
                    </FormControl>
                  </Grid>

                  <Grid xs={12} sm={3}>
                    <FormControl>
                      <BaseInput
                        label="Tracking number"
                        value={trackingNumber}
                        onChange={(e: any) => setTrackingNumber(e.target.value)}
                      />
                    </FormControl>
                  </Grid>

                  {deliveryMethod === "LTL" && <Grid xs={12} sm={2}>
                    <FormControl>
                      <Switch
                        checked={isPallet}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                          setIsPallet(event.target.checked)
                        }
                        endDecorator={"Pallet?"}
                      />
                    </FormControl>
                  </Grid>}

                </>
              }
            </Grid>

            <Grid xs={12} sm={4}>
              <FormControl>
                <BaseInput
                  label={"Customer PO"}
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                />
              </FormControl>
            </Grid>

            <Grid sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <IconButton
                  color="primary"
                  size="sm"
                  onClick={onToggleOpen}
                  variant="outlined"
                >
                  <AddIcon />
                </IconButton>
              </Box>

              <Stack spacing={2}>
                <SOTableItems
                  listItems={listItems}
                  salesOrderDetail={{
                    discount_item: calculateDiscountItems(listItems),
                    total: calculateTotalItems(listItems),
                    tax_cost: 0,
                    shipping_cost: shippingCost,
                    pallet_cost: palletCost
                  }}
                  onEditItem={onEditItem}
                  onRemoveItem={onRemoveItem}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack >

        <ModalAddNewSOItem
          isOpen={isOpen}
          itemSelected={itemSelected}
          onCloseModal={onCloseModal}
          onCreateItem={onCreateItem}
          customer={selectedCustomer}
          items={listItems}
        />
        {
          !!itemsOutStock && (
            <ModalNotStock
              onClose={() => setItemsOutStock(null)}
              onRemove={handleRemoveOutStock}
              onContinue={(deliveryType) =>
                handleCreateNewSO(true, deliveryType)()
              }
              // onClose={onClickModalNotStock}
              itemsOutStock={itemsOutStock}
            />
          )
        }
      </PageWrapper >

      {!!openCustomer && (
        <BaseModal
          isOpen={openCustomer}
          onClose={() => { setOpenCustomer(false) }}
          title={"Add new customer"}
          actions={
            <Box>
              <Button
                loading={loading}
                onClick={handleCreateNewCustomer}
              >
                Save
              </Button>
            </Box>
          }
        >
          <Box>
            <Grid container spacing={2} xs={12} sm>

              <Grid xs={12} sm={6}>
                <FormControl>
                  <BaseInput
                    label="Display Name *"
                    value={newCustomer.company}
                    placeholder="Company name or Person name"
                    onChange={(e) =>
                      setNewCustomer({
                        ...newCustomer,
                        company: e.target.value,
                        first_name: e.target.value,
                        last_name: "",
                      })
                    }
                  />
                </FormControl>
              </Grid>



              <Grid xs={12} sm={6}>
                <FormControl>
                  <BaseInput
                    label="Phone"
                    value={newCustomer.phone}
                    onChange={(e) =>
                      setNewCustomer({ ...newCustomer, phone: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} sm={6}>
                <FormControl>
                  <BaseInput
                    label="Email"
                    value={newCustomer.email}
                    onChange={(e) =>
                      setNewCustomer({ ...newCustomer, email: e.target.value })
                    }
                  />
                </FormControl>
              </Grid>


              <Grid xs={6} sm={6}>
                <FormControl>
                  <BaseNumberInput
                    label="Due date after"
                    value={newCustomer.due_date_number}
                    onChange={(e) =>
                      setNewCustomer({ ...newCustomer, due_date_number: e })
                    }
                  />
                </FormControl>
              </Grid>


            </Grid>
          </Box>
        </BaseModal>
      )
      }
      {
        !!isOpenAddAdress && (
          <AddressModal
            isOpen={!!isOpenAddAdress}
            onClose={() => setIsOpenAddAdress(undefined)}
            onSave={(data) => {
              if (!selectedCustomer?.id) return;

              const addJob = {
                ...data,
                customer_id: selectedCustomer?.id,
                fromAdmin: true,
              };
              dispatch(
                data?.id
                  ? updateCustomerAddress(addJob)
                  : createCustomerAddress(addJob)
              )
                .then(unwrapResult)
                .then(({ response }) => {
                  if (response.apiStatus) {
                    notification.success("Create success");
                    const newAddressUpdate = response.data;
                    const newList = (selectedCustomer.customer_address || []).concat([
                      newAddressUpdate,
                    ]);
                    setSelectedCustomer({
                      ...selectedCustomer,
                      customer_address: newList,
                    });
                    setAddressListCustomer(
                      (newList?.map((item) => ({
                        label: parseAddress(item),
                        value: item?.id,
                        type: item.type,
                      })) as any) || []
                    );

                    console.log('newList', newList)

                    if (newAddressUpdate.type === "ship") {
                      setShippingId(newAddressUpdate.id);
                    }
                    if (newAddressUpdate.type === "bill") {
                      setBillingId(newAddressUpdate.id);
                    }
                    setIsOpenAddAdress(undefined);
                  } else {
                    notification.error("Create failed");
                  }
                });
            }}
            selectedAddress={isOpenAddAdress}
          />
        )
      }
    </>
  );
}

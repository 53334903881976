import { Grid, Option, Select } from "@mui/joy";
import { useEffect, useState } from "react";
import { AreaTypeEnum, IArea } from "types/warehouse";

export default function SelectWarehouseLocation({
  areas,
  onSelect,
}: {
  areas: IArea[];
  onSelect: (area?: IArea) => void;
}) {
  const [selectedAsiel, setSelectedAsiel] = useState<IArea>();
  const [selectedLevel, setSelectedLevel] = useState<IArea>();
  const [selectedBin, setSelectedBin] = useState<IArea>();

  const aisleList = areas.filter((area) => area.type === AreaTypeEnum.AISLE);
  const levelList = areas.filter(
    (area) =>
      area.type === AreaTypeEnum.LEVEL && area.parent_id === selectedAsiel?.id
  );
  const binList = areas.filter(
    (area) =>
      area.type === AreaTypeEnum.BIN && area.parent_id === selectedLevel?.id
  );

  useEffect(() => {
    if (selectedBin) {
      onSelect(selectedBin);
      return;
    }

    if (selectedLevel) {
      onSelect(selectedLevel);
      return;
    }

    if (selectedAsiel) {
      onSelect(selectedAsiel);
      return;
    }

    onSelect();
  }, [selectedAsiel, selectedLevel, selectedBin]);

  return (
    <Grid container spacing={1}>
      <Grid xs={12} sm={4}>
        <Select
          placeholder="Select Aisle"
          autoFocus={false}
          value={selectedAsiel?.id}
          onChange={(e, newValue) => {
            setSelectedAsiel(
              aisleList.find((item) => item.id === newValue) || undefined
            );
            setSelectedLevel(undefined);
            setSelectedBin(undefined);
          }}
        >
          {aisleList
            ?.map((item) => ({
              label: item.type + ":" + item.name,
              value: item.id as any,
            }))
            .map((item) => (
              <Option value={item.value}>{item.label}</Option>
            ))}
        </Select>
      </Grid>

      {!!selectedAsiel && !!levelList?.length && (
        <Grid xs={12} sm={4}>
          <Select
            placeholder="Select Level"
            autoFocus={false}
            value={selectedLevel?.id}
            onChange={(e, newValue) => {
              setSelectedLevel(
                levelList.find((item) => item.id === newValue) || undefined
              );
              setSelectedBin(undefined);
            }}
          >
            {levelList
              ?.map((item) => ({
                label: item.type + ":" + item.name,
                value: item.id as any,
              }))
              .map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
          </Select>
        </Grid>
      )}

      {!!selectedLevel && !!binList?.length && (
        <Grid xs={12} sm={4}>
          <Select
            placeholder="Select Bin"
            autoFocus={false}
            value={selectedBin?.id}
            onChange={(e, newValue) => {
              setSelectedBin(
                binList.find((item) => item.id === newValue) || undefined
              );
            }}
          >
            {binList
              ?.map((item) => ({
                label: item.type + ":" + item.name,
                value: item.id as any,
              }))
              .map((item) => (
                <Option value={item.value}>{item.label}</Option>
              ))}
          </Select>
        </Grid>
      )}
    </Grid>
  );
}

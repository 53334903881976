import { ScheduleOutlined } from "@mui/icons-material";
import { Box } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import { getSOHistory } from "redux-store/reducers/sales-order";
import { formatDate } from "utils/common";

interface IHistory {
  id: any;
  created_at: string;
  text_key: string;
  text_value: string;
  user?: {
    name: string;
  };
  customer?: {
    first_name: string;
  };
}
interface ISourceID {
  source_id?: string;
}

export default function HistoryTimeline({ source_id }: ISourceID) {
  const [historyList, setHistoryList] = useState<IHistory[]>([]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchDetail();
  }, []);

  const fetchDetail = () => {
    if (!source_id) return;
    dispatch(
      getSOHistory({
        source_id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data) setHistoryList(response.data);
      });
  };

  return (
    <Box
      sx={{
        p: 3,
      }}
    >
      {historyList?.map((item, index) => (
        <Box
          key={item.id}
          sx={{
            display: "flex",
            gap: 1,
            pb: 2.5,
            "&:not(:last-child)": {
              marginBottom: 1,
            },
            position: "relative",
            "&:not(:last-child)::before": {
              content: '""',
              position: "absolute",
              width: 2,
              height: "100%",
              backgroundColor: (theme) => theme.palette.primary[300],
              left: 11,
              top: 11,
              zIndex: -1,
            },
          }}
        >
          <Box
            sx={{
              width: "24px",
              height: "24px",
              minWidth: "24px",
              borderRadius: "50%",
              backgroundColor: (theme) => theme.palette.primary[500],
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: (theme) => theme.palette.background.body,
              "& svg": {
                width: "16px",
                height: "16px",
              },
            }}
          >
            <ScheduleOutlined />
          </Box>
          <Box>
            <Box
              sx={{
                fontSize: "16px",
              }}
            >
              {formatDate(item.created_at, true)}
            </Box>
            <Box
              sx={{
                fontSize: "12px",
                color: (theme) => theme.palette.text.tertiary,
              }}
            >
              {item.customer?.first_name ? "Customer" : ""}
              {item.user?.name ? "Employee" : ""}
            </Box>
            <Box
              sx={{
                fontSize: "13px",
                color: (theme) => theme.palette.text.tertiary,
                whiteSpace: "pre",
              }}
            >
              <strong>
                {item.customer?.first_name ? item.customer?.first_name : ""}
                {item.user?.name ? item.user?.name : ""}
              </strong>
              {item.text_key === "Action" && " has processed the "}"
              {item.text_value}"
            </Box>
          </Box>
        </Box>
      ))}
    </Box>
  );
}

import { Autocomplete, LoadScript } from '@react-google-maps/api';
import { useRef } from 'react';
import BaseInput from './BaseInput';
const libraries: any = ['places']; // Specify libraries needed

const AddressAutocompleteForm = ({
  setNewAddress
}: {
  setNewAddress: (address: {
    address: string;
    address2: string;
    city: string;
    state: string;
    zip_code: string;
    country: string;
  }) => void;
}) => {
  const autocompleteRef = useRef<any>(null);
  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place) {
      const addressComponents = place.address_components;

      // Helper function to get a specific address component
      const getComponent = (type: string) => {
        const component = addressComponents?.find((component: any) =>
          component.types.includes(type)
        );
        return component ? component.short_name : '';
      };

      // Update address state based on address components
      setNewAddress({
        address: `${getComponent('street_number')} ${getComponent('route')}`,
        address2: '',
        city: getComponent('locality'),
        state: getComponent('administrative_area_level_1'),
        zip_code: getComponent('postal_code'),
        country: getComponent('country'),
      });
    }
  };

  return (
    <LoadScript googleMapsApiKey="AIzaSyDyHzY3xisiuFbdgslPn1scqUg-dIlX5og" libraries={libraries}>
      <Autocomplete
        onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
        onPlaceChanged={onPlaceChanged}
      >
        <BaseInput
          sx={{
            width: "100%"
          }}
          placeholder="Enter your address"
          label="Search address by Google"
        />
      </Autocomplete>
    </LoadScript>
  );
};

export default AddressAutocompleteForm;

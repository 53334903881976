import { Button } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import CustomerView from "components/CustomerView";
import PageWrapper from "components/PageWrapper";
import { ViewDetailModalWrapper } from "components/ViewDetailModal";
import _ from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { getListReturn } from "redux-store/reducers/return";
import ROUTERS from "routers/constants";
import { formatDate, showCustomerName } from "utils/common";

export default function ReturnListPage() {
  const returnList = useAppSelector((state) => state.return.list);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleGetListReturn = () => {
    dispatch(getListReturn({}));
  };
  useEffect(() => {
    handleGetListReturn();
  }, []);
  return (
    <>
      <PageWrapper
        pageAction={
          <Button size="sm" onClick={() => navigate(ROUTERS.RETURN_NEW)}>
            Create
          </Button>
        }
        pageName="Return"
        breadcrumb={[
          {
            label: "Return",
          },
        ]}
      >
        <CommonList
          data={returnList}
          columns={[
            {
              key: "id",
              label: "#",
              width: 80,
              render: (row) => <b>{row.id}</b>,
            },
            {
              key: "customerName",
              label: "Customer",
              render: (row) => (
                <CustomerView
                  customer={row.return_items?.[0]?.customer as any}
                />
              ),
              rawValue: (row) =>
                showCustomerName(row.return_items?.[0]?.customer),
            },
            {
              key: "quantity",
              label: "Quantity",
              rawValue: (row) => {
                return _.sumBy(row.return_items, (item) => item?.quantity || 0);
              },
            },
            {
              key: "return_reason",
              label: "Reason",
            },
            {
              key: "status",
              label: "Status",
              width: 200,
              render: (row) => <ChipStatus status={row.status} size="small" />,
            },
            {
              key: "created_at",
              label: "Created At",
              width: 120,
              render: (row) => (
                <Typography>{formatDate(row.created_at)}</Typography>
              ),
            },
          ]}
          searchKeys={["id", "customerName", "return_reason", "created_at"]}
          sortKeys={[]}
          filterStatus={{
            statusKey: "status",
            data: [{ label: "Created", value: "Completed" }],
          }}
          onRowClick={(row) =>
            navigate(`${ROUTERS.RETURN_DETAIL.replace(":id", row.id as any)}`)
          }
        />
      </PageWrapper>
      <ViewDetailModalWrapper />
    </>
  );
}

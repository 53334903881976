import { Box } from "@mui/joy";
import CustomerDetailPage from "pages/customer-admin/detail";
import InvoiceDetailPage from "pages/invoice/detail";
import SalesOrderDetailPage from "pages/sales-order/detail";
import { useCallback, useEffect, useState } from "react";
import ROUTERS from "routers/constants";
import BaseModal from "./BaseModal";

interface IViewDetailModalProps {
  type: "CUSTOMER" | "INVOICE" | "SO" | "PO";
  id: any;
}
let showDetailModal = null as unknown as ({
  type,
  id,
}: IViewDetailModalProps) => Promise<unknown>;

export function ViewDetailModalWrapper() {
  const [isOpen, setIsOpen] = useState<
    | (IViewDetailModalProps & {
        onUpdate: (value?: any) => void;
        onClose: () => void;
      })
    | null
  >(null);

  const showDetailModalLocal = useCallback(
    ({ type, id }: IViewDetailModalProps) => {
      return new Promise<boolean>((resolve) => {
        setIsOpen({
          type,
          id,
          onUpdate: () => {
            resolve(true);
            setIsOpen(null);
          },
          onClose: () => {
            resolve(false);
            setIsOpen(null);
          },
        });
      });
    },
    []
  );

  useEffect(() => {
    showDetailModal = showDetailModalLocal;
  }, [showDetailModalLocal]);

  const type = isOpen?.type;
  const id = isOpen?.id;
  const detailViewProps = {
    detailId: id,
    onViewModalCancel: isOpen?.onClose,
    onViewModalUpdate: isOpen?.onUpdate,
  };
  return (
    <>
      {!!type && !!id && (
        <BaseModal
          isOpen={true}
          onClose={isOpen.onClose}
          title={""}
          width={1200}
        >
          <Box
            sx={{
              py: 2,
            }}
          >
            {type === "CUSTOMER" && <CustomerDetailPage {...detailViewProps} />}
            {type === "INVOICE" && <InvoiceDetailPage {...detailViewProps} />}
            {type === "SO" && <SalesOrderDetailPage {...detailViewProps} />}
          </Box>
        </BaseModal>
      )}
    </>
  );
}
const ViewDetail = {
  show: (params: IViewDetailModalProps) => showDetailModal(params),
};

export const ViewDetailLink = (
  props: IViewDetailModalProps & {
    children: React.ReactNode;
  }
) => {
  if (!props.id) return <>{props.children}</>;
  return (
    <Box
      sx={{
        color: (theme) => theme.palette.primary[600],
        "&:hover *":{
          color: (theme) => theme.palette.primary[600],
        }
      }}
      onClick={(e) => {
        e.stopPropagation();
        // showDetailModal(props);
        switch (props.type) {
          case "CUSTOMER":
            window.open(
              ROUTERS.CUSTOMER_DETAIL.replace(":id", props.id as any),
              "_blank"
            );
            break;
          case "INVOICE":
            window.open(
              ROUTERS.INVOICE_DETAIL.replace(":id", props.id as any),
              "_blank"
            );
            break;
          case "SO":
            window.open(
              ROUTERS.SALES_ORDER_DETAIL.replace(":id", props.id as any),
              "_blank"
            );
            break;
          case "PO":
            window.open(
              ROUTERS.PURCHASE_DETAIL.replace(":id", props.id as any),
              "_blank"
            );
            break;
        }
      }}
    >
      {props.children}
    </Box>
  );
};
export default ViewDetail;

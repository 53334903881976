import { Box } from "@mui/joy";
import DialogContent from "@mui/joy/DialogContent";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import ModalDialog from "@mui/joy/ModalDialog";
import * as React from "react";

export default function BaseFullScreenModal({
  isOpen,
  onClose,
  children,
  title
}: {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
  title?: string;
}) {
  return (
    <Modal
      open={isOpen}
      onClose={(e, reason) => {
        if (reason !== "escapeKeyDown") {
          onClose();
        }
      }}
    >
      <ModalDialog layout={"fullscreen"}>
        <Box sx={{ display: "flex", alignItems: "start", alignContent: "space-between" }}>
          <strong>{title}</strong>
          <ModalClose />
        </Box>
        <DialogContent>
          {children}
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
}

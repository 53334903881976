import { Box, Button, FormControl, Tooltip } from "@mui/joy";
import { ClickAwayListener } from "@mui/material";
import { useState } from "react";
import BaseCurrencyInput from "./BaseCurrencyInput";
import BaseInput from "./BaseInput";
import BaseSelect from "./BaseSelect";

export default function InlineEditModal({
  onSave,
  label,
  saveText,
  placement,
  type = "text",
  children,
  options,
  width,
}: {
  onSave?: (newData: any, closeEdit: () => void) => void;
  label?: string;
  saveText?: string;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  type?: "text" | "textarea" | "currency" | "select";
  children: any;
  options?: any[];
  width?: number;
}) {
  const [openEdit, setOpenEdit] = useState<{
    value: any;
  } | null>(null);

  const handleSave = () => {
    onSave?.(openEdit?.value, () => {
      setOpenEdit(null);
    });
  };
  return (
    <ClickAwayListener onClickAway={() => setOpenEdit(null)}>
      <Tooltip
        arrow
        variant="outlined"
        placement={placement}
        open={!!openEdit}
        sx={{
          zIndex: 1300,
        }}
        title={
          <Box
            sx={{
              py: 1,
            }}
          >
            <FormControl
              sx={{
                width: width,
              }}
            >
              {type === "text" && (
                <BaseInput
                  size="small"
                  label={label}
                  value={openEdit?.value}
                  onChange={(e) => {
                    setOpenEdit({
                      value: e.target.value,
                    });
                  }}
                />
              )}
              {type === "textarea" && (
                <BaseInput
                  size="small"
                  sx={{
                    minWidth: 260,
                  }}
                  multiline
                  rows={3}
                  label={label}
                  value={openEdit?.value}
                  onChange={(e) => {
                    setOpenEdit({
                      value: e.target.value,
                    });
                  }}
                />
              )}
              {type === "currency" && (
                <BaseCurrencyInput
                  label={label}
                  value={openEdit?.value}
                  onChange={(e) => {
                    setOpenEdit({
                      value: Number(e),
                    });
                  }}
                />
              )}
              {type === "select" && !!options && (
                <BaseSelect
                  label={label}
                  value={openEdit?.value}
                  onChange={(e) => {
                    setOpenEdit({
                      value: e,
                    });
                  }}
                  options={options}
                />
              )}
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: 1,
                mt: 1,
              }}
            >
              <Button
                onClick={handleSave}
                disabled={!openEdit?.value}
                size="sm"
              >
                {saveText || "Save"}
              </Button>
            </Box>
          </Box>
        }
      >
        <Box
          onClick={() =>
            setOpenEdit({
              value: "",
            })
          }
        >
          {children}
        </Box>
      </Tooltip>
    </ClickAwayListener>
  );
}

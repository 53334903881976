import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import { Tooltip } from "@mui/material";
import { PRICE_LEVEL } from "app-constants";

import CommonList from "components/CommonList";
import PageWrapper from "components/PageWrapper";
import _ from "lodash";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { getListCustomer } from "redux-store/reducers/customer";
import ROUTERS from "routers/constants";
import { formatDate } from "utils/common";
import { getPermission } from "utils/roles";

export default function CustomerListPage() {
  const customerList = useAppSelector((state) => state.customer.list);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleGetListCustomer = () => {
    dispatch(getListCustomer({}));
  };
  useEffect(() => {
    handleGetListCustomer();
  }, []);


  const getPriceLevel = (value: string) => {
    const f = _.find(PRICE_LEVEL, { value })
    return f ? f.label : ""
  }

  return (
    <PageWrapper
      pageAction={
        getPermission('ALLOW_CREATE_CUSTOMER') && <Button
          color="primary"
          size="sm"
          onClick={() => navigate(ROUTERS.CUSTOMER_NEW)}
        >
          Create
        </Button>
      }
      pageName="Customers"
      breadcrumb={[
        {
          label: "Customer",
        },
      ]}
    >
      <CommonList
        data={customerList}
        columns={[
          {
            key: "cus_number",
            label: "Distributor ID",
            width: 120,
          },
          {
            key: "displayName",
            label: "Name",
            rawValue: (row) => `${row.first_name}`,
            render: (row) => <><strong>{row.first_name}</strong></>,
          },
          {
            key: "email",
            label: "Email",
            width: 210,
            render: (row) => <Tooltip title={row.email}><span>{row.email}</span></Tooltip>,
          },
          {
            key: "phone",
            label: "Phone",
            width: 150,
          },
          {
            width: 100,
            key: "price_level",
            label: "Price Level",
            render: (row) => getPriceLevel(row.price_level as string),
          },
          // {
          //   key: "status",
          //   label: "Status",
          //   width: 80,
          //   render: (row) => <ChipStatus size="small" status={row.status} />,
          // },
          {
            key: "created_at",
            label: "Created At",
            width: 100,
            render: (row) => (
              <Typography>{formatDate(row.created_at)}</Typography>
            ),
          },
        ]}
        searchKeys={["displayName", "email", "phone", "cus_number"]}
        sortKeys={["created_at", "status", "email", "displayName", "cus_number", "phone"]}
        filterStatus={{
          statusKey: "status",
          data: [
            {
              label: "Active",
              value: "Active",
            },
            {
              label: "Inactive",
              value: "Inactive",
            },
          ],
        }}
        onRowClick={(row) =>
          navigate(`${ROUTERS.CUSTOMER_DETAIL.replace(":id", row.id as any)}`)
        }
      />
    </PageWrapper>
  );
}

import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";

import { Radio, Stack, Table } from "@mui/joy";
import { DeliveryTypeEnum } from "app-constants";
import BaseModal from "components/BaseModal";
import { useState } from "react";
import { TSalesOrderItemNotStock } from "types/customer-portal-so";
import { showProductItemName } from "utils/common";

type TModalNotStock = {
  itemsOutStock: TSalesOrderItemNotStock[];
  onRemove: () => void;
  onContinue: (type: DeliveryTypeEnum) => void;
  onClose: () => void;
};

const ModalNotStock = ({
  onRemove,
  onContinue,
  onClose,
  itemsOutStock,
}: TModalNotStock) => {
  const [deliveryType, setDeliveryType] = useState(DeliveryTypeEnum.FULL);
  return (
    <BaseModal
      title="Back Order Confirmation"
      onClose={onClose}
      isOpen={true}
      actions={
        <>
          <Button color="primary" variant="plain" onClick={onRemove}>
            Remove Out Stock Item
          </Button>
          <Button sx={{ ml: 1 }} onClick={() => onContinue(deliveryType)}>
            Continue Process
          </Button>
        </>
      }
    >
      <Stack>
        <Typography level="body-sm" sx={{ mb: 1 }}>
          We would like to provide you with an update regarding your order.
          Currently, one or more items in your order are out of stock. You have
          two options:
        </Typography>
        <Typography level="body-sm">
          - Full delivery option: We will hold your item until we have all the
          items in your order and send them out together.
        </Typography>

        <Typography level="body-sm">
          - Partial delivery: It will result in your order being split into two.
          We will send out one order with the items we have in stock, and the
          other order will be placed on backorder.
        </Typography>

        <Table variant={"outlined"} size="sm" sx={{ mt: 1.5 }}>
          <thead>
            <tr>
              <th>Item</th>
              <th
                style={{
                  width: 80,
                }}
              >
                Order Qty
              </th>
              <th
                style={{
                  width: 80,
                }}
              >
                In stock
              </th>
            </tr>
          </thead>
          <tbody>
            {itemsOutStock?.map((row) => (
              <tr key={row.id}>
                <td>{showProductItemName(row.item)}</td>
                <td>{row.quantity_order}</td>
                <td>{row.instock}</td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Typography
          textColor="inherit"
          level="body-md"
          sx={{ mb: 1.5, mt: 1.5 }}
        >
          <b>Attention!</b> <br />
          Please review your order carefully. You can choose to:
        </Typography>

        <Box sx={{ display: "flex", gap: 2 }}>
          <Radio
            label={DeliveryTypeEnum.FULL}
            checked={deliveryType === DeliveryTypeEnum.FULL}
            onChange={() => setDeliveryType(DeliveryTypeEnum.FULL)}
            value={DeliveryTypeEnum.FULL}
            name="radio-buttons"
            slotProps={{ input: { "aria-label": DeliveryTypeEnum.FULL } }}
          />
          <Radio
            label={DeliveryTypeEnum.PARTIAL}
            checked={deliveryType === DeliveryTypeEnum.PARTIAL}
            onChange={() => setDeliveryType(DeliveryTypeEnum.PARTIAL)}
            value={DeliveryTypeEnum.PARTIAL}
            name="radio-buttons"
            slotProps={{ input: { "aria-label": DeliveryTypeEnum.PARTIAL } }}
          />
        </Box>
      </Stack>
    </BaseModal>
  );
};

export default ModalNotStock;


import { Typography } from "@mui/joy";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import CustomerView from "components/CustomerView";
import PageWrapper from "components/PageWrapper";
import {
  ViewDetailLink, ViewDetailModalWrapper
} from "components/ViewDetailModal";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { getListInvoice } from "redux-store/reducers/invoice";
import ROUTERS from "routers/constants";
import { formatDate, showCurrency } from "utils/common";

export default function InvoiceListPage() {
  const invoiceList = useAppSelector((state) => state.invoice.list);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleGetListInvoice = () => {
    dispatch(getListInvoice({}));
  };
  useEffect(() => {
    handleGetListInvoice();
  }, []);
  return (
    <>
      <PageWrapper
        pageName="Invoices"
        breadcrumb={[
          {
            label: "Invoices",
          },
        ]}
      >
        <CommonList
          data={invoiceList}
          columns={[
            {
              key: "inv",
              label: "#",
              width: 100,
              rawValue: (row) => row.invoice?.inv_number,
              render: (row) => <b>{row.invoice?.inv_number}</b>,
            },

            {
              key: "customerName",
              label: "Customer",
              render: (row) => <CustomerView customer={row.customer as any} />,
              rawValue: (row) =>
                `${row.customer?.first_name} ${row.customer?.last_name} ${row.customer?.email} ${row.customer?.cus_number}`,
            },
            {
              key: "so_number",
              label: "Sales Order",
              width: 120,
              render: (row) => (
                <ViewDetailLink type="SO" id={row?.id}>
                  {row.so_number}
                </ViewDetailLink>
              ),
            },
            {
              key: "invoice_total",
              label: "Total",
              width: 120,
              rawValue: (row) => showCurrency(row.invoice?.total),
            },
            {
              key: "due_date",
              label: "Due Date",
              width: 120,
              rawValue: (row) => row.invoice?.due_date,
              render: (row) => (
                <Typography>{formatDate(row.invoice?.due_date)}</Typography>
              ),
            },
            {
              key: "invoice_status",
              label: "Status",
              width: 90,
              rawValue: (row) => row.invoice?.status,
              render: (row) => (
                <ChipStatus status={row.invoice?.status} size="small" />
              ),
            },

          ]}
          searchKeys={["inv", "so_number", "customerName"]}
          sortKeys={["due_date", "inv", "so_number", "invoice_total"]}
          filterStatus={{
            statusKey: "invoice_status",
            data: [
              { label: "Unpaid", value: "Unpaid" },
              { label: "Partially Paid", value: "Partially Paid" },
              { label: "Paid", value: "Paid" },
              { label: "Overdue", value: "Overdue" },
            ],
            customFilter: (row, selectedStatus) => {
              if (selectedStatus === "Overdue") {
                return (
                  row.invoice?.status === "Unpaid" &&
                  new Date(row.invoice?.due_date) < new Date()
                );
              }
              return row.invoice?.status === selectedStatus;
            },
          }}
          onRowClick={(row) =>
            navigate(
              `${ROUTERS.INVOICE_DETAIL.replace(":id", row.invoice?.id as any)}`
            )
          }
        />
      </PageWrapper>
      <ViewDetailModalWrapper />
    </>
  );
}

import Box from "@mui/joy/Box";
import Stack from "@mui/joy/Stack";
import Table from "@mui/joy/Table";

import { useAppSelector } from "redux-store";

import { ClearOutlined, ModeEdit } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import CustomerView from "components/CustomerView";
import { IReturnItem } from "types/return";
import { showProductItemName } from "utils/common";

type TReturnTableItem = {
  listItems: IReturnItem[];
  onEditItem?: (item: IReturnItem) => (event: any) => void;
  onRemoveItem?: (id?: number) => (event: any) => void;
  hasSerialNumber?: boolean;
};

const ReturnTableItems = ({
  listItems,
  onEditItem,
  onRemoveItem,
  hasSerialNumber,
}: TReturnTableItem) => {
  const productItems =
    useAppSelector((state) => state.product.productItems) || [];

  const getId = (item: IReturnItem) => item.item_id || item?.item?.id;

  return (
    <Stack spacing={2}>
      {hasSerialNumber ? (
        <Table variant={"outlined"}>
          <thead>
            <tr>
              <th>Item Name</th>
              <th>Customer</th>
              <th>SO #</th>
              {!!onRemoveItem && <th style={{ width: "65px" }}></th>}
            </tr>
          </thead>
          <tbody>
            {listItems.map((item) => {
              const product = productItems.find?.(
                (product) => product.id === getId(item)
              );

              return (
                <tr key={getId(item)}>
                  <td>
                    {showProductItemName(product, true)}
                    <small
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      SN: {item.item_real?.serial_number}
                    </small>
                  </td>
                  <td>
                    <CustomerView customer={item.customer as any} />
                  </td>
                  <td>{item.so_number || "-"}</td>
                  {!!onRemoveItem && (
                    <td>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                        }}
                      >
                        <Tooltip title="Remove" arrow>
                          <ClearOutlined
                            sx={{ cursor: "pointer" }}
                            fontSize="small"
                            onClick={onRemoveItem?.(item.item?.id)}
                          />
                        </Tooltip>
                      </Box>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      ) : (
        <Table variant={"outlined"}>
          <thead>
            <tr>
              <th>Item Name</th>
              <th style={{ width: "100px", textAlign: "right" }}>Qty</th>
              {!!onRemoveItem && <th style={{ width: "65px" }}></th>}
            </tr>
          </thead>
          <tbody>
            {listItems.map((item) => {
              const product = productItems.find?.(
                (product) => product.id === getId(item)
              );

              return (
                <tr key={getId(item)}>
                  <td>{showProductItemName(product, true)}</td>
                  <td
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {item.quantity || "-"}
                  </td>
                  {!!onRemoveItem && (
                    <td>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                        }}
                      >
                        <Tooltip title="Edit" arrow>
                          <ModeEdit
                            sx={{ cursor: "pointer" }}
                            fontSize="small"
                            onClick={onEditItem?.(item)}
                          />
                        </Tooltip>
                        <Tooltip title="Remove" arrow>
                          <ClearOutlined
                            sx={{ cursor: "pointer" }}
                            fontSize="small"
                            onClick={onRemoveItem?.(item.item?.id)}
                          />
                        </Tooltip>
                      </Box>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </Stack>
  );
};

export default ReturnTableItems;

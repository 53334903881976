import { IFreightQuote } from "types/freight-quote";
import { Request } from "utils/request";

export const createCustomerFreightQuoteApi = (payload: IFreightQuote) => {
  return Request.call<{ data: IFreightQuote }>({
    url: "/customer-portal/freight-quote/create",
    method: "PUT",
    data: payload,
  });
};

export const getListCustomerFreightQuoteApi = () => {
  return Request.call<{ data: IFreightQuote[] }>({
    url: "/customer-portal/freight-quote/list",
    method: "GET",
  });
};

export const getDetailCustomerFreightQuoteApi = (payload: { id: any }) => {
  return Request.call<{ data: IFreightQuote }>({
    url: "/customer-portal/freight-quote/detail/" + payload.id,
    method: "GET",
  });
};

export const cancelFreightQuoteApi = (payload: { id: any }) => {
  return Request.call<{ data: IFreightQuote }>({
    url: "/customer-portal/freight-quote/delete",
    method: "PUT",
    data: payload,
  });
};

export const acceptFreightQuoteApi = (payload: { id: any }) => {
  return Request.call<{ data: IFreightQuote }>({
    url: "/customer-portal/freight-quote/accept",
    method: "PUT",
    data: payload,
  });
};

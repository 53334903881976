import { createAsyncThunk } from "@reduxjs/toolkit";
import notification from "utils/notification";
export const createCallApiAsyncAction = <T, R>(
  actionName: string,
  asyncAction: (params: T) => Promise<R>,
  options?: {
    cache: (params: T, thunkAPI: any) => any;
  }
) => {
  return createAsyncThunk(actionName, async (params: T, thunkAPI) => {
    if (options?.cache) {
      const cacheResponse = options?.cache(params, thunkAPI);

      if (cacheResponse) {
        return {
          response: cacheResponse as R,
          request: params,
        };
      }
    }
    // do before api calling
    try {
      const response = await asyncAction(params);

      // @ts-ignore
      if (response.apiStatus) {
        return {
          response,
          request: params,
        };
      } else {
        throw response;
      }
    } catch (e: any) {
      if (e?.data?.message === "Unauthorized") {
        notification.error("Your session has expired. Please login again.");
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        thunkAPI.dispatch(
          require("redux-store/reducers/auth").setSession(null)
        );
      }

      return {
        response: e as R,
        request: params,
      };
    }
  });
};

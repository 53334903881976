import { Grid } from "@mui/joy";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import { useEffect, useState } from "react";
import { ICustomerAddress } from "types/customer-portal-so";
import AddressAutocompleteForm from "./AddressAutocompleteForm";

export default function AddressModal({
  isOpen,
  selectedAddress,
  onSave,
  onClose,
}: {
  isOpen: boolean;
  selectedAddress?: ICustomerAddress | null;
  onSave: (saveAddress: ICustomerAddress) => void;
  onClose: () => void;
}) {
  const [newAddress, setNewAddress] = useState<ICustomerAddress>({
    name: "",
    address: "",
    address2: "",
    phone: "",
    email: "",
    city: "",
    state: "",
    zip_code: "",
    country: "",
    type: "",
    company: "",
  });

  useEffect(() => {
    if (selectedAddress?.id) {
      setNewAddress({
        ...selectedAddress,
      });
    } else {
      setNewAddress({
        name: "",
        address: "",
        address2: "",
        phone: "",
        email: "",
        city: "",
        state: "",
        zip_code: "",
        country: "",
        company: "",
        type: selectedAddress?.type || "",
      });
    }
  }, [selectedAddress]);

  return (
    <BaseModal
      title={newAddress?.id ? `Edit ${newAddress.type} address` : "New Address"}
      isOpen={!!isOpen}
      onClose={onClose}
      actions={
        <Button
          onClick={() => {
            delete (newAddress as any).full_address;
            if (newAddress?.id) {
              delete (newAddress as any).QBId;
              delete (newAddress as any).is_default;
            }
            onSave(newAddress);
          }}
        >
          Save
        </Button>
      }
    >
      <Grid container spacing={2}>

        {/* <Grid sm={6} xs={12}>
          <FormControl>
            <BaseSelect
              options={[
                {
                  label: "Shipping",
                  value: "ship",
                },
                {
                  label: "Billing",
                  value: "bill",
                },
              ]}
              label="Type"
              value={newAddress?.type}
              disabled={!!newAddress?.id}
              onChange={(e) => setNewAddress({ ...newAddress, type: e })}
            />
          </FormControl>
        </Grid> */}
        <Grid sm={12} xs={12}>
          <AddressAutocompleteForm
            setNewAddress={(address) => {
              setNewAddress({
                ...newAddress,
                ...address,
              });
            }} />

        </Grid>

        <Grid sm={6} xs={12}>
          <FormControl>
            <BaseInput
              label="Company Name"
              value={newAddress?.company}
              onChange={(e) =>
                setNewAddress({ ...newAddress, company: e.target.value })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={6} xs={12}>
          <FormControl>
            <BaseInput
              label="Contact Name"
              value={newAddress?.name}
              onChange={(e) =>
                setNewAddress({ ...newAddress, name: e.target.value })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={6} xs={12}>
          <FormControl>
            <BaseInput
              label="Phone"
              value={newAddress?.phone}
              onChange={(e) =>
                setNewAddress({ ...newAddress, phone: e.target.value })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={6} xs={12}>
          <FormControl>
            <BaseInput
              label="Email"
              value={newAddress?.email}
              onChange={(e) =>
                setNewAddress({ ...newAddress, email: e.target.value })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={12} xs={12}>
          <FormControl>
            <BaseInput
              label="Address"
              value={newAddress?.address}
              onChange={(e) =>
                setNewAddress({ ...newAddress, address: e.target.value })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={12} xs={12}>
          <FormControl>
            <BaseInput
              label="Address 2"
              value={newAddress?.address2}
              onChange={(e) =>
                setNewAddress({ ...newAddress, address2: e.target.value })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={6} xs={12}>
          <FormControl>
            <BaseInput
              label="City"
              value={newAddress?.city}
              onChange={(e) =>
                setNewAddress({ ...newAddress, city: e.target.value })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={6} xs={12}>
          <FormControl>
            <BaseInput
              label="State"
              value={newAddress?.state}
              onChange={(e) =>
                setNewAddress({ ...newAddress, state: e.target.value })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={6} xs={12}>
          <FormControl>
            <BaseInput
              label="Zip Code"
              value={newAddress?.zip_code}
              onChange={(e) =>
                setNewAddress({ ...newAddress, zip_code: e.target.value })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={6} xs={12}>
          <FormControl>
            <BaseInput
              label="Country"
              value={newAddress?.country}
              onChange={(e) =>
                setNewAddress({ ...newAddress, country: e.target.value })
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </BaseModal>
  );
}

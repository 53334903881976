import { HelpOutlineOutlined } from "@mui/icons-material";
import { Box, Button, Stack, Table, Typography } from "@mui/joy";
import { Tooltip } from "@mui/material";
import { ChipStatus } from "components/ChipStatus";
import { IPurchaseOrderApproval } from "types/purchase";
import { formatDate } from "utils/common";

export default function ApprovalContent({
  warehouseApprovalRows,
  activeWorkflow,
  activeStatusAfterApprove,
  isActionLoading,
  handleAction,
  allowAccess,
}: {
  warehouseApprovalRows: IPurchaseOrderApproval[];
  activeWorkflow?: string;
  activeStatusAfterApprove?: string;
  isActionLoading: string;
  handleAction: ({
    workflow,
    status_after_approve,
  }: IPurchaseOrderApproval) => Promise<void>;
  allowAccess?: boolean;
}) {
  return (
    <Stack spacing={1}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {!!allowAccess && !!activeWorkflow && (
          <>
            <Button
              sx={{ mr: 1.5 }}
              size="sm"
              variant="outlined"
              loading={isActionLoading === "loading"}
              disabled={isActionLoading === "deny_loading"}
              onClick={() =>
                handleAction({
                  workflow: activeWorkflow,
                  status_after_approve: activeStatusAfterApprove,
                })
              }
            >
              {activeWorkflow}
            </Button>

            <Tooltip
              arrow
              title={`Your prompt attention is needed for the approval of a critical
              purchase order. By clicking the '${activeWorkflow}' button, you play a crucial
              role in maintaining our production efficiency and compliance
              standards.`}
            >
              <HelpOutlineOutlined fontSize="small" />
            </Tooltip>
          </>
        )}
      </Box>

      <Box>
        <Table
          variant={"outlined"}
          stickyHeader
          hoverRow
          sx={{
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
          }}
        >
          <thead>
            <tr>
              <th>Stage</th>
              <th style={{ width: "150px" }}>Person</th>
              <th style={{ width: "140px" }}>Date</th>
              <th style={{ width: "100px" }}>Status</th>
            </tr>
          </thead>
          <tbody>
            {warehouseApprovalRows?.map((row) => (
              <tr key={row.workflow}>
                <td>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      style={{ height: "auto", width: "38px", padding: "2px" }}
                      src={row.icon}
                      alt={row.workflow}
                    />
                    <Typography sx={{ fontWeight: 600, ml: 2 }}>
                      {row.region} {row.workflow}
                    </Typography>
                  </Box>
                </td>
                <td>
                  {!row.user?.name && "-"}
                  <Typography sx={{ fontWeight: 400 }}>
                    {row.user?.name}
                  </Typography>
                  <small>{row.user?.role?.name}</small>
                </td>

                <td>
                  {row.id && row.approval_status
                    ? formatDate(row.updated_at, true)
                    : "-"}
                </td>

                <td>
                  <ChipStatus
                    status={
                      row.id && row.approval_status
                        ? row.approval_status
                        : "Awaiting"
                    }
                    size="small"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Box>
    </Stack>
  );
}

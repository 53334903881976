import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";

import CommonList from "components/CommonList";
import PageWrapper from "components/PageWrapper";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { getListPromotion } from "redux-store/reducers/promotion";
import ROUTERS from "routers/constants";
import { formatDate } from "utils/common";

export default function PromotionListPage() {
  const promotionList = useAppSelector((state) => state.promotion.list);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleGetListPromotion = () => {
    dispatch(getListPromotion({}));
  };
  useEffect(() => {
    handleGetListPromotion();
  }, []);
  return (
    <PageWrapper
      pageAction={
        <Button
          color="primary"
          size="sm"
          onClick={() => navigate(ROUTERS.PROMOTION_NEW)}
        >
          Create
        </Button>
      }
      pageName="Promotions"
      breadcrumb={[
        {
          label: "Promotion",
        },
      ]}
    >
      <CommonList
        data={promotionList}
        columns={[
          {
            key: "name",
            label: "Name",
          },
          {
            key: "code",
            label: "Code",
          },
          {
            key: "status",
            label: "Status",
          },
          {
            key: "created_at",
            label: "Created At",
            width: 123,
            render: (row) => (
              <Typography>{formatDate(row.created_at)}</Typography>
            ),
          },
        ]}
        searchKeys={["name", "code"]}
        sortKeys={["created_at", "name"]}
        filterStatus={{
          statusKey: "status",
          data: [
            {
              label: "Active",
              value: "Active",
            },
            {
              label: "Inactive",
              value: "Inactive",
            },
          ],
        }}
        onRowClick={(row) =>
          navigate(`${ROUTERS.PROMOTION_DETAIL.replace(":id", row.id as any)}`)
        }
      />
    </PageWrapper>
  );
}

import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";

import { Button, Grid } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "redux-store";

import { SOStatusEnum } from "app-constants";
import { ChipStatus } from "components/ChipStatus";
import ConfirmUtils from "components/ConfirmUtils";
import PageWrapper from "components/PageWrapper";
import { DetailSkeleton } from "components/SkeletonComponents";
import {
  cancelSalesOrder,
  confirmSalesOrder,
  getDetailCustomerSaleOrder,
} from "redux-store/reducers/customer-portal-so";
import { ISalesOrder } from "types/sales-order";
import AppEvent from "utils/app-events";
import { formatDate } from "utils/common";
import useListenEmitEvent from "utils/common-hooks/useListenEmitEvent";
import notification from "utils/notification";
import DepositList from "./components/DepositList";
import DetailContent from "./components/DetailContent";
import ShipmentContent from "./components/ShipmentContent";

export default function PurchaseDetailPage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const id = useParams()?.id || detailId;
  const dispatch = useAppDispatch();
  const [cancelLoading, setCancelLoading] = useState(false);

  const [purchaseDetail, setPurchaseDetail] = useState<ISalesOrder>();

  const fetchDetailData = () => {
    if (!id) return;
    dispatch(
      getDetailCustomerSaleOrder({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.id) setPurchaseDetail(response.data);
        setCancelLoading(false);
      });
  };

  useEffect(() => {
    fetchDetailData();
  }, [id]);

  const handleCancelOrder = async () => {
    if (!(await ConfirmUtils.sure())) return;
    setCancelLoading(true);
    dispatch(
      cancelSalesOrder({
        so_id: id as any,
      })
    )
      .then(unwrapResult)
      .then(() => {
        fetchDetailData();
      });
  };

  const handleConfirmOrder = async () => {
    if (!(await ConfirmUtils.sure())) return;

    setCancelLoading(true);
    dispatch(
      confirmSalesOrder({
        so_id: id as any,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success("Confirm order success");
        } else {
          notification.error("Confirm order failed");
        }
        fetchDetailData();
      });
  };

  useListenEmitEvent({
    event: AppEvent.types.SO_STATUS_CHANGE,
    matchCondition: (payload) =>
      !!purchaseDetail?.id && payload.so_id == purchaseDetail.id,
    handle: () => {
      fetchDetailData();
    },
  });

  return (
    <Box sx={{ mt: "24px" }}>
      <PageWrapper
        pageName={
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography>
              Order Number: #{purchaseDetail?.so_number || ""}
              <br />
              <Typography sx={{ fontSize: 14, fontWeight: 300 }}>
                Date: {formatDate(purchaseDetail?.created_at, true)}
              </Typography>
            </Typography>
            {!!purchaseDetail && (
              <ChipStatus status={purchaseDetail.status} size="medium" />
            )}
          </Box>
        }
        pageAction={
          <Grid container spacing={1}>
            {[SOStatusEnum.AWAITING_CUSTOMER].includes(
              purchaseDetail?.status as any
            ) && (
              <Grid>
                <Button
                  variant="outlined"
                  size="sm"
                  onClick={handleConfirmOrder}
                  loading={cancelLoading}
                >
                  Accept Cost
                </Button>
              </Grid>
            )}

            {[SOStatusEnum.REVIEWING_COST].includes(
              purchaseDetail?.status as any
            ) && (
              <Grid>
                <Button
                  variant="outlined"
                  size="sm"
                  color="neutral"
                  onClick={handleCancelOrder}
                  loading={cancelLoading}
                >
                  Cancel Order
                </Button>
              </Grid>
            )}
          </Grid>
        }
        skeletonLoading={!purchaseDetail ? <DetailSkeleton /> : undefined}
        tabs={[
          {
            label: "Detail",
            content: !!purchaseDetail && (
              <DetailContent
                purchaseDetail={purchaseDetail}
                onUpdateSuccess={() => {
                  fetchDetailData();
                }}
              />
            ),
          },
          {
            label: "Deposit",
            content: !!purchaseDetail && (
              <DepositList salesOrderDetail={purchaseDetail as ISalesOrder} />
            ),
          },
          {
            label: "Shipment",
            content: !!purchaseDetail && (
              <ShipmentContent
                salesOrderDetail={purchaseDetail}
                onUpdateSuccess={() => {
                  fetchDetailData();
                }}
              />
            ),
          },
        ]}
      ></PageWrapper>
    </Box>
  );
}

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Box, Button, Card, FormControl, FormLabel, Grid, IconButton, Stack, Typography } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import BaseSelect from "components/BaseSelect";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import { getListApprovalConfig, updateApprovalConfig } from "redux-store/reducers/settings";
import { IApprovalConfigs } from "types/settings";
import notification from "utils/notification";

const FieldList = ({
  configs,
  onChange,
  type,
  workflow,
}: {
  configs: IApprovalConfigs[];
  onChange: (val: IApprovalConfigs[]) => void;
  type: string
  workflow: string
}) => {
  const [items, setItems] = useState<IApprovalConfigs[]>(JSON.parse(JSON.stringify(configs)));
  const employeeList = useAppSelector(state => state.employee.list)

  const handleAddNewOption = () => {
    const newItems = [...items];
    newItems.push({
      user_id: undefined,
      level: items.length + 1,
      type: type,
      workflow: workflow,
    });
    setItems(newItems);
  }

  useEffect(() => {
    onChange(items)
  }, [items])
  return <Stack spacing={1} mt={2} mb={2}>
    <Grid
      container
      sx={{
        color: (theme) => theme.palette.neutral[500],
      }}
    >
      <Grid sx={{
        width: 50,
        pr: 1,
      }}>Level</Grid>
      <Grid
        sx={{
          width: 400,
          pl: 1,
        }}
      >Employee</Grid>
    </Grid>

    <Box sx={{ marginLeft: 1, width: 40 }}>
      {!items?.length ? (
        <IconButton
          color="primary"
          size="sm"
          onClick={handleAddNewOption}
          variant="outlined"
        >
          <AddIcon />
        </IconButton>
      ) : null}
    </Box>
    {items.map((itemDetail, index) => (
      <Grid container key={index} >
        <Grid sx={{
          width: 50,
          pr: 1,
          display: "flex",
          alignItems: "center",
        }}>
          <FormControl>
            <Typography>{index + 1}</Typography>
          </FormControl>
        </Grid>
        <Grid sx={{
          width: 400,
          pl: 1,
        }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FormControl sx={{ width: "100%", marginRight: 1 }}>
              <BaseSelect
                options={employeeList?.map(item => ({
                  value: item.id,
                  label: item.name || '',
                }))}

                value={itemDetail.user_id}
                onChange={(val) => {
                  const newItems = [...items];
                  newItems[index].user_id = val;
                  setItems(newItems);
                }}
              />
            </FormControl>
            <IconButton
              variant="outlined"
              size="sm"
              disabled={items.length <= 1}
              onClick={() => {
                if (items.length <= 1) return;
                const newItems = [...items];
                newItems.splice(index, 1);
                setItems(newItems);
              }}
            >
              <RemoveIcon />
            </IconButton>
            <Box sx={{ marginLeft: 1, width: 40 }}>
              {index === items.length - 1 ? (
                <IconButton
                  color="primary"
                  size="sm"
                  onClick={handleAddNewOption}
                  variant="outlined"
                >
                  <AddIcon />
                </IconButton>
              ) : null}
            </Box>
          </Box>
        </Grid>
      </Grid>
    ))}

  </Stack>
}

export default function ApprovalConfigs() {
  const approvalConfigs = useAppSelector(state => state.settings.approvalConfigs)
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const fetchDetail = () => {
    dispatch(getListApprovalConfig({
    }))
  }

  const [soOverdueConfigs, setSoOverdueConfigs] = useState(approvalConfigs.filter(item => item.type === "so" && item.workflow === 'overdue'))
  const [soNormalConfigs, setSoNormalConfigs] = useState(approvalConfigs.filter(item => item.type === "so" && item.workflow === 'normal'))
  const [pricingApproveConfigs, setPricingApproveConfigs] = useState(approvalConfigs.filter(item => item.type === "so" && item.workflow === 'cost_review'))
  const handleUpdateSettings = () => {
    setLoading(true)
    dispatch(updateApprovalConfig({
      config_approval: [...soOverdueConfigs.map((v, idx) => ({ ...v, level: idx + 1 })), ...soNormalConfigs.map((v, idx) => ({ ...v, level: idx + 1 })), ...pricingApproveConfigs.map((v, idx) => ({ ...v, level: idx + 1 }))]
    })).then(unwrapResult).then(
      ({ response }) => {
        if (response.data) {
          notification.success('Update success')
          fetchDetail()
        } else {
          notification.error('Update failed')
        }
      }
    ).finally(() => {
      setLoading(false)
    })

  }
  return <Stack
    spacing={2}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",

      }}
    >
      <Button
        color="primary"
        size="sm"
        onClick={handleUpdateSettings}
        loading={loading}
      >
        {"Update"}
      </Button>
    </Box>
    <Card
      color="neutral"
      invertedColors={false}
      orientation="vertical"
      size="sm"
      variant="outlined"
    >
      <Stack spacing={1}>
        <FormLabel>Sales Order - Invoice Overdue</FormLabel>

        <FieldList
          configs={soOverdueConfigs}
          onChange={setSoOverdueConfigs}
          type={"so"}
          workflow="overdue"
        />

      </Stack>
    </Card>
    <Card
      color="neutral"
      invertedColors={false}
      orientation="vertical"
      size="sm"
      variant="outlined"
    >
      <Stack spacing={1}>
        <FormLabel>Sales Order - Normal</FormLabel>
        <FieldList
          configs={soNormalConfigs}
          onChange={setSoNormalConfigs}
          type={"so"}
          workflow="normal"
        />
      </Stack>

    </Card>

    <Card
      color="neutral"
      invertedColors={false}
      orientation="vertical"
      size="sm"
      variant="outlined"
    >
      <Stack spacing={1}>
        <FormLabel>Pricing Approve</FormLabel>
        <FieldList
          configs={pricingApproveConfigs}
          onChange={setPricingApproveConfigs}
          type={"so"}
          workflow="cost_review"
        />
      </Stack>

    </Card>
  </Stack>
}
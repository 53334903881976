import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  applySOPromoApi,
  approveSOFullApi,
  approveSalesOrderApi,
  cancelSalesOrderApi,
  completeOrderApi,
  completePackingApi,
  completePickPackageApi,
  createSODepositApi,
  createSOPackageApi,
  createSalesOrderApi,
  deleteSODepositApi,
  deleteSOPackageApi,
  deleteSalesOrderApi,
  denySalesOrderApi,
  getApprovalsSalesOrderApi,
  getDetailSalesOrderApi,
  getPackageUPSRateApi,
  getPickingItemsListApi,
  getSODepositListApi,
  getSOHistoryApi,
  getSOPackagesApi,
  getShipCarrierApi,
  listSalesOrderApi,
  listSalesOrderPickApi,
  markAsShippedMultiPackageApi,
  moveItemPackageApi,
  pickSerialNumberApi,
  savePackageUPSSyncApi,
  shipSOPackageApi,
  undoToPackingApi,
  unpickPackageApi,
  updateSOAddressSectionApi,
  updateSOInternalNoteApi,
  updateSOItemApi,
  updateSOPackageApi,
  updateSOShipSectionApi,
  voidUpsShipmentApi,
} from "services/sales-order.service";
import { ISalesOrder } from "types/sales-order";

const TYPE = "SALES_ORDER";

export interface ISalesOrderState {
  list: ISalesOrder[];
  pickList: ISalesOrder[];
}

const initialState: ISalesOrderState = {
  list: [],
  pickList: [],
};

export const createSalesOrder = createCallApiAsyncAction(
  TYPE + "/createSalesOrder",
  createSalesOrderApi
);
export const getListSalesOrder = createCallApiAsyncAction(
  TYPE + "/getListSalesOrder",
  listSalesOrderApi
);

export const getListSalesOrderPick = createCallApiAsyncAction(
  TYPE + "/getListSalesOrderPick",
  listSalesOrderPickApi
);

export const getDetailSalesOrder = createCallApiAsyncAction(
  TYPE + "/getDetailSalesOrder",
  getDetailSalesOrderApi
);

export const cancelSalesOrder = createCallApiAsyncAction(
  TYPE + "/cancelSalesOrder",
  cancelSalesOrderApi
);

export const deleteSalesOrder = createCallApiAsyncAction(
  TYPE + "/deleteSalesOrder",
  deleteSalesOrderApi
);

export const approveSalesOrder = createCallApiAsyncAction(
  TYPE + "/approveSalesOrder",
  approveSalesOrderApi
);

export const denySalesOrder = createCallApiAsyncAction(
  TYPE + "/denySalesOrder",
  denySalesOrderApi
);

export const getApprovalsSalesOrder = createCallApiAsyncAction(
  TYPE + "/getApprovalsSalesOrder",
  getApprovalsSalesOrderApi
);

export const updateSOShipSection = createCallApiAsyncAction(
  TYPE + "/updateSOShipSection",
  updateSOShipSectionApi
);

export const updateSOAddressSection = createCallApiAsyncAction(
  TYPE + "/updateSOAddressSection",
  updateSOAddressSectionApi
);

export const updateSOInternalNote = createCallApiAsyncAction(
  TYPE + "/updateSOInternalNote",
  updateSOInternalNoteApi
);

export const applySOPromo = createCallApiAsyncAction(
  TYPE + "/applySOPromo",
  applySOPromoApi
);

export const getSOHistory = createCallApiAsyncAction(
  TYPE + "/getSOHistory",
  getSOHistoryApi
);

export const getSOPackages = createCallApiAsyncAction(
  TYPE + "/getSOPackages",
  getSOPackagesApi
);

export const getPackageUPSRate = createCallApiAsyncAction(
  TYPE + "/getPackageUPSRate",
  getPackageUPSRateApi
);

export const unpickItemFromPackage = createCallApiAsyncAction(
  TYPE + "/unpickPackage",
  unpickPackageApi
);

export const moveItemPackage = createCallApiAsyncAction(
  TYPE + "/moveItemPackage",
  moveItemPackageApi
);

export const savePackageUPSSync = createCallApiAsyncAction(
  TYPE + "/savePackageUPSSync",
  savePackageUPSSyncApi
);

export const createSOPackage = createCallApiAsyncAction(
  TYPE + "/createSOPackage",
  createSOPackageApi
);

export const updateSOPackage = createCallApiAsyncAction(
  TYPE + "/updateSOPackage",
  updateSOPackageApi
);

export const deleteSOPackage = createCallApiAsyncAction(
  TYPE + "/deleteSOPackage",
  deleteSOPackageApi
);

export const markAsShippedMultiPackage = createCallApiAsyncAction(
  TYPE + "/markAsShippedMultiPackage",
  markAsShippedMultiPackageApi
);

export const undoToPacking = createCallApiAsyncAction(
  TYPE + "/undoToPacking",
  undoToPackingApi
);



export const voidUpsShipment = createCallApiAsyncAction(
  TYPE + "/voidUpsShipment",
  voidUpsShipmentApi
);



export const completePacking = createCallApiAsyncAction(
  TYPE + "/completePacking",
  completePackingApi
);


export const getPickingItemsList = createCallApiAsyncAction(
  TYPE + "/getPickingItemsList",
  getPickingItemsListApi
);

export const pickSerialNumber = createCallApiAsyncAction(
  TYPE + "/pickSerialNumber",
  pickSerialNumberApi
);



export const shipSOPackage = createCallApiAsyncAction(
  TYPE + "/shipSOPackage",
  shipSOPackageApi
);

export const completePickPackage = createCallApiAsyncAction(
  TYPE + "/completePickPackage",
  completePickPackageApi
);

export const createSODeposit = createCallApiAsyncAction(
  TYPE + "/createSODeposit",
  createSODepositApi
);

export const deleteSODeposit = createCallApiAsyncAction(
  TYPE + "/deleteSODeposit",
  deleteSODepositApi
);

export const getSODepositList = createCallApiAsyncAction(
  TYPE + "/getSODepositList",
  getSODepositListApi
);

export const updateSOItem = createCallApiAsyncAction(
  TYPE + "/updateSOItem",
  updateSOItemApi
);

export const completeOrder = createCallApiAsyncAction(
  TYPE + "/completeOrder",
  completeOrderApi
);

export const approveSOFull = createCallApiAsyncAction(
  TYPE + "/approveSOFull",
  approveSOFullApi
);

export const getShipCarrier = createCallApiAsyncAction(
  TYPE + "/getShipCarrier",
  getShipCarrierApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListSalesOrder,
      stateKey: "list",
    });

    addListBuilderCase({
      builder,
      fetchAction: getListSalesOrderPick,
      stateKey: "pickList",
    });
  },
  reducers: {},
});

export default slice.reducer;

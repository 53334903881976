import { Typography } from "@mui/joy";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import PageWrapper from "components/PageWrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  getListManufacturing
} from "redux-store/reducers/manufacturing";
import ROUTERS from "routers/constants";
import { formatDate } from "utils/common";

export default function ManufacturingListPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const manufacturingList = useAppSelector((state) => state.manufacturing.list);
  const [filterStatus, setFilterStatus] = useState<string>("");
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const [openShippingInfo, setOpenShippingInfo] = useState(false);
  const handleGetListManufacturing = () => {
    dispatch(getListManufacturing({}));
  };

  useEffect(() => {
    handleGetListManufacturing();
  }, []);

  return (
    <PageWrapper
      pageName="Manufacturing"
      breadcrumb={[
        {
          label: "Manufacturing",
        },
      ]}
    >
      <CommonList
        data={manufacturingList}
        defaultStatusFilter={filterStatus}
        onChangeStatusFilter={(status) => {
          setFilterStatus(status);
        }}
        columns={[
          {
            key: "po_number",
            label: "#",
            width: 80,
            render: (row) => <b>{row.po_number}</b>,
          },
          {
            key: "name",
            label: "Name",
          },
          {
            key: "status",
            label: "Status",
            width: 200,
            render: (row) => <ChipStatus status={row.status} />,
          },
          {
            key: "created_at",
            label: "Created At",
            width: 140,
            render: (row) => (
              <Typography>{formatDate(row.created_at)}</Typography>
            ),
          },
        ]}
        sortKeys={["created_at", "po_number", "name", "total"]}
        onRowClick={(row) =>
          navigate(
            `${ROUTERS.MANUFACTURING_DETAIL.replace(":id", row.id as any)}`
          )
        }
      />
    </PageWrapper>
  );
}

import { Typography } from "@mui/joy";
import { FreightQuoteStatusEnum } from "app-constants";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import CustomerView from "components/CustomerView";
import PageWrapper from "components/PageWrapper";
import { ViewDetailModalWrapper } from "components/ViewDetailModal";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { getListFreightQuote } from "redux-store/reducers/freight-quote";
import ROUTERS from "routers/constants";
import { formatDate } from "utils/common";

export default function FreightQuoteListPage() {
  const freightQuoteList = useAppSelector((state) => state.freightQuote.list);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleGetListFreightQuote = () => {
    dispatch(getListFreightQuote({}));
  };
  useEffect(() => {
    handleGetListFreightQuote();
  }, []);
  return (
    <>
      <PageWrapper
        pageName="Freight Quotes"
        breadcrumb={[
          {
            label: "Freight Quotes",
          },
        ]}
      >
        <CommonList
          data={freightQuoteList}
          columns={[
            {
              key: "id",
              label: "#",
              width: 40,
            },
            {
              key: "customerName",
              label: "Customer",
              width: 150,
              render: (row) => <CustomerView customer={row.customer as any} />,
              rawValue: (row) =>
                `${row.customer?.first_name} ${row.customer?.last_name} ${row.customer?.email} ${row.customer?.cus_number}`,
            },
            {
              key: "is_expedited",
              label: "Expedited",
              width: 80,
              rawValue: (row) => (row.is_expedited ? "Yes" : "No"),
            },
            {
              key: "shipping_service",
              label: "Service",
              width: 150,
              rawValue: (row) => row.shipping_service || "-",
            },
            {
              key: "status",
              label: "Status",
              width: 100,
              render: (row) => <ChipStatus size="small" status={row.status} />,
            },
            {
              key: "created_at",
              label: "Created At",
              width: 120,
              render: (row) => (
                <Typography>{formatDate(row.created_at)}</Typography>
              ),
            },
          ]}
          searchKeys={["id", "note"]}
          sortKeys={["created_at", "id", "note", "status"]}
          filterStatus={{
            statusKey: "status",
            data: [
              { label: "Submitted", value: FreightQuoteStatusEnum.SUBMITTED },
              {
                label: "Cost Provided",
                value: FreightQuoteStatusEnum.COST_PROVIDED,
              },
            ],
          }}
          onRowClick={(row) =>
            navigate(
              `${ROUTERS.FREIGHT_QUOTE_DETAIL.replace(":id", row.id as any)}`
            )
          }
        />
      </PageWrapper>
      <ViewDetailModalWrapper />
    </>
  );
}

import Typography from "@mui/joy/Typography";

import { ClearOutlined, ModeEdit } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Box, Button, Card, FormControl, Grid, IconButton, Stack, Table } from "@mui/joy";
import { Tooltip } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { POStatusEnum } from "app-constants";
import BaseCurrencyInput from "components/BaseCurrencyInput";
import BaseModal from "components/BaseModal";
import ConfirmUtils from "components/ConfirmUtils";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import { updateCheckCostItem, updatePurchaseItem, updatePurchaseVendor } from "redux-store/reducers/purchase";
import { IPurchaseOrder, IPurchaseOrderItem } from "types/purchase";
import {
  calcGrantTotalPO,
  showCurrency,
  showProductItemName
} from "utils/common";
import notification from "utils/notification";
import { RolesEnum, getPermission } from "utils/roles";
import ModalAddNew from "./ModalAddNew";
import ModalChangeSupplier from "./ModalChangeSupplier";
export default function DetailContent({
  purchaseDetail,
  onUpdateSuccess,
}: {
  purchaseDetail: IPurchaseOrder;
  onUpdateSuccess: () => void;
}) {
  const dispatch = useAppDispatch();
  const roleName = useAppSelector((state) => state.auth.user?.roleName);
  const [itemSelected, setItemSelected] = useState<IPurchaseOrderItem>();
  const [isOpenCheckCost, setIsOpenCheckCost] = useState<IPurchaseOrderItem | null>(null);
  const [checkCost, setCheckCost] = useState(0);
  const [isOpenSupplier, setIsOpenSupplier] = useState(false);
  const [supplierId, setSupplierId] = useState(purchaseDetail.supplier.id);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (purchaseDetail.supplier.id !== supplierId && !!supplierId) {
      // call api update
      dispatch(
        updatePurchaseVendor({
          po_id: purchaseDetail.id,
          supplier_id: supplierId
        })
      )
        .then(unwrapResult)
        .then((rs) => {
          if (rs.response.apiStatus) {
            onUpdateSuccess();
            notification.success("Update vendor success");
          } else {
            notification.error("Update vendor failed");
          }
        });
    }
  }, [supplierId]);

  const onCloseModal = () => {
    setIsOpen(false);
    setItemSelected(undefined);
  };

  const onCreateItem = (item: IPurchaseOrderItem) => {
    setIsOpen(false);
    setItemSelected(undefined);
    dispatch(
      updatePurchaseItem({
        po_id: purchaseDetail.id,
        id: item.id || undefined,
        item_id: item.item?.id || item.item_id,
        quantity: item.quantity,
        cost: !getPermission("NOT_PO_VIEW_PRICE") ? item.cost : undefined,
        note: item.note,
      })
    )
      .then(unwrapResult)
      .then((rs) => {
        if (rs.response.apiStatus) {
          onUpdateSuccess();
          notification.success("Save item success");
        } else {
          notification.error("Save item failed");
        }
      });
  };

  const onRemoveItem = (item: IPurchaseOrderItem) => {
    ConfirmUtils.sure().then((isOk) => {
      if (!isOk) return;

      dispatch(
        updatePurchaseItem({
          po_id: purchaseDetail.id,
          id: item.id || undefined,
          item_id: item.item?.id || item.item_id,
          quantity: item.quantity,
          cost: item.cost,
          note: item.note,
          is_delete: true,
        })
      )
        .then(unwrapResult)
        .then((rs) => {
          if (rs.response.apiStatus) {
            onUpdateSuccess();
            notification.success("Delete item success");
          } else {
            notification.error("Delete item failed");
          }
        });
    });
  };

  const updateCheckCost = () => {
    dispatch(
      updateCheckCostItem({
        po_id: purchaseDetail.id,
        id: isOpenCheckCost?.id,
        item_id: isOpenCheckCost?.item?.id,
        check_cost: Number(checkCost) || null
      })
    )
      .then(unwrapResult)
      .then((rs) => {
        if (rs.response.apiStatus) {
          onUpdateSuccess();
          notification.success("Update item success");
          setIsOpenCheckCost(null)
        } else {
          notification.error("Update item failed");
        }
      });
  }

  const checkCostItem = (item: IPurchaseOrderItem) => {
    setIsOpenCheckCost(item)
  };

  const onEditItem = (itemSelected: IPurchaseOrderItem) => {
    setItemSelected(itemSelected);
    setIsOpen(true);
  };

  const allowUpdateItems = ([
    RolesEnum.CEO,
    RolesEnum.COO,
    RolesEnum.Director,
    RolesEnum.GeneralManager,
    RolesEnum.Administrator,
  ].includes(roleName as RolesEnum) && purchaseDetail?.status === POStatusEnum.PENDING_APPROVAL) ||
    (purchaseDetail?.status === POStatusEnum.PENDING_APPROVAL &&
      !getPermission("NOT_PO_EDIT"));

  return (
    <Stack spacing={2}>
      <Card
        color="neutral"
        invertedColors={false}
        orientation="vertical"
        size="sm"
        variant="outlined"
      >
        <Grid container spacing={2}>
          <Grid sm={4}>
            <Typography
              sx={{
                fontSize: 14,
                mb: 1,
              }}
            >
              Vendor{""}
              <IconButton
                sx={{ ml: 2 }}
                size="sm"
                onClick={() => setIsOpenSupplier(true)}
              >
                <ModeEdit fontSize="small" />
              </IconButton>
            </Typography>

            <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
              {purchaseDetail?.supplier?.name}
            </Typography>

            <Typography sx={{ fontSize: 14 }}>
              {purchaseDetail?.supplier.address}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              {purchaseDetail?.supplier?.city}, {purchaseDetail?.supplier.state}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              {purchaseDetail?.supplier?.zip_code}{" "}
              {purchaseDetail?.supplier.country}
            </Typography>

            {purchaseDetail?.supplier?.phone && <Typography sx={{ fontSize: 14 }}>
              P: {purchaseDetail?.supplier?.phone}
            </Typography>}

            {purchaseDetail?.supplier?.email && <Typography sx={{ fontSize: 14 }}>
              E: {purchaseDetail?.supplier?.email}
            </Typography>}
          </Grid>
          <Grid sm={4}>
            <Typography
              sx={{
                fontSize: 14,
                mb: 1,
              }}
            >
              Ship To
            </Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
              {purchaseDetail?.warehouse.name}
            </Typography>

            <Typography sx={{ fontSize: 14 }}>
              {purchaseDetail?.warehouse.address}
            </Typography>

            <Typography sx={{ fontSize: 14 }}>
              {purchaseDetail?.warehouse.city},{" "}
              {purchaseDetail?.warehouse.state}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              {purchaseDetail?.warehouse.zip_code}{" "}
              {purchaseDetail?.warehouse.country}
            </Typography>
          </Grid>


        </Grid>
      </Card>

      <Stack spacing={2}>
        {allowUpdateItems && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <IconButton
              color="primary"
              size="sm"
              onClick={() => {
                setIsOpen(true);
              }}
              variant="outlined"
            >
              <AddIcon />
            </IconButton>
          </Box>
        )}
        <Table variant={"outlined"}>
          <thead>
            <tr>
              <th
                style={{
                  width: "40px",
                }}
              >#</th>
              <th
                style={{
                  width: "140px",
                }}
              >Code</th>
              <th>Description</th>
              <th style={{ width: "90px", textAlign: "right" }}>Qty</th>
              <th style={{ width: "90px", textAlign: "right" }}>Unit</th>

              {!getPermission('NOT_PO_VIEW_PRICE') ? (
                <>
                  <th
                    style={{
                      textAlign: "right",
                      width: "120px"
                    }}
                  >
                    Unit Cost
                  </th>
                  <th
                    style={{
                      textAlign: "right",
                      width: "120px"
                    }}
                  >
                    Cost Checked
                  </th>
                  <th
                    style={{
                      width: "140px",
                      textAlign: "right",
                    }}
                  >
                    Total
                  </th>
                </>
              ) : (
                <th style={{ width: "100px" }}></th>
              )}
              {allowUpdateItems && <th style={{ width: "65px" }}></th>}
            </tr>
          </thead>
          <tbody>
            {purchaseDetail?.purchase_order_items.map(
              (lineItem: any, index: number) => {
                const item = lineItem.item;

                return (
                  <tr key={item?.id}>
                    <td>{index + 1}</td>
                    <td><strong>
                      {item.code}
                    </strong></td>
                    <td>
                      {showProductItemName(item, false)}
                    </td>

                    <td style={{ textAlign: "right" }}>
                      {lineItem?.quantity}
                    </td>

                    <td style={{ textAlign: "right" }}>
                      {item?.unit}
                    </td>
                    {!getPermission("NOT_PO_VIEW_PRICE") ? (
                      <>
                        <td style={{ textAlign: "right" }}>
                          {" "}
                          {showCurrency(lineItem?.cost)}{" "}
                        </td>

                        <td style={{ textAlign: "right" }}>
                          <Box
                            sx={{
                              cursor: "pointer",
                              '&:hover': {
                                textDecorationLine: "underline"
                              }
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              checkCostItem(lineItem);
                            }}
                          >{lineItem?.check_cost ? <Box sx={{ color: "red" }}>{showCurrency(lineItem?.check_cost)}</Box> : <>--</>}</Box>
                        </td>

                        <td style={{ textAlign: "right" }}>
                          {" "}
                          {showCurrency(lineItem?.total)}{" "}
                        </td>


                      </>
                    ) : (
                      <td style={{ width: "100px" }}></td>
                    )}

                    {allowUpdateItems && (
                      <td>
                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                          }}
                        >
                          <Tooltip title="Edit" arrow>
                            <ModeEditIcon
                              sx={{ cursor: "pointer" }}
                              fontSize="small"
                              onClick={(e) => {
                                onEditItem(lineItem);
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Remove" arrow>
                            <ClearOutlined
                              sx={{ cursor: "pointer" }}
                              fontSize="small"
                              onClick={(e) => {
                                onRemoveItem(lineItem);
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </td>
                    )}
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flex: 1 }}>
            {purchaseDetail ? (
              <>
                <Typography fontSize="sm">Purchase Order Name:</Typography>
                <Typography fontSize="sm" fontWeight={500}>
                  {purchaseDetail?.name || "-"}
                </Typography>

                <Typography fontSize="sm" sx={{ mt: 2 }}>
                  Comment or Special Instructions:
                </Typography>

                <Typography fontSize="sm" fontWeight={500}>
                  {purchaseDetail?.note || "-"}
                </Typography>
              </>
            ) : (
              <></>
            )}
          </Box>
          {!getPermission("NOT_PO_VIEW_PRICE") && (
            <>
              <Box mr={6}>
                <Typography fontSize="sm" fontWeight={600} textAlign="right">
                  Subtotal
                </Typography>
                <Typography fontSize="sm" textAlign="right" mb={2}>
                  Other
                </Typography>
                <Typography fontSize="sm" fontWeight={600} textAlign="right">
                  Total
                </Typography>
              </Box>
              <Box>
                <Typography fontSize="sm" fontWeight={600} textAlign="right">
                  {showCurrency(purchaseDetail?.total)}
                </Typography>

                <Typography fontSize="sm" textAlign="right" mb={2}>
                  {showCurrency(purchaseDetail?.other_cost)}
                </Typography>
                <Typography fontSize="sm" fontWeight={600} textAlign="right">
                  {purchaseDetail
                    ? showCurrency(calcGrantTotalPO(purchaseDetail))
                    : "-"}
                </Typography>
              </Box>
            </>
          )}
        </Box>
      </Stack>

      <ModalChangeSupplier
        isOpen={isOpenSupplier}
        itemId={supplierId}
        setItemId={(id: string | number) => {
          setSupplierId(+id);
        }}
        onCloseModal={() => setIsOpenSupplier(false)}
      />

      <ModalAddNew
        isOpen={isOpen}
        itemSelected={itemSelected}
        onCloseModal={onCloseModal}
        onCreateItem={onCreateItem}
        items={purchaseDetail?.purchase_order_items}
      />

      <BaseModal
        title="Add Checked Cost"
        isOpen={!!isOpenCheckCost}
        width={280}
        onClose={() => setIsOpenCheckCost(null)}
        actions={
          <Button onClick={updateCheckCost}>
            Save
          </Button>
        }
      >
        <Grid container spacing={2}>
          <Grid sm={12} >
            <FormControl>
              <BaseCurrencyInput
                label="Unit Cost"
                value={checkCost}
                onChange={(e: any) => setCheckCost(e)}
              />
            </FormControl>
          </Grid>
        </Grid>
      </BaseModal>
    </Stack>
  );
}

import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";

import { Typography } from "@mui/joy";
import { FreightQuoteStatusEnum } from "app-constants";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import PageWrapper from "components/PageWrapper";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { getListCustomerFreightQuote } from "redux-store/reducers/customer-portal-freight-quote";
import { getListCustomerAddress } from "redux-store/reducers/customer-portal-so";
import { getCustomerProductItems } from "redux-store/reducers/product";
import { ROUTERS_CUSTOMER } from "routers/constants";
import { formatDate } from "utils/common";

export default function FreightQuoteListPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const freightQuoteList =
    useAppSelector((state) => state.customerPortalFreightQuote.list) || [];

  const handleGetListFreightQuote = () => {
    dispatch(getListCustomerFreightQuote({}));
  };

  useEffect(() => {
    handleGetListFreightQuote();
    dispatch(getCustomerProductItems({}));
    dispatch(getListCustomerAddress({}));
  }, []);

  return (
    <Box sx={{ mt: "24px" }}>
      <PageWrapper
        pageName="Freight Quote List"
        pageAction={
          <Button
            size="sm"
            color="primary"
            onClick={() => navigate(ROUTERS_CUSTOMER.FREIGHT_QUOTE_NEW)}
          >
            Create
          </Button>
        }
      >
        <CommonList
          data={freightQuoteList}
          columns={[
            {
              key: "id",
              label: "#",
              width: 40,
            },
            {
              key: "is_expedited",
              label: "Expedited",
              width: 80,
              rawValue: (row) => (row.is_expedited ? "Yes" : "No"),
            },
            {
              key: "shipping_service",
              label: "Service",
              width: 150,
              rawValue: (row) => row.shipping_service || "-",
            },
            {
              key: "status",
              label: "Status",
              width: 100,
              render: (row) => <ChipStatus size="small" status={row.status} />,
            },
            {
              key: "created_at",
              label: "Created At",
              width: 120,
              render: (row) => (
                <Typography>{formatDate(row.created_at)}</Typography>
              ),
            },
          ]}
          searchKeys={["id", "note"]}
          sortKeys={["created_at", "id", "note", "status"]}
          filterStatus={{
            statusKey: "status",
            data: [
              { label: "Submitted", value: FreightQuoteStatusEnum.SUBMITTED },
              {
                label: "Cost Provided",
                value: FreightQuoteStatusEnum.COST_PROVIDED,
              },
            ],
          }}
          onRowClick={(row) =>
            navigate(
              `${ROUTERS_CUSTOMER.FREIGHT_QUOTE_DETAIL.replace(
                ":id",
                row.id as any
              )}`
            )
          }
        />
      </PageWrapper>
    </Box>
  );
}

import { Autocomplete, Chip } from "@mui/material";
import _ from "lodash";
import { useMemo } from "react";
import BaseInput from "./BaseInput";

type IProps = {
  value: any[];
  onChange: (val: any[]) => void;
  label?: string;
  limitTags?: number;
  options?: {
    value: any
    label: any
  }[]
  freeSolo?: boolean
  placeholder?: string;
  displayOption?: (value: any) => string
};

const BaseTagsInput = ({ placeholder, value, onChange, label, limitTags = 20, options, freeSolo = false, displayOption }: IProps) => {
  const mapLabel = useMemo(() => {
    return _.reduce(options, function (map, obj) {
      map[obj.value] = obj.label;
      return map;
    }, {} as any);

  }, [options])

  const localOptions = useMemo(() => {
    let option: { value: any; label: string }[] = []
    let optionRaw = new Set()

    _.forEach(options, v => {
      if (!value?.includes(v.value) && !optionRaw.has(v.value)) {
        optionRaw.add(v.value);
        option.push(v)
      }
    })

    return option;
  }, [options, value])

  return (
    <Autocomplete
      limitTags={limitTags}
      size="small"
      multiple
      options={localOptions}
      value={value}
      onChange={(e, value) => {
        if (value.length > limitTags) return;
        onChange(_.map(value, (v) => v.value || v));
      }}
      fullWidth
      freeSolo={freeSolo}
      renderTags={(value: readonly any[], getTagProps) => {
        console.log('xxxxxxxxx', mapLabel[value[0]], mapLabel, value[0])
        return value.map((option: any, index: number) => (
          <Chip
            color="primary"
            size="small"
            variant="outlined"
            label={mapLabel[option]}
            {...getTagProps({ index })}
          />
        ))
      }
      }
      renderInput={(params) => <BaseInput {...params} label={label} placeholder={placeholder} />}
    />
  );
};

export default BaseTagsInput;

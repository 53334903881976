import Typography from "@mui/joy/Typography";

import { Box, Card, Grid, Stack, Table } from "@mui/joy";
import { IPurchaseOrder } from "types/purchase";
import { formatDate, showCurrency, showProductItemName } from "utils/common";

export default function DetailContent({
  manufacturing,
  onUpdateSuccess,
}: {
  manufacturing: IPurchaseOrder;
  onUpdateSuccess: () => void;
}) {
  return (
    <Stack spacing={2}>
      <Card
        color="neutral"
        invertedColors={false}
        orientation="vertical"
        size="sm"
        variant="outlined"
      >
        <Grid container spacing={2}>
          
          <Grid sm={4}>
            <Typography
              sx={{
                fontSize: 14,
                mb: 1,
              }}
            >
              Vendor
            </Typography>

            <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
              {manufacturing?.supplier?.name}
            </Typography>

            <Typography sx={{ fontSize: 14 }}>
              {manufacturing?.supplier.address}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              {manufacturing?.supplier?.city}, {manufacturing?.supplier.state}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              {manufacturing?.supplier?.zip_code}{" "}
              {manufacturing?.supplier.country}
            </Typography>

            <Typography sx={{ fontSize: 14 }}>
              P: {manufacturing?.supplier?.phone || '-'}
            </Typography>

            <Typography sx={{ fontSize: 14 }}>
             E: {manufacturing?.supplier?.email || '-'}
            </Typography>
          </Grid>
          <Grid sm={4}>
            <Typography
              sx={{
                fontSize: 14,
                mb: 1,
              }}
            >
              Ship To
            </Typography>
            <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
              {manufacturing?.warehouse.name}
            </Typography>

            <Typography sx={{ fontSize: 14 }}>
              {manufacturing?.warehouse.address}
            </Typography>

            <Typography sx={{ fontSize: 14 }}>
              {manufacturing?.warehouse.city}, {manufacturing?.warehouse.state}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              {manufacturing?.warehouse.zip_code}{" "}
              {manufacturing?.warehouse.country}
            </Typography>
          </Grid>
          <Grid sm={4}>
            <Typography
              sx={{
                fontSize: 14,
                mb: 1,
              }}
            >
              Shipment
            </Typography>

            <Typography sx={{ fontSize: 14 }}>
              ETD:{" "}
              {manufacturing?.container?.etd
                ? formatDate(manufacturing?.container?.etd)
                : "-"}
            </Typography>

            <Typography sx={{ fontSize: 14 }}>
              ETA:{" "}
              {manufacturing?.container?.eta
                ? formatDate(manufacturing?.container?.eta)
                : "-"}
            </Typography>

            <Typography sx={{ fontSize: 14 }}>
              Container No:{" "}
              {manufacturing?.container?.container_number
                ? manufacturing?.container?.container_number
                : "-"}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              Seal No:{" "}
              {manufacturing?.container?.seal_number
                ? manufacturing?.container?.seal_number
                : "-"}
            </Typography>

            <Typography sx={{ fontSize: 14 }}>
              Custom Tax:{" "}
              {manufacturing?.container?.custom_tax ? showCurrency(manufacturing?.container?.custom_tax):'-'}
            </Typography>
            <Typography sx={{ fontSize: 14 }}>
              Shipping Cost:{" "}
              {manufacturing?.container?.shipping_cost ? showCurrency(manufacturing?.container?.shipping_cost || 0):'-'}
            </Typography>
          </Grid>
        </Grid>
      </Card>

      <Stack spacing={2}>
        <Table variant={"outlined"}>
          <thead>
            <tr>
              <th
              style={{
                width: "40px", 
              }}
              >#</th>
              <th
              style={{
                width: "140px", 
              }}
              >Code</th>
              <th>Description</th>
              <th style={{ width: "90px", textAlign: "right" }}>Qty</th>
              <th style={{ width: "90px", textAlign: "right" }}>Unit</th>
            </tr>
          </thead>
          <tbody>
            {manufacturing?.purchase_order_items.map(
              (lineItem: any, index: number) => {
                const item = lineItem.item;

                return (
                  <tr key={index}>
                    <td>{index+1}</td>
                    <td><strong>
                    {item.code}  
                    </strong></td>
                    <td>
                      {showProductItemName(item, false)}
                    </td>

                    <td style={{ textAlign: "right" }}>
                      {lineItem?.quantity}
                    </td>

                    <td style={{ textAlign: "right" }}>
                     {item?.unit}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
        </Table>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flex: 1 }}>
            {manufacturing ? (
              <>
                <Typography fontSize="sm">Purchase Order Name:</Typography>

                <Typography fontSize="sm" fontWeight={500}>
                  {manufacturing?.name || "-"}
                </Typography>

                <Typography fontSize="sm" sx={{ mt: 2 }}>
                  Comment or Special Instructions:
                </Typography>

                <Typography fontSize="sm" fontWeight={500}>
                  {manufacturing?.note || "-"}
                </Typography>
              </>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
}

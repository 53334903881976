import type { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import { persistEncryptReducer } from "redux-store/redux-utils";
import ROUTERS, { ROUTERS_CUSTOMER } from "routers/constants";
import {
  customerLoginApi,
  customerUpdateProfileApi,
  loginApi,
  meApi,
} from "services/auth.service";
import { IUser } from "types/auth";
// import ROUTERS, { ROUTERS_CUSTOMER } from "routers/constants";

const TYPE = "AUTH";
export interface IAuthState {
  session: {
    access_token: string;
  };
  user?: IUser;
  isCustomer: boolean;
}

const initialState: IAuthState = {
  session: {
    access_token: "",
  },
  user: undefined,
  isCustomer: false,
};

export const fetchMe = createCallApiAsyncAction(TYPE + "/fetchMe", meApi);

export const fetchLogin = createCallApiAsyncAction(
  TYPE + "/fetchLogin",
  loginApi
);

export const fetchCustomerLogin = createCallApiAsyncAction(
  TYPE + "/fetchCustomerLogin",
  customerLoginApi
);

export const customerUpdateProfile = createCallApiAsyncAction(
  TYPE + "/customerUpdateProfile",
  customerUpdateProfileApi
);

const authSlice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchMe.fulfilled, (state, action) => {
      const { response } = action.payload;

      if (response.apiStatus) {
        state.user = response.data;
        state.user.roleName = response.data?.roles?.[0]?.name || "";
      } else if ((response.data as any)?.message === "Unauthorized") {
        state.user = undefined;
        if (state.session?.access_token !== "") {
          state.session = {
            access_token: "",
          };
        }
      }
    });

    // fetchLogin
    builder.addCase(fetchLogin.fulfilled, (state, action) => {
      const { response } = action.payload;

      if (response.data?.access_token) {
        state.session = {
          access_token: response.data.access_token,
        };
      } else {
        state.user = undefined;
        if (state.session?.access_token !== "") {
          state.session = {
            access_token: "",
          };
        }
      }
    });
    // fetchCustomerLogin
    builder.addCase(fetchCustomerLogin.fulfilled, (state, action) => {
      const { response } = action.payload;

      if (response.data?.access_token) {
        state.session = {
          access_token: response.data.access_token,
        };
        state.isCustomer = response.data.is_customer;
      } else {
        state.user = undefined;
        if (state.session?.access_token !== "") {
          state.session = {
            access_token: "",
          };
        }
        state.isCustomer = false;
      }
    });
    // customerUpdateProfile
    builder.addCase(customerUpdateProfile.fulfilled, (state, action) => {
      const { response } = action.payload;
      const data = response.data || {};

      if (data?.id && state.user) {
        state.user.bio = data.bio;
        state.user.email = data.email;
        state.user.first_name = data.first_name;
        state.user.last_name = data.last_name;
        state.user.phone = data.phone;
        state.user.photo = data.photo;
      }
    });
  },
  reducers: {
    setSession(
      state,
      action: PayloadAction<{
        user?: IUser;
        access_token?: string;
      } | null>
    ) {
      if (action.payload?.access_token) {
        if (action.payload.user) {
          state.user = action.payload.user;
          state.user.roleName = action.payload.user?.roles?.[0]?.name || "";
        }
        state.session = {
          access_token: action.payload.access_token,
        };
      } else {
        const isCustomer = state.isCustomer;
        state.user = undefined;
        if (state.session?.access_token !== "") {
          state.session = {
            access_token: "",
          };
        }
        state.isCustomer = false;

        [
          "EMPLOYEE",
          "CUSTOMER_PORTAL_SALES_ORDER",
          "PURCHASE",
          "PRODUCT",
          "AUTH",
        ].forEach((item) => {
          localStorage.removeItem(`persist:${item}`);
        });

        localStorage.removeItem("TFIREBASE");

        setTimeout(() => {
          if (isCustomer) {
            window.location.href = ROUTERS_CUSTOMER.LOGIN;
          } else {
            window.location.href = ROUTERS.LOGIN;
          }
        }, 0);
      }
    },
  },
});

export const { setSession } = authSlice.actions;
export default persistEncryptReducer(TYPE, authSlice.reducer, [
  "user",
  "session",
  "isCustomer",
]) as typeof authSlice.reducer;

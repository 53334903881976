import { Request } from "utils/request";
import { IPromotion } from "types/promotion";

export const listPromotionApi = () => {
  return Request.call<{ data: IPromotion[] }>({
    url: "/promotion/list",
    method: "GET",
  });
};

export const createPromotionApi = (payload: IPromotion) => {
  return Request.call<{ data: IPromotion }>({
    url: "/promotion/create",
    method: "PUT",
    data: payload,
  });
};

export const updatePromotionApi = (payload: IPromotion) => {
  delete payload.user_id;
  return Request.call<{ data: IPromotion }>({
    url: "/promotion/update",
    method: "PUT",
    data: payload,
  });
};

export const getDetailPromotionApi = (payload: { id: any }) => {
  return Request.call<{ data: IPromotion }>({
    url: "/promotion/detail/" + payload.id,
    method: "GET",
  });
};

import Typography from "@mui/joy/Typography";

import { Box, Button } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { ChipStatus } from "components/ChipStatus";
import Documents from "components/Documents";
import HistoryTimeline from "components/HistoryTimeline";
import InlineEditModal from "components/InlineEditModal";
import PageWrapper from "components/PageWrapper";
import { DetailSkeleton } from "components/SkeletonComponents";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "redux-store";
import { getDetailManufacturing } from "redux-store/reducers/manufacturing";
import ROUTERS from "routers/constants";
import { IPurchaseOrder } from "types/purchase";
import AppEvent from "utils/app-events";
import { formatDate } from "utils/common";
import useListenEmitEvent from "utils/common-hooks/useListenEmitEvent";
import ApprovalContent from "./components/ApprovalContent";
import DetailContent from "./components/DetailContent";
import ItemsManagementV2 from "./components/ItemsManagementV2";
import ProductionQueue from "./components/ProductionQueueContent";
import { useApproveHook } from "./components/useApproveHook";

export default function ManufacturingDetailPage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const id = useParams()?.id || detailId;
  const dispatch = useAppDispatch();
  const [selectedTab, setSelectedTab] = useState("Detail");
  const [manufacturing, setManufacturing] = useState<IPurchaseOrder | null>(
    null
  );
  const fetchDetail = () => {
    if (!id) return;
    dispatch(
      getDetailManufacturing({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.id) setManufacturing(response.data);
      });
  };
  useEffect(() => {
    fetchDetail();
  }, [id]);

  const {
    activeWorkflow,
    warehouseApprovalRows,
    isActionLoading,
    handleDenyAction,
    activeStatusAfterApprove,
    handleAction,
    fetchApprovalList,
    allowAccess,
  } = useApproveHook({
    manufacturing,
    onApproveSuccess: () => {
      fetchDetail();
    },
  });

  useListenEmitEvent({
    event: AppEvent.types.PO_STATUS_CHANGE,
    matchCondition: (payload) =>
      !!manufacturing?.id && payload.po_id == manufacturing.id,
    handle: () => {
      fetchDetail();
      fetchApprovalList();
    },
  });

  return (
    <PageWrapper
      onChangeTab={setSelectedTab}
      pageName={
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Typography>
            Purchase Order: #{manufacturing?.po_number || ""} <br />
            <Typography sx={{ fontSize: 14, fontWeight: 300 }}>
              Created at: {formatDate(manufacturing?.created_at, true)}
            </Typography>
          </Typography>
          {!!manufacturing && (
            <ChipStatus status={manufacturing.status} size="medium" />
          )}
        </Box>
      }
      pageAction={
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {allowAccess && selectedTab === "Detail" && !!activeWorkflow && (
            <InlineEditModal
              label="Reason"
              placement="right-end"
              saveText="Save"
              type="textarea"
              onSave={(newData, closeEdit) => {
                handleDenyAction({
                  workflow: activeWorkflow,
                  reason: newData,
                });
                closeEdit();
              }}
            >
              <Button
                variant="plain"
                color="danger"
                loading={isActionLoading === "deny_loading"}
              >
                Deny
              </Button>
            </InlineEditModal>
          )}
        </Box>
      }
      breadcrumb={[
        {
          label: "Manufacturing",
          link: ROUTERS.MANUFACTURING,
        },
        {
          label: "Detail",
        },
      ]}
      skeletonLoading={!manufacturing ? <DetailSkeleton /> : undefined}
      tabs={[
        {
          label: "Detail",
          content: !!manufacturing && (
            <DetailContent
              manufacturing={manufacturing}
              onUpdateSuccess={() => {
                fetchDetail();
              }}
            />
          ),
        },
        {
          label: "Approval",
          content: !!manufacturing && (
            <ApprovalContent
              warehouseApprovalRows={warehouseApprovalRows}
              activeWorkflow={activeWorkflow}
              activeStatusAfterApprove={activeStatusAfterApprove}
              isActionLoading={isActionLoading}
              handleAction={handleAction}
              allowAccess={allowAccess}
            />
          ),
        },
        {
          label: "Production Queue",
          content: !!manufacturing && (
            <ProductionQueue
              manufacturing={manufacturing}
              onApproveSuccess={() => {
                fetchDetail();
              }}
            />
          ),
        },
        // {
        //   label: "Pallet Management",
        //   content: !!manufacturing && (
        //     <ItemsManagement
        //       viewMode="manufacturing"
        //       manufacturing={manufacturing}
        //       onUpdateSuccess={() => {
        //         fetchDetail();
        //       }}
        //     />
        //   ),
        // },
        {
          label: "Pallet Management",
          content: !!manufacturing && (
            <ItemsManagementV2
              viewMode="manufacturing"
              manufacturing={manufacturing}
              onUpdateSuccess={() => {
                fetchDetail();
              }}
            />
          ),
        },
        {
          label: "Documents",
          content: <Documents source_id={"po_" + manufacturing?.id} />,
        },
        {
          label: "History",
          content: <HistoryTimeline source_id={"po_" + manufacturing?.id} />,
        },
      ]}
    ></PageWrapper>
  );
}

import { IInvoice } from "types/invoice";
import { Request } from "utils/request";

export const listInvoiceApi = () => {
  return Request.call<{ data: IInvoice[] }>({
    url: "/customer-portal/invoice/list",
    method: "GET",
  });
};

export const getDetailInvoiceApi = (payload: { id: any }) => {
  return Request.call<{ data: IInvoice }>({
    url: "/customer-portal/invoice/detail/" + payload.id,
    method: "GET",
  });
};

import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";

import { FormControl, Grid } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import BaseCheckbox from "components/BaseCheckbox";
import BaseCurrencyInput from "components/BaseCurrencyInput";
import BaseDatePicker from "components/BaseDatePicker";
import BaseInput from "components/BaseInput";
import BaseNumberInput from "components/BaseNumberInput";
import PageWrapper from "components/PageWrapper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux-store";
import { createPromotion } from "redux-store/reducers/promotion";
import ROUTERS from "routers/constants";
import { IPromotion } from "types/promotion";
import notification from "utils/notification";

export default function PromotionNewPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [newPromotion, setNewPromotion] = useState<IPromotion>({
    code: "",
    name: "",
    percentage: 0,
    amount: 0,
    count: 0,
    is_percentage: false,
    start_date: "",
    end_date: "",
    status: "Active",
  });

  const handleCreateNewPromotion = () => {
    setLoading(true);
    dispatch(createPromotion(newPromotion))
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        if (response.data?.id) {
          notification.success("Create promotion success");
          navigate(ROUTERS.PROMOTION);
        } else {
          notification.error("Create promotion failed");
        }
      });
  };
  return (
    <>
      <PageWrapper
        pageAction={
          <Box>
            <Button
              sx={{ marginRight: 1 }}
              variant="plain"
              onClick={() => navigate(ROUTERS.PROMOTION)}
              disabled={loading}
            >
              Cancel
            </Button>

            <Button
              color="primary"
              size="sm"
              onClick={handleCreateNewPromotion}
              loading={loading}
            >
              Save
            </Button>
          </Box>
        }
        pageName="New Promotion"
        breadcrumb={[
          {
            label: "Promotion",
            link: ROUTERS.PROMOTION,
          },
          {
            label: "New",
          },
        ]}
      >
        <Box sx={{ py: 1 }}>
          <Grid container spacing={3}>
            <Grid sm={6}>
              <FormControl>
                <BaseInput
                  label="Code"
                  value={newPromotion.code}
                  onChange={(e) =>
                    setNewPromotion({ ...newPromotion, code: e.target.value })
                  }
                />
              </FormControl>
            </Grid>

            <Grid sm={6}>
              <FormControl>
                <BaseInput
                  label="Name"
                  value={newPromotion.name}
                  onChange={(e) =>
                    setNewPromotion({ ...newPromotion, name: e.target.value })
                  }
                />
              </FormControl>
            </Grid>

            <Grid sm={6}>
              <FormControl>
                <BaseDatePicker
                  label="Start Date"
                  value={newPromotion.start_date}
                  onChange={(e) =>
                    setNewPromotion({ ...newPromotion, start_date: e || "" })
                  }
                />
              </FormControl>
            </Grid>

            <Grid sm={6}>
              <FormControl>
                <BaseDatePicker
                  label="End Date"
                  value={newPromotion.end_date}
                  onChange={(e) =>
                    setNewPromotion({ ...newPromotion, end_date: e || "" })
                  }
                />
              </FormControl>
            </Grid>

            <Grid sm={6}>
              <Grid container spacing={2}>
                <Grid
                  sm={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: 56,
                  }}
                >
                  <FormControl>
                    <BaseCheckbox
                      label="Is Percentage"
                      checked={newPromotion.is_percentage}
                      onChange={(e) =>
                        setNewPromotion({
                          ...newPromotion,
                          is_percentage: e.target.checked,
                        })
                      }
                    />
                  </FormControl>
                </Grid>
                {newPromotion.is_percentage ? (
                  <Grid sm>
                    <FormControl>
                      <BaseNumberInput
                        label="Discount Percentage"
                        value={newPromotion.percentage}
                        onChange={(num) =>
                          setNewPromotion({ ...newPromotion, percentage: num })
                        }
                        InputProps={{
                          endAdornment: "%",
                        }}
                        max={100}
                        min={0}
                      />
                    </FormControl>
                  </Grid>
                ) : (
                  <Grid sm>
                    <FormControl>
                      <BaseCurrencyInput
                        label="Discount Amount"
                        value={newPromotion.amount}
                        onChange={(num) =>
                          setNewPromotion({
                            ...newPromotion,
                            amount: Number(num),
                          })
                        }
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </Grid>

            <Grid sm={6}>
              <Grid container spacing={2}>
                <Grid
                  sm={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    height: 56,
                  }}
                >
                  <FormControl>
                    <BaseCheckbox
                      label="Is Limited"
                      checked={newPromotion.count !== -1}
                      onChange={(e) =>
                        setNewPromotion({
                          ...newPromotion,
                          count: e.target.checked ? 0 : -1,
                        })
                      }
                    />
                  </FormControl>
                </Grid>
                <Grid sm>
                  {newPromotion.count !== -1 && (
                    <FormControl>
                      <BaseNumberInput
                        label="Count"
                        value={newPromotion.count}
                        onChange={(num) =>
                          setNewPromotion({ ...newPromotion, count: num })
                        }
                      />
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </PageWrapper>
    </>
  );
}

import {
  Box,
  Button,
  Divider,
  Dropdown,
  FormControl,
  Grid,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Table,
  Typography,
} from "@mui/joy";

import { useEffect, useState } from "react";

import { PACKAGE_SHIPMENT_STATUS, SOStatusEnum } from "app-constants";

import {
  Apps,
  Clear,
  DeleteOutline,
  Edit,
  MoveDownOutlined
} from "@mui/icons-material";
import { unwrapResult } from "@reduxjs/toolkit";
import BaseCheckbox from "components/BaseCheckbox";
import BaseCurrencyInput from "components/BaseCurrencyInput";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import BaseSelect from "components/BaseSelect";
import CardWithLabel from "components/CardWithLabel";
import { ChipStatus } from "components/ChipStatus";
import ConfirmUtils from "components/ConfirmUtils";
import MoreActions from "components/MoreActions";
import PickingProcessModal from "components/PickingProcessModal";
import _ from "lodash";
import { useParams } from "react-router";
import { useAppDispatch } from "redux-store";
import { setBreadcrumb } from "redux-store/reducers/common";
import {
  completePacking,
  completePickPackage,
  createSOPackage,
  deleteSOPackage,
  getDetailSalesOrder,
  getPackageUPSRate,
  getSOPackages,
  getShipCarrier,
  markAsShippedMultiPackage,
  moveItemPackage,
  savePackageUPSSync,
  shipSOPackage,
  undoToPacking,
  unpickItemFromPackage,
  updateSOPackage,
  voidUpsShipment,
} from "redux-store/reducers/sales-order";
import { getWarehouseAreas } from "redux-store/reducers/warehouse";
import ROUTERS from "routers/constants";
import { printSOApi } from "services/sales-order.service";
import { ISalesOrder, IShipmentPackage } from "types/sales-order";
import {
  downloadBase64Image,
  downloadPrintPdf,
  showCurrency,
  soBarcode,
} from "utils/common";
import notification from "utils/notification";
import { getPermission } from "utils/roles";
import EditShipSection from "./components/EditShipSection";

const ShipmentItem = ({
  shipmentItem,
  onEdit,
  onDelete,
  onShip,
  onClick,
  onClickCompletePick,
  salesOrderDetail,
  allowEdit,
  isPageMode,
  handleSavePackage,
  onUnPick,
  onMovePackage,
}: {
  shipmentItem: IShipmentPackage;
  onEdit: any;
  onDelete: any;
  onShip: any;
  onClick: any;
  onClickCompletePick: any;
  salesOrderDetail: ISalesOrder;
  allowEdit?: boolean;
  isPageMode?: boolean;
  handleSavePackage: any;
  onUnPick: any;
  onMovePackage?: any;
}) => {
  const listSOItems = salesOrderDetail.sales_order_items;

  const pickedList = _.reduce(
    shipmentItem.picked,
    (acc: any, item) => {
      if (!acc[item.item_id]) {
        acc[item.item_id] = {
          ...item,
          qty: 0,
          serial_numbers: [],
        };
      }

      acc[item.item_id].qty += item.qty;
      acc[item.item_id].serial_numbers.push(item?.serial?.serial_number);

      return acc;
    },
    {}
  );
  const isReadyToPick = salesOrderDetail.status === SOStatusEnum.READY_TO_PICK;
  const isReadyToPack = salesOrderDetail.status === SOStatusEnum.READY_TO_PACK;
  const isReadyToShip = salesOrderDetail.status === SOStatusEnum.READY_TO_SHIP;
  const isShipped = salesOrderDetail.status === SOStatusEnum.SHIPPED;

  const handleOnDelete = (val: any) => {
    onUnPick(pickedList[val.item_id]);
  };
  const handleMovePackage = (val: any) => {
    onMovePackage?.({
      ...pickedList[val.item_id],
      code: val.item?.code
    });
  };
  const pickedItemsRender = _.compact(
    _.map(listSOItems, (val, index) => {
      const pickQty = pickedList[val.item_id as number]?.qty || 0;
      if (!isReadyToPick && !pickQty) {
        return null;
      }

      return (
        (!!pickQty || shipmentItem.status !== "Shipped") && (
          <tr key={index}>
            <td>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ fontSize: "16px", width: 160 }}>
                    <b>{val.item?.code}</b>
                  </Box>
                  <Box>
                    {isReadyToPick && (
                      <Typography>
                        <small>Picked Qty</small>: {pickQty} / {val.quantity} (
                        {val.item?.unit})
                      </Typography>
                    )}

                    {isReadyToPack && (
                      <Typography>
                        <small>Packed Qty</small>: {pickQty} / {val.quantity} (
                        {val.item?.unit})
                      </Typography>
                    )}

                    {isReadyToShip && (
                      <Typography>
                        <small>Ship Qty</small>: {pickQty} / {val.quantity} (
                        {val.item?.unit})
                      </Typography>
                    )}

                    {isShipped && (
                      <Typography>
                        <small>Shipped Qty</small>: {pickQty} / {val.quantity} (
                        {val.item?.unit})
                      </Typography>
                    )}
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  {shipmentItem.status === PACKAGE_SHIPMENT_STATUS.PACKING && isReadyToPack && !!onMovePackage && !!pickQty && (
                    <IconButton
                      size="sm"
                      color="primary"
                      onClick={(e) => {
                        handleMovePackage(val);
                        e.stopPropagation();
                      }}
                    >
                      <MoveDownOutlined fontSize="small" />
                    </IconButton>
                  )}
                  {isReadyToPick && !!pickQty && (
                    <IconButton
                      size="sm"
                      color="danger"
                      onClick={(e) => {
                        handleOnDelete(val);
                        e.stopPropagation();
                      }}
                    >
                      <Clear fontSize="small" />
                    </IconButton>
                  )}
                </Box>
              </Box>
            </td>
          </tr>
        )
      );
    })
  );

  const actions = [];
  if (isReadyToPick) {
    actions.push({
      label: "Start Picking",
      onClick: () => {
        onClick();
      },
    });

    actions.push({
      label: "Complete Picking",
      onClick: () => {
        onClickCompletePick();
      },
    });
  }

  if (isReadyToPack) {
    // actions.push({
    //   label: "Select Items",
    //   onClick: () => {
    //     onClick();
    //   },
    // });

    actions.push({
      label: "Edit Package",
      disabled: !allowEdit,
      onClick: () => {
        onEdit(shipmentItem);
      },
    });

    // actions.push({
    //   label: "Complete Packing",
    //   disabled:
    //     !allowEdit || shipmentItem.status !== PACKAGE_SHIPMENT_STATUS.PACKING,
    //   onClick: () => {
    //     handleSavePackage(shipmentItem, PACKAGE_SHIPMENT_STATUS.READY_TO_SHIP);
    //     // onEdit(shipmentItem);
    //   },
    // });

    actions.push({
      label: "Delete",
      disabled: !!shipmentItem.ilock || !allowEdit,
      onClick: () => {
        onDelete(shipmentItem);
      },
    });
  }

  if (isReadyToShip) {
    actions.push({
      label: "Edit Package",
      disabled: !allowEdit,
      onClick: () => {
        onEdit(shipmentItem);
      },
    });
  }

  return (
    <CardWithLabel
      label={isReadyToPick ? "Picked list" : "Package"}
      sx={{
        p: 1,
      }}
      onTouchStart={(e: any) => {
        console.log(e);
      }}
    >
      <Grid container sx={{ display: "flex" }} spacing={1}>
        <Grid sx={{ flex: 1 }}>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{ flex: 1, display: "flex", alignItems: "center", gap: 1 }}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: 1,
                  }}
                >
                  {!isReadyToPick ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        px: 0.5,
                      }}
                    >
                      <Box sx={{ mr: 2, fontSize: 16, fontWeight: 600 }}>
                        Number #{shipmentItem.id}
                      </Box>
                      <ChipStatus status={shipmentItem.status} size="small" />
                    </Box>
                  ) : (
                    <div>Please review picked list</div>
                  )}

                  <Box
                    onClick={(e) => e.stopPropagation()}
                    sx={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: 1,
                      marginLeft: "auto",
                    }}
                  >
                    {!!actions.length && (
                      <MoreActions placement="bottom-end" actions={actions} />
                    )}
                  </Box>
                </Box>

                {!isReadyToPick && (
                  <Table
                    borderAxis="x"
                    sx={{
                      "--TableCell-height": "30px",
                      "--TableCell-paddingX": "6px",
                      "--TableCell-paddingY": "6px",
                      fontSize: "14px",
                    }}
                  >
                    <tr
                      style={{
                        visibility: "hidden",
                      }}
                    >
                      <th
                        style={{
                          width: 120,
                          padding: 0,
                          height: 1,
                        }}
                      ></th>
                      <th
                        style={{
                          height: 1,
                          padding: 0,
                        }}
                      ></th>
                    </tr>

                    {/* <tr>
                      <td>Carrier:</td>
                      <td>
                        <b>{shipmentItem.carrier || "-"}</b>
                      </td>
                    </tr>
                    <tr>
                      <td>Service:</td>
                      <td>
                        <b>{shipmentItem.type}</b>
                      </td>
                    </tr> */}
                    <tr>
                      <td>Weight:</td>
                      <td>
                        <b>{shipmentItem.weight}</b> (lbs)
                      </td>
                    </tr>
                    <tr>
                      <td>Dimensions:</td>
                      <td>
                        <b>
                          {shipmentItem.length}x{shipmentItem.width}x
                          {shipmentItem.height}
                        </b>{" "}
                        (inches)
                      </td>
                    </tr>

                    {!!shipmentItem.tracking_number && (
                      <tr>
                        <td>Tracking #:</td>
                        <td>
                          <b>{shipmentItem.tracking_number || "-"}</b>
                          {!!shipmentItem.ship_label && (
                            <span
                              onClick={() => {
                                downloadBase64Image(
                                  "data:image/gif;base64," +
                                  shipmentItem.ship_label,
                                  `${shipmentItem.tracking_number}.gif`
                                );
                              }}
                              style={{
                                cursor: "pointer",
                                marginLeft: "10px",
                                color: "blue",
                              }}
                            >
                              Download
                            </span>
                          )}
                        </td>
                      </tr>
                    )}

                    {!!Number(shipmentItem.ship_cost) && (
                      <tr>
                        <td>Shipping cost:</td>
                        <td>
                          <b>{showCurrency(shipmentItem.ship_cost)}</b>
                        </td>
                      </tr>
                    )}

                    {/* <tr>
                      <td>Created at:</td>
                      <td>
                        <b>{formatDate(shipmentItem.created_at, true)}</b>
                      </td>
                    </tr> */}

                    {/* {shipmentItem.status ===
                      PACKAGE_SHIPMENT_STATUS.SHIPPED && (
                      <tr>
                        <td>Shipped at:</td>
                        <td>
                          <b>{formatDate(shipmentItem.created_at, true)}</b>
                        </td>
                      </tr>
                    )} */}

                    {!!shipmentItem.note && (
                      <tr>
                        <td>Note:</td>
                        <td>{shipmentItem.note}</td>
                      </tr>
                    )}
                  </Table>
                )}

                {!isReadyToPick && <Divider sx={{ my: 1 }} />}

                <Table
                  borderAxis="x"
                  sx={{
                    "--TableCell-height": "30px",
                    "--TableCell-paddingX": "6px",
                    "--TableCell-paddingY": "6px",
                    fontSize: "14px",
                  }}
                >
                  <tr
                    style={{
                      visibility: "hidden",
                    }}
                  >
                    <th
                      style={{
                        height: 1,
                        padding: 0,
                      }}
                    ></th>
                  </tr>
                  {pickedItemsRender?.length ? (
                    pickedItemsRender
                  ) : (
                    <tr>
                      <td>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: 14,
                              color: "red",
                            }}
                          >
                            No items
                          </Typography>
                        </Box>
                      </td>
                    </tr>
                  )}
                </Table>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </CardWithLabel>
  );
};

export default function SalesOrderDetailPickPage({
  detailId,
  onUpdateSuccess,
  isPageMode = true,
}: {
  detailId?: any;
  onUpdateSuccess?: any;
  isPageMode?: boolean;
}) {
  const dispatch = useAppDispatch();
  const [printLoading, setPrintLoading] = useState(false);

  const id = useParams()?.id || detailId;
  const [newPackage, setNewPackage] = useState<IShipmentPackage | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const [shipmentList, setShipmentList] = useState<IShipmentPackage[]>([]);
  const [selectedPackage, setSelectedPackage] =
    useState<IShipmentPackage | null>(null);
  const [shipCarrierList, setShipCarrierList] = useState<any[]>([]);
  const [isOpenEditShip, setIsOpenEditShip] = useState(false);
  const [unpickPackage, setUnpickPackage] = useState<any>(null);
  const [movePackage, setMovePackage] = useState<any>(null);

  const pickedList = _.reduce(
    shipmentList,
    (acc: any, item) => {
      const picked = item.picked || [];
      picked.forEach((p) => {
        if (!acc[p.item_id]) {
          acc[p.item_id] = 0;
        }

        acc[p.item_id] += p.qty;
      });

      return acc;
    },
    {}
  );

  const [salesOrderDetail, setSalesOrderDetail] = useState<
    ISalesOrder | undefined
  >();

  useEffect(() => {
    const fallbackStatus =
      salesOrderDetail?.status === SOStatusEnum.SHIPPED
        ? SOStatusEnum.READY_TO_PICK
        : salesOrderDetail?.status;
    if (isPageMode) {
      dispatch(
        setBreadcrumb([
          {
            label: "PPS Processing",
            link: `${ROUTERS.SALES_ORDER_PICK}?status=${fallbackStatus}`,
          },
          {
            label: salesOrderDetail?.status || "",
          },
        ])
      );
    }
  }, [isPageMode, salesOrderDetail?.status]);

  useEffect(() => {
    fetchDetail();
    dispatch(getShipCarrier({}))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          setShipCarrierList(
            _.map(response.data || [], (v) => ({ title: v.name }))
          );
        }
      });
  }, [id]);

  useEffect(() => {
    fetchWarehouseAreas();
  }, []);

  const fetchDetail = () => {
    if (!id) return;
    dispatch(
      getDetailSalesOrder({
        id: Number(id),
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.id) {
          setSalesOrderDetail(response.data);
          fetchList();
        }
      });

    onUpdateSuccess && onUpdateSuccess();
  };

  const fetchList = () => {
    dispatch(
      getSOPackages({
        so_id: Number(id),
      })
    )
      .then(unwrapResult)
      .then(async ({ response }) => {
        setLoading(false);
        if (response.apiStatus) {
          setShipmentList(
            _.orderBy(response.data, ["created_at"], ["asc"]) || []
          );
        } else {
          notification.error("Get list package failed");
        }
      });
  };

  const handleOpenCreatePackage = () => {
    let serviceType = "";

    if (salesOrderDetail?.delivery_method === "UPS") {
      serviceType = salesOrderDetail.shipping_service || "";
    } else if (salesOrderDetail?.delivery_method === "LTL") {
      serviceType = salesOrderDetail.delivery_company || "";
    }

    setNewPackage({
      weight: 0,
      height: 0,
      width: 0,
      length: 0,
      carrier: salesOrderDetail?.delivery_method || "",
      type: serviceType,
      tracking_number: "",
      note: "",
      status: PACKAGE_SHIPMENT_STATUS.PICKING,
    });
  };

  const handleDeleteSOPackage = async (shipmentItem: IShipmentPackage) => {
    if (shipmentItem.real_item?.length) {
      notification.error(
        "Can not delete package has items. Please remove items first"
      );
      return;
    }

    if (!(await ConfirmUtils.sure())) return;

    dispatch(
      deleteSOPackage({
        so_id: salesOrderDetail?.id,
        id: shipmentItem.id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        if (response.apiStatus) {
          notification.success("Delete successfully");
          fetchList();
        } else {
          notification.error("Delete package failed");
        }
      });
  };

  const handleCompletePick = async (shipmentItem: any) => {
    if (!(await ConfirmUtils.sure())) return;
    dispatch(
      completePickPackage({
        id: shipmentItem.id,
        so_id: shipmentItem.so_id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        if (response.apiStatus) {
          notification.success("Action success");
          fetchDetail();
        } else {
          notification.error("Please make sure you picked all items.");
        }
      });
  };

  const handleShipPackage = async (shipmentItem: IShipmentPackage) => {
    if (!(await ConfirmUtils.sure())) return;
    dispatch(
      shipSOPackage({
        id: shipmentItem.id,
        so_id: shipmentItem.so_id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        if (response.apiStatus) {
          notification.success("Package shipped");
          fetchDetail();
        } else {
          notification.error("Update failed");
        }
      });
  };

  const fetchWarehouseAreas = () => {
    dispatch(getWarehouseAreas({ warehouse_id: 1 }));
  };

  const handleSavePackage = (
    newPackage: IShipmentPackage,
    newStatus?: string
  ) => {
    if (!newPackage) return;
    const newObj = {
      so_id: salesOrderDetail?.id,
      type: newPackage.type,
      weight: newPackage.weight || 0,
      height: newPackage.height || 0,
      width: newPackage.width || 0,
      length: newPackage.length || 0,
      ship_cost: newPackage.ship_cost || 0,
      carrier: newPackage.carrier,
      tracking_number: newPackage.tracking_number,
      note: newPackage.note,
      id: newPackage.id || undefined,
      status: newStatus || newPackage.status,
    };

    if (
      [PACKAGE_SHIPMENT_STATUS.READY_TO_SHIP].includes(newStatus || "") &&
      newObj.weight * newObj.height * newObj.width * newObj.length === 0
    ) {
      return notification.error("Dimensions and Weight are required");
    }

    setLoading(true);
    dispatch(newPackage.id ? updateSOPackage(newObj) : createSOPackage(newObj))
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        if (response.apiStatus) {
          notification.success("Save successfully");
          setNewPackage(null);
          fetchDetail();
        } else {
          notification.error("Save package failed");
        }
      });
  };

  const menuClick = async (type: string, isLabel?: boolean) => {
    if (type === "getRate") {
      const listNeedToGetTracking = shipmentList;

      const tracking_numbers = _.filter(
        shipmentList.map((e) => e.tracking_number),
        (el) => !!el
      );
      if (tracking_numbers.length) {
        notification.error("Please void tracking number before get new");
        return;
      }

      if (
        !salesOrderDetail ||
        !listNeedToGetTracking.length ||
        !(await ConfirmUtils.sure())
      )
        return;



      setActionLoading(true);
      dispatch(
        getPackageUPSRate({
          so_id: salesOrderDetail.id,
          isLabel: !!isLabel,
          packages: shipmentList.map(v => {
            let total = 0;

            _.forEach(v.picked, (item) => {

              total += item.qty * ((salesOrderDetail?.sales_order_items?.find(e => e.item_id === item.item_id))?.cost || 0)
            })

            return {
              id: v.id,
              total,
            }
          })
        })
      )
        .then(unwrapResult)
        .then(({ response }) => {
          setActionLoading(false);
          if (response.apiStatus) {
            _.forEach(
              response?.data?.RateResponse?.RatedShipment?.RatedPackage,
              (v: any, index: number) => {
                shipmentList[index].ship_label = "";
                shipmentList[index].tracking_number = "";
                shipmentList[index].ship_cost = Number(
                  v.NegotiatedCharges?.TotalCharge?.MonetaryValue || 0
                );
                setShipmentList([...shipmentList]);
              }
            );
            _.forEach(
              response?.data?.ShipmentResponse?.ShipmentResults.PackageResults,
              (v: any, index: number) => {
                shipmentList[index].tracking_number = v.TrackingNumber;
                shipmentList[index].ship_label = v.ShippingLabel?.GraphicImage;
                setShipmentList([...shipmentList]);
              }
            );
            const shipping_cost = isLabel
              ? Number(
                response?.data?.ShipmentResponse?.ShipmentResults
                  ?.NegotiatedRateCharges?.TotalCharge?.MonetaryValue || 0
              )
              : response?.data?.RateResponse?.RatedShipment
                ?.NegotiatedRateCharges?.TotalCharge?.MonetaryValue || 0;
            dispatch(
              savePackageUPSSync({
                so_id: salesOrderDetail.id,
                shipping_cost: shipping_cost,
                ups_shipment_id:
                  response?.data?.ShipmentResponse?.ShipmentResults
                    ?.ShipmentIdentificationNumber || "",
                packages: shipmentList.map((e) => ({
                  id: e.id,
                  tracking_number: e.tracking_number,
                  ship_label: e.ship_label,
                  ...(!isLabel ? { ship_cost: Number(e.ship_cost) } : {}),
                })),
              })
            );

            notification.success("Action success");
            setSalesOrderDetail({
              ...salesOrderDetail,
              shipping_cost: shipping_cost * (115 / 100),
            });
          } else {
            notification.error(response.data.message || "Action failed");
          }
        });

      return;
    }

    if (type === "voidLabel") {
      const tracking_numbers = _.filter(
        shipmentList.map((e) => e.tracking_number),
        (el) => !!el
      );
      if (!tracking_numbers.length) {
        notification.error("No package tracking number");
      }

      if (
        !salesOrderDetail ||
        !tracking_numbers.length ||
        !(await ConfirmUtils.sure())
      )
        return;

      setActionLoading(true);

      dispatch(
        voidUpsShipment({
          tracking_numbers,
          so_id: salesOrderDetail.id,
        })
      )
        .then(unwrapResult)
        .then(({ response }) => {
          setActionLoading(false);
          if (response.apiStatus) {
            notification.success("Action success");
            fetchDetail();
          } else {
            notification.error(response.data.message || "Action failed");
          }
        });
    }

    if (type === "complete_packing") {
      const checks = _.filter(shipmentList, (e: any) => {
        return (e.picked || []).length && !!(Number(e.weight || 0) * Number(e.length || 0) * Number(e.height || 0) * Number(e.width || 0))
      })

      if (checks.length !== shipmentList.length) {
        return notification.error("Dimensions and Weight and Item Picked are required");
      }


      if (
        !salesOrderDetail ||
        !(await ConfirmUtils.sure())
      )
        return;

      setActionLoading(true);

      dispatch(
        completePacking({
          ids: shipmentList.map(e => e.id),
          so_id: salesOrderDetail.id,
        })
      )
        .then(unwrapResult)
        .then(({ response }) => {
          setActionLoading(false);
          if (response.apiStatus) {
            notification.success("Action success");
            fetchDetail();
          } else {
            notification.error(response.data.message || "Action failed");
          }
        });
    }


    if (type === "undo_pack") {
      const ids = shipmentList
        .filter((e) => {
          return e.status === PACKAGE_SHIPMENT_STATUS.READY_TO_SHIP;
        })
        .map((e) => e.id);

      if (!ids.length) {
        notification.error("No package Ready to Ship");
      }

      if (!salesOrderDetail || !ids.length || !(await ConfirmUtils.sure()))
        return;

      dispatch(
        undoToPacking({
          ids: ids,
          so_id: salesOrderDetail.id,
        })
      )
        .then(unwrapResult)
        .then(({ response }) => {
          setLoading(false);
          if (response.apiStatus) {
            notification.success("Action success");
            fetchDetail();
          } else {
            notification.error("Action failed");
          }
        });
    }

    if (type === "ship") {
      const ids = shipmentList
        .filter((e) => {
          return e.status === PACKAGE_SHIPMENT_STATUS.READY_TO_SHIP;
        })
        .map((e) => e.id);

      if (!ids.length) {
        notification.error("No package Ready to Ship");
      }

      if (!salesOrderDetail || !ids.length || !(await ConfirmUtils.sure()))
        return;

      dispatch(
        markAsShippedMultiPackage({
          ids: ids,
          so_id: salesOrderDetail.id,
        })
      )
        .then(unwrapResult)
        .then(({ response }) => {
          setLoading(false);
          if (response.apiStatus) {
            notification.success("Action success");
            fetchDetail();
          } else {
            notification.error("Action failed");
          }
        });
    }

    if (salesOrderDetail && type === "print_pickup_slip") {

      if (!salesOrderDetail || salesOrderDetail?.status !== SOStatusEnum.READY_TO_SHIP ||

        !(await ConfirmUtils.show({
          title: "Pick-Up Slip Confirmation Email",
          description: <>The Pick-Up Slip document for <b>{salesOrderDetail.customer?.first_name} </b> will be sent to the customer's email at: <b>{salesOrderDetail.customer?.email}</b></>,
        }))
      )
        return;

      setActionLoading(true);
      printSOApi({
        printType: 'PICK_UP_SLIP',
        so: {
          ...salesOrderDetail,
        },
        barcode: await soBarcode(salesOrderDetail?.so_number || '')
      })
        .then((rs: any) => {
          setActionLoading(false);
          if (rs.status === 200) {
            notification.success("Send email success")
          } else {
            notification.error("Send emai failed")
          }
          // downloadPrintPdf(rs.data, `pick_up_slip_${salesOrderDetail.so_number}.pdf`)
        });
    }
  };

  if (!salesOrderDetail) return null;

  // const isBeforePick = [
  //   SOStatusEnum.REVIEWING_COST,
  //   SOStatusEnum.AWAITING_CUSTOMER,
  //   SOStatusEnum.CUSTOMER_APPROVED,
  // ].includes(salesOrderDetail.status as any);

  // const allowEdit = [
  //   SOStatusEnum.READY_TO_PICK,
  //   SOStatusEnum.PARTIALLY_SHIPPED,
  // ].includes(salesOrderDetail.status as any);

  return (
    <Stack spacing={1}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {isPageMode && (
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              Sales Order: #{salesOrderDetail?.so_number}
              <ChipStatus status={salesOrderDetail.status} size="medium" />
            </Box>
            {[SOStatusEnum.READY_TO_PACK, SOStatusEnum.READY_TO_SHIP].includes(salesOrderDetail?.status as SOStatusEnum) && (
              <Box
                sx={{
                  position: "relative",
                }}
              >
                <small>
                  Shipping Method: <b>{salesOrderDetail.delivery_method}{salesOrderDetail.shipping_service ||
                    salesOrderDetail.delivery_company ? " - " : ''}{salesOrderDetail.shipping_service ||
                      salesOrderDetail.delivery_company}</b>
                  {getPermission("ALLOW_UPDATE_SO") && [SOStatusEnum.READY_TO_PACK].includes(salesOrderDetail?.status as SOStatusEnum) && (
                    <Edit
                      onClick={() => {
                        setIsOpenEditShip(true);
                      }}
                      fontSize="small"
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                    ></Edit>
                  )}
                </small>
                <br />
                <small>
                  Shipping Cost: <b>{showCurrency(salesOrderDetail.shipping_cost || 0)}</b>
                </small>
                <br />
                {["LTL"].includes(salesOrderDetail?.delivery_method) && (
                  <small>
                    Pallet Charge: <b>{salesOrderDetail?.is_pallet ? "Yes" : "No"}</b>
                  </small>
                )}
                {["UPS"].includes(salesOrderDetail?.delivery_method) && salesOrderDetail?.customer?.ups_acc && (
                  <small>
                    USP account: <b>{salesOrderDetail && salesOrderDetail?.customer.ups_acc}</b>
                  </small>
                )}
              </Box>
            )}
          </Box>
        )}

        <Box sx={{ display: "flex", alignItems: "center" }}>
          {salesOrderDetail?.status === SOStatusEnum.READY_TO_PICK && (
            <Button
              size="sm"
              variant="outlined"
              loading={printLoading}
              onClick={async () => {
                setPrintLoading(true);
                printSOApi({
                  printType: "PICK",
                  so: { ...salesOrderDetail },
                  barcode: await soBarcode(salesOrderDetail?.so_number || ""),
                }).then((rs: any) => {
                  downloadPrintPdf(
                    rs.data,
                    `pick_${salesOrderDetail.so_number}.pdf`
                  );

                  setPrintLoading(false);
                });
              }}
            >
              Print picking list
            </Button>
          )}
          {salesOrderDetail?.status === SOStatusEnum.READY_TO_SHIP && (
            <Dropdown size="sm">
              <MenuButton
                size="sm"
                sx={{ mr: 1 }}
                startDecorator={<Apps fontSize="small" />}
                loading={actionLoading}
              >
                Action
              </MenuButton>
              <Menu size="sm" placement="bottom-end">

                {salesOrderDetail?.delivery_method === 'Will Call' && <MenuItem
                  onClick={() => {
                    menuClick("print_pickup_slip");
                  }}
                >
                  Send email Pick Up Slip
                </MenuItem>}

                <MenuItem
                  onClick={() => {
                    menuClick("ship");
                  }}
                  color="success"
                >
                  Complete Shipping
                </MenuItem>

                <MenuItem
                  onClick={() => {
                    menuClick("undo_pack");
                  }}
                  color="danger"
                >
                  Undo
                </MenuItem>
              </Menu>
            </Dropdown>
          )}

          {salesOrderDetail?.status === SOStatusEnum.READY_TO_PACK && (
            <Dropdown size="sm">
              <MenuButton
                size="sm"
                sx={{ mr: 1 }}
                startDecorator={<Apps fontSize="small" />}
                loading={actionLoading}
              >
                Action
              </MenuButton>
              <Menu size="sm" placement="bottom-end">
                {salesOrderDetail?.delivery_method === "UPS" && (
                  <>
                    <MenuItem
                      onClick={() => {
                        menuClick("getRate");
                      }}
                      color="primary"
                    >
                      Get UPS Rate
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        menuClick("getRate", true);
                      }}
                      color="primary"
                    >
                      Create UPS Shipment
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        menuClick("voidLabel");
                      }}
                      color="danger"
                    >
                      Void UPS Shipment
                    </MenuItem>

                    <Divider />
                  </>
                )}
                <MenuItem
                  onClick={handleOpenCreatePackage}
                >
                  Add Package
                </MenuItem>

                <MenuItem
                  color="success"
                  onClick={() => {
                    menuClick("complete_packing");
                  }}
                >
                  Complete Packing
                </MenuItem>

              </Menu>
            </Dropdown>

            // <IconButton
            //   size="sm"
            //   variant="outlined"
            //   color="primary"
            //   
            // >
            //   <Add />
            // </IconButton>
          )}
        </Box>
      </Box>

      {shipmentList.map((shipmentItem, index) => {
        return (
          <ShipmentItem
            onDelete={handleDeleteSOPackage}
            onEdit={(item: any) => {
              setNewPackage({
                ...item,
                carrier: item.carrier || salesOrderDetail?.delivery_method,
              });
            }}
            onShip={handleShipPackage}
            onUnPick={(unPickItem: any) => {
              setUnpickPackage({
                ...shipmentItem,
                unPickItem,
              });
            }}
            onMovePackage={
              shipmentList.length > 1
                ? (moveItem: any) => {
                  setMovePackage({
                    ...shipmentItem,
                    moveItem,
                    moveTo: shipmentList.filter((e) => e.id !== shipmentItem.id && e.status === PACKAGE_SHIPMENT_STATUS.PACKING)?.[0]?.id,
                  });
                }
                : undefined
            }
            shipmentItem={shipmentItem}
            onClickCompletePick={() => {
              handleCompletePick(shipmentItem);
            }}
            onClick={() => setSelectedPackage(shipmentItem)}
            salesOrderDetail={salesOrderDetail}
            allowEdit={true}
            isPageMode={isPageMode}
            handleSavePackage={handleSavePackage}
          />
        );
      })}

      {!!newPackage && (
        <BaseModal
          title={newPackage.id ? `Package #${newPackage.id}` : "Create package"}
          isOpen={!!newPackage}
          onClose={() => setNewPackage(null)}
          actions={
            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <Button
                variant="outlined"
                loading={loading}
                onClick={() => handleSavePackage(newPackage)}
              >
                Save
              </Button>
            </Box>
          }
          width={600}
        >
          <Grid container rowSpacing={2} columnSpacing={1}>
            {/* <Grid xs={5} sm={4}>
              <FormControl>
                <BaseSelect
                  label="Carrier"
                  options={DELIVERY_METHOD.map((v) => ({
                    value: v,
                    label: v,
                  }))}
                  value={newPackage?.carrier}
                  onChange={(e) =>
                    setNewPackage({
                      ...newPackage,
                      carrier: e,
                      type: "",
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid xs={7} sm={8}>
              <FormControl>
                {["UPS", "LTL"].includes(newPackage?.carrier as string) ? (
                  <>
                    {newPackage?.carrier === "UPS" && (
                      <FormControl>
                        <BaseSelect
                          label="Service"
                          options={SHIPPING_SERVICES}
                          value={newPackage?.type}
                          onChange={(e: any) =>
                            setNewPackage({
                              ...newPackage,
                              type: e,
                            })
                          }
                        />
                      </FormControl>
                    )}

                    {newPackage?.carrier === "LTL" && (
                      <FormControl>
                        <SelectOrAddNew
                          label="Service"
                          value={newPackage?.type}
                          options={shipCarrierList}
                          onChange={(v) =>
                            setNewPackage({
                              ...newPackage,
                              type: v,
                            })
                          }
                        />
                      </FormControl>
                    )}
                  </>
                ) : (
                  <BaseInput
                    label="Type"
                    value={newPackage?.type}
                    onChange={(e) =>
                      setNewPackage({
                        ...newPackage,
                        type: e.target.value,
                      })
                    }
                  />
                )}
              </FormControl>
            </Grid>

            <Grid xs={6} sm={6}>
              <FormControl>
                <BaseInput
                  label="Tracking Number"
                  value={newPackage?.tracking_number}
                  onChange={(e) =>
                    setNewPackage({
                      ...newPackage,
                      tracking_number: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>

            <Grid xs={6} sm={6}>
              <FormControl>
                <BaseCurrencyInput
                  label="Shipping Cost"
                  value={newPackage?.ship_cost}
                  onChange={(e) =>
                    setNewPackage({
                      ...newPackage,
                      ship_cost: Number(e),
                    })
                  }
                />
              </FormControl>
            </Grid> */}



            <Grid xs={4} sm={3}>
              <FormControl>
                <BaseCurrencyInput
                  is_number
                  label="Length"
                  value={newPackage?.length}
                  onChange={(e) =>
                    setNewPackage({
                      ...newPackage,
                      length: e,
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid xs={4} sm={3}>
              <FormControl>
                <BaseCurrencyInput
                  is_number
                  label="Width"
                  value={newPackage?.width}
                  onChange={(e) =>
                    setNewPackage({
                      ...newPackage,
                      width: e,
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid xs={4} sm={3}>
              <FormControl>
                <BaseCurrencyInput
                  is_number
                  label="Height"
                  value={newPackage?.height}
                  onChange={(e) =>
                    setNewPackage({
                      ...newPackage,
                      height: e,
                    })
                  }
                />
              </FormControl>
            </Grid>

            <Grid xs={4} sm={3}>
              <FormControl>
                <BaseCurrencyInput
                  is_number
                  label="Weight"
                  value={newPackage?.weight}
                  onChange={(e) =>
                    setNewPackage({
                      ...newPackage,
                      weight: e,
                    })
                  }
                />
              </FormControl>
            </Grid>


            {
              [PACKAGE_SHIPMENT_STATUS.READY_TO_SHIP as string, PACKAGE_SHIPMENT_STATUS.SHIPPED as string].includes(newPackage?.status as string) &&
              <>
                <Grid xs={6} sm={6}>
                  <FormControl>
                    <BaseInput
                      label="Tracking Number"
                      value={newPackage?.tracking_number}
                      onChange={(e) =>
                        setNewPackage({
                          ...newPackage,
                          tracking_number: e.target.value,
                        })
                      }
                    />
                  </FormControl>
                </Grid>

                <Grid xs={6} sm={6}>
                  <FormControl>
                    <BaseCurrencyInput
                      label="Shipping Cost"
                      value={newPackage?.ship_cost}
                      onChange={(e) =>
                        setNewPackage({
                          ...newPackage,
                          ship_cost: Number(e),
                        })
                      }
                    />
                  </FormControl>
                </Grid>
              </>
            }

            <Grid xs={12} sm={12}>
              <FormControl>
                <BaseInput
                  multiline
                  minRows={2}
                  label="Note"
                  value={newPackage?.note}
                  onChange={(e) =>
                    setNewPackage({
                      ...newPackage,
                      note: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </BaseModal>
      )}

      {!!selectedPackage && !!salesOrderDetail && (
        <PickingProcessModal
          salesOrderDetail={salesOrderDetail}
          selectedPackage={selectedPackage}
          pickedList={pickedList}
          onPick={() => {
            fetchList();
          }}
          onClose={() => {
            fetchDetail();
            setSelectedPackage(null);
          }}
        />
      )}

      {isOpenEditShip && (
        <EditShipSection
          isOpen={isOpenEditShip}
          so={salesOrderDetail}
          onClose={() => setIsOpenEditShip(false)}
          onUpdateSuccess={fetchDetail}
          inPPSPage
        />
      )}

      {!!unpickPackage && (
        <BaseModal
          title={"Unpick"}
          isOpen={true}
          onClose={() => setUnpickPackage(null)}
          width={300}
        >
          <Grid container spacing={2}>
            <Grid xs={12}>
              {unpickPackage?.unPickItem?.serial_numbers?.length === 0 && (
                <Box>
                  <Typography>No items to unpick</Typography>
                </Box>
              )}
              {unpickPackage?.unPickItem?.serial_numbers?.map(
                (item: any, index: number) => {
                  return (
                    <Box
                      key={item}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>{item}</Typography>

                      <IconButton
                        color="danger"
                        size="sm"
                        onClick={async () => {
                          if (!(await ConfirmUtils.sure())) return;

                          dispatch(
                            unpickItemFromPackage({
                              so_id: salesOrderDetail.id,
                              package_id: unpickPackage.id,
                              serial_number: item,
                            })
                          )
                            .then(unwrapResult)
                            .then(({ response }) => {
                              if (response.apiStatus) {
                                notification.success("Unpick successfully");
                                setUnpickPackage({
                                  ...unpickPackage,
                                  unPickItem: {
                                    ...unpickPackage.unPickItem,
                                    serial_numbers: _.filter(
                                      unpickPackage.unPickItem?.serial_numbers,
                                      (v: any) => v !== item
                                    ),
                                  },
                                });
                                fetchDetail();
                              } else {
                                notification.error("Unpick failed");
                              }
                            });
                        }}
                      >
                        <DeleteOutline fontSize="small" />
                      </IconButton>
                    </Box>
                  );
                }
              )}
            </Grid>
          </Grid>
        </BaseModal>
      )}

      {!!movePackage && (
        <BaseModal
          title={`Move ${movePackage.moveItem?.code}`}
          isOpen={true}
          onClose={() => setMovePackage(null)}
          width={400}
          actions={
            <Box>
              <Button size="sm"
                loading={movePackage.loading}
                onClick={async () => {
                  if (!(await ConfirmUtils.sure())) return;

                  setMovePackage({
                    ...movePackage,
                    loading: true,
                  });
                  dispatch(
                    moveItemPackage({
                      so_id: salesOrderDetail.id,
                      from_id: movePackage.id,
                      to_id: movePackage.moveTo,
                      serial_numbers: movePackage.selected_serial_numbers,
                    })
                  ).then(unwrapResult).then(rs => {
                    if (rs.response.apiStatus) {
                      notification.success("Move successfully");
                      fetchDetail();
                      setMovePackage(null);
                    } else {
                      setMovePackage({
                        ...movePackage,
                        loading: false,
                      });
                      notification.error(rs.response.message || "Move failed");
                    }

                  })
                }
                }
                disabled={!movePackage?.moveTo || !movePackage?.selected_serial_numbers?.length}
              >Proccess</Button>
            </Box>
          }
        >
          <Grid container spacing={2}>
            <Grid xs={12}>
              <FormControl>
                <BaseSelect
                  label="To package"
                  options={shipmentList
                    .filter((e) => e.id !== movePackage.id && e.status === PACKAGE_SHIPMENT_STATUS.PACKING)
                    .map((v) => ({
                      value: v.id,
                      label: `Package #${v.id}`,
                    }))}
                  value={movePackage?.moveTo}
                  onChange={(e) =>
                    setMovePackage({
                      ...movePackage,
                      moveTo: e,
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid xs={12}>
              {movePackage?.moveItem?.serial_numbers?.length === 0 && (
                <Box>
                  <Typography>No items to unpick</Typography>
                </Box>
              )}
              <CardWithLabel label={"Select Items"}>
                {movePackage?.moveItem?.serial_numbers?.map(
                  (item: any, index: number) => {
                    return (
                      <Box
                        key={item}
                        sx={{
                          my: 1,
                        }}
                      >
                        <BaseCheckbox
                          label={item}
                          checked={movePackage?.selected_serial_numbers?.includes(
                            item
                          )}
                          onChange={(e) =>
                            setMovePackage({
                              ...movePackage,
                              selected_serial_numbers: e.target.checked
                                ? [
                                  ...movePackage?.selected_serial_numbers || [],
                                  item,
                                ]
                                : _.filter(
                                  movePackage?.selected_serial_numbers,
                                  (v: any) => v !== item
                                ),
                            })
                          }
                        />
                      </Box>
                    );
                  }
                )}
              </CardWithLabel>
            </Grid>
          </Grid>
        </BaseModal>
      )}
    </Stack>
  );
}

import AddIcon from "@mui/icons-material/Add";
import {
  Grid,
  IconButton,
  Stack,
  Switch,
  Table,
  Tooltip,
  Typography,
} from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import { unwrapResult } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux-store";
import { ROUTERS_CUSTOMER } from "routers/constants";

import { ClearOutlined, ModeEdit } from "@mui/icons-material";
import {
  DeliveryTypeEnum,
  SHIPPING_SERVICES
} from "app-constants";
import AddressModal from "components/AddressModal";
import BaseInput from "components/BaseInput";
import BaseSelect from "components/BaseSelect";
import BaseSelectWithAdd, { ADD_NEW_VALUE } from "components/BaseSelectWithAdd";
import ConfirmUtils from "components/ConfirmUtils";
import PageWrapper from "components/PageWrapper";
import { createCustomerAddress, createCustomerSaleOrder, getListCustomerAddress, updateCustomerAddress } from "redux-store/reducers/customer-portal-so";
import { ICustomerAddress, TSalesOrderItemNotStock } from "types/customer-portal-so";
import { ISalesOrderItem } from "types/sales-order";
import { parseAddress, showProductItemName } from "utils/common";
import notification from "utils/notification";
import ModalAddNewCustomerPOItem from "./components/ModalAddNew";
import ModalNotStock from "./components/ModalNotStock";

export default function PurchaseNewPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [optionsAddressList, setOptionsAddressList] = useState<any[]>([]);
  const fetchDetailData = (ne: ICustomerAddress | null) => {
    dispatch(
      getListCustomerAddress({})
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response?.data) {
          const nAdd: any[] = [...response.data]
          if (ne) {
            nAdd.push(ne)
          }
          const addOpt = nAdd?.map((item: any) => ({
            label: parseAddress(item),
            value: item?.id,
            type: item?.type,
          }));
          setOptionsAddressList(addOpt)
        }

      });
  };
  useEffect(() => {
    fetchDetailData(null);
  }, []);


  const [isOpenAddAdress, setIsOpenAddAdress] = useState<ICustomerAddress>();
  const [isOpen, setIsOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState<ISalesOrderItem>();
  const [listItems, setListItems] = useState<ISalesOrderItem[]>([]);
  const [shippingId, setShippingId] = useState("");
  const [billingId, setBillingId] = useState("");
  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [deliveryCompany, setDeliveryCompany] = useState("");
  const [trackingNumber, setTrackingNumber] = useState("");
  const [expeditedShipping, setExpeditedShipping] = useState(false);
  const [isLTL, setIsLTL] = useState(false);
  const [shippingService, setShippingService] = useState("");
  const [isSameDayPickUp, setIsSameDayPickUp] = useState(false);

  const [itemsOutStock, setItemsOutStock] = useState<
    TSalesOrderItemNotStock[] | null
  >(null);

  const onToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const onCloseModal = () => {
    setIsOpen(false);
    setItemSelected(undefined);
  };

  const onCreateItem = (item: ISalesOrderItem) => {
    onToggleOpen();
    const isExist = listItems.find((i) => i.item_id === item.item_id);
    if (isExist) {
      setListItems(
        listItems.map((i) => (i.item_id === item.item_id ? item : i))
      );
    } else {
      setListItems([...listItems, item]);
    }
    setItemSelected(undefined);
  };

  const onEditItem = (itemSelected: ISalesOrderItem) => () => {
    setItemSelected(itemSelected);
    onToggleOpen();
  };

  const onRemoveItem = (id?: number) => (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    ConfirmUtils.sure().then((isOk) => {
      if (!isOk) return;
      setListItems(listItems.filter((item) => item.item?.id !== id));
    });
  };

  const handleCreateNewPurchase =
    (isBackOrder?: boolean, deliveryType?: DeliveryTypeEnum) => () => {
      const data = {
        name,
        tax_cost: 0,
        shipping_cost: 0,
        total: 0,
        note,
        sales_order_items: listItems.map((item) => ({
          item_id: item.item_id,
          quantity: item.quantity,
          cost: 0,
          tax_cost: 0,
          shipping_cost: 0,
          other_cost: 0,
          total: 0,
          note: item.note,
        })),
        shipping_address_id: +shippingId,
        billing_address_id: +billingId,
        delivery_company: deliveryCompany || "",
        tracking_number: trackingNumber || "",
        delivery_method: deliveryMethod,
        delivery_type: deliveryType || DeliveryTypeEnum.FULL,
        is_back_order: isBackOrder ? "yes" : "no",
        ship_date: "",
        shipping_service: shippingService,
      };

      setItemsOutStock(null);
      setLoading(true);
      dispatch(createCustomerSaleOrder(data))
        .then(unwrapResult)
        .then(({ response }) => {
          setLoading(false);
          const data = response.data || {};
          if (data?.id) {
            notification.success("Create PO success");
            if (data?.id && !data?.item_not_stock?.length) {
              navigate(ROUTERS_CUSTOMER.PURCHASE_ORDER);
            }
          } else if (data?.item_not_stock?.length) {
            setItemsOutStock(
              data?.item_not_stock?.map((item: any) => {
                return {
                  ...item,
                  item: listItems.find((i: any) => i.item_id === item.id)?.item,
                };
              })
            );
          } else {
            const err: any = { ...data };
            notification.error(err.message || "Create PO fail");
          }
        });
    };

  const handleRemoveOutStock = () => {
    const newListItems = listItems
      .map((item) => {
        const foundOutStock = itemsOutStock?.find((i) => i.id === item.item_id);
        if (foundOutStock) {
          if (foundOutStock.instock === 0) {
            return null;
          } else {
            return {
              ...item,
              quantity: foundOutStock.instock,
            };
          }
        } else {
          return item;
        }
      })
      .filter(Boolean) as ISalesOrderItem[];

    setListItems(newListItems);

    setItemsOutStock(null);
  };




  return (
    <Box sx={{ mt: "24px" }}>
      <PageWrapper
        pageName={"New Order"}
        pageAction={
          <Box>
            <Button
              sx={{ marginRight: 1 }}
              variant="plain"
              onClick={() => navigate(ROUTERS_CUSTOMER.PURCHASE_ORDER)}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              size="sm"
              onClick={handleCreateNewPurchase()}
              loading={loading}
            >
              Process Order
            </Button>
          </Box>
        }
      >
        <Stack>
          <Grid container spacing={2}>
            <Grid xs={12} container sm={12} sx={{ mt: 1 }}>
              <Grid xs={12} sm={6}>

              </Grid>

              {deliveryMethod === "by_abaco" && (
                <>
                  <Grid
                    xs={12}
                    sm={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Switch
                      checked={expeditedShipping}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setExpeditedShipping(event.target.checked)
                      }
                      endDecorator={"Expedited Ship"}
                    />
                  </Grid>

                  {expeditedShipping && (
                    <Grid
                      xs={12}
                      sm={3}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Switch
                        checked={isLTL}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => setIsLTL(event.target.checked)}
                        endDecorator={"LTL"}
                      />
                    </Grid>
                  )}
                </>
              )}

              {deliveryMethod === "my_own_shipping" && (
                <>
                  <Grid
                    xs={12}
                    sm={3}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Switch
                      checked={expeditedShipping}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setExpeditedShipping(event.target.checked)
                      }
                      endDecorator={"Expedited Ship"}
                    />
                  </Grid>
                </>
              )}

              {deliveryMethod === "will_call" && (
                <>
                  <Grid
                    xs={12}
                    sm={6}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Switch
                      checked={isSameDayPickUp}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setIsSameDayPickUp(event.target.checked)
                      }
                      endDecorator={"Is same day pick up"}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            {deliveryMethod === "by_abaco" && (
              <Grid xs={12} sm={12} container spacing={2}>
                {expeditedShipping && (
                  <Grid xs={12} sm={6}>
                    <FormControl>
                      <BaseSelect
                        label="Shipping Service"
                        options={SHIPPING_SERVICES}
                        value={shippingService}
                        onChange={(e: any) => setShippingService(e)}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid xs={12} sm={6}>
                  <FormControl>

                  </FormControl>
                </Grid>
              </Grid>
            )}
            {deliveryMethod === "my_own_shipping" && (
              <>
                {expeditedShipping && (
                  <Grid xs={12} sm={4}>
                    <FormControl>
                      <BaseSelect
                        label="Shipping Service"
                        options={SHIPPING_SERVICES}
                        value={shippingService}
                        onChange={(e: any) => setShippingService(e)}
                      />
                    </FormControl>
                  </Grid>
                )}
                <Grid xs={12} sm={4}>
                  <FormControl>
                    <BaseInput
                      label="Ship Via"
                      value={deliveryCompany}
                      onChange={(e: any) => setDeliveryCompany(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={4}>
                  <FormControl>
                    <BaseInput
                      label="Tracking #"
                      value={trackingNumber}
                      onChange={(e) => setTrackingNumber(e.target.value)}
                    />
                  </FormControl>
                </Grid>
              </>
            )}

            {deliveryMethod !== "will_call" && (
              <Grid xs={12} sm={6}>
                <FormControl>
                  <BaseSelectWithAdd
                    label="Ship To"
                    options={
                      optionsAddressList?.filter(
                        (v: any) => v.type === "ship"
                      ) || []
                    }
                    value={shippingId}
                    allowAddNew={true}
                    onChange={(v) => {
                      if (v === ADD_NEW_VALUE) {
                        setShippingId("");
                        setIsOpenAddAdress({
                          phone: "",
                          type: "ship",
                        });
                      } else {
                        setShippingId(v);
                      }
                    }}
                  />
                </FormControl>
              </Grid>
            )}
            <Grid xs={12} sm={6}>
              <FormControl>
                <BaseSelectWithAdd
                  label="Bill To"
                  options={
                    optionsAddressList?.filter(
                      (v: any) => v.type === "bill"
                    ) || []
                  }
                  value={billingId}
                  allowAddNew={true}
                  onChange={(v) => {
                    if (v === ADD_NEW_VALUE) {
                      setBillingId("");
                      setIsOpenAddAdress({
                        phone: "",
                        type: "bill",
                      });
                    } else {
                      setBillingId(v);
                    }
                  }}
                />

              </FormControl>
            </Grid>

            <Grid xs={12} sm={6}>
              <FormControl>
                <BaseInput
                  label={"My Company Order #"}
                  value={name}
                  onChange={(e: any) => setName(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <BaseInput
                  label={"Shipping Instruction"}
                  value={note}
                  onChange={(e) => setNote(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>

          <Stack>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
                mt: 2,
              }}
            >
              <IconButton
                color="primary"
                size="sm"
                onClick={onToggleOpen}
                variant="outlined"
              >
                <AddIcon />
              </IconButton>
            </Box>

            <Stack spacing={2}>
              <Table variant={"outlined"} stickyHeader>
                <thead>
                  <tr>
                    <th>Item Name</th>
                    <th style={{ width: "80px", textAlign: "right" }}>Qty</th>
                    {!!onRemoveItem && <th style={{ width: "65px" }}></th>}
                  </tr>
                </thead>
                <tbody>
                  {listItems.map((item, index) => {
                    const product = item.item;

                    return (
                      <tr key={index}>
                        <td>{showProductItemName(product, true)}</td>
                        <td
                          style={{
                            textAlign: "right",
                          }}
                        >
                          {item.quantity || "-"}
                        </td>
                        {!!onRemoveItem && (
                          <td>
                            <Box
                              sx={{
                                display: "flex",
                                gap: 1,
                              }}
                            >
                              <Tooltip title="Edit" arrow>
                                <ModeEdit
                                  sx={{ cursor: "pointer" }}
                                  fontSize="small"
                                  onClick={onEditItem?.(item)}
                                />
                              </Tooltip>
                              <Tooltip title="Remove" arrow>
                                <ClearOutlined
                                  sx={{ cursor: "pointer" }}
                                  fontSize="small"
                                  onClick={onRemoveItem?.(item.item?.id)}
                                />
                              </Tooltip>
                            </Box>
                          </td>
                        )}
                      </tr>
                    );
                  })}

                  {!listItems.length ? (
                    <tr>
                      <td
                        colSpan={3}
                        style={{
                          textAlign: "center",
                          padding: "16px 0",
                        }}
                      >
                        <Typography>No Item</Typography>
                      </td>
                    </tr>
                  ) : (
                    ""
                  )}
                </tbody>
              </Table>
            </Stack>
          </Stack>
        </Stack>
      </PageWrapper>

      <ModalAddNewCustomerPOItem
        isOpen={isOpen}
        itemSelected={itemSelected}
        onCloseModal={onCloseModal}
        onCreateItem={onCreateItem}
        items={listItems}
      />
      {!!itemsOutStock && (
        <ModalNotStock
          onClose={() => setItemsOutStock(null)}
          onRemove={handleRemoveOutStock}
          onContinue={(deliveryType) =>
            handleCreateNewPurchase(true, deliveryType)()
          }
          // onClose={onClickModalNotStock}
          itemsOutStock={itemsOutStock}
        />
      )}


      {!!isOpenAddAdress && (
        <AddressModal
          isOpen={!!isOpenAddAdress}
          onClose={() => setIsOpenAddAdress(undefined)}
          onSave={(data) => {
            const addJob = {
              ...data,
            };
            dispatch(
              data?.id
                ? updateCustomerAddress(addJob)
                : createCustomerAddress(addJob)
            )
              .then(unwrapResult)
              .then(({ response }) => {
                if (response.apiStatus) {
                  dispatch(getListCustomerAddress({}))
                  notification.success("Create success");
                  const newAddressUpdate = response.data;
                  // hand
                  fetchDetailData(newAddressUpdate)
                  if (newAddressUpdate.type === "ship") {
                    setShippingId(newAddressUpdate.id);
                  }
                  if (newAddressUpdate.type === "bill") {
                    setBillingId(newAddressUpdate.id);
                  }
                  setIsOpenAddAdress(undefined);
                } else {
                  notification.error("Create failed");
                }
              });
          }}
          selectedAddress={isOpenAddAdress}
        />
      )}
    </Box>
  );
}

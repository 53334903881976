import { Box } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { unwrapResult } from "@reduxjs/toolkit";
import { ChipStatus } from "components/ChipStatus";
import PageWrapper from "components/PageWrapper";
import { DetailSkeleton } from "components/SkeletonComponents";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { getDetailInvoice } from "redux-store/reducers/customer-portal-invoice";
import { IInvoice } from "types/invoice";
import DetailContent from "./components/DetailContent";

export default function InvoiceDetailPage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const id = useParams()?.id || detailId;
  const invoiceFromState = useAppSelector((state) =>
    state.invoice.list?.find((invoice) => invoice.id === id)
  );
  const [invoiceDetail, setInvoiceDetail] = useState<IInvoice | undefined>(
    invoiceFromState
  );

  const dispatch = useAppDispatch();

  const fetchDetail = () => {
    if (!id) return;
    dispatch(
      getDetailInvoice({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.id) setInvoiceDetail(response.data);
      });
  };

  useEffect(() => {
    fetchDetail();
  }, [id]);

  return (
    <Box mt="24px">
      <PageWrapper
        pageName={
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography>
              Invoice Number: #{invoiceDetail?.invoice?.inv_number || ""}
            </Typography>
            {!!invoiceDetail && (
              <ChipStatus
                status={invoiceDetail?.invoice?.status}
                size="medium"
              />
            )}
          </Box>
        }
        skeletonLoading={!invoiceDetail ? <DetailSkeleton /> : undefined}
        tabs={[
          {
            label: "Detail",
            content: !!invoiceDetail && (
              <DetailContent invoiceDetail={invoiceDetail} />
            ),
          },
        ]}
      />
    </Box>
  );
}

import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Button, FormControl, Grid } from "@mui/joy";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import BaseCurrencyInput from "./BaseCurrencyInput";
import BaseDatePicker from "./BaseDatePicker";
import BaseInput from "./BaseInput";
import BaseModal from "./BaseModal";
import BaseNumberInput from "./BaseNumberInput";
import BaseSelect from "./BaseSelect";

interface IEditFields {
  label: string;
  key: string;
  value: any;
  type?: "text" | "number" | "select" | "date" | "money" | "textarea";
  options?: {
    label: string;
    value: any;
  }[];
  required?: boolean;
  disabled?: boolean;
}

interface IProps {
  editBtnPosition: {
    top?: number;
    left?: number;
    right?: number;
    bottom?: number;
  };
  onSave?: (newData: any, closeEdit: () => void) => void;
  title?: string;
  editFields: IEditFields[];
  renderButton?: (onClick: any) => React.ReactNode;
}
export default function EditHoverPopup({
  editBtnPosition,
  onSave,
  title = "Edit",
  editFields,
  renderButton,
}: IProps) {
  const [isOpenEditModal, setIsOpenEditModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState([...editFields]);

  useEffect(() => {
    if (isOpenEditModal) {
      setEditData(_.cloneDeep(editFields));
    } else {
      setEditData([]);
    }
  }, [editFields, isOpenEditModal]);

  const onClose = () => {
    setIsOpenEditModal(false);
    setLoading(false);
  };

  const handleSave = () => {
    setLoading(true);
    const newDataObj = editData.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {} as any);
    onSave?.(newDataObj, () => {
      onClose();
    });
  };

  const renderEditFields = (item: IEditFields, index: number) => {
    if (item.type === "text") {
      return (
        <BaseInput
          key={item.key}
          label={item.label}
          value={item.value}
          onChange={(e) => {
            const newItems = [...editData];
            newItems[index].value = e.target.value;
            setEditData(newItems);
          }}
        />
      );
    }

    if (item.type === "number") {
      return (
        <BaseNumberInput
          key={item.key}
          label={item.label}
          value={item.value}
          onChange={(e) => {
            const newItems = [...editData];
            newItems[index].value = e;
            setEditData(newItems);
          }}
        />
      );
    }

    if (item.type === "select") {
      return (
        <BaseSelect
          key={item.key}
          label={item.label}
          value={item.value}
          onChange={(e) => {
            const newItems = [...editData];
            newItems[index].value = e;
            setEditData(newItems);
          }}
          options={item.options || []}
        />
      );
    }

    if (item.type === "textarea") {
      return (
        <BaseInput
          key={item.key}
          label={item.label}
          value={item.value}
          onChange={(e) => {
            const newItems = [...editData];
            newItems[index].value = e.target.value;
            setEditData(newItems);
          }}
          multiline
          rows={3}
        />
      );
    }

    if (item.type === "date") {
      return (
        <BaseDatePicker
          key={item.key}
          label={item.label}
          value={item.value}
          onChange={(e) => {
            const newItems = [...editData];
            newItems[index].value = e;
            setEditData(newItems);
          }}
        />
      );
    }

    if (item.type === "money") {
      return (
        <BaseCurrencyInput
          key={item.key}
          label={item.label}
          value={item.value}
          onChange={(e) => {
            const newItems = [...editData];
            newItems[index].value = e;
            setEditData(newItems);
          }}
        />
      );
    }
  };

  return (
    <>
      {renderButton ? (
        renderButton(() => setIsOpenEditModal(true))
      ) : (
        <ModeEditIcon
          sx={{
            position: "absolute",
            cursor: "pointer",
            ...editBtnPosition,
            height: 18,
          }}
          onClick={() => setIsOpenEditModal(true)}
        />
      )}

      {!!isOpenEditModal && (
        <BaseModal
          isOpen={true}
          onClose={onClose}
          title={title}
          width={500}
          actions={
            <Button onClick={handleSave} loading={loading}>
              Save
            </Button>
          }
        >
          <Grid container spacing={2}>
            {editData.map((item, index) => (
              <Grid sm={12}>
                <FormControl>{renderEditFields(item, index)}</FormControl>
              </Grid>
            ))}
          </Grid>
        </BaseModal>
      )}
    </>
  );
}

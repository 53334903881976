import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import { persistEncryptReducer } from "redux-store/redux-utils";
import {
  createEmployeeApi,
  getDetailEmployeeApi,
  getQuickBooksTokenApi,
  getQuickBooksUrlApi,
  getRoleListApi,
  getUPSTokenApi,
  getUpsUrlApi,
  listEmployeeApi,
  updateEmployeeApi,
  updatePasswordApi,
} from "services/employee.service";
import { IEmployee, IRole } from "types/employee";

const TYPE = "EMPLOYEE";

export interface IEmployeeState {
  list: IEmployee[];
  roleList: IRole[];
}

const initialState: IEmployeeState = {
  list: [],
  roleList: [],
};

export const createEmployee = createCallApiAsyncAction(
  TYPE + "/createEmployee",
  createEmployeeApi
);

export const updateEmployee = createCallApiAsyncAction(
  TYPE + "/updateEmployee",
  updateEmployeeApi
);

export const updatePassword = createCallApiAsyncAction(
  TYPE + "/updatePassword",
  updatePasswordApi
);

export const getListEmployee = createCallApiAsyncAction(
  TYPE + "/getListEmployee",
  listEmployeeApi
);

export const getDetailEmployee = createCallApiAsyncAction(
  TYPE + "/getDetailEmployee",
  getDetailEmployeeApi
);

export const getRole = createCallApiAsyncAction(
  TYPE + "/getRole",
  getRoleListApi
);

export const getQuickBooksUrl = createCallApiAsyncAction(
  TYPE + "/getQuickBooksUrl",
  getQuickBooksUrlApi
);

export const getUpsUrl = createCallApiAsyncAction(
  TYPE + "/getUpsUrl",
  getUpsUrlApi
);

export const getQuickBooksToken = createCallApiAsyncAction(
  TYPE + "/getQuickBooksToken",
  getQuickBooksTokenApi
);

export const getUPSToken = createCallApiAsyncAction(
  TYPE + "/getUPSToken",
  getUPSTokenApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListEmployee,
      stateKey: "list",
    });
    addListBuilderCase({
      builder,
      fetchAction: getRole,
      stateKey: "roleList",
    });
  },
  reducers: {},
});

export default persistEncryptReducer(TYPE, slice.reducer, [
  "roleList",
]) as typeof slice.reducer;

import Typography from "@mui/joy/Typography";

import { Box, Button, Grid } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { InvoiceStatusEnum } from "app-constants";
import { ChipStatus } from "components/ChipStatus";
import ConfirmUtils from "components/ConfirmUtils";
import MoreActions from "components/MoreActions";
import PageWrapper from "components/PageWrapper";
import { DetailSkeleton } from "components/SkeletonComponents";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  createInvoicePayment,
  getDetailInvoice,
  syncInvoiceQBO
} from "redux-store/reducers/invoice";
import ROUTERS from "routers/constants";
import { printSOApi } from "services/sales-order.service";
import { IInvoice } from "types/invoice";
import { downloadPrintPdf, soBarcode } from "utils/common";
import notification from "utils/notification";
import CreateInvoicePaymentModal from "./components/CreatePaymentModal";
import DetailContent from "./components/DetailContent";

export default function InvoiceDetailPage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const id = useParams()?.id || detailId;
  const [moreActionLoading, setMoreActionLoading] = useState(false);
  const invoiceFromState = useAppSelector((state) =>
    state.invoice.list?.find((invoice) => invoice.id === id)
  );
  const [openCreatePaymentModal, setOpenCreatePaymentModal] = useState(false);
  const [invoiceDetail, setInvoiceDetail] = useState<IInvoice | undefined>(
    invoiceFromState
  );

  const dispatch = useAppDispatch();

  const fetchDetail = () => {
    if (!id) return;
    dispatch(
      getDetailInvoice({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.id) setInvoiceDetail(response.data);
      });
  };

  useEffect(() => {
    fetchDetail();
  }, [id]);

  const handleCreatePayment = () => {
    setOpenCreatePaymentModal(true);
  };

  const handleRemindPayment = async () => {
    if (
      !(await ConfirmUtils.show({
        title: "Invoice Confirmation Email",
        description: <>The Invoice document for <b>{invoiceDetail?.customer?.first_name} </b> will be sent to the customer's email at: <b>{invoiceDetail?.customer?.email}</b></>,
      }))
    ) return;
    if (!invoiceDetail) return;
    setMoreActionLoading(true);
    printSOApi({
      printType: 'INVOICE',
      is_email: true,
      so: {
        ...invoiceDetail,
        grandTotal: invoiceDetail.invoice?.total,
      },
      barcode: await soBarcode(invoiceDetail?.invoice?.inv_number || '')
    })
      .then((rs: any) => {
        setMoreActionLoading(false);
        if (!rs.data) {
          notification.error("Send email failed")
          return
        } else {
          notification.success("Send email success")
        }
      });
  };

  const handlePrintInvoice = async () => {
    if (!invoiceDetail) return;
    setMoreActionLoading(true);
    printSOApi({
      printType: 'INVOICE',
      so: {
        ...invoiceDetail,
        grandTotal: invoiceDetail.invoice?.total,
      },
      barcode: await soBarcode(invoiceDetail?.invoice?.inv_number || '')
    })
      .then((rs: any) => {
        setMoreActionLoading(false);
        if (!rs.data) {
          notification.error("Print failed")
          return
        }
        downloadPrintPdf(rs.data, `invoice_${invoiceDetail?.invoice?.inv_number}.pdf`)
      });
  }

  const handleSyncQBO = async () => {
    if (!(await ConfirmUtils.sure())) return;
    setMoreActionLoading(true);
    dispatch(
      syncInvoiceQBO({
        invoice_id: invoiceDetail?.invoice?.id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setMoreActionLoading(false);
        if (response.data?.id) {
          notification.success("Sync successfully");
        } else {
          notification.error("Sync failed");
        }
      });
  };




  const handleCreatePaymentItem = (item: any) => {
    dispatch(
      createInvoicePayment({
        ...item,
        invoice_id: invoiceDetail?.invoice?.id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success("Create payment successfully");
          fetchDetail();
          setOpenCreatePaymentModal(false);
        } else {
          notification.error(response.message || "Create payment failed");
        }
      });
  };

  return (
    <>
      <PageWrapper
        pageName={
          <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
            <Typography>
              Invoice Number: #{invoiceDetail?.invoice?.inv_number || ""}
            </Typography>
            {!!invoiceDetail && (
              <ChipStatus
                status={invoiceDetail?.invoice?.status}
                size="medium"
              />
            )}
          </Box>
        }
        pageAction={
          <Grid container spacing={1}>
            {[
              InvoiceStatusEnum.PARTIALLY_PAID,
              InvoiceStatusEnum.UNPAID,
            ].includes(invoiceDetail?.invoice?.status as any) && (
                <Grid>
                  <Button
                    variant="outlined"
                    size="sm"
                    onClick={handleCreatePayment}
                  >
                    Create Payment
                  </Button>
                </Grid>
              )}

            <Grid>
              <MoreActions
                loading={moreActionLoading}
                actions={[
                  // {
                  //   label: "Send Email Invoice",
                  //   onClick: handleRemindPayment,
                  // },
                  {
                    label: "Print Invoice",
                    onClick: handlePrintInvoice,
                  },
                ]}
              />
            </Grid>
          </Grid>
        }
        breadcrumb={[
          {
            label: "Invoice",
            link: ROUTERS.INVOICE,
          },
          {
            label: "Detail",
          },
        ]}
        skeletonLoading={!invoiceDetail ? <DetailSkeleton /> : undefined}
        tabs={[
          {
            label: "Detail",
            content: !!invoiceDetail && (
              <DetailContent invoiceDetail={invoiceDetail} />
            ),
          },
        ]}
      />

      <CreateInvoicePaymentModal
        isOpen={openCreatePaymentModal}
        onClose={() => setOpenCreatePaymentModal(false)}
        onCreate={handleCreatePaymentItem}
      />
    </>
  );
}

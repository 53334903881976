import { IReturn, IReturnItem, IReturnRealItem } from "types/return";
import { Request } from "utils/request";

export const listReturnApi = () => {
  return Request.call<{ data: IReturn[] }>({
    url: "/return/list",
    method: "GET",
  });
};

export const createReturnApi = (
  payload: IReturn & {
    return_items: IReturnItem[];
  }
) => {
  return Request.call<{ data: IReturn }>({
    url: "/return/create",
    method: "PUT",
    data: payload,
  });
};

export const getDetailReturnApi = (payload: { id: any }) => {
  return Request.call<{ data: IReturn }>({
    url: "/return/detail/" + payload.id,
    method: "GET",
  });
};

export const cancelReturnApi = (payload: { id: any }) => {
  return Request.call<{
    data: {
      id: any;
    };
  }>({
    url: "/return/delete",
    method: "PUT",
    data: payload,
  });
};

export const updateReturnApi = (payload: {
  id: any;
  return_reason: any;
  refund_amount: any;
}) => {
  return Request.call<{
    data: {
      id: any;
    };
  }>({
    url: "/return/update",
    method: "PUT",
    data: payload,
  });
};

export const completeReturnApi = (payload: { id: any }) => {
  return Request.call<{
    data: {
      id: any;
    };
  }>({
    url: "/return/complete",
    method: "PUT",
    data: payload,
  });
};

export const getRealItemsBySerialNumberApi = (payload: {
  serial_numbers: string[];
}) => {
  return Request.call<{ data: IReturnRealItem[] }>({
    url: "/return/find-real-item",
    method: "PUT",
    data: payload,
  });
};

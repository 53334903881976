import AddIcon from "@mui/icons-material/Add";
import { FormControl, Grid, IconButton, Stack, Switch } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import { unwrapResult } from "@reduxjs/toolkit";
import { RETURN_OPTIONS } from "app-constants";
import BaseSelect from "components/BaseSelect";
import PageWrapper from "components/PageWrapper";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import ROUTERS from "routers/constants";
import { ICustomerDropdown } from "types/customer-portal-so";
import { IReturnItem } from "types/return";

import BaseCurrencyInput from "components/BaseCurrencyInput";
import BaseInput from "components/BaseInput";
import ConfirmUtils from "components/ConfirmUtils";
import _ from "lodash";
import { getDetailCustomerDropdown } from "redux-store/reducers/customer";
import { getProductItems } from "redux-store/reducers/product";
import { createReturn } from "redux-store/reducers/return";
import { showCustomerName } from "utils/common";
import notification from "utils/notification";
import ModalAddNewReturnItem from "./components/ModalAddNew";
import ReturnTableItems from "./components/ReturnTableItems";

export default function ReturnNewPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const customerDropdown = useAppSelector(
    (state) => state.customer.customerDropdown
  );
  const [isOpen, setIsOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState<IReturnItem>();
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomerDropdown>();
  const [returnReason, setReturnReason] = useState("");
  const [listItems, setListItems] = useState<IReturnItem[]>([]);
  const [hasSerialNumber, setHasSerialNumber] = useState(true);
  const [soNumber, setSONumber] = useState("");
  const [refundAmount, setRefundAmount] = useState(0);
  useEffect(() => {
    dispatch(getDetailCustomerDropdown({}));
    dispatch(getProductItems({}));
  }, []);

  useEffect(() => {
    setListItems([]);
    setSelectedCustomer(undefined);
    setSONumber("");
  }, [hasSerialNumber]);

  const onCloseModal = () => {
    setIsOpen(false);
    setItemSelected(undefined);
  };

  const onEditItem = (item: IReturnItem) => (event: any) => {
    setItemSelected(item);
    setIsOpen(true);
  };

  const onRemoveItem = (id?: number) => (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    ConfirmUtils.sure().then((isOk) => {
      if (!isOk) return;
      setListItems(listItems.filter((item) => item?.item?.id !== id));
    });
  };

  const handleCreateNewReturn = () => {
    setLoading(true);
    dispatch(
      createReturn({
        return_reason: returnReason,
        refund_amount: refundAmount,
        is_serial_number: !!hasSerialNumber,
        note: "",
        return_items: listItems.map((item) => ({
          item_id: item.item_id,
          quantity: item.quantity,
          so_number: hasSerialNumber ? item.so_number : soNumber,
          customer_id: hasSerialNumber
            ? item.customer_id
            : selectedCustomer?.id,
          item_real_id: hasSerialNumber ? item.item_real_id : undefined,
        })),
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        if (response.apiStatus && response.data.id) {
          notification.success("Create Return success");
          navigate(ROUTERS.RETURN_DETAIL.replace(":id", response.data.id));
        } else {
          notification.error("Create Return fail");
        }
      });
  };

  const disabledSave = hasSerialNumber
    ? listItems.length === 0
    : listItems.length === 0 || !selectedCustomer || !soNumber;
  return (
    <>
      <PageWrapper
        pageAction={
          <Box>
            <Button
              sx={{ marginRight: 1 }}
              variant="plain"
              size="sm"
              onClick={() => navigate(ROUTERS.RETURN)}
              disabled={loading}
            >
              Cancel
            </Button>

            <Button
              color="primary"
              size="sm"
              onClick={handleCreateNewReturn}
              loading={loading}
              disabled={disabledSave}
            >
              Create
            </Button>
          </Box>
        }
        pageName="New Return"
        breadcrumb={[
          {
            label: "Return",
            link: ROUTERS.RETURN,
          },
          {
            label: "New",
          },
        ]}
      >
        <Stack spacing={2} sx={{ pt: 1 }}>
          <Grid container spacing={2}>
            <Grid
              xs={12}
              sm={4}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Box sx={{ height: 40 }}>
                <Switch
                  checked={hasSerialNumber}
                  onChange={(event) => setHasSerialNumber(event.target.checked)}
                  endDecorator={"Has Serial Number"}
                />
              </Box>
            </Grid>
            <Grid xs={12} sm={4}>
              <FormControl>
                <BaseSelect
                  label="Return options"
                  options={RETURN_OPTIONS}
                  value={returnReason}
                  onChange={(val) => {
                    setReturnReason(val);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={4}>
              <FormControl>
                <BaseCurrencyInput
                  label="Refund Amount"
                  value={refundAmount}
                  onChange={(e) => {
                    setRefundAmount(Number(e));
                  }}
                />
              </FormControl>
            </Grid>
            {!hasSerialNumber && (
              <>
                <Grid xs={12} sm={6}>
                  <FormControl>
                    <BaseSelect
                      label="Customer"
                      options={customerDropdown?.map((item) => ({
                        label: showCustomerName(item),
                        value: item.id,
                      }))}
                      value={selectedCustomer?.id}
                      onChange={(val) => {
                        setSelectedCustomer(
                          customerDropdown?.find((item) => item.id === val)
                        );
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={12} sm={6}>
                  <FormControl>
                    <BaseInput
                      label="SO #"
                      value={soNumber}
                      onChange={(e) => {
                        setSONumber(e.target.value);
                      }}
                    />
                  </FormControl>
                </Grid>
              </>
            )}

            <Grid sm={12} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <IconButton
                  color="primary"
                  size="sm"
                  onClick={() => {
                    setItemSelected(undefined);
                    setIsOpen(true);
                  }}
                  variant="outlined"
                >
                  <AddIcon />
                </IconButton>
              </Box>

              <Stack spacing={2}>
                <ReturnTableItems
                  listItems={listItems}
                  hasSerialNumber={hasSerialNumber}
                  onEditItem={onEditItem}
                  onRemoveItem={onRemoveItem}
                />
              </Stack>
            </Grid>
          </Grid>
        </Stack>

        {!!isOpen && (
          <ModalAddNewReturnItem
            editedItem={itemSelected}
            onCreated={(newData) => {
              const foundItem = listItems.find(
                (item) => item.item_id === newData.item_id
              );
              if (foundItem) {
                foundItem.quantity = newData.quantity;
              } else {
                setListItems([...listItems, newData]);
              }
            }}
            onItemsCreated={(newData) => {
              setListItems(_.uniqBy([...listItems, ...newData], "item_id"));
            }}
            isOpen={isOpen}
            hasSerialNumber={hasSerialNumber}
            onCloseModal={onCloseModal}
          />
        )}
      </PageWrapper>
    </>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  cancelReturnApi,
  completeReturnApi,
  createReturnApi,
  getDetailReturnApi,
  getRealItemsBySerialNumberApi,
  listReturnApi,
  updateReturnApi,
} from "services/return.service";
import { IReturn } from "types/return";

const TYPE = "RETURN";

export interface IReturnState {
  list: IReturn[];
}

const initialState: IReturnState = {
  list: [],
};

export const createReturn = createCallApiAsyncAction(
  TYPE + "/createReturn",
  createReturnApi
);
export const getListReturn = createCallApiAsyncAction(
  TYPE + "/getListReturn",
  listReturnApi
);

export const getDetailReturn = createCallApiAsyncAction(
  TYPE + "/getDetailReturn",
  getDetailReturnApi
);

export const cancelReturn = createCallApiAsyncAction(
  TYPE + "/cancelReturn",
  cancelReturnApi
);

export const updateReturn = createCallApiAsyncAction(
  TYPE + "/updateReturn",
  updateReturnApi
);

export const completeReturn = createCallApiAsyncAction(
  TYPE + "/completeReturn",
  completeReturnApi
);

export const getRealItemsBySerialNumber = createCallApiAsyncAction(
  TYPE + "/getRealItemsBySerialNumber",
  getRealItemsBySerialNumberApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListReturn,
      stateKey: "list",
    });
  },
  reducers: {},
});

export default slice.reducer;

import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  Radio,
  RadioGroup,
  Table
} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { unwrapResult } from "@reduxjs/toolkit";
import { ITEM_TYPE, ITEM_TYPE_VALUE } from "app-constants";
import BaseCurrencyInput from "components/BaseCurrencyInput";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import BaseNumberInput from "components/BaseNumberInput";
import CommonList from "components/CommonList";
import MoreActions from "components/MoreActions";
import PageWrapper from "components/PageWrapper";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  getListInventoryItem,
  updateInventoryItem,
  updateInventoryItemPrice,
} from "redux-store/reducers/inventory";
import ROUTERS from "routers/constants";
import {
  exportInvertory,
  showCurrency,
  showProductNameString
} from "utils/common";
import notification from "utils/notification";
import { RolesEnum, getPermission } from "utils/roles";
import { isMobile } from 'utils/ui-uitils';
export default function InventoryItemListPage() {
  const params = new URLSearchParams(window.location.search);
  const [viewType, setViewType] = useState((params.get("viewType") as any) || "all")
  const roleName = useAppSelector((state) => state.auth.user?.roleName);
  const isAllow = [RolesEnum.CEO, RolesEnum.VietnamAccounting, RolesEnum.Director, RolesEnum.COO].includes(roleName as RolesEnum)

  const inventoryItemList = useAppSelector((state) => {
    switch (viewType) {
      case 'all':
        return state.inventory.allItems
      case 'y':
        return state.inventory.listItems;
      case 'n':
        return state.inventory.listParts;
      case 'fset':
        return state.inventory.listFset;
      case 'rset':
        return state.inventory.listRset;
      default:
        return [];
    }
  });
  const totalMoney = useAppSelector((state) => state.inventory.totalMoney);
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const [openEditItem, setOpenEditItem] = useState<{
    length: number;
    width: number;
    height: number;
    weight: number;
    cbm: number;
    code: string;
    id: any;

  }>();

  const [openEditPrice, setOpenEditPrice] = useState<{
    cost: number;
    price: number;
    fob_price: number;
    special_price: number;
    regular_price: number;
    code: string;
    unit: string;
    id: any;
  }>();

  const [loadingEdit, setLoadingEdit] = useState(false);
  const [openExport, setOpenExport] = useState(false);
  const [configObj, setConfigObj] = useState({
    avail: 0,
    instock: 0,
  });

  const arrayColumns = [
    {
      name: "Item Code",
      key: "code",
    },

    {
      key: "progress",
      name: "In Progress",
    },
    {
      name: "In Transit",
      key: "in_transit",
    },
    {
      key: "backorder",
      name: "Backorder",
    },
    {
      name: "Available",
      key: "avail",
    },
    {
      name: "In Stock",
      key: "instock",
    },
    {
      name: "US Price",
      key: "price",
    },
    {
      name: "Special Price",
      key: "special_price",
    }
  ];

  if (getPermission("VIEW_STOCK_VALUE")) {
    arrayColumns.push({
      name: "Cost",
      key: "cost",
    });
    arrayColumns.push({
      name: "Stock Value",
      key: "money",
    });
  }

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleGetListInventoryItem = () => {
    dispatch(getListInventoryItem({}));
  };

  const exportExcel = () => {
    setOpenExport(true);
  };

  useEffect(() => {
    handleGetListInventoryItem();
    setSelectedList(arrayColumns.map((e) => e.key));
  }, []);

  const handleSelectedItem = (checked: boolean, item: string) => {
    if (checked) {
      setSelectedList([...selectedList, item]);
    } else {
      setSelectedList(selectedList.filter((id) => id !== item));
    }
  };

  const handleExportInventory = () => {
    if (!selectedList.length) {
      notification.error("Need select a column");
    }
    const newArray: any[] = [];
    inventoryItemList.forEach((item) => {
      let n;
      if (_.isNumber(configObj.avail) && _.isNumber(configObj.instock) && Number(configObj.avail) > 0 && Number(configObj.instock) > 0) {
        if (
          item.available &&
          item.quantity &&
          item.available > configObj.avail &&
          item.quantity > configObj.instock
        ) {
          n = item;
        }
      } else if (
        !_.isNumber(configObj.avail) &&
        _.isNumber(configObj.instock) && Number(configObj.instock) > 0
      ) {
        if (item.quantity && item.quantity > configObj.instock) {
          n = item;
        }
      } else if (
        _.isNumber(configObj.avail) && Number(configObj.avail) > 0 &&
        !_.isNumber(configObj.instock)
      ) {
        if (item.available && item.available > configObj.avail) {
          n = item;
        }
      } else {
        n = item;
      }

      if (n) {
        newArray.push(n);
      }
    });

    exportInvertory(newArray, selectedList, arrayColumns);
    setTimeout(() => {
      setOpenExport(false);
    }, 1000);
  };

  return (
    <PageWrapper
      pageName={"Inventory Management"}
      pageAction={
        <Box
          sx={{
            display: "flex",
            gap: 2,
            alignItems: isMobile() ? "start" : "center",
          }}
        >
          <Box
            sx={{
              flex: 1
            }}
          >
            <RadioGroup
              onChange={(e) => {
                setViewType(e.target.value)
                const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?viewType=' + e.target.value;
                window.history.pushState({ path: newurl }, '', newurl);
              }}
              defaultValue="y"
              value={viewType}
              name="view-type"

              orientation={isMobile() ? "vertical" : "horizontal"}
            >
              <Radio value="all" label="All" variant="outlined" />
              <Radio value="y" label="Invididual" variant="outlined" />
              <Radio value="n" label="Spare part" variant="outlined" />
              <Radio value="fset" label="Fixed Set" variant="outlined" />
              <Radio value="rset" label="Random Set" variant="outlined" />
            </RadioGroup>
          </Box>

          <Button color="primary" size="sm" onClick={() => exportExcel()}>
            Export
          </Button>
          {getPermission("VIEW_STOCK_VALUE") && <Button
            size="sm"
            variant="plain"
            onClick={() => navigate(ROUTERS.INVENTORY_GRID)}
          >
            <AutoFixHighIcon></AutoFixHighIcon>
          </Button>}
        </Box>
      }
      breadcrumb={[
        {
          label: "Inventory",
        },
      ]}
    >
      <CommonList
        pageSize={50}
        data={inventoryItemList}
        columns={[
          {
            key: "displayName",
            label: "Item Code",
            width: 170,
            render: (row) => (
              <div>
                <Typography fontWeight={500} className={"lineItem"}>
                  <strong>{row.code}</strong>
                  {ITEM_TYPE[row?.type] && [ITEM_TYPE_VALUE.fset, ITEM_TYPE_VALUE.rset].includes(row?.type) && <small className={row?.type}>
                    {row?.compo}
                  </small>}
                </Typography>
                {getPermission("VIEW_STOCK_VALUE") && (
                  <Typography>Cost: {showCurrency(row.cost)}</Typography>
                )}
              </div>
            ),
            rawValue: (row) => showProductNameString(row, true),
          },
          {
            key: "progress",
            label: "Progress",
            width: 90,
          },
          {
            key: "in_transit",
            label: "Transit",
            width: 90,
          },
          {
            key: "backorder",
            label: "Backorder",
            width: 100,
          },
          {
            key: "available",
            label: "Available",
            width: 100,
            render: (row) => (
              <Typography color={row.quantity > 0 ? "success" : "neutral"}>
                {row.available}
              </Typography>
            ),
          },
          {
            key: "quantity",
            label: "InStock",
            width: 100,
            render: (row) => (
              <Typography color={row.quantity > 0 ? "success" : "neutral"}>
                {row.quantity}
              </Typography>
            ),
          },
          {
            key: "price",
            label: "US Price",
            width: 90,
            render: (row) => <Typography>{showCurrency(row.price)}</Typography>,
          },
          {
            key: "special_price",
            label: "Special",
            width: 100,
            render: (row) => (
              <Typography>{showCurrency(row.special_price)}</Typography>
            ),
          },
          {
            key: "money",
            label: "Stock Value",
            width: 120,
            hide: !getPermission("VIEW_STOCK_VALUE"),
            rawValue: (row) => row.quantity * row.cost,
            render: (row) => (
              <Typography color={row.quantity > 0 ? "success" : "neutral"}>
                {showCurrency(row.quantity * row.cost)}
              </Typography>
            ),
          },
          {
            key: "action",
            label: "Action",
            width: 40,
            stopPropagation: true,
            render: (row) => (
              <MoreActions
                actions={[
                  {
                    disabled: getPermission("VIEW_STOCK_VALUE"),
                    label: "Edit Price",
                    onClick: () => {
                      setOpenEditPrice({
                        price: row.price || 0,
                        cost: row.cost || 0,
                        fob_price: row.fob_price || 0,
                        special_price: row.special_price || 0,
                        regular_price: row.regular_price,
                        code: row.code,
                        unit: row.unit,
                        id: row.id,
                      });
                    },
                  },
                  {
                    label: "Dimension & Weight",
                    disabled: !selectedList.length,
                    onClick: () => {
                      setOpenEditItem({
                        length: row.length || 0,
                        width: row.width || 0,
                        height: row.height || 0,
                        weight: row.weight || 0,
                        code: row.code || "",
                        cbm: row.cbm || 0,
                        id: row.id,
                      });
                    }
                  },
                ]}
              />

            ),
          },
        ]}
        searchKeys={["displayName"]}
        sortKeys={[
          "quantity",
          "special_price",
          "price",
          "progress",
          "backorder",
          "in_transit",
          "displayName",
          "available",
          "money",
        ]}
        filterStatus={{
          statusKey: "",
          data: [],
        }}
        onRowClick={(row) =>
          navigate(`${ROUTERS.INVENTORY_DETAIL.replace(":id", row.id as any)}`)
        }
        tableActions={
          getPermission("VIEW_STOCK_VALUE") && (
            <Box
              sx={{
                display: "flex",
                gap: 2,
                alignItems: "center",
                paddingRight: 1,
              }}
            >
              <Typography>Total stock value:</Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {showCurrency(totalMoney)}
              </Typography>
            </Box>
          )
        }
      />

      {!!openEditItem && (
        <BaseModal
          title="Edit Dimension & Weight"
          isOpen={true}
          onClose={() => setOpenEditItem(undefined)}
          actions={
            <Button
              color="primary"
              onClick={() => {
                setLoadingEdit(true);
                dispatch(
                  updateInventoryItem({
                    ...openEditItem,
                  })
                )
                  .then(unwrapResult)
                  .then(({ response }) => {
                    setLoadingEdit(false);
                    if (response.apiStatus) {
                      notification.success("Update success");
                      setOpenEditItem(undefined);
                      handleGetListInventoryItem();
                    } else {
                      notification.error("Update failed");
                    }
                  });
              }}
              size="sm"
              loading={loadingEdit}
            >
              Save
            </Button>
          }
        >
          <Grid container spacing={2}>
            <Grid xs={12} sm={4}>
              <FormControl>
                <BaseInput
                  label="Code"
                  disabled={true}
                  value={openEditItem.code}
                  onChange={(e) =>
                    setOpenEditItem({
                      ...openEditItem,
                      code: e.target.value,
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={4}>
              <FormControl>
                <BaseNumberInput
                  label="Weight (kg)"
                  value={openEditItem.weight}
                  onChange={(e) =>
                    setOpenEditItem({
                      ...openEditItem,
                      weight: e,
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={4}>
              <FormControl>
                <BaseNumberInput
                  label="CMB (m3)"
                  value={openEditItem.cbm}
                  onChange={(e) =>
                    setOpenEditItem({
                      ...openEditItem,
                      cbm: e,
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={4}>
              <FormControl>
                <BaseNumberInput
                  label="Lenght (mm)"
                  value={openEditItem.length}
                  onChange={(e) =>
                    setOpenEditItem({
                      ...openEditItem,
                      length: e,
                    })
                  }
                />
              </FormControl>
            </Grid>

            <Grid xs={12} sm={4}>
              <FormControl>
                <BaseNumberInput
                  label="Width (mm)"
                  value={openEditItem.width}
                  onChange={(e) =>
                    setOpenEditItem({
                      ...openEditItem,
                      width: e,
                    })
                  }
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={4}>
              <FormControl>
                <BaseNumberInput
                  label="Height (mm)"
                  value={openEditItem.height}
                  onChange={(e) =>
                    setOpenEditItem({
                      ...openEditItem,
                      height: e,
                    })
                  }
                />
              </FormControl>
            </Grid>
          </Grid>
        </BaseModal>
      )}

      {!!openEditPrice && (
        <BaseModal
          title="Edit Price"
          isOpen={!!openEditPrice}
          onClose={() => setOpenEditPrice(undefined)}
          actions={
            <Button
              color="primary"
              onClick={() => {
                if (!isAllow) return;
                setLoadingEdit(true);
                dispatch(
                  updateInventoryItemPrice({
                    ...openEditPrice,
                  })
                )
                  .then(unwrapResult)
                  .then(({ response }) => {
                    setLoadingEdit(false);
                    if (response.apiStatus) {
                      notification.success("Update success");
                      handleGetListInventoryItem();
                    } else {
                      notification.error("Update failed");
                    }
                  });
              }}
              size="sm"
              loading={loadingEdit}
            >
              Save
            </Button>
          }
        >
          <Grid container spacing={2}>
            <Grid
              container
              spacing={2}
            >
              <Grid xs={12} sm={12}>
                Code: <b>{openEditPrice.code}</b>
              </Grid>
              <Grid xs={12} sm={4}>
                <FormControl>
                  <BaseCurrencyInput
                    label="Cost"
                    value={openEditPrice?.cost}
                    onChange={(e: any) => {
                      setOpenEditPrice(
                        {
                          ...openEditPrice,
                          cost: Number(e)
                        }
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} sm={4}>
                <FormControl>
                  <BaseCurrencyInput
                    label="FOB Price"
                    value={openEditPrice?.fob_price}
                    onChange={(e: any) => {
                      setOpenEditPrice(
                        {
                          ...openEditPrice,
                          fob_price: Number(e)
                        }
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} sm={4}>
                <FormControl>
                  <BaseCurrencyInput
                    label="US WH Price"
                    value={openEditPrice?.price}
                    onChange={(e: any) => {
                      setOpenEditPrice(
                        {
                          ...openEditPrice,
                          price: Number(e)
                        }
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} sm={4}>
                <FormControl>
                  <BaseCurrencyInput
                    label="Special Price"
                    value={openEditPrice?.special_price}
                    onChange={(e: any) => {
                      setOpenEditPrice(
                        {
                          ...openEditPrice,
                          special_price: Number(e)
                        }
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} sm={4}>
                <FormControl>
                  <BaseCurrencyInput
                    label="End User Price"
                    value={openEditPrice?.regular_price}
                    onChange={(e: any) => {
                      setOpenEditPrice(
                        {
                          ...openEditPrice,
                          regular_price: Number(e)
                        }
                      );
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} sm={4}>
                <FormControl>
                  <BaseInput
                    label="Unit"
                    value={openEditPrice?.unit}
                    onChange={(e: any) => {
                      setOpenEditPrice(
                        {
                          ...openEditPrice,
                          unit: e.target.value
                        }
                      );
                    }}
                  />
                </FormControl>
              </Grid>
            </Grid>

          </Grid>
        </BaseModal>
      )
      }

      {
        !!openExport && (
          <BaseModal
            title="Export setup"
            isOpen={true}
            onClose={() => setOpenExport(false)}
            actions={
              <Button
                color="primary"
                onClick={() => {
                  handleExportInventory();
                }}
                size="sm"
                loading={loadingEdit}
              >
                Process
              </Button>
            }
          >
            <Grid container spacing={2}>
              <Grid xs={12} sm={6}>
                <FormControl>
                  <BaseNumberInput
                    label="Available qty is greater than:"
                    value={configObj.avail}
                    onChange={(e) =>
                      setConfigObj({
                        ...configObj,
                        avail: Number(e),
                      })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} sm={6}>
                <FormControl>
                  <BaseNumberInput
                    label="InStock Qty is greater than:"
                    value={configObj.instock}
                    onChange={(e) =>
                      setConfigObj({
                        ...configObj,
                        instock: Number(e),
                      })
                    }
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} sm={12}>
                <Table>
                  <tbody>
                    {arrayColumns.map((r: any) => {
                      return (
                        <tr>
                          <td
                            style={{
                              width: "40px",
                            }}
                          >
                            <Checkbox
                              checked={selectedList.includes(r.key)}
                              onChange={(e) => {
                                handleSelectedItem(e.target.checked, r.key);
                              }}
                            />
                          </td>
                          <td>{r.name}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Grid>
            </Grid>
          </BaseModal>
        )
      }
    </PageWrapper >
  );
}

import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Typography from "@mui/joy/Typography";

import AddIcon from "@mui/icons-material/Add";
import { Card, Grid, IconButton, Stack } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import ROUTERS from "routers/constants";

import { ModeEdit } from "@mui/icons-material";
import BaseInput from "components/BaseInput";
import ConfirmUtils from "components/ConfirmUtils";
import PageWrapper from "components/PageWrapper";
import { createPurchase } from "redux-store/reducers/purchase";
import { IPurchaseOrderItem } from "types/purchase";
import notification from "utils/notification";
import ModalAddNew from "./components/ModalAddNew";
import ModalChangeSupplier from "./components/ModalChangeSupplier";
import ModalChangeWarehouse from "./components/ModalChangeWarehouse";
import PurchaseTableItem from "./components/PurchaseTableItem";

export default function PurchaseNewPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const supplierList = useAppSelector((state) => state.purchase.supplierList);
  const warehouseList = useAppSelector((state) => state.purchase.warehouseList);

  const [itemSelected, setItemSelected] = useState<IPurchaseOrderItem>();
  const [listItems, setListItems] = useState<IPurchaseOrderItem[]>([]);
  const [name, setName] = useState("");
  const [note, setNote] = useState("");
  const [supplierId, setSupplierId] = useState(supplierList[0]?.id);
  const [warehouseId, setWarehouseId] = useState(warehouseList[0]?.id);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenSupplier, setIsOpenSupplier] = useState(false);
  const [isOpenWarehouse, setIsOpenWarehouse] = useState(false);

  const supplierSelected = supplierList.find((item) => item.id === supplierId);
  const warehouseSelected = warehouseList.find(
    (item) => item.id === warehouseId
  );

  const onToggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const onCloseModal = () => {
    setIsOpen(false);
    setItemSelected(undefined);
  };

  const onCreateItem = (item: IPurchaseOrderItem) => {
    onToggleOpen();
    const isExist = listItems.find((i) => i.item_id === item.item_id);

    if (isExist) {
      setListItems(
        listItems.map((i) => (i.item_id === item.item_id ? item : i))
      );
    } else {
      setListItems([...listItems, item]);
    }

    setItemSelected(undefined);
  };

  const onEditItem = (itemSelected: IPurchaseOrderItem) => () => {
    setItemSelected(itemSelected);
    onToggleOpen();
  };

  const onRemoveItem = (id?: number) => (event: any) => {
    event.stopPropagation();
    event.preventDefault();

    ConfirmUtils.sure().then((isOk) => {
      if (!isOk) return;
      setListItems(listItems.filter((item) => item.item?.id !== id));
    });
  };

  const handleCreateNewPurchase = async () => {
    const total =
      listItems.reduce((acc, item) => {
        return acc + Number(item.cost) * Number(item.quantity);
      }, 0) || 0;

    const data: any = {
      name,
      supplier_id: supplierId,
      warehouse_id: warehouseId,
      tax_cost: 0,
      shipping_cost: 0,
      other_cost: 0,
      total,
      note,
      purchase_order_items: listItems.map((item) => ({
        item_id: item.item_id,
        quantity: item.quantity,
        cost: item.cost,
        tax_cost: 0,
        shipping_cost: 0,
        other_cost: 0,
        total: Number(item.cost) * Number(item.quantity) || 0,
        note: item.note,
      })),
    };

    setLoading(true);
    dispatch(createPurchase(data))
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        if (response.data.id) {
          notification.success("Create purchase order successfully");
          navigate(ROUTERS.PURCHASE);
        } else {
          notification.error("Create purchase order failed");
        }
      });
  };

  return (
    <>
      <PageWrapper
        pageAction={
          <Box>
            <Button
              sx={{ marginRight: 1 }}
              variant="plain"
              size="sm"
              onClick={() => navigate(ROUTERS.PURCHASE)}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              size="sm"
              onClick={handleCreateNewPurchase}
              loading={loading}
            >
              Save
            </Button>
          </Box>
        }
        pageName={`New Purchase`}
        breadcrumb={[
          {
            label: "Purchase Order",
            link: ROUTERS.PURCHASE,
          },
          {
            label: "New",
          },
        ]}
      >
        <Grid container spacing={2}>
          <Grid sm={12}>
            <Card
              color="neutral"
              invertedColors={false}
              orientation="vertical"
              size="sm"
              variant="outlined"
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Grid sx={{ flex: 1, mr: 1 }}>
                  <FormLabel
                    sx={{
                      fontWeight: 600,
                      mb: 0.5,
                      display: "flex",
                    }}
                  >
                    Vendor{" "}
                    <IconButton
                      sx={{ ml: 2 }}
                      size="sm"
                      onClick={() => setIsOpenSupplier(true)}
                    >
                      <ModeEdit fontSize="small" />
                    </IconButton>
                  </FormLabel>
                  <Typography sx={{ mb: 0.5 }}>
                    {supplierSelected?.name}
                  </Typography>
                  <Typography>{supplierSelected?.address}</Typography>
                  <Typography>
                    {(supplierSelected?.city || "N/A") +
                      ", " +
                      (supplierSelected?.state || "N/A") +
                      ", " +
                      (supplierSelected?.country || "N/A") +
                      ", " +
                      (supplierSelected?.zip_code || "N/A")}
                  </Typography>
                  <Typography>{supplierSelected?.email}</Typography>
                  <Typography>{supplierSelected?.phone}</Typography>
                </Grid>

                <Grid sx={{ flex: 1, mr: 1 }}>
                  <FormLabel
                    sx={{
                      fontWeight: 600,
                      mb: 0.5,
                      display: "flex",
                    }}
                  >
                    Ship To
                    <IconButton
                      sx={{ ml: 2 }}
                      size="sm"
                      onClick={() => setIsOpenWarehouse(true)}
                    >
                      <ModeEdit fontSize="small" />
                    </IconButton>
                  </FormLabel>
                  <Typography sx={{ mb: 0.5 }}>
                    {warehouseSelected?.name}
                  </Typography>
                  <Typography>{warehouseSelected?.address}</Typography>
                  <Typography>
                    {(warehouseSelected?.city || "N/A") +
                      ", " +
                      (warehouseSelected?.state || "N/A") +
                      ", " +
                      (warehouseSelected?.country || "N/A") +
                      ", " +
                      (warehouseSelected?.zip_code || "N/A")}
                  </Typography>
                  <Typography>{warehouseSelected?.email}</Typography>
                  <Typography>{warehouseSelected?.phone}</Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid sm={6}>
            <FormControl>
              <BaseInput
                label="Purchase Order Name"
                value={name}
                onChange={(e: any) => setName(e.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid sm={6}>
            <FormControl>
              <BaseInput
                label="Note"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </FormControl>
          </Grid>

          <Grid sm={12}>
            <Stack>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mb: 1,
                }}
              >
                <IconButton
                  color="primary"
                  size="sm"
                  onClick={onToggleOpen}
                  variant="outlined"
                >
                  <AddIcon />
                </IconButton>
              </Box>
              <PurchaseTableItem
                listItems={listItems}
                onEditItem={onEditItem}
                onRemoveItem={onRemoveItem}
              />
            </Stack>
          </Grid>
        </Grid>
      </PageWrapper>

      <ModalAddNew
        isOpen={isOpen}
        itemSelected={itemSelected}
        onCloseModal={onCloseModal}
        onCreateItem={onCreateItem}
        items={listItems}
      />

      <ModalChangeSupplier
        isOpen={isOpenSupplier}
        itemId={supplierId}
        setItemId={(id: string | number) => {
          setSupplierId(+id);
        }}
        onCloseModal={() => setIsOpenSupplier(false)}
      />
      <ModalChangeWarehouse
        isOpen={isOpenWarehouse}
        itemId={warehouseId}
        setItemId={(id: string | number) => {
          setWarehouseId(+id);
        }}
        onCloseModal={() => setIsOpenWarehouse(false)}
      />
    </>
  );
}

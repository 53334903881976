import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  createFreightQuoteApi,
  getDetailFreightQuoteApi,
  getListFreightQuoteApi,
  updateCarrierFreightQuoteApi,
  updateFreightQuoteApi,
} from "services/freight-quote.service";
import { IFreightQuote } from "types/freight-quote";

const TYPE = "FREIGHT_QUOTE";

export interface IFreightQuoteState {
  list: IFreightQuote[];
}

const initialState: IFreightQuoteState = {
  list: [],
};

export const getListFreightQuote = createCallApiAsyncAction(
  TYPE + "/getListFreightQuote",
  getListFreightQuoteApi
);

export const getDetailFreightQuote = createCallApiAsyncAction(
  TYPE + "/getDetailFreightQuote",
  getDetailFreightQuoteApi
);

export const updateFreightQuote = createCallApiAsyncAction(
  TYPE + "/updateFreightQuote",
  updateFreightQuoteApi
);

export const updateCarrierFreightQuote = createCallApiAsyncAction(
  TYPE + "/updateCarrierFreightQuote",
  updateCarrierFreightQuoteApi
);

export const createFreightQuote = createCallApiAsyncAction(
  TYPE + "/createFreightQuote",
  createFreightQuoteApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListFreightQuote,
      stateKey: "list",
    });
  },
  reducers: {},
});

export default slice.reducer;

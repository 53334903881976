import { Skeleton } from "@mui/joy";

export const DetailSkeleton = ({ height = 300 }: { height?: number }) => {
  return (
    <>
      <Skeleton variant="text" width="50%" />
      <Skeleton variant="text" width="80%" />
      <Skeleton variant="text" width="96%" />
      <Skeleton variant="text" width="92%" />
      <Skeleton variant="rectangular" height={height} />
    </>
  );
};

import Typography from "@mui/joy/Typography";

import { Box, Card, Divider, Grid, Stack } from "@mui/joy";
import { useAppDispatch } from "redux-store";

import { VerifiedUserOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import CommonList from "components/CommonList";
import _ from "lodash";
import { IFreightQuote } from "types/freight-quote";
import { formatDate, showCurrency, showProductItemName } from "utils/common";

export default function DetailContent({
  freightQuote,
  onUpdateSuccess,
}: {
  freightQuote: IFreightQuote;
  onUpdateSuccess: () => void;
}) {
  const dispatch = useAppDispatch();

  let items = freightQuote?.quote_items || [];

  if (freightQuote?.package?.id) {
    items = _.map(
      _.groupBy(freightQuote.package.real_item, "item.id"),
      (val, id) => {
        return {
          quantity: val.length,
          item: val[0].item,
        };
      }
    ) as any;
  }
  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        <Card
          color="neutral"
          invertedColors={false}
          orientation="vertical"
          size="sm"
          variant="outlined"
        >
          <Grid container spacing={2}>
            <Grid xs={12} sm={4}>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Customer
              </Typography>

              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {freightQuote?.customer?.first_name}{" "}
                {freightQuote?.customer?.last_name}
                {freightQuote?.customer?.price_level === "Special" && (
                  <Tooltip
                    title={freightQuote?.customer?.price_level + " Price"}
                  >
                    <VerifiedUserOutlined
                      fontSize="small"
                      sx={{ ml: 1 }}
                      color="success"
                    />
                  </Tooltip>
                )}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                <b>P</b>: {freightQuote?.customer?.phone || "-"}
              </Typography>
              <Typography sx={{ fontSize: 14, mb: 1 }}>
                <b>M</b>: {freightQuote?.customer?.email || "-"}
              </Typography>

              <Divider
                sx={{
                  mt: 2,
                  display: {
                    xs: "block",
                    sm: "none",
                  },
                }}
              />
            </Grid>

            <Grid xs={12} sm={4}>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Ship To
              </Typography>

              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {freightQuote?.shipping_address?.name}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                {freightQuote?.shipping_address.address}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {freightQuote?.shipping_address?.city},{" "}
                {freightQuote?.shipping_address.state}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {freightQuote?.shipping_address?.zip_code}{" "}
                {freightQuote?.shipping_address.country}
              </Typography>

              <Divider
                sx={{
                  mt: 2,
                  display: {
                    xs: "block",
                    sm: "none",
                  },
                }}
              />
            </Grid>

            <Grid xs={12} sm={4}>
              <Typography sx={{ fontSize: 14, mt: 1 }}>Carrier Name</Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                {freightQuote?.carrier_name || "-"}
              </Typography>

              <Typography sx={{ fontSize: 14, mt: 1 }}>
                Carrier Quote
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 600 }}>
                {freightQuote?.carrier_cost
                  ? showCurrency(freightQuote?.carrier_cost)
                  : "-"}
              </Typography>

              <Divider
                sx={{
                  mt: 2,
                  display: {
                    xs: "block",
                    sm: "none",
                  },
                }}
              />
            </Grid>
          </Grid>
        </Card>

        <Card
          color="neutral"
          invertedColors={false}
          orientation="vertical"
          size="sm"
          variant="outlined"
        >
          <Grid container spacing={2}>
            <Grid xs={6} sm>
              <Typography
                sx={{
                  fontSize: 14,
                }}
              >
                Expedited Ship
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                {freightQuote?.is_expedited ? "Yes" : "No"}
              </Typography>
            </Grid>

            <Grid xs={6} sm>
              <Typography
                sx={{
                  fontSize: 14,
                }}
              >
                Ship Service
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                {freightQuote?.shipping_service || "-"}
              </Typography>
            </Grid>

            <Grid xs={6} sm>
              <Typography
                sx={{
                  fontSize: 14,
                }}
              >
                Ship Instruction
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                {freightQuote?.note || "-"}
              </Typography>
            </Grid>

            <Grid xs={6} sm>
              <Typography
                sx={{
                  fontSize: 14,
                }}
              >
                Customer Quote
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                {freightQuote?.shipping_cost
                  ? showCurrency(freightQuote?.shipping_cost)
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        </Card>

        {!!freightQuote?.package?.id && (
          <Card
            color="neutral"
            invertedColors={false}
            orientation="vertical"
            size="sm"
            variant="outlined"
          >
            <Grid container sx={{ display: "flex" }} spacing={1}>
              <Grid xs={12} sm={4}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 2,
                    mb: 1,
                  }}
                >
                  <Box>
                    <Typography level="body-md">
                      Package ID #{freightQuote?.package.id}
                    </Typography>
                    <Typography level="body-xs" color="neutral">
                      {formatDate(freightQuote?.package.created_at, true)}
                    </Typography>

                    <Typography level="body-sm">
                      Notes: {freightQuote?.package.note || "-"}
                    </Typography>
                  </Box>
                </Box>
              </Grid>

              <Grid xs={12} sm={4}>
                <Typography level="body-sm">
                  Type: {freightQuote?.package.type}
                </Typography>
                <Typography level="body-sm">
                  Weight:{" "}
                  {freightQuote?.package.weight
                    ? `${freightQuote?.package.weight}`
                    : "-"}
                </Typography>
                <Typography level="body-sm">
                  Dimensions:{" "}
                  {!freightQuote?.package.length ||
                  !freightQuote?.package.width ||
                  !freightQuote?.package.height
                    ? "-"
                    : `${freightQuote?.package.length}x${freightQuote?.package.width}x${freightQuote?.package.height}`}
                </Typography>
              </Grid>

              <Grid xs={12} sm={4}>
                <Typography level="body-sm">
                  Carrier:{" "}
                  {freightQuote?.package.carrier
                    ? freightQuote?.package.carrier
                    : "-"}
                </Typography>
                <Typography level="body-sm">
                  Tracking:{" "}
                  {freightQuote?.package.tracking_number
                    ? freightQuote?.package.tracking_number
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        )}
      </Stack>

      <Stack spacing={2}>
        <CommonList
          mobileTitle="Items"
          noLoading
          data={items}
          columns={[
            {
              key: "item_name",
              label: freightQuote?.package?.id
                ? "Picked Item"
                : "Estimate Item",
              rawValue: (lineItem) => showProductItemName(lineItem.item),
              render: (lineItem) => showProductItemName(lineItem.item, true),
            },
            {
              label: "Qty",
              key: "quantity",
            },
          ]}
        />
      </Stack>
    </Stack>
  );
}

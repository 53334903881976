import Typography from "@mui/joy/Typography";

import { Add, ClearOutlined, Done, ModeEdit } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  Stack,
  Tooltip,
} from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import ConfirmUtils from "components/ConfirmUtils";
import PageWrapper from "components/PageWrapper";
import { DetailSkeleton } from "components/SkeletonComponents";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  createWarehouseArea,
  getDetailWarehouse,
} from "redux-store/reducers/warehouse";
import ROUTERS from "routers/constants";
import { AreaTypeEnum, IArea, IWarehouse } from "types/warehouse";
import notification from "utils/notification";

export default function WarehouseDetailPage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const id = useParams()?.id || detailId;
  const warehouseFromState = useAppSelector((state) =>
    state.warehouse.list?.find((warehouse) => warehouse.id === id)
  );
  const [warehouseDetail, setWarehouseDetail] = useState<
    IWarehouse | undefined
  >(warehouseFromState);

  const dispatch = useAppDispatch();

  const [newArea, setNewArea] = useState<IArea>();
  const [selectedAsiel, setSelectedAsiel] = useState<IArea>();
  const [selectedLevel, setSelectedLevel] = useState<IArea>();
  const onCreateArea = async (isDelete?: boolean) => {
    if (!warehouseDetail?.id || !newArea) return;
    const sendObj = {
      ...newArea,
      status: "Active",
    };

    if (isDelete) {
      if (!(await ConfirmUtils.sure())) return;

      sendObj.status = "Inactive";
    }
    if (sendObj.type === AreaTypeEnum.AISLE) {
      delete sendObj.parent_id;
    }
    dispatch(createWarehouseArea(sendObj))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          setNewArea(undefined);
          notification.success("Save success");
          fetchDetail();
        } else {
          notification.error("Save failed");
        }
      });
  };

  const onCloseAddModal = () => {
    setNewArea(undefined);
  };

  const fetchDetail = () => {
    if (!id) return;
    dispatch(
      getDetailWarehouse({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.id) setWarehouseDetail(response.data);
      });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchDetail();
  }, [id]);

  const onRemoveItem = async (area: IArea) => {
    if (!(await ConfirmUtils.sure())) {
      return;
    }
    dispatch(
      createWarehouseArea({
        ...area,
        status: "Inactive",
        parent_id: undefined,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          setNewArea(undefined);
          notification.success("Delete success");
          fetchDetail();
        } else {
          notification.error("Delete failed");
        }
      });
  };

  const renderAreaType = (areaType: AreaTypeEnum) => {
    let parentId = 0;
    let shouldShow = false;
    if (areaType === AreaTypeEnum.AISLE) {
      shouldShow = true;
    }
    if (areaType === AreaTypeEnum.LEVEL) {
      parentId = selectedAsiel?.id || 0;
      shouldShow = !!selectedAsiel;
    }
    if (areaType === AreaTypeEnum.BIN) {
      parentId = selectedLevel?.id || 0;
      shouldShow = !!selectedLevel;
    }
    const parentArea = warehouseDetail?.areas?.find(
      (area) => area.id === parentId
    );
    return (
      <Grid xs={12} sm={4} key={areaType + "_" + parentId}>
        {!!shouldShow && (
          <Card>
            <List size="sm">
              <ListItem>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Typography fontWeight={"500"}>
                    <span
                      style={{
                        textTransform: "capitalize",
                      }}
                    >
                      {areaType}
                    </span>

                    {parentArea
                      ? " of " +
                        (areaType === AreaTypeEnum.LEVEL
                          ? "aisle:"
                          : "level:") +
                        " " +
                        parentArea?.name
                      : ""}
                  </Typography>
                  <IconButton
                    size="sm"
                    color="primary"
                    onClick={() => {
                      setNewArea({
                        type: areaType,
                        parent_id: parentId,
                        name: "",
                        warehouse_id: warehouseDetail?.id || 0,
                      });
                    }}
                  >
                    <Add />
                  </IconButton>
                </Box>
              </ListItem>
              <Divider />

              {warehouseDetail?.areas
                ?.filter((area) => {
                  return (
                    area.type === areaType &&
                    (area.parent_id ? area.parent_id === parentId : true)
                  );
                })
                .map((area) => {
                  const isSelected =
                    areaType === AreaTypeEnum.AISLE
                      ? selectedAsiel?.id === area.id
                      : selectedLevel?.id === area.id;
                  return (
                    <ListItem>
                      <ListItemButton
                        selected={isSelected}
                        onClick={() => {
                          if (areaType === AreaTypeEnum.AISLE) {
                            setSelectedAsiel(area);
                            setSelectedLevel(undefined);
                          }
                          if (areaType === AreaTypeEnum.LEVEL) {
                            setSelectedLevel(area);
                          }
                        }}
                      >
                        {isSelected && <Done color="success" />}
                        {area.name}

                        <Box
                          sx={{
                            display: "flex",
                            gap: 1,
                            ml: "auto",
                          }}
                        >
                          <Tooltip title="Edit" arrow>
                            <ModeEdit
                              sx={{ cursor: "pointer" }}
                              fontSize="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                setNewArea({
                                  ...area,
                                });
                              }}
                            />
                          </Tooltip>
                          <Tooltip title="Remove" arrow>
                            <ClearOutlined
                              sx={{ cursor: "pointer" }}
                              fontSize="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                onRemoveItem(area);
                              }}
                            />
                          </Tooltip>
                        </Box>
                      </ListItemButton>
                    </ListItem>
                  );
                })}
            </List>
          </Card>
        )}
      </Grid>
    );
  };

  return (
    <>
      <PageWrapper
        pageName="Detail Warehouse"
        breadcrumb={[
          {
            label: "Warehouse",
            link: ROUTERS.WAREHOUSE,
          },
          {
            label: "Detail",
          },
        ]}
        skeletonLoading={!warehouseDetail ? <DetailSkeleton /> : undefined}
      >
        <Stack spacing={2}>
          <Grid container spacing={1}>
            <Grid sm={6}>
              <FormLabel>{warehouseDetail?.name}</FormLabel>
              <Typography level="body-sm">
                {warehouseDetail?.address}, {warehouseDetail?.city}
              </Typography>
              <Typography level="body-sm">
                {warehouseDetail?.state}, {warehouseDetail?.zip_code},{" "}
                {warehouseDetail?.country}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            {[AreaTypeEnum.AISLE, AreaTypeEnum.LEVEL, AreaTypeEnum.BIN].map(
              renderAreaType
            )}
          </Grid>
        </Stack>

        {!!newArea && (
          <BaseModal
            isOpen={!!newArea}
            onClose={onCloseAddModal}
            title={_.startCase(
              `${newArea.id ? "Update" : "Add"} ${newArea.type}`
            )}
            actions={
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                }}
              >
                <Button onClick={() => onCreateArea()}>{"Save"}</Button>
              </Box>
            }
            width={320}
          >
            <Grid container spacing={2}>
              <Grid sm={12}>
                <FormControl>
                  <BaseInput
                    label={"Name"}
                    value={newArea.name}
                    onChange={(e: any) =>
                      setNewArea({
                        ...newArea,
                        name: e.target.value,
                      })
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </BaseModal>
        )}
      </PageWrapper>
    </>
  );
}

import { Box, Button, FormControl, Grid, LinearProgress, Table } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { ITEM_TYPE_VALUE } from "app-constants";
import BaseModal from "components/BaseModal";
import BaseNumberInput from "components/BaseNumberInput";
import BaseSelect from "components/BaseSelect";
import ConfirmUtils from "components/ConfirmUtils";
import SelectWarehouseLocation from "components/SelectWarehouseLocation";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import { setBreadcrumb } from "redux-store/reducers/common";
import { getProductItems } from "redux-store/reducers/product";
import { confirmAuditV2, getWarehouseAreas, scanItemAuditV2, scanUpQtyItemAuditV2 } from "redux-store/reducers/warehouse";
import { IArea } from "types/warehouse";
import notification from "utils/notification";

let scanningText = "";
export default function AuditPage() {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [qty, setQty] = useState<number>();
  const [serialSelect, setSerialSelect] = useState<any>();

  const [warehouseAreas, setWarehouseAreas] = useState<IArea[]>([]);
  const [selectedArea, setSelectedArea] = useState<number | undefined>();
  const [selectedItemId, setSelectedItemId] = useState<number | undefined>();
  const [item, setItem] = useState<any>();
  const [serialList, setSerialList] = useState<{
    serial_number: string
    qty: number
    id: any
  }[]>([])
  const productItems =
    useAppSelector((state) => state.product.productItems) || [];
  const scanModeRef = React.createRef<HTMLDivElement>();
  useEffect(() => {
    if (!productItems?.length) {
      dispatch(getProductItems({}));
    }
    dispatch(getWarehouseAreas({ warehouse_id: 1 }))
      .then(unwrapResult)
      .then(({ response }) => {
        setWarehouseAreas(response.data);
      });

    dispatch(
      setBreadcrumb([
        {
          label: "Item Audit",
        },
      ])
    );
    scanningText = "";
  }, []);

  const handleUpdateQty = (serial: any) => {
    setSerialSelect(serial)
    setIsOpen(true)
    scanModeRef.current?.focus();
  }

  const handleAdjustStock = async () => {

    if (!(await ConfirmUtils.sure())) return;

    setLoading(true);
    dispatch(
      confirmAuditV2({
        item_id: selectedItemId,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success("Action success")
        } else {
          notification.error(response.message || "Action fail")
        }
        setLoading(false);

      })
  }

  const handleUpQty = () => {
    const obj: any = {
      id: serialSelect.id,
      item_id: selectedItemId,
      qty
    }
    setLoading(true);
    dispatch(
      scanUpQtyItemAuditV2(obj)
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        setItem(response?.data?.item)
        setSerialList(response?.data?.serials)
        if (!response.apiStatus) {
          notification.error(response.message)
        }
        if (response?.data?.item?.error) {
          notification.error(response?.data?.item?.error)
        }
        handleClose()
      })
  }
  const handleClose = () => {
    setIsOpen(false)
    scanModeRef.current?.focus();
    setSerialSelect(null)
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    if (loading) {
      scanningText = "";
      return;
    }
    if (e.key === "Enter") {
      if (!scanningText) return;
      if (!selectedArea || !selectedItemId) {
        scanningText = "";
        return notification.error("Please select item, location")
      }
      // call api
      setLoading(true);
      dispatch(
        scanItemAuditV2({
          serial_number: scanningText, item_id: selectedItemId, area_id: selectedArea,
        })
      )
        .then(unwrapResult)
        .then(({ response }) => {
          setLoading(false);
          if (!response.apiStatus) {
            notification.error(response.message)
          }

          setItem(response?.data.item)
          setSerialList(response?.data?.serials)
          if (response?.data?.item?.error) {
            notification.error(response?.data?.item?.error)
          }
          scanningText = "";
        })
    } else {
      scanningText += e.key.replace(/^Shift/, "");
    }
  };

  useEffect(() => {
    document.addEventListener("keypress", handleKeyDown);
    return () => {
      document.removeEventListener("keypress", handleKeyDown);
    };
  }, [handleKeyDown, selectedArea, loading]);

  useEffect(() => {
    setSerialList([])
    setItem(null)
  }, [selectedItemId]);

  const totalQty = _.sumBy(serialList, v => Number(v.qty) || 0)

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid
          sm={12}
          xs={12}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              scanModeRef.current?.focus();
            }
          }}
        >
          <Box
            sx={{
              mb: 1
            }}>
            <BaseSelect
              label="Item Code"
              options={
                _.map(productItems, (item) => ({ label: item.code, value: item.id }))
              }
              value={selectedItemId}
              onChange={(e) => {
                setSelectedItemId(e);
                scanModeRef.current?.focus();
              }}
            />
          </Box>

          <SelectWarehouseLocation
            areas={warehouseAreas}
            onSelect={(area) => {
              scanModeRef.current?.focus();
              setTimeout(() => {
                setSelectedArea(area?.id);
              }, 100);
            }}
          />
        </Grid>

        <Grid sm={12} xs={12}>
          {loading ? (
            <LinearProgress thickness={1} />
          ) : (
            <div style={{ height: "1px", width: "100%" }}></div>
          )}

          {!!selectedItemId && !!serialList?.length &&

            <>
              <Table size="sm" borderAxis="both">
                <thead>
                  <tr>
                    <th
                      style={{
                        paddingTop: 0,
                      }}
                    >
                      Serial Number
                    </th>
                    <th style={{
                      paddingTop: 0,
                      width: 80,
                    }}>Qty ({totalQty})
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {(serialList || []).map((e) => {
                    return (
                      <tr
                        onClick={() => {
                          if (item.type === ITEM_TYPE_VALUE.n) {
                            handleUpdateQty(e)
                          }
                        }}
                        key={`${e.id}`}
                      >
                        <td>
                          {e.serial_number}
                        </td>
                        <td>
                          {e.qty}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <Button
                sx={{ mt: 1 }}
                fullWidth
                onClick={() => {
                  handleAdjustStock()
                }}
              >Process</Button>
            </>
          }
        </Grid>
      </Grid>

      <BaseModal
        isOpen={isOpen}
        onClose={() => {
          handleClose()
        }}
        title={"Update Qty"}
        actions={
          <Button onClick={() => { handleUpQty() }} loading={loading}>
            Save
          </Button>
        }
      >
        <Grid container>
          <FormControl>
            <BaseNumberInput value={qty}
              onChange={(e) => {
                setQty(Number(e))
              }}
            >
            </BaseNumberInput>
          </FormControl>
        </Grid>
      </BaseModal>
    </Box>
  );
}


import { Button, FormControl, Grid } from "@mui/joy";
import BaseCurrencyInput from "components/BaseCurrencyInput";
import BaseDatePicker from "components/BaseDatePicker";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import { useEffect, useState } from "react";
import { IContainer } from "types/purchase";



export default function ContainerModal({
  isOpen,
  onClose,
  onSave,
  isLoading = false,
  data
}: {
  isOpen: boolean;
  onClose: () => void;
  onSave: (data: IContainer) => void;
  isLoading?: boolean;
  data: IContainer
}) {
  const [completeShippingInfo, setCompleteShippingInfo] =
    useState<IContainer>({
      eta: "",
      etd: "",
      shipping_cost: 0,
      container_number: "",
      seal_number: "",
      custom_tax: 0,
    });

  useEffect(() => {
    if (isOpen) {
      setCompleteShippingInfo(data)
    }
  }, [isOpen]);

  return (
    <BaseModal
      title={completeShippingInfo?.id ? "Edit container" : "Create container"}
      isOpen={!!isOpen}
      onClose={onClose}
      width={500}
      actions={
        <Button
          loading={isLoading}
          onClick={() => {
            onSave(completeShippingInfo);
          }}
        >
          Process
        </Button>
      }
    >
      <Grid container spacing={2}>
        <Grid sm={6}>
          <FormControl>
            <BaseDatePicker
              label={"ETA"}
              value={completeShippingInfo?.eta}
              onChange={(e) =>
                setCompleteShippingInfo({
                  ...completeShippingInfo,
                  eta: e as string,
                })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={6}>
          <FormControl>
            <BaseDatePicker
              label={"ETD"}
              value={completeShippingInfo?.etd}
              onChange={(e) =>
                setCompleteShippingInfo({
                  ...completeShippingInfo,
                  etd: e as string,
                })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={6}>
          <FormControl>
            <BaseInput
              label="Container Number"
              value={completeShippingInfo?.container_number}
              onChange={(e) =>
                setCompleteShippingInfo({
                  ...completeShippingInfo,
                  container_number: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={6}>
          <FormControl>
            <BaseInput
              label="Seal Number"
              value={completeShippingInfo?.seal_number}
              onChange={(e) =>
                setCompleteShippingInfo({
                  ...completeShippingInfo,
                  seal_number: e.target.value,
                })
              }
            />
          </FormControl>
        </Grid>

        <Grid sm={6}>
          <FormControl>
            <BaseCurrencyInput
              label="Shipping Cost"
              value={completeShippingInfo?.shipping_cost}
              onChange={(e) =>
                setCompleteShippingInfo({
                  ...completeShippingInfo,
                  shipping_cost: Number(e),
                })
              }
            />
          </FormControl>
        </Grid>
        <Grid sm={6}>
          <FormControl>
            <BaseCurrencyInput
              label="Custom Tax"
              value={completeShippingInfo?.custom_tax}
              onChange={(e) =>
                setCompleteShippingInfo({
                  ...completeShippingInfo,
                  custom_tax: Number(e),
                })
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </BaseModal>
  );
}

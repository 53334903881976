import { BoxProps } from "@mui/joy"

export default function CardWithLabel(
  { children, label, ...props }: BoxProps & { label: string }
) {
  return <fieldset
    style={{
      border: `1px solid #ccc`,
      borderRadius: `var(--mui-shape-borderRadius)`,
      padding: '12px',
      ...props.style
    }}
  >
    <legend
      style={{
        fontSize: '14px'
      }}
    >{label}</legend>
    <div
      style={{
        marginTop: '-8px'
      }}
    >{children}</div>
  </fieldset>

}
import Box from "@mui/joy/Box";
import Typography from "@mui/joy/Typography";

import { Card, Divider, Grid, Stack } from "@mui/joy";
import { useAppDispatch } from "redux-store";

import { VerifiedUserOutlined } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { ChipBackOrder } from "components/ChipStatus";
import CommonList from "components/CommonList";
import { ISalesOrder } from "types/sales-order";
import { showCurrency, showProductItemName } from "utils/common";

export default function DetailContent({
  purchaseDetail,
  onUpdateSuccess,
}: {
  purchaseDetail: ISalesOrder;
  onUpdateSuccess: () => void;
}) {
  const dispatch = useAppDispatch();
  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        <Card
          color="neutral"
          invertedColors={false}
          orientation="vertical"
          size="sm"
          variant="outlined"
        >
          <Grid container spacing={2}>
            <Grid xs={12} sm={4}>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Customer
              </Typography>

              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {purchaseDetail?.customer?.first_name}{" "}
                {purchaseDetail?.customer?.last_name}
                {purchaseDetail?.customer?.price_level === "Special" && (
                  <Tooltip
                    title={purchaseDetail?.customer?.price_level + " Price"}
                  >
                    <VerifiedUserOutlined
                      fontSize="small"
                      sx={{ ml: 1 }}
                      color="success"
                    />
                  </Tooltip>
                )}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                <b>P</b>: {purchaseDetail?.customer?.phone || "-"}
              </Typography>
              <Typography sx={{ fontSize: 14, mb: 1 }}>
                <b>M</b>: {purchaseDetail?.customer?.email || "-"}
              </Typography>

              {purchaseDetail?.is_back_order === "yes" && <ChipBackOrder />}

              <Divider
                sx={{
                  mt: 2,
                  display: {
                    xs: "block",
                    sm: "none",
                  },
                }}
              />
            </Grid>

            <Grid xs={12} sm={4}>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Ship To
              </Typography>

              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {purchaseDetail?.shipping_address?.name}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                {purchaseDetail?.shipping_address.address}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {purchaseDetail?.shipping_address?.city},{" "}
                {purchaseDetail?.shipping_address.state}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {purchaseDetail?.shipping_address?.zip_code}{" "}
                {purchaseDetail?.shipping_address.country}
              </Typography>

              <Divider
                sx={{
                  mt: 2,
                  display: {
                    xs: "block",
                    sm: "none",
                  },
                }}
              />
            </Grid>
            <Grid xs={12} sm={4}>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Bill To
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {purchaseDetail?.billing_address.name}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                {purchaseDetail?.billing_address.address}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                {purchaseDetail?.billing_address.city},{" "}
                {purchaseDetail?.billing_address.state}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {purchaseDetail?.billing_address.zip_code}{" "}
                {purchaseDetail?.billing_address.country}
              </Typography>
            </Grid>
          </Grid>
        </Card>

        <Card
          color="neutral"
          invertedColors={false}
          orientation="vertical"
          size="sm"
          variant="outlined"
        >
          <Grid container spacing={2}>
            <Grid xs={6} sm>
              <Typography
                sx={{
                  fontSize: 14,
                }}
              >
                Type
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                {purchaseDetail?.delivery_type || "-"}
              </Typography>
            </Grid>

            <Grid xs={6} sm>
              <Typography
                sx={{
                  fontSize: 14,
                }}
              >
                Ship Date
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                {purchaseDetail?.ship_date || "-"}
              </Typography>
            </Grid>

            <Grid xs={6} sm>
              <Typography
                sx={{
                  fontSize: 14,
                }}
              >
                Ship Method
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                {purchaseDetail?.delivery_method}
              </Typography>
            </Grid>
            <Grid xs={6} sm>
              <Typography
                sx={{
                  fontSize: 14,
                }}
              >
                Ship Via
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                {purchaseDetail?.delivery_company || "-"}
              </Typography>
            </Grid>
            <Grid xs={12} sm>
              <Typography
                sx={{
                  fontSize: 14,
                }}
              >
                Tracking #
              </Typography>
              <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                {purchaseDetail?.tracking_number || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Stack>

      <Stack spacing={2}>
        <CommonList
          mobileTitle="Items"
          noLoading
          data={purchaseDetail?.sales_order_items || []}
          columns={[
            {
              key: "item_name",
              label: "Item Name",
              rawValue: (lineItem) => showProductItemName(lineItem.item),
              render: (lineItem) => showProductItemName(lineItem.item, true),
            },
            {
              label: "Qty",
              key: "quantity",
            },
            {
              key: "cost",
              label: "Unit Cost",
              rawValue: (lineItem) => showCurrency(lineItem.cost),
            },
            {
              key: "total",
              label: "Total",
              rawValue: (lineItem) => showCurrency(lineItem.total),
            },
          ]}
        />

        <Box
          sx={{
            display: "flex",
            gap: 1,
            flexDirection: { xs: "column-reverse", sm: "row" },
          }}
        >
          <Box sx={{ flex: 1 }}>
            {purchaseDetail?.user ? (
              <>
                <Typography fontSize="sm">Sales Rep</Typography>

                <Typography
                  fontSize="sm"
                  fontWeight={500}
                  sx={{
                    mb: 2,
                  }}
                >
                  {purchaseDetail?.user.name}
                </Typography>
              </>
            ) : (
              ""
            )}

            <>
              <Typography fontSize="sm">My Company Order #</Typography>

              <Typography fontSize="sm" fontWeight={500}>
                {purchaseDetail?.name || "-"}
              </Typography>

              <Typography fontSize="sm" sx={{ mt: 2 }}>
                Comment or Special Instructions
              </Typography>

              <Typography fontSize="sm" fontWeight={500}>
                {purchaseDetail?.note || "-"}
              </Typography>
            </>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box mr={6}>
              <Typography fontSize="sm" fontWeight={600} textAlign="right">
                Subtotal
              </Typography>
              <Typography fontSize="sm" textAlign="right">
                Tax
              </Typography>
              <Typography fontSize="sm" textAlign="right">
                Shipping
              </Typography>

              <Typography fontSize="sm" textAlign="right">
                Total Discount
              </Typography>

              <Typography fontSize="sm" fontWeight={600} textAlign="right">
                Total
              </Typography>
            </Box>
            <Box>
              <Typography fontSize="sm" fontWeight={600} textAlign="right">
                {showCurrency(purchaseDetail?.total)}
              </Typography>
              <Typography fontSize="sm" textAlign="right">
                {showCurrency(purchaseDetail?.tax_cost)}
              </Typography>
              <Typography fontSize="sm" textAlign="right">
                {showCurrency(purchaseDetail?.shipping_cost)}
              </Typography>
              <Typography fontSize="sm" textAlign="right">
                {showCurrency(purchaseDetail?.discount_item)}
              </Typography>

              <Typography fontSize="sm" fontWeight={600} textAlign="right">
                {showCurrency(purchaseDetail?.total)}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Stack>
    </Stack>
  );
}

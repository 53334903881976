import { Avatar, FormControl, Grid } from "@mui/joy";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import { unwrapResult } from "@reduxjs/toolkit";
import { PRICE_LEVEL } from "app-constants";
import BaseInput from "components/BaseInput";
import BaseNumberInput from "components/BaseNumberInput";
import BaseSelect from "components/BaseSelect";
import PageWrapper from "components/PageWrapper";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux-store";
import { createCustomer } from "redux-store/reducers/customer";
import ROUTERS from "routers/constants";
import { ICustomer } from "types/customer";
import notification from "utils/notification";

export default function CustomerNewPage() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [newCustomer, setNewCustomer] = useState<ICustomer>({
    password: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    company: "",
    ups_acc: "",
    price_level: PRICE_LEVEL[0].value,
    due_date_number: 30,
  });

  const handleCreateNewCustomer = () => {
    setLoading(true);
    dispatch(createCustomer(newCustomer))
      .then(unwrapResult)
      .then(({ response }) => {
        setLoading(false);
        if (response.data?.id) {
          notification.success("Create customer success");
          navigate(ROUTERS.CUSTOMER);
        } else {
          const err: any = { ...response.data }
          notification.error(err.message || "Create customer failed");
        }
      });
  };
  return (
    <>
      <PageWrapper
        pageAction={
          <Box>
            <Button
              sx={{ marginRight: 1 }}
              size="sm"
              variant="plain"
              onClick={() => navigate(ROUTERS.CUSTOMER)}
              disabled={loading}
            >
              Cancel
            </Button>

            <Button
              color="primary"
              size="sm"
              onClick={handleCreateNewCustomer}
              loading={loading}
            >
              Save
            </Button>
          </Box>
        }
        pageName="New Customer"
        breadcrumb={[
          {
            label: "Customer",
            link: ROUTERS.CUSTOMER,
          },
          {
            label: "New",
          },
        ]}
      >
        <Grid
          container
          spacing={2}
          sx={{
            pt: 1,
          }}
        >
          <Grid xs={12} sx={{ width: 120 }}>
            <Avatar
              variant="outlined"
              size="sm"
              sx={{
                height: 100,
                width: 100,
              }}
            />
          </Grid>
          <Grid container spacing={2} xs={12} sm>

            <Grid xs={12} sm={6}>
              <FormControl>
                <BaseInput
                  label="Display Name *"
                  value={newCustomer.company}
                  placeholder="Company name or Person name"
                  onChange={(e) =>
                    setNewCustomer({
                      ...newCustomer,
                      company: e.target.value,
                      first_name: e.target.value,
                      last_name: "",
                    })
                  }
                />
              </FormControl>
            </Grid>

            <Grid xs={6} sm={6}>
              <BaseSelect
                label={"Price Level"}
                options={PRICE_LEVEL}
                value={newCustomer.price_level}
                onChange={(e) =>
                  setNewCustomer({
                    ...newCustomer,
                    price_level: e,
                  })
                }
              />
            </Grid>

            <Grid xs={12} sm={6}>
              <FormControl>
                <BaseInput
                  label="Phone"
                  value={newCustomer.phone}
                  onChange={(e) =>
                    setNewCustomer({ ...newCustomer, phone: e.target.value })
                  }
                />
              </FormControl>
            </Grid>

            <Grid xs={12} sm={6}>
              <FormControl>
                <BaseInput
                  label="Email"
                  value={newCustomer.email}
                  onChange={(e) =>
                    setNewCustomer({ ...newCustomer, email: e.target.value })
                  }
                />
              </FormControl>
            </Grid>
            <Grid xs={12} sm={6}>
              <FormControl>
                <BaseInput
                  label="Password (Optional)"
                  type="password"
                  value={newCustomer.password}
                  onChange={(e) =>
                    setNewCustomer({ ...newCustomer, password: e.target.value })
                  }
                />
              </FormControl>
            </Grid>



            <Grid xs={6} sm={6}>
              <FormControl>
                <BaseNumberInput
                  label="Due date after"
                  value={newCustomer.due_date_number}
                  onChange={(e) =>
                    setNewCustomer({ ...newCustomer, due_date_number: e })
                  }
                />
              </FormControl>
            </Grid>

            <Grid xs={6} sm={6}>
              <FormControl>
                <BaseInput
                  label="UPS Account"
                  value={newCustomer.ups_acc}
                  onChange={(e) =>
                    setNewCustomer({ ...newCustomer, ups_acc: e.target.value })
                  }
                />
              </FormControl>
            </Grid>


          </Grid>
        </Grid>
      </PageWrapper>
    </>
  );
}

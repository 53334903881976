import { IUser } from "types/auth";
import { Request } from "utils/request";

export const meApi = ({ isCustomer }: { isCustomer?: boolean }) => {
  return Request.call<{
    data: IUser;
  }>({
    url: isCustomer ? "customer-portal/me" : "/profile/me",
    method: "GET",
  });
};

export const customerUpdateProfileApi = (payload: {
  first_name: string;
  last_name: string;
  phone: string;
  email: string;
  bio: string;
  photo: string;
  company?: string;
}) => {
  return Request.call<{
    data: any;
  }>({
    url: "/customer-portal/update/me",
    method: "PUT",
    data: payload,
  });
};

export const loginApi = (payload: { username: string; password: string }) => {
  return Request.call<{
    data: {
      access_token: string;
      roles?: {
        name: string;
      }[];
    };
  }>({
    url: "/auth/login",
    method: "PUT",
    data: payload,
  });
};

export const customerLoginApi = (payload: {
  email: string;
  password: string;
}) => {
  return Request.call<{
    data: {
      access_token: string;
      is_customer: boolean;
    };
  }>({
    url: "/auth/customer/login",
    method: "PUT",
    data: payload,
  });
};

export const postDeleteFirebaseTokenApi = async (payload: {
  token: string;
  is_customer?: boolean;
}) => {
  return Request.call<{
    data: any;
  }>({
    url: `/${
      payload.is_customer ? "customer-portal" : "user"
    }/firebase/token/delete`,
    method: "PUT",
    data: payload,
  });
};

export const postSaveFirebaseTokenApi = async (payload: {
  token: string;
  is_customer?: boolean;
}) => {
  return Request.call<{
    data: any;
  }>({
    url: `/${
      payload.is_customer ? "customer-portal" : "user"
    }/firebase/token/save`,
    method: "PUT",
    data: payload,
  });
};

import { Request } from "utils/request";
import { IApprovalConfigs } from "types/settings";

export const updateApprovalConfigApi = (payload: {
  config_approval: IApprovalConfigs[];
}) => {
  return Request.call<{ data: IApprovalConfigs[] }>({
    url: "/config-approval/update",
    method: "PUT",
    data: payload,
  });
};

export const getApprovalConfigApi = () => {
  return Request.call<{ data: IApprovalConfigs[] }>({
    url: "/config-approval/list",
    method: "GET",
  });
};

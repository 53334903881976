import { Box, Button, Typography } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import * as ExcelJS from "exceljs";
import _ from "lodash";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux-store";
import { importProduct } from "redux-store/reducers/product";
import ROUTERS from "routers/constants";
import notification from "utils/notification";

const getNameAndSuffix = (name: string) => {
  const parts = name.split("_");
  const suffix = parts[parts.length - 1];
  const productName = _.reduce(
    parts,
    (acc, part, index) => {
      if (index === parts.length - 1) return acc;
      return `${acc} ${part}`;
    },
    ""
  );

  return [productName.trim() || suffix.trim(), suffix.trim()];
};

const getCellValue = (value: any) => {
  if (value === "#N/A") return "";
  if (typeof value === "object") return "";
  return value;
};

const ExcelToJsonConverter: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [jsonData, setJsonData] = useState<any[] | null>(null);
  const [totalItems, setTotalItems] = useState<number>(0);
  const [file, setFile] = useState<File | null>(null);
  const [importLoading, setImportLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const onDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setFile(file);
    setError(null);
    const items: any[] = []
    const reader = new FileReader();
    reader.onloadend = (e) => {
      const arrayBuffer: any = reader.result;
      const workbook = new ExcelJS.Workbook();
      workbook.xlsx.load(arrayBuffer).then(function (workbook) {
        try {
          const worksheet = workbook.getWorksheet(1);
          worksheet?.eachRow((row, rowNumber) => {
            if (rowNumber > 1 && row) {
              const rowValues = row.values as any[]; // Cast to any[] to access as array
              const rowArray = rowValues.slice(1); // Remove the first element (index 0) as it's usually undefined
              rowArray[2] = ""
              rowArray[13] = ""
              items.push(rowArray);
            }
          });
          setJsonData(items);
          setTotalItems(items.length);
        } catch (e) {
          setError("Invalid file format");
        }
      });
    };

    reader.readAsBinaryString(file);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      ".xls": ["application/vnd.ms-excel"],
      ".xlsx": [
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ],
    },
  });

  const handleImport = () => {
    if (!jsonData?.length) return;

    setImportLoading(true);
    dispatch(
      importProduct({
        products_chunk: jsonData,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setImportLoading(false);
        if (response.apiStatus) {
          setImportLoading(false);
          notification.success("Import success");
          navigate(ROUTERS.PRODUCT);
        } else {
          notification.error("Import failed");
        }
      });
  };

  return (
    <div>
      <div {...getRootProps()}>
        <Box
          sx={{
            ...dropzoneStyle,
            opacity: importLoading ? 0.5 : 1,
          }}
        >
          {!importLoading && <input {...getInputProps()} />}

          {error ? (
            <Typography color="danger">{error}</Typography>
          ) : file ? (
            <p>
              <b>{file.name}</b> ({Math.floor(file.size / 1024)} kb)
            </p>
          ) : (
            <p>Drag and drop an Excel file here, or click to select one</p>
          )}
        </Box>
      </div>

      {jsonData && (
        <>
          <Box sx={{ mt: 3, display: "flex", justifyContent: "center" }}>
            <Button loading={importLoading} onClick={handleImport}>
              Process Import
            </Button>
          </Box>
          <Typography level="body-sm" sx={{ mt: 3 }}>
            Your upload is currently in progress, and we appreciate your
            patience. Before we proceed, please be aware that this process
            involves uploading <b>{totalItems}</b> items. Your understanding and
            patience are highly valued. Now, your upload is underway, and we
            kindly ask that you do not close or refresh your browser until the
            upload is complete to ensure that all <b>{totalItems}</b> items are
            successfully uploaded. Closing the browser prematurely may interrupt
            the process. Once the upload is finished, you will be notified
          </Typography>
        </>
      )}
    </div>
  );
};

const dropzoneStyle: React.CSSProperties = {
  border: "2px dashed #cccccc",
  borderRadius: "4px",
  padding: "80px 0",
  textAlign: "center",
  cursor: "pointer",
};

export default ExcelToJsonConverter;

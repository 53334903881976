import { ClearOutlined, Edit, VerifiedUserOutlined } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  IconButton,
  Stack,
  Table,
} from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { Tooltip } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { SOStatusEnum } from "app-constants";
import ConfirmUtils from "components/ConfirmUtils";
import { useState } from "react";
import { useAppDispatch } from "redux-store";

import AddressModal from "components/AddressModal";
import EditHoverPopup from "components/EditHoverPopup";
import { updateCustomerAddress } from "redux-store/reducers/customer-portal-so";
import {
  approveSOFull,
  updateSOInternalNote,
  updateSOItem,
} from "redux-store/reducers/sales-order";
import { ICustomerAddress } from "types/customer-portal-so";
import { ISalesOrder, ISalesOrderItem } from "types/sales-order";
import {
  calcGrantTotal,
  formatDate,
  showCurrency,
  showProductItemName,
} from "utils/common";
import notification from "utils/notification";
import { getPermission } from "utils/roles";
import EditAddressSection from "./EditAddressSection";
import EditShipSection from "./EditShipSection";
import ModalAddNewSOItem from "./ModalAddNew";

export default function DetailContent({
  salesOrderDetail,
  onUpdateSuccess,
}: {
  salesOrderDetail: ISalesOrder;
  onUpdateSuccess: () => void;
}) {
  const dispatch = useAppDispatch();
  const [approveLoading, setApproveLoading] = useState(false);
  const [itemSelected, setItemSelected] = useState<ISalesOrderItem>();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenEditShip, setIsOpenEditShip] = useState(false);
  const [isOpenEditAddress, setIsOpenEditAddress] = useState(false);
  const selectedCustomer = salesOrderDetail.customer;

  const [isOpenAddAdress, setIsOpenAddAdress] = useState<ICustomerAddress>();

  const onCloseModal = () => {
    setIsOpen(false);
    setItemSelected(undefined);
  };

  const onCreateItem = (item: ISalesOrderItem) => {
    setIsOpen(false);
    setItemSelected(undefined);
    dispatch(
      updateSOItem({
        so_id: salesOrderDetail.id,
        id: item.id || undefined,
        item_id: item.item?.id || item.item_id,
        quantity: item.quantity,
        cost: item.cost,
        discount: item.discount,
        percent: item.percent,
        note: item.note,
      })
    )
      .then(unwrapResult)
      .then((rs) => {
        if (rs.response.apiStatus) {
          onUpdateSuccess();
          notification.success("Save item success");
        } else {
          notification.error(rs.response.message || "Save item failed");
        }
      });
  };

  const onRemoveItem = (item: ISalesOrderItem) => {
    ConfirmUtils.sure().then((isOk) => {
      if (!isOk) return;

      dispatch(
        updateSOItem({
          so_id: salesOrderDetail.id,
          id: item.id || undefined,
          item_id: item.item?.id || item.item_id,
          quantity: item.quantity,
          cost: item.cost,
          note: item.note,
          is_delete: true,
        })
      )
        .then(unwrapResult)
        .then((rs) => {
          if (rs.response.apiStatus) {
            onUpdateSuccess();
            notification.success("Delete item success");
          } else {
            notification.error(rs.response.message || "Delete item failed");
          }
        });
    });
  };

  const onEditItem = (itemSelected: ISalesOrderItem) => {
    setItemSelected(itemSelected);
    setIsOpen(true);
  };

  const allowUpdateItems =
    getPermission("ALLOW_UPDATE_SO") &&
    ![
      SOStatusEnum.READY_TO_PACK,
      SOStatusEnum.READY_TO_SHIP,
      SOStatusEnum.SHIPPED,
      SOStatusEnum.COMPLETED,
    ].includes(salesOrderDetail.status as any);

  return (
    <Stack spacing={2}>
      <Stack spacing={2}>
        <Card
          color="neutral"
          invertedColors={false}
          orientation="vertical"
          size="sm"
          variant="outlined"
          sx={{
            position: "relative",
          }}
        >
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Grid sx={{ flex: 1, mr: 1 }}>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Customer
              </Typography>

              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {salesOrderDetail?.customer?.first_name}{" "}
                {salesOrderDetail?.customer?.last_name}
                {salesOrderDetail?.customer?.price_level === "Special" && (
                  <Tooltip
                    title={salesOrderDetail?.customer?.price_level + " Price"}
                  >
                    <VerifiedUserOutlined
                      fontSize="small"
                      sx={{ ml: 1 }}
                      color="success"
                    />
                  </Tooltip>
                )}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                <b>ID:</b> {salesOrderDetail?.customer?.cus_number}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                <b>P</b>: {salesOrderDetail?.customer?.phone}
              </Typography>
              <Typography sx={{ fontSize: 14, mb: 1 }}>
                <b>M</b>: {salesOrderDetail?.customer?.email}
              </Typography>

              {salesOrderDetail?.customer?.ups_acc && <Typography sx={{ fontSize: 14, mb: 1 }}>
                <b>UPS ACC</b>: {salesOrderDetail?.customer?.ups_acc}
              </Typography>}
            </Grid>

            <Grid sx={{ flex: 1, mr: 1, position: "relative" }}>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Ship To{" "}
                {getPermission("ALLOW_UPDATE_SO") && ![SOStatusEnum.SHIPPED, SOStatusEnum.COMPLETED].includes(salesOrderDetail.status as SOStatusEnum) && (
                  <Edit
                    onClick={() => {
                      setIsOpenAddAdress(salesOrderDetail?.shipping_address);
                    }}
                    fontSize="small"
                    style={{
                      position: "absolute",
                      left: 55,
                      top: -2,
                      cursor: "pointer",
                    }}
                  />
                )}
              </Typography>

              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {salesOrderDetail?.shipping_address?.name}
              </Typography>

              <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                {salesOrderDetail?.shipping_address?.company}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                {salesOrderDetail?.shipping_address.address}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {salesOrderDetail?.shipping_address?.city},{" "}
                {salesOrderDetail?.shipping_address.state}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {salesOrderDetail?.shipping_address?.zip_code}{" "}
                {salesOrderDetail?.shipping_address.country}
              </Typography>
              {salesOrderDetail?.shipping_address.phone && (
                <Typography sx={{ fontSize: 14 }}>
                  Phone: {salesOrderDetail?.shipping_address.phone}
                </Typography>
              )}
            </Grid>

            <Grid sx={{ flex: 1, ml: 1, position: "relative" }}>
              <Typography
                sx={{
                  fontSize: 14,
                  mb: 1,
                }}
              >
                Bill To{" "}
                {getPermission("ALLOW_UPDATE_SO") && ![SOStatusEnum.SHIPPED, SOStatusEnum.COMPLETED].includes(salesOrderDetail.status as SOStatusEnum) && (
                  <Edit
                    onClick={() => {
                      setIsOpenAddAdress(salesOrderDetail?.billing_address);
                    }}
                    fontSize="small"
                    style={{
                      position: "absolute",
                      left: 46,
                      top: -2,
                      cursor: "pointer",
                    }}
                  />
                )}
              </Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {salesOrderDetail?.billing_address.name}
              </Typography>

              <Typography sx={{ fontSize: 16, fontWeight: 500 }}>
                {salesOrderDetail?.billing_address?.company}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                {salesOrderDetail?.billing_address.address}
              </Typography>

              <Typography sx={{ fontSize: 14 }}>
                {salesOrderDetail?.billing_address.city},{" "}
                {salesOrderDetail?.billing_address.state}
              </Typography>
              <Typography sx={{ fontSize: 14 }}>
                {salesOrderDetail?.billing_address.zip_code}{" "}
                {salesOrderDetail?.billing_address.country}
              </Typography>

              {salesOrderDetail?.billing_address.phone && (
                <Typography sx={{ fontSize: 14 }}>
                  Phone: {salesOrderDetail?.billing_address.phone}
                </Typography>
              )}
            </Grid>

            <Grid sx={{ flex: 1, ml: 1 }}>
              <Typography fontSize="sm">Sales Rep</Typography>
              {salesOrderDetail && (
                <Typography fontSize="sm" fontWeight={500}>
                  {salesOrderDetail?.user?.name}
                </Typography>
              )}
              <Typography fontSize="sm" sx={{ mt: 1 }}>
                Customer PO
                {getPermission("ALLOW_UPDATE_SO") && ![SOStatusEnum.SHIPPED, SOStatusEnum.COMPLETED].includes(salesOrderDetail.status as SOStatusEnum) && (
                  <Edit
                    onClick={() => {
                      setIsOpenEditAddress(true);
                    }}
                    fontSize="small"
                    style={{ cursor: "pointer", marginLeft: 8 }}
                  ></Edit>
                )}
              </Typography>

              <Typography fontSize="sm" fontWeight={500}>
                {salesOrderDetail?.name || "-"}
              </Typography>
            </Grid>
          </Grid>
        </Card>

        <Card
          color="neutral"
          orientation="vertical"
          size="sm"
          variant="outlined"
          sx={{
            position: "relative",
          }}
        >
          {getPermission("ALLOW_UPDATE_SO") && ![SOStatusEnum.SHIPPED, SOStatusEnum.COMPLETED].includes(salesOrderDetail.status as SOStatusEnum) && (
            <Edit
              onClick={() => {
                setIsOpenEditShip(true);
              }}
              fontSize="small"
              style={{
                position: "absolute",
                right: 10,
                top: 10,
                cursor: "pointer",
              }}
            ></Edit>
          )}

          <Grid
            container
            sx={{
              display: "flex",
            }}
          >
            <Grid sx={{ flex: 1 }}>
              <Grid container>
                <Grid sx={{ flex: 1, ml: 1, mr: 1 }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                    }}
                  >
                    Ship Method
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                    {salesOrderDetail?.delivery_method}
                  </Typography>
                </Grid>

                {["UPS", "LTL"].includes(salesOrderDetail?.delivery_method) && (
                  <>
                    <Grid sx={{ flex: 1, mr: 1 }}>
                      {["LTL"].includes(salesOrderDetail?.delivery_method) ? (
                        <>
                          <Typography
                            sx={{
                              fontSize: 14,
                            }}
                          >
                            Logistics Company
                          </Typography>
                          <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                            {salesOrderDetail?.delivery_company || "-"}
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            sx={{
                              fontSize: 14,
                            }}
                          >
                            Ship Service
                          </Typography>
                          <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                            {salesOrderDetail?.shipping_service || "-"}
                          </Typography>
                        </>
                      )}
                    </Grid>

                    <Grid sx={{ flex: 1, ml: 1 }}>
                      <Typography
                        sx={{
                          fontSize: 14,
                        }}
                      >
                        Freight Cost
                      </Typography>
                      <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                        {showCurrency(salesOrderDetail?.shipping_cost)}
                      </Typography>
                    </Grid>

                    <Grid sx={{ flex: 1, ml: 1 }}>
                      <Typography
                        sx={{
                          fontSize: 14,
                        }}
                      >
                        Tracking #
                      </Typography>
                      <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                        {salesOrderDetail?.tracking_number || "-"}
                      </Typography>
                    </Grid>

                    {["LTL"].includes(salesOrderDetail?.delivery_method) && (
                      <Grid sx={{ flex: 1, ml: 1 }}>
                        <Typography
                          sx={{
                            fontSize: 14,
                          }}
                        >
                          Pallet Charge
                        </Typography>
                        <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                          {salesOrderDetail?.is_pallet ? "Yes" : "No"}
                        </Typography>
                      </Grid>
                    )}
                  </>
                )}

                <Grid sx={{ flex: 1, ml: 1 }}>
                  <Typography
                    sx={{
                      fontSize: 14,
                    }}
                  >
                    Ship Date
                  </Typography>
                  <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                    {salesOrderDetail?.ship_date
                      ? formatDate(salesOrderDetail?.ship_date)
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Stack>

      <Stack spacing={2}>
        {allowUpdateItems && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 1,
            }}
          >
            <IconButton
              color="primary"
              size="sm"
              onClick={() => {
                setIsOpen(true);
              }}
              variant="outlined"
            >
              <AddIcon />
            </IconButton>
          </Box>
        )}
        <Table variant={"outlined"}>
          <thead>
            <tr>
              <th>Item Name</th>
              <th style={{ width: "110px", textAlign: "right" }}>Qty</th>
              <th style={{ textAlign: "right" }}> Unit Cost </th>
              <th style={{ textAlign: "right" }}> Discount </th>
              <th style={{ textAlign: "right" }}> Total </th>
              {allowUpdateItems && <th style={{ width: "65px" }}></th>}
            </tr>
          </thead>
          <tbody>
            {salesOrderDetail?.sales_order_items?.map((item) => {
              const product = item.item;
              return (
                <tr key={item.item_id || product?.id}>
                  <td>{showProductItemName(product, true)}</td>
                  <td style={{ textAlign: "right" }}>
                    {item.quantity} {product?.unit}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {showCurrency(item.cost)}{" "}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {showCurrency(item.discount)}{" "}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    {showCurrency(Number(item.total) - Number(item.discount))}{" "}
                  </td>

                  {allowUpdateItems && (
                    <td>
                      <Box
                        sx={{
                          display: "flex",
                          gap: 1,
                        }}
                      >
                        <Tooltip title="Edit" arrow>
                          <ModeEditIcon
                            sx={{ cursor: "pointer" }}
                            fontSize="small"
                            onClick={(e) => {
                              onEditItem(item);
                            }}
                          />
                        </Tooltip>
                        <Tooltip title="Remove" arrow>
                          <ClearOutlined
                            sx={{ cursor: "pointer" }}
                            fontSize="small"
                            onClick={(e) => {
                              onRemoveItem(item);
                            }}
                          />
                        </Tooltip>
                      </Box>
                    </td>
                  )}
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flex: 1 }}>
            {salesOrderDetail ? (
              <>
                <Box>
                  <Typography fontSize="sm" sx={{ mt: 1 }}>
                    Note or Special Instructions
                  </Typography>

                  <Typography fontSize="sm" fontWeight={500}>
                    {salesOrderDetail?.note || "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography fontSize="sm" sx={{ mt: 1 }}>
                    Internal Note:
                    {getPermission("ALLOW_UPDATE_SO") && (<EditHoverPopup
                      renderButton={(onClick) => (
                        <Edit
                          onClick={onClick}
                          style={{
                            marginLeft: 8,
                            cursor: "pointer",
                          }}
                        />
                      )}
                      title="Internal Note"
                      editBtnPosition={{}}
                      editFields={[
                        {
                          label: "Internal Note",
                          key: "internal_note",
                          value: salesOrderDetail?.internal_note,
                          type: "textarea",
                        },
                      ]}
                      onSave={(newData, closeEdit) => {
                        dispatch(
                          updateSOInternalNote({
                            id: salesOrderDetail.id,
                            internal_note: newData.internal_note,
                          })
                        )
                          .then(unwrapResult)
                          .then(({ response }) => {
                            if (response.apiStatus) {
                              notification.success("Edit successfully");
                              closeEdit();
                              onUpdateSuccess();
                            } else {
                              notification.error("Edit failed");
                            }
                          });
                      }}
                    />)}
                  </Typography>

                  <Typography fontSize="sm" fontWeight={500}>
                    {salesOrderDetail?.internal_note || "-"}
                  </Typography>
                </Box>
              </>
            ) : (
              <></>
            )}
          </Box>
          <Box mr={6}>
            <Typography fontSize="sm" fontWeight={600} textAlign="right">
              Subtotal
            </Typography>
            <Typography fontSize="sm" textAlign="right">
              Tax
            </Typography>
            <Typography fontSize="sm" textAlign="right">
              Shipping
            </Typography>

            {salesOrderDetail.is_pallet && (
              <Typography fontSize="sm" textAlign="right">
                Pallet fee
              </Typography>
            )}

            <Typography fontSize="sm" textAlign="right">
              Total Discount
            </Typography>

            <Typography fontSize="sm" fontWeight={600} textAlign="right">
              Total
            </Typography>
          </Box>
          <Box>
            <Typography fontSize="sm" textAlign="right">
              {showCurrency(salesOrderDetail?.total)}
            </Typography>
            <Typography fontSize="sm" textAlign="right">
              {showCurrency(salesOrderDetail?.tax_cost)}
            </Typography>
            <Typography fontSize="sm" textAlign="right">
              {showCurrency(salesOrderDetail?.shipping_cost)}
            </Typography>

            {salesOrderDetail.is_pallet && (
              <Typography fontSize="sm" textAlign="right">
                {showCurrency(salesOrderDetail?.pallet_cost)}
              </Typography>
            )}

            <Typography fontSize="sm" textAlign="right">
              {showCurrency(salesOrderDetail?.discount_item)}
            </Typography>

            <Typography fontSize="sm" fontWeight={600} textAlign="right">
              {salesOrderDetail
                ? showCurrency(calcGrantTotal(salesOrderDetail))
                : ""}
            </Typography>
          </Box>
        </Box>

        {getPermission("ALLOW_UPDATE_SO") &&
          [SOStatusEnum.REVIEWING_COST].includes(salesOrderDetail?.status as SOStatusEnum) && <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              loading={approveLoading}
              onClick={async () => {
                if (!await ConfirmUtils.sure()) return;
                setApproveLoading(true);
                dispatch(approveSOFull({
                  so_id: salesOrderDetail.id,
                })).unwrap().then((rs) => {
                  if (rs.response.apiStatus) {
                    notification.success("Approve success");
                    onUpdateSuccess();
                  } else {
                    notification.error("Approve failed");
                  }
                  setApproveLoading(false);
                });
              }}
            >Approve</Button>
          </Box>}
      </Stack>

      <Divider />

      <ModalAddNewSOItem
        isOpen={isOpen}
        itemSelected={itemSelected}
        onCloseModal={onCloseModal}
        onCreateItem={onCreateItem}
        customer={selectedCustomer}
        items={salesOrderDetail?.sales_order_items}
        salesOrderDetail={salesOrderDetail}
      />

      {
        isOpenEditShip && (
          <EditShipSection
            isOpen={isOpenEditShip}
            so={salesOrderDetail}
            onClose={() => setIsOpenEditShip(false)}
            onUpdateSuccess={onUpdateSuccess}
          />
        )
      }

      {
        isOpenEditAddress && (
          <EditAddressSection
            isOpen={isOpenEditAddress}
            so={salesOrderDetail}
            onClose={() => setIsOpenEditAddress(false)}
            onUpdateSuccess={onUpdateSuccess}
          />
        )
      }

      {
        !!isOpenAddAdress && (
          <AddressModal
            isOpen={!!isOpenAddAdress}
            onClose={() => setIsOpenAddAdress(undefined)}
            onSave={(data) => {
              if (!salesOrderDetail?.customer_id) return;

              const addJob = {
                ...data,
                customer_id: salesOrderDetail?.customer_id,
                fromAdmin: true,
              };
              dispatch(updateCustomerAddress(addJob))
                .then(unwrapResult)
                .then(({ response }) => {
                  if (response.apiStatus) {
                    onUpdateSuccess();
                    notification.success("Update success");
                    setIsOpenAddAdress(undefined);
                  } else {
                    notification.error("Create failed");
                  }
                });
            }}
            selectedAddress={isOpenAddAdress}
          />
        )
      }
    </Stack >
  );
}

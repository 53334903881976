import { Card, Grid, Stack } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { COLORS } from "app-constants";
import { LotRealItemDragV2 } from "components/LotRealItemDragV2";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import { getListPalletActive } from "redux-store/reducers/manufacturing";
import { IPurchaseOrder } from "types/purchase";
export default function ItemsManagementV2({
  manufacturing,
  onUpdateSuccess,
  viewMode = "manufacturing",
}: {
  manufacturing: IPurchaseOrder;
  onUpdateSuccess: () => void;
  viewMode: "warehouse" | "manufacturing";
}) {
  const colorMap: {
    [key: string]: string;
  } = {};

  const dispatch = useAppDispatch();
  const [palletActive, setPalletActive] = useState<any>([]);

  for (let i = 0; i < manufacturing.purchase_order_items.length; i++) {
    const id = manufacturing.purchase_order_items[i].item?.id || "";
    colorMap[id] = COLORS[i % COLORS.length];
  }

  useEffect(() => {
    dispatch(
      getListPalletActive({})
    ).then(unwrapResult)
      .then(({ response }) => {
        setPalletActive(
          (response?.data || [])
        );
      });
  }, []);

  return (
    <Stack spacing={2}>
      {manufacturing.purchase_order_items.map((lineItem) => {
        return (
          <Card
            sx={{
              "&:hover": {
                border: `1px solid ${colorMap[lineItem.item?.id || ""]}`,
                borderBottom: `3px solid ${colorMap[lineItem.item?.id || ""]}`,
              },
            }}
          >
            <Grid sm>
              <LotRealItemDragV2
                lineItem={lineItem}
                purchaseOrderDetail={manufacturing}
                palletActive={palletActive}
                viewMode={viewMode}
                colorMap={colorMap}
              />
            </Grid>
          </Card>
        );
      })}
    </Stack>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import {
  listPromotionApi,
  createPromotionApi,
  getDetailPromotionApi,
  updatePromotionApi,
} from "services/promotion.service";
import { IPromotion } from "types/promotion";

const TYPE = "PROMOTION";

export interface IPromotionState {
  list: IPromotion[];
}

const initialState: IPromotionState = {
  list: [],
};

export const createPromotion = createCallApiAsyncAction(
  TYPE + "/createPromotion",
  createPromotionApi
);

export const updatePromotion = createCallApiAsyncAction(
  TYPE + "/updatePromotion",
  updatePromotionApi
);

export const getListPromotion = createCallApiAsyncAction(
  TYPE + "/getListPromotion",
  listPromotionApi
);

export const getDetailPromotion = createCallApiAsyncAction(
  TYPE + "/getDetailPromotion",
  getDetailPromotionApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListPromotion,
      stateKey: "list",
    });
  },
  reducers: {},
});

export default slice.reducer;

import { Box } from "@mui/joy";
import Typography from "@mui/joy/Typography";

export default function CustomerView({
  customer,
}: {
  customer?: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    cus_number: string;
  };
}) {
  return (
    // <ViewDetailLink type="CUSTOMER" id={customer?.id}>
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <div>
        <Typography>
          <strong>{`${customer?.first_name} ${customer?.last_name || ''}`}</strong>
        </Typography>
        <Typography level="body-xs">Distributor ID: {customer?.cus_number}</Typography>
      </div>
    </Box>
    // </ViewDetailLink>
  );
}

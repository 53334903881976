import { useEffect } from "react";
import AppEvent, { AppEventTypes } from "utils/app-events";

const useListenEmitEvent = ({
  event,
  matchCondition,
  handle,
}: {
  event: AppEventTypes;
  matchCondition?: (payload: any) => boolean;
  handle: () => void;
}) => {
  useEffect(() => {
    const handleListenMessage = (payload: any) => {
      if (matchCondition) {
        if (!matchCondition(payload)) return;
        handle();
      } else {
        handle();
      }
    };
    AppEvent.on(event, handleListenMessage);
    return () => {
      AppEvent.off(event, handleListenMessage);
    };
  }, [event, matchCondition, handle]);
};

export default useListenEmitEvent;

import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import Grid from "@mui/joy/Grid";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux-store";
import { TProductItem } from "types/product";

import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import BaseNumberInput from "components/BaseNumberInput";
import BaseSelect from "components/BaseSelect";
import { ISalesOrderItem } from "types/sales-order";

type TBasicModal = {
  isOpen: boolean;
  itemSelected?: ISalesOrderItem;
  onCloseModal: () => void;
  onCreateItem: (item: ISalesOrderItem) => void;
  items?: ISalesOrderItem[];
};

export default function ModalAddNewCustomerPOItem({
  isOpen,
  itemSelected,
  onCloseModal,
  onCreateItem,
  items,
}: TBasicModal) {
  const productItems =
    useAppSelector((state) => state.product.customerProductItems) || [];

  const [lineItemId, setLineItemId] = useState("");
  const [itemId, setItemId] = useState<number | null>(null);

  const [quantity, setQuantity] = useState(1);
  const [note, setNote] = useState("");

  const options =
    productItems?.map?.((item: TProductItem) => ({
      label: item.code,
      value: item.id,
    })) || [];

  const productItemSelected = productItems.find?.((item) => item.id === itemId);

  const onResetState = () => {
    setLineItemId("");
    setItemId(null);
    setQuantity(1);
    setNote("");
  };

  const onClose = () => {
    onResetState();
    onCloseModal();
  };

  const onCreate = () => {
    if (!itemId || !quantity) return;

    onCreateItem({
      id: lineItemId,
      item_id: itemId ? Number(itemId) : 0,
      quantity: Number(quantity),
      cost: Number(productItemSelected?.price),
      note,
      item: productItemSelected as any,
    });

    onResetState();
  };

  useEffect(() => {
    if (itemSelected) {
      setLineItemId(itemSelected?.id);
      setItemId(itemSelected?.item?.id as number);
      setQuantity(itemSelected.quantity || 1);
      setNote(itemSelected?.note || "");
    }
  }, [itemSelected]);

  useEffect(() => {
    if (productItemSelected) {
      const existItem = items?.find(
        (item) => item.item?.id === +productItemSelected.id
      );
      if (existItem) {
        setLineItemId(existItem?.id);
        setQuantity(existItem.quantity || 1);
        setNote(existItem?.note || "");
      } else {
        setLineItemId("");
        setQuantity(1);
        setNote("");
      }
    }
  }, [productItemSelected]);

  return (
    <BaseModal
      title="Select Item"
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <Button onClick={onCreate}>
          {itemSelected?.id ? "Update" : "Add"}
        </Button>
      }
    >
      <Grid container spacing={2}>
        <Grid xs={12} sm={12}>
          <FormControl>
            <BaseSelect
              label={"Item"}
              options={options}
              value={itemId}
              onChange={setItemId}
            />
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <FormControl>
            <BaseNumberInput
              label="Qty"
              value={quantity}
              onChange={(e: any) => setQuantity(e)}
            />
          </FormControl>
        </Grid>

        <Grid xs={12} sm={12}>
          <FormControl>
            <BaseInput
              label={"Note"}
              multiline
              minRows={2}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
    </BaseModal>
  );
}

import { Add, DeleteOutline } from "@mui/icons-material";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import { Box, IconButton, Typography } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { SOStatusEnum } from "app-constants";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import ConfirmUtils from "components/ConfirmUtils";
import { useEffect, useState } from "react";
import { useAppDispatch } from "redux-store";
import {
  deleteSODeposit,
  getSODepositList,
} from "redux-store/reducers/sales-order";
import { IDepositsItem } from "types/invoice";
import { ISalesOrder } from "types/sales-order";
import { formatDate, showCurrency } from "utils/common";
import notification from "utils/notification";
import { getPermission } from "utils/roles";
import CreateDeposit from "./CreateDeposit";
export default function DepositList({
  salesOrderDetail,
  onUpdateSuccess,
}: {
  salesOrderDetail: ISalesOrder;
  onUpdateSuccess: () => void;
}) {
  const dispatch = useAppDispatch();
  const [deleteLoadingIds, setDeleteLoadingIds] = useState<any[]>([]);
  const [depositList, setDepositList] = useState<IDepositsItem[]>([]);
  const [isDepositModalOpen, setIsDepositModalOpen] =
    useState<IDepositsItem | null>(null);
  const fetchList = () => {
    if (!salesOrderDetail?.id) return;
    dispatch(
      getSODepositList({
        so_id: salesOrderDetail.id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        let newData: any = [];
        if (response.apiStatus) {
          newData = response.data || [];
        }

        newData.finishLoading = true;

        setDepositList(newData);
      });
  };

  const handleDeleteItem = async (item: IDepositsItem) => {
    if (!(await ConfirmUtils.sure())) return;
    setDeleteLoadingIds([...deleteLoadingIds, item.id]);
    dispatch(
      deleteSODeposit({
        id: item.id,
        so_id: salesOrderDetail.id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        setDeleteLoadingIds(deleteLoadingIds.filter((id) => id !== item.id));
        if (response.apiStatus) {
          notification.success("Delete success");
          fetchList();
        } else {
          notification.error("Delete failed");
        }
      });
  };

  const handleOpenEdit = (item: IDepositsItem) => {
    setIsDepositModalOpen({
      ...item,
    });
  };

  // id?: any;
  // so_id?: any;
  // amount?: number;
  // deposit_date?: string;
  // deposit_type?: string;
  // deposit_transaction?: string;
  // deposit_method?: string;
  // receive_bank?: string;
  // note?: string;
  // created_at?: string;

  useEffect(() => {
    fetchList();
  }, [salesOrderDetail]);
  return (
    <Box>
      {getPermission('ALLOW_UPDATE_SO') && salesOrderDetail.status !== SOStatusEnum.CANCELLED &&
        !depositList?.length && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              gap: 1,
            }}
          >
            <IconButton
              size="sm"
              color="primary"
              variant="outlined"
              onClick={() => {
                setIsDepositModalOpen({} as IDepositsItem);
              }}
            >
              <Add />
            </IconButton>
          </Box>
        )}
      {depositList?.length ? (
        <CommonList
          data={depositList}
          columns={[
            {
              label: "Status",
              key: "status",
              width: 120,
              render: (item) => <ChipStatus status={item.status} />,
            },
            {
              label: "Percent",
              key: "percent",
              width: 120,
              render: (item) => item.percent + "%",
            },
            {
              label: "Amount",
              key: "amount",
              width: 120,
              render: (item) => showCurrency(item.amount),
            },
            {
              label: "Deposit Information",
              key: "info",
              hide: !!depositList.find((item) => item.status === "Pending"),
              render: (item) => (
                <>
                  <Box>
                    <Typography>Method: {item.deposit_method}</Typography>
                    <Typography>Receive Bank: {item.receive_bank}</Typography>
                    <Typography>
                      Date: {formatDate(item.deposit_date)}
                    </Typography>
                    <Typography>
                      Transaction: {item.deposit_transaction}
                    </Typography>
                  </Box>
                </>
              ),
            },
            {
              label: "Note",
              key: "note",
            },
            {
              label: "",
              key: "action",
              width: 100,
              hide: !getPermission('ALLOW_UPDATE_SO') && !!depositList.find((item) => item.status !== "Pending"),
              render: (item) => (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 1,
                  }}
                >
                  <IconButton
                    size="sm"
                    color="success"
                    onClick={() => handleOpenEdit(item)}
                    disabled={deleteLoadingIds.includes(item.id)}
                  >
                    <PriceCheckIcon />
                  </IconButton>
                  <IconButton
                    color="danger"
                    size="sm"
                    onClick={() => handleDeleteItem(item)}
                    disabled={deleteLoadingIds.includes(item.id)}
                  >
                    <DeleteOutline />
                  </IconButton>
                </Box>
              ),
            },
          ]}
          sortKeys={["amount"]}
        />
      ) : (
        <Typography textAlign={"center"}>No data</Typography>
      )}
      {!!salesOrderDetail && !!isDepositModalOpen && (
        <CreateDeposit
          isOpen={!!isDepositModalOpen}
          detail={isDepositModalOpen}
          onClose={() => {
            setIsDepositModalOpen(null);
          }}
          onUpdateSuccess={() => {
            fetchList();
            onUpdateSuccess();
            setIsDepositModalOpen(null);
          }}
          salesOrderDetail={salesOrderDetail as ISalesOrder}
        />
      )}
    </Box>
  );
}

import { DownloadOutlined, LocalShippingOutlined, QrCode2Rounded } from "@mui/icons-material";
import { Box, Button, FormControl, Stack, Tooltip, Typography } from "@mui/joy";
import { Chip } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { PalletStatusEnum } from "app-constants";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import ConfirmUtils from "components/ConfirmUtils";
import PageWrapper from "components/PageWrapper";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  createPallet,
  exportPallet,
  getListPallet,
  updatePalletRequest
} from "redux-store/reducers/manufacturing";
import { IPalletList } from "types/purchase";
import { exportPalletBarcode, exportPalletCheckListExcel, formatDate, showProductNameString } from "utils/common";
import notification from "utils/notification";
import { RolesEnum } from "utils/roles";

export default function PalletListPage() {
  const dispatch = useAppDispatch();

  const [onCloseMark, setOnCloseMark] = useState<boolean>(false);
  const [filterStatus, setFilterStatus] = useState<string>("");
  const [palletList, setPalletList] = useState<IPalletList>([]);
  const [updatePallet, setUpdatePallet] = useState<any>({
    note: '',
    status: PalletStatusEnum.SHIPPED,
  });

  const roleName = useAppSelector((state) => state.auth.user?.roleName);

  const handleGetListPallet = () => {
    dispatch(getListPallet({})).then(unwrapResult)
      .then(({ response }) => {
        if (response.data) setPalletList(response.data);
      });
  };

  const handleCreatePallet = () => {
    dispatch(createPallet({ note: "" })).then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success("Created successfully");
          handleGetListPallet();
        } else {
          notification.error("Created failed");
        }

      });
  };

  const handleUpdatePallet = () => {
    dispatch(updatePalletRequest({ ...updatePallet })).then(unwrapResult)
      .then(({ response }) => {
        if (response?.apiStatus) {
          notification.success("Action success");
          setOnCloseMark(false);
          setUpdatePallet({
            ...updatePallet,
            note: '',
          })
          handleGetListPallet();
        } else {
          notification.error(response.message || "Action failed");
        }
      });
  };

  const groupByPoIdAndItemId = (pallet: any) => {
    const groupedByPo = Object.values(
      pallet.reduce((acc: any, item: any) => {
        if (!acc[item.po_id]) {
          acc[item.po_id] = {
            po_id: item.po_id,
            po_number: item?.purchase_order?.po_number,
            items: {}
          };
        }
        if (!acc[item.po_id].items[item.item_id]) {
          acc[item.po_id].items[item.item_id] = {
            name: showProductNameString(item.item),
            code: item.item.code,
            unit: item.item.unit,
            qty: 0,
          };
        }
        acc[item.po_id].items[item.item_id].qty += item.qty;
        return acc;
      }, {})
    );

    const formattedGroupedByPo = groupedByPo.map((poGroup: any) => ({
      po_id: poGroup.po_id,
      po_number: poGroup.po_number,
      items: Object.values(poGroup.items)
    }));

    return {
      pallet_map: formattedGroupedByPo
    };
  }


  const handleDetailPallet = (id: any) => {
    dispatch(exportPallet({ id })).then(unwrapResult)
      .then(async ({ response }) => {
        if (response?.data) {
          const r = groupByPoIdAndItemId(response?.data)
          await exportPalletCheckListExcel({ id, ...r })
        }
      });
  };


  const handleExportPallet = async (pallet_number: string) => {
    await exportPalletBarcode("PALLET" + pallet_number)
  };

  useEffect(() => {
    handleGetListPallet();
  }, []);

  return (
    <>
      <PageWrapper
        pageName="Pallet"
        breadcrumb={[
          {
            label: "Pallet",
          },
        ]}
        pageAction={
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >

            {[
              RolesEnum.VietnamPlanning,
              RolesEnum.VietnamFactory,
              RolesEnum.CEO,
              RolesEnum.COO,
              RolesEnum.Director,
              RolesEnum.GeneralManager,
              RolesEnum.Administrator,
            ].includes(roleName as any) && (
                <Box>
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => {
                      ConfirmUtils.show({
                        title: "Generate Pallet",
                        description: `This action will create a new pallet.`,
                      }).then((isOk) => {
                        if (isOk) {
                          handleCreatePallet()
                        }
                      });
                    }}
                  >
                    Create
                  </Button>

                </Box>
              )}
          </Box>
        }
      >
        <CommonList
          noLoading={true}
          data={palletList}
          defaultStatusFilter={filterStatus}
          onChangeStatusFilter={(status) => {
            setFilterStatus(status);
          }}
          columns={[
            {
              key: "pallet_number",
              label: "#",
              width: 120,
              render: (row) => <b>{row.pallet_number}</b>,
            },
            {
              key: "status",
              label: "Status",
              render: (row) => (
                row.status === PalletStatusEnum.RECEIVED ? <ChipStatus status={row.status} size="small" /> : <Chip size="small" variant="outlined" color={row.status === PalletStatusEnum.SHIPPED ? "primary" : "secondary"} label={row.status} />
              ),
              width: 200,
            },
            {
              key: "note",
              label: "Note",
              width: 200,
            },
            {
              key: "user",
              label: "Created by",
              width: 200,
              render: (row) => (
                <Typography>{row?.user?.name}</Typography>
              ),
            },
            {
              key: "created_at",
              label: "Created At",
              render: (row) => (
                <Typography>{formatDate(row.created_at)}</Typography>
              ),
            },
            {
              key: "_",
              label: "Action",
              width: 120,
              render: (row) => (
                <Box>

                  <Tooltip title="Barcode" arrow>
                    <QrCode2Rounded
                      sx={{ cursor: "pointer" }}
                      fontSize="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleExportPallet(row.pallet_number)
                      }}
                    />
                  </Tooltip>

                  <Tooltip title="Export Detail" arrow>
                    <DownloadOutlined
                      sx={{ cursor: "pointer", ml: 1 }}
                      fontSize="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDetailPallet(row.id)
                      }}
                    />
                  </Tooltip>

                  {
                    row.status === "Active" ?
                      <Tooltip title="Mark as Shipped" arrow>
                        <LocalShippingOutlined
                          fontSize="small"
                          sx={{ cursor: "pointer", ml: 1 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            setUpdatePallet({
                              ...updatePallet,
                              id: row.id,
                            })
                            setOnCloseMark(true);
                          }}
                        />
                      </Tooltip>
                      : ""
                  }
                </Box>
              ),
            },

          ]}
          searchKeys={["pallet_number"]}
          sortKeys={["created_at", "pallet_number"]}
          filterStatus={{
            statusKey: "status",
            data: [
              PalletStatusEnum.ACTIVE,
              PalletStatusEnum.RECEIVED,
              PalletStatusEnum.SHIPPED,
            ].map((v) => ({ label: v, value: v })),
          }}
        />
      </PageWrapper>


      <BaseModal
        title="Mark as Shipped"
        onClose={() => {
          setOnCloseMark(false);
          setUpdatePallet({
            ...updatePallet,
            note: '',
          })
        }}
        isOpen={onCloseMark}
        actions={
          <>
            <Button onClick={
              (e) => {
                handleUpdatePallet()
              }
            }
              color="primary">
              Submit
            </Button>
          </>
        }
      >
        <Stack>
          <Typography level="body-sm" sx={{ mb: 1 }}>
            If this pallet is shipping to warehouse please mark as Shipped status and take note.
          </Typography>
          <Typography
            textColor="inherit"
            level="body-md"
            sx={{ mb: 1.5, mt: 1.5 }}
          >
            <b>Note</b> <br />
            <br />
            <FormControl>
              <BaseInput
                value={updatePallet?.note}
                onChange={(e) =>
                  setUpdatePallet({
                    ...updatePallet,
                    note: e.target.value,
                  })
                }
                label={"Note"}
                multiline
                minRows={3}
              />
            </FormControl>
          </Typography>


        </Stack>
      </BaseModal>

    </>
  );
}

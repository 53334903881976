import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux-store";
import { TProductItem } from "types/product";
import { IPurchaseOrderItem } from "types/purchase";

import BaseCurrencyInput from "components/BaseCurrencyInput";
import BaseInput from "components/BaseInput";
import BaseModal from "components/BaseModal";
import BaseNumberInput from "components/BaseNumberInput";
import BaseSelect from "components/BaseSelect";
import { showCurrency } from "utils/common";
import { getPermission } from "utils/roles";

type TBasicModal = {
  isOpen: boolean;
  itemSelected?: IPurchaseOrderItem;
  onCloseModal: () => void;
  onCreateItem: (item: IPurchaseOrderItem) => void;
  items?: IPurchaseOrderItem[];
};

export default function ModalAddNew({
  isOpen,
  itemSelected,
  onCloseModal,
  onCreateItem,
  items,
}: TBasicModal) {
  const productItems =
    useAppSelector((state) => state.product.productItems) || [];

  const [lineItemId, setLineItemId] = useState("");
  const [itemId, setItemId] = useState("");

  const [quantity, setQuantity] = useState(1);
  const [cost, setCost] = useState("");
  const [note, setNote] = useState("");

  const options =
    productItems?.map?.((item: TProductItem) => ({
      label: item.code,
      value: item.id,
    })) || [];

  const productItemSelected = productItems.find?.(
    (item) => item.id === +itemId
  );

  const onResetState = () => {
    setLineItemId("");
    setItemId("");
    setQuantity(1);
    setCost("");
    setNote("");
  };

  const onClose = () => {
    onResetState();
    onCloseModal();
  };

  const onCreate = () => {
    if (!itemId || !quantity) return;

    onCreateItem({
      id: lineItemId,
      item_id: itemId ? Number(itemId) : 0,
      quantity: Number(quantity),
      cost: Number(cost),
      note,
      item: productItemSelected as any,
    });

    onResetState();
  };

  useEffect(() => {
    if (itemSelected) {
      setLineItemId(itemSelected?.id);
      setItemId(itemSelected?.item?.id?.toString() || "");
      setQuantity(itemSelected.quantity || 1);
      setCost(itemSelected.cost?.toString() || "");
      setNote(itemSelected?.note || "");
    }
  }, [itemSelected]);

  useEffect(() => {
    if (productItemSelected) {
      const existItem = items?.find(
        (item) => item.item?.id === +productItemSelected.id
      );
      if (existItem) {
        setLineItemId(existItem?.id);
        setQuantity(existItem.quantity || 1);
        setCost(existItem.cost?.toString() || "");
        setNote(existItem?.note || "");
        return;
      }
      setLineItemId("");
      setCost(productItemSelected?.cost?.toString() || "");
      setQuantity(1);
      setNote("");
    }
  }, [productItemSelected]);

  return (
    <BaseModal
      title="Select Item"
      isOpen={isOpen}
      onClose={onClose}
      actions={
        <Button onClick={onCreate}>
          {itemSelected?.id ? "Update" : "Add"}
        </Button>
      }
    >
      <Grid container spacing={2}>
        <Grid sm={12}>
          <FormControl>
            <BaseSelect
              label={"Item"}
              options={options}
              value={itemId}
              onChange={setItemId}
            />
          </FormControl>
        </Grid>
        <Grid sm={3}>
          <FormControl>
            <BaseNumberInput
              label="Quantity"
              value={quantity}
              onChange={(e) => setQuantity(e)}
              min={1}
            />
          </FormControl>
        </Grid>
        {!getPermission("NOT_PO_VIEW_PRICE") && (
          <>
            <Grid sm={4}>
              <FormControl>
                <BaseCurrencyInput
                  label="Unit Cost"
                  value={cost}
                  onChange={(e: any) => setCost(e)}
                />
              </FormControl>
            </Grid>
            <Grid
              sm
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <FormControl>
                <Typography>
                  Total: {showCurrency(+quantity * +cost)}
                </Typography>
              </FormControl>
            </Grid>
          </>
        )}
        <Grid sm={12}>
          <FormControl>
            <BaseInput
              label="Note"
              multiline
              minRows={2}
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </FormControl>
        </Grid>
      </Grid>
    </BaseModal>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { addListBuilderCase } from "redux-store/middlewares/builderUtils";
import { createCallApiAsyncAction } from "redux-store/middlewares/createAsyncAction";
import { persistEncryptReducer } from "redux-store/redux-utils";

import {
  applySOPromoApi,
  cancelSalesOrderApi,
  confirmSalesOrderApi,
  createCustomerAddressApi,
  createCustomerContactApi,
  createCustomerSaleOrderApi,
  createUpdateBranchApi,
  deleteCustomerAddressApi,
  deleteCustomerContactApi,
  getCustomerPODepositListApi,
  getCustomerPOPackagesApi,
  getDetailCustomerSaleOrderApi,
  getListCustomerAddressApi,
  getListCustomerContactApi,
  getListCustomerSaleOrderApi,
  listBranchApi,
  updateCustomerAddressApi,
  updateCustomerContactApi,
} from "services/customer-portal/customer-portal-so.service";

import {
  CustomerAddressList,
  TSaleOrderDiscountList,
} from "types/customer-portal-so";
import { ISalesOrder } from "types/sales-order";

const TYPE = "CUSTOMER_PORTAL_SALES_ORDER";

export interface ICustomerPortalSalesOrderState {
  list?: ISalesOrder[];
  addressList?: CustomerAddressList;
  discountList: TSaleOrderDiscountList;
}

const initialState: ICustomerPortalSalesOrderState = {
  list: [],
  addressList: [],
  discountList: [],
};

export const createCustomerSaleOrder = createCallApiAsyncAction(
  TYPE + "/createCustomerPurchase",
  createCustomerSaleOrderApi
);

export const getListCustomerSaleOrder = createCallApiAsyncAction(
  TYPE + "/getListCustomerSaleOrder",
  getListCustomerSaleOrderApi
);

export const getDetailCustomerSaleOrder = createCallApiAsyncAction(
  TYPE + "/getDetailCustomerSaleOrder",
  getDetailCustomerSaleOrderApi
);

export const getListCustomerAddress = createCallApiAsyncAction(
  TYPE + "/getListCustomerAddress",
  getListCustomerAddressApi
);

export const cancelSalesOrder = createCallApiAsyncAction(
  TYPE + "/cancelSalesOrder",
  cancelSalesOrderApi
);

export const confirmSalesOrder = createCallApiAsyncAction(
  TYPE + "/confirmSalesOrder",
  confirmSalesOrderApi
);

export const applySOPromo = createCallApiAsyncAction(
  TYPE + "/applySOPromo",
  applySOPromoApi
);

// address
export const updateCustomerAddress = createCallApiAsyncAction(
  TYPE + "/updateCustomerAddress",
  updateCustomerAddressApi
);

export const createCustomerAddress = createCallApiAsyncAction(
  TYPE + "/createCustomerAddress",
  createCustomerAddressApi
);

export const deleteCustomerAddress = createCallApiAsyncAction(
  TYPE + "/deleteCustomerAddress",
  deleteCustomerAddressApi
);
// end address

// contact
export const getListCustomerContact = createCallApiAsyncAction(
  TYPE + "/getListCustomerContact",
  getListCustomerContactApi
);
export const updateCustomerContact = createCallApiAsyncAction(
  TYPE + "/updateCustomerContact",
  updateCustomerContactApi
);

export const createCustomerContact = createCallApiAsyncAction(
  TYPE + "/createCustomerContact",
  createCustomerContactApi
);

export const deleteCustomerContact = createCallApiAsyncAction(
  TYPE + "/deleteCustomerContact",
  deleteCustomerContactApi
);
// end contact

export const getCustomerPOPackages = createCallApiAsyncAction(
  TYPE + "/getCustomerPOPackages",
  getCustomerPOPackagesApi
);

export const getCustomerPODepositList = createCallApiAsyncAction(
  TYPE + "/getCustomerPODepositList",
  getCustomerPODepositListApi
);

export const listBranch = createCallApiAsyncAction(
  TYPE + "/listBranch",
  listBranchApi
);

export const createUpdateBranch = createCallApiAsyncAction(
  TYPE + "/createUpdateBranch",
  createUpdateBranchApi
);

const slice = createSlice({
  name: TYPE,
  initialState,
  extraReducers: (builder) => {
    addListBuilderCase({
      builder,
      fetchAction: getListCustomerSaleOrder,
      stateKey: "list",
    });
    addListBuilderCase({
      builder,
      fetchAction: getListCustomerAddress,
      stateKey: "addressList",
    });
  },
  reducers: {},
});

export default persistEncryptReducer(TYPE, slice.reducer, [
  "addressList",
  "discountList",
]) as typeof slice.reducer;

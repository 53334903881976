import Typography from "@mui/joy/Typography";

import { ClearOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  Grid,
  Stack,
  Tooltip
} from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import { ITEM_TYPE, ITEM_TYPE_VALUE, SERIAL_VERSIONS } from "app-constants";
import BaseDatePicker from "components/BaseDatePicker";
import BaseModal from "components/BaseModal";
import BaseNumberInput from "components/BaseNumberInput";
import BaseSelect from "components/BaseSelect";
import CardWithLabel from "components/CardWithLabel";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import ConfirmUtils from "components/ConfirmUtils";
import MoreActions from "components/MoreActions";
import PageWrapper from "components/PageWrapper";
import SelectWarehouseLocation from "components/SelectWarehouseLocation";
import { DetailSkeleton } from "components/SkeletonComponents";
import dayjs from "dayjs";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  addOldSerialItem,
  getDetailInventoryItem,
  reStockItem,
  relocateItem,
  removeStockItem
} from "redux-store/reducers/inventory";
import { getWarehouseAreas } from "redux-store/reducers/warehouse";
import ROUTERS from "routers/constants";
import { IInventoryItem, IRealItem, IRelocateItem } from "types/inventory";
import {
  displayAreasLevel,
  exportBarCodeCSV,
  findAreaLevel,
  getSerialDateFromSerial,
  printSerialBarCodeFromArrays,
  showCurrency
} from "utils/common";
import notification from "utils/notification";
import { getPermission } from "utils/roles";
import { isMobile } from "utils/ui-uitils";

let scanningText = ""
export default function InventoryItemDetailPage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const warehouseAreas = useAppSelector(
    (state) => state.warehouse.warehouseAreas
  );
  const id = useParams()?.id || detailId;
  const [selectedList, setSelectedList] = useState<string[]>([]);
  const inventoryFromState = useAppSelector((state) =>
    [...state.inventory.listItems, ...state.inventory.listParts]?.find((inventory) => inventory.id === id)
  );
  const [inventoryDetail, setInventoryItemDetail] = useState<
    IInventoryItem | undefined
  >(inventoryFromState);

  const [displayList, setDisplayList] = useState<any[]>([]);

  const [selectedArea, setSelectedArea] = useState<number | null>(null);
  const [showRelocateModal, setShowRelocateModal] = useState(false);
  const [loadingRelocate, setLoadingRelocate] = useState(false);
  const [actionLoading, setActionLoading] = useState(false);


  const [openRestockModal, setOpenRestockModal] = useState<
    | {
      item_id: any;
      quantity: number;
      version?: string;
      version_date?: string;
      area_id?: any;
    }
    | undefined
  >();
  const { user } = useAppSelector((state) => state.auth);

  const dispatch = useAppDispatch();
  const loadDetailData = () => {
    if (!id) return;
    dispatch(
      getDetailInventoryItem({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.id) {
          const o: any = { ...response.data }
          const qty = o?.type === ITEM_TYPE_VALUE.n ? o?.item_real.reduce((sum: number, r: any) => sum + (r?.qty || 0), 0) : o?.item_real.length
          setInventoryItemDetail({ ...response.data, in_stock: qty, available: qty - o.on_hold });

        };
      });
  };
  useEffect(() => {
    loadDetailData();
  }, [id]);

  useEffect(() => {
    dispatch(getWarehouseAreas({ warehouse_id: 1 }));
  }, []);

  const handleSelectedItem = (checked: boolean, item: IRealItem) => {
    if (checked) {
      setSelectedList([...selectedList, item.id]);
    } else {
      setSelectedList(selectedList.filter((id) => id !== item.id));
    }
  };
  const handleAddOldSerial = (item: IRealItem) => {
    ConfirmUtils.show({
      title: "Add Old Serial Number",
      prompt: {
        label: "Old Serial Number",
        required: true,
      },
    }).then((obj) => {
      if (obj && obj.promptText) {
        dispatch(
          addOldSerialItem({
            id: item.id,
            old_serial: obj.promptText,
          })
        )
          .then(unwrapResult)
          .then(({ response }) => {
            if (response.apiStatus) {
              loadDetailData();
              notification.success("Add old serial number successfully");
            } else {
              notification.error("Add old serial number failed");
            }
          });
      }
    });
  };
  const relocateItemHandle = (payload: IRelocateItem) => {
    setLoadingRelocate(true);
    dispatch(relocateItem(payload))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          loadDetailData();
          setSelectedList([]);
          setShowRelocateModal(false);
          notification.success("Relocate item successfully");
        } else {
          notification.error("Relocate item failed");
        }
        setLoadingRelocate(false);
      });
  };

  const restockItemHandle = () => {
    if (!openRestockModal) return;
    setLoadingRelocate(true);
    dispatch(
      reStockItem({
        ...openRestockModal,
        version_date: dayjs(openRestockModal?.version_date).format("DDMMYY"),
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          loadDetailData();
          setOpenRestockModal(undefined);
          notification.success("Restock item successfully");
        } else {
          notification.error("Restock item failed");
        }
        setLoadingRelocate(false);
      });
  };
  const handleDeleteMulti = async () => {
    let title = "Adjust Stock";
    if (await ConfirmUtils.sure(title)) {
      dispatch(removeStockItem({
        ids: selectedList
      }))
        .then(unwrapResult)
        .then(({ response }) => {
          if (response.apiStatus) {
            loadDetailData();
            notification.success("Action success");
          } else {
            notification.error("Action fail");
          }
        });
    }
  };

  const onRemoveStockItem = (item: any) => {
    if (item.status !== "In Stock") {
      return
    }
    let title = "Adjust Stock";

    ConfirmUtils.sure(title).then((isOk) => {
      if (isOk) {
        dispatch(removeStockItem({
          serial_number: item.serial_number
        }))
          .then(unwrapResult)
          .then(({ response }) => {
            if (response.apiStatus) {
              loadDetailData();
              notification.success("Action success");
            } else {
              notification.error(response.message || "Action fail");
            }
          });
      }
    });
  };

  const columns = [
    ...(isMobile() ? [] : [{
      key: "item_selected",
      label: (
        <MoreActions
          actions={[
            {
              label: "Select this page",
              onClick: () => {
                setSelectedList(
                  _.uniq(selectedList.concat(displayList))
                );
              },
            },
            {
              label: "Select all",
              onClick: () => {
                setSelectedList(
                  inventoryDetail?.item_real.map((e) => e.id) || []
                );
              },
            },
            {
              label: "Unselect",
              onClick: () => {
                setSelectedList([]);
              },
            },
          ]}
        />
      ),
      render: (row: any) => (
        <Checkbox
          checked={selectedList.includes(row?.id)}
          onChange={(e) => handleSelectedItem(e.target.checked, row)}
        />
      ),
      width: 40,
    }]),
    {
      key: "serial_number",
      label: "Serial Number",
      render: (item: any) => (
        <>
          <Typography>{item.serial_number || "-"}</Typography>
          {item.old_serial ? (
            <small>{item.old_serial}</small>
          ) : (
            !item.po &&
            !item.so &&
            !item.package_id && (
              <Typography
                level="body-xs"
                color="primary"
                onClick={() => {
                  handleAddOldSerial(item);
                }}
              >
                Add old serial
              </Typography>
            )
          )}
        </>
      ),
      width: 200,
    },
    {
      key: "factory_date",
      label: "Date",
      rawValue: (v: any) => getSerialDateFromSerial(v.serial_number),
      width: 100,
    },
    {
      key: "location",
      label: "Location",
      render: (item: any) =>
        !!item?.warehouse_area_id
          ? displayAreasLevel(
            findAreaLevel(item?.warehouse_area_id, warehouseAreas)
          )
          : "-",
    },
    ...(inventoryDetail?.type === ITEM_TYPE_VALUE.n ? [{
      key: "qty",
      label: "Qty",
      rawValue: (item: any) => item.qty,
      width: 80,
    }, {
      key: "sold",
      label: "Sold",
      rawValue: (item: any) => item.sold,
      width: 80,
    }] : []),

    {
      key: "po_id",
      label: "PO",
      render: (row: any) => <>{row?.po?.po_number}</>,
      width: 100,
    },
    {
      key: "status",
      label: "Status",
      rawValue: (item: any) => (item.status),
      render: (row: any) => <ChipStatus status={row?.status} size="small" />,
      width: 100,
    },
  ]

  if (getPermission("DELETE_STOCK")) {
    columns.push({
      width: 50,
      key: "act",
      label: "Action",
      render: (item) =>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Tooltip title="Remove stock" arrow>
            <ClearOutlined
              sx={{ cursor: "pointer" }}
              fontSize="small"
              onClick={() => onRemoveStockItem(item)}
            />
          </Tooltip>
        </Box>
    })
  }

  return (
    <>
      <PageWrapper
        pageName={"Detail Item"}
        breadcrumb={[
          {
            label: "Inventory",
            link: ROUTERS.INVENTORY,
          },
          {
            label: "Detail",
          },
        ]}
        skeletonLoading={!inventoryDetail ? <DetailSkeleton /> : undefined}
      // pageAction={[
      //   <Box
      //     sx={{
      //       display: "flex",
      //       gap: 1,
      //     }}
      //   >
      //     {
      //       user?.roleName === RolesEnum.CEO &&
      //       <Button
      //         variant="plain"
      //         color="primary"
      //         size="sm"
      //         onClick={() => {
      //           setOpenRestockModal({
      //             quantity: 1,
      //             item_id: inventoryDetail?.id,
      //             version: "01",
      //           });
      //         }}
      //       >
      //         Manual Re-Stock
      //       </Button>
      //     }

      //   </Box >,
      // ]
      // }
      >
        <Grid container spacing={2}>
          <Grid sm={5}>
            <Stack>
              {inventoryDetail &&
                <>
                  <Typography fontWeight={500} className={"lineItem"}>
                    <strong>{inventoryDetail.code} </strong>
                    {ITEM_TYPE[inventoryDetail?.type] && [ITEM_TYPE_VALUE.fset, ITEM_TYPE_VALUE.rset].includes(inventoryDetail?.type) ? <small className={inventoryDetail?.type}>
                      {ITEM_TYPE[inventoryDetail?.type]} - {inventoryDetail?.compo} components
                    </small>
                      :
                      ITEM_TYPE[inventoryDetail?.type] && <small className={inventoryDetail?.type}>
                        {ITEM_TYPE[inventoryDetail?.type]}
                      </small>
                    }
                  </Typography>
                  <Typography>
                    <small>{inventoryDetail.product.name} - {inventoryDetail.name}</small>
                  </Typography>
                </>
              }
            </Stack>
          </Grid>
          <Grid sm={2}>
            <Stack>
              <Typography sx={{ fontSize: 14 }}>Available</Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {
                  Math.floor((inventoryDetail?.available || 0) / (inventoryDetail?.compo || 1))
                }
                {' '}
                {
                  inventoryDetail?.unit
                }
              </Typography>
            </Stack>
          </Grid>
          <Grid sm={2}>
            <Stack>
              <Typography sx={{ fontSize: 14 }}> Instock</Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {
                  Math.floor((inventoryDetail?.in_stock || 0) / (inventoryDetail?.compo || 1))
                }
                {' '}
                {
                  inventoryDetail?.unit
                }
              </Typography>
            </Stack>
          </Grid>

          {getPermission("VIEW_STOCK_VALUE") && <Grid sm={2}>
            <Stack>
              <Typography sx={{ fontSize: 14 }}>Stock Value</Typography>
              <Typography sx={{ fontSize: 16, fontWeight: 600 }}>
                {!!inventoryDetail &&
                  showCurrency(
                    ((Math.floor((inventoryDetail?.in_stock || 0) / inventoryDetail.compo) as number) *
                      inventoryDetail?.cost) as number
                  )}
              </Typography>
            </Stack>
          </Grid>}
        </Grid>

        <CommonList
          pageSize={50}
          noLoading={true}
          data={inventoryDetail?.item_real || []}
          onChangeList={setDisplayList}
          tableActions={
            !isMobile() &&
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: 1,
              }}
            >
              {!!selectedList.length && (
                <Typography>{selectedList.length} selected</Typography>
              )}



              <MoreActions
                loading={actionLoading}
                actions={[
                  {
                    label: "Delete selected item",
                    disabled: !getPermission("DELETE_STOCK") || !selectedList.length,
                    onClick: () => {
                      handleDeleteMulti()
                    },
                  },
                  {
                    label: "Relocate selected item",
                    disabled: !selectedList.length,
                    onClick: () => {
                      setSelectedArea(null);
                      setShowRelocateModal(true);
                    },
                  },
                  {
                    label: "Print Barcode",
                    disabled: !selectedList.length,
                    onClick: () => {
                      printSerialBarCodeFromArrays(
                        inventoryDetail?.item_real
                          .filter(
                            (v) =>
                              v.serial_number && selectedList.includes(v.id)
                          )
                          .map((v) => v.serial_number) || []
                      );
                    },
                  },
                  {
                    label: "Export Barcode Data",
                    disabled: !selectedList.length,
                    onClick: () => {
                      exportBarCodeCSV(
                        inventoryDetail?.item_real
                          .filter(
                            (v) =>
                              v.serial_number && selectedList.includes(v.id)
                          )
                          .map((v) => ({
                            serial_number: v.serial_number,
                            code: inventoryDetail?.code,
                          })) || []
                      );
                    },
                  },
                ]}
              />
            </Box>
          }
          columns={columns}
          searchKeys={["serial_number"]}
          filterStatus={{
            statusKey: "",
            data: [],
          }}
          sortKeys={["serial_number", "status", "factory_date"]}
        />
      </PageWrapper >

      {!!openRestockModal && (
        <BaseModal
          isOpen={!!openRestockModal}
          onClose={() => setOpenRestockModal(undefined)}
          title="Manual Re-Stock Item"
          actions={
            <Button
              loading={loadingRelocate}
              onClick={restockItemHandle}
              disabled={
                !openRestockModal?.quantity ||
                !openRestockModal?.area_id ||
                !openRestockModal?.version_date ||
                !openRestockModal?.version
              }
            >
              Process
            </Button>
          }
          width={900}
        >
          <Box>
            <Grid container spacing={2}>
              <Grid xs={12} sm={3}>
                <FormControl>
                  <BaseNumberInput
                    label="Quantity"
                    value={openRestockModal?.quantity}
                    onChange={(val) => {
                      setOpenRestockModal({
                        ...openRestockModal,
                        quantity: val,
                      });
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} sm={3}>
                <FormControl>
                  <BaseSelect
                    label="Version"
                    value={openRestockModal?.version}
                    options={SERIAL_VERSIONS}
                    onChange={(e) => {
                      setOpenRestockModal({
                        ...openRestockModal,
                        version: e,
                      });
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid xs={12} sm={3}>
                <FormControl>
                  <BaseDatePicker
                    label="Date Version"
                    value={openRestockModal?.version_date}
                    onChange={(e) => {
                      setOpenRestockModal({
                        ...openRestockModal,
                        version_date: e,
                      });
                    }}
                  />
                </FormControl>
              </Grid>

              <Grid sm={12} xs={12}>
                <CardWithLabel label="To Location">
                  <SelectWarehouseLocation
                    areas={warehouseAreas}
                    onSelect={(area) => {
                      setOpenRestockModal({
                        ...openRestockModal,
                        area_id: area?.id,
                      });
                    }}
                  />
                </CardWithLabel>
              </Grid>
            </Grid>
          </Box>
        </BaseModal>
      )}

      <BaseModal
        isOpen={showRelocateModal}
        onClose={() => setShowRelocateModal(false)}
        title="Relocate Items"
        actions={
          <Button
            loading={loadingRelocate}
            onClick={() => {
              relocateItemHandle({
                area_id: Number(selectedArea),
                id: selectedList,
              });
            }}
            disabled={!selectedList.length || !selectedArea}
          >
            Relocate
          </Button>
        }
        width={900}
      >
        <Box>
          <Grid container spacing={2}>
            <Grid sm={12}>
              <CommonList
                pageSize={20}
                noLoading={true}
                data={
                  selectedList.map((id) =>
                    inventoryDetail?.item_real.find((e) => e.id === id)
                  ) as IRealItem[]
                }
                size="sm"
                columns={[
                  {
                    key: "serial_number",
                    label: "Serial Number",
                  },
                  {
                    key: "location",
                    label: "From Location",
                    render: (item) =>
                      !!item?.warehouse_area_id
                        ? displayAreasLevel(
                          findAreaLevel(
                            item?.warehouse_area_id,
                            warehouseAreas
                          )
                        )
                        : "-",
                  },
                ]}
                searchKeys={[]}
                filterStatus={{
                  statusKey: "",
                  data: [],
                }}
                sortKeys={["status", "serial_number", "item_slot"]}
              />
            </Grid>

            <Grid sm={12} xs={12}>
              <CardWithLabel label="To Location">
                <SelectWarehouseLocation
                  areas={warehouseAreas}
                  onSelect={(area) => setSelectedArea(area?.id as any)}
                />
              </CardWithLabel>
            </Grid>
          </Grid>
        </Box>
      </BaseModal>
    </>
  );
}

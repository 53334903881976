import { Typography } from "@mui/joy";
import Box from "@mui/joy/Box";
import { ChipStatus } from "components/ChipStatus";
import CommonList from "components/CommonList";
import PageWrapper from "components/PageWrapper";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import { getListInvoice } from "redux-store/reducers/customer-portal-invoice";
import { ROUTERS_CUSTOMER } from "routers/constants";
import { formatDate, showCurrency } from "utils/common";

export default function PurchaseListPage() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const invoiceList =
    useAppSelector((state) => state.customerPortalInvoice.list) || [];

  const handleGetListInvoice = () => {
    dispatch(getListInvoice({}));
  };

  useEffect(() => {
    handleGetListInvoice();
  }, []);

  return (
    <Box sx={{ mt: "24px" }}>
      <PageWrapper pageName="Invoices">
        <CommonList
          data={invoiceList}
          columns={[
            {
              key: "inv",
              label: "#INV",
              width: 70,
              rawValue: (row) => row.invoice?.inv_number,
            },
            {
              key: "so_number",
              label: "#SO",
              width: 70,
            },
            {
              key: "company",
              label: "Company",
              rawValue: (row) => row.customer?.first_name,
            },
            {
              key: "invoice_total",
              label: "Total",
              width: 130,
              rawValue: (row) => showCurrency(row.invoice?.total),
            },
            {
              key: "invoice_status",
              label: "Status",
              width: 100,
              rawValue: (row) => row.invoice?.status,
              render: (row) => (
                <ChipStatus status={row.invoice?.status} size="small" />
              ),
            },
            {
              key: "due_date",
              label: "Due Date",
              width: 120,
              rawValue: (row) => row.invoice?.due_date,
              render: (row) => (
                <Typography>{formatDate(row.invoice?.due_date)}</Typography>
              ),
            },
          ]}
          searchKeys={["inv"]}
          sortKeys={["due_date", "inv", "invoice_total"]}
          filterStatus={{
            statusKey: "invoice_status",
            data: [
              { label: "Unpaid", value: "Unpaid" },
              { label: "Partially Paid", value: "Partially Paid" },
              { label: "Paid", value: "Paid" },
            ],
          }}
          onRowClick={(row) =>
            navigate(
              `${ROUTERS_CUSTOMER.INVOICE_DETAIL.replace(
                ":id",
                row.invoice?.id as any
              )}`
            )
          }
        />
      </PageWrapper>
    </Box>
  );
}

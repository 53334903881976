import { Box, Button } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import { unwrapResult } from "@reduxjs/toolkit";
import { ChipStatus } from "components/ChipStatus";
import ConfirmUtils from "components/ConfirmUtils";
import Documents from "components/Documents";
import PageWrapper from "components/PageWrapper";
import { DetailSkeleton } from "components/SkeletonComponents";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux-store";
import {
  cancelReturn,
  completeReturn,
  getDetailReturn,
} from "redux-store/reducers/return";
import ROUTERS from "routers/constants";
import { IReturn } from "types/return";
import { formatDate } from "utils/common";
import notification from "utils/notification";
import DetailContent from "./components/DetailContent";

export default function ReturnDetailPage({
  detailId,
  onViewModalCancel,
  onViewModalUpdate,
}: {
  detailId?: string;
  onViewModalCancel?: () => void;
  onViewModalUpdate?: () => void;
}) {
  const id = useParams()?.id || detailId;
  const [selectedTab, setSelectedTab] = useState("Detail");
  const navigate = useNavigate();

  const [actionLoading, setActionLoading] = useState("");
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const returnFromState = useAppSelector((state) =>
    state.return.list?.find((r) => r.id === id)
  );
  const [returnDetail, setReturnDetail] = useState<IReturn | undefined>(
    returnFromState
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    fetchDetail();
  }, [id]);

  const fetchDetail = () => {
    if (!id) return;
    dispatch(
      getDetailReturn({
        id,
      })
    )
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.data?.id) setReturnDetail(response.data);
      });
  };

  const handleCancel = async () => {
    if (!returnDetail) return;
    if (!(await ConfirmUtils.sure())) return;
    setActionLoading("cancel");
    dispatch(cancelReturn({ id: returnDetail.id }))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success("Delete return successfully");
          navigate(ROUTERS.RETURN);
        } else {
          notification.error("Delete return failed");
        }
      })
      .finally(() => {
        setActionLoading("");
      });
  };

  const handleComplete = async () => {
    if (!returnDetail) return;
    if (!(await ConfirmUtils.sure())) return;
    setActionLoading("complete");
    dispatch(completeReturn({ id: returnDetail.id }))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          notification.success("Complete return successfully");
        } else {
          notification.error("Complete return failed");
        }
        fetchDetail();
      })
      .finally(() => {
        setActionLoading("");
      });
  };

  const showActions =
    returnDetail?.status && !["Completed"].includes(returnDetail?.status);

  return (
    <>
      <div
        style={{
          position: "relative",
        }}
      >
        <PageWrapper
          pageName={
            <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
              <Typography>
                Return Number: #{returnDetail?.id || ""} <br />
                <Typography sx={{ fontSize: 14, fontWeight: 300 }}>
                  Date: {formatDate(returnDetail?.created_at, true)}
                </Typography>
              </Typography>
              {!!returnDetail && (
                <ChipStatus status={returnDetail.status} size="medium" />
              )}
            </Box>
          }
          pageAction={
            showActions && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: 1,
                }}
              >
                <Button
                  size="sm"
                  variant="plain"
                  color="danger"
                  loading={actionLoading === "cancel"}
                  disabled={!!actionLoading}
                  onClick={handleCancel}
                >
                  Delete
                </Button>
                <Button
                  loading={actionLoading === "complete"}
                  disabled={!!actionLoading}
                  onClick={handleComplete}
                  size="sm"
                >
                  Complete and Restock
                </Button>
              </Box>
            )
          }
          breadcrumb={[
            {
              label: "Return",
              link: ROUTERS.RETURN,
            },
            {
              label: "Detail",
            },
          ]}
          skeletonLoading={!returnDetail ? <DetailSkeleton /> : undefined}
          tab={selectedTab}
          onChangeTab={setSelectedTab}
          tabs={[
            {
              label: "Detail",
              content: !!returnDetail && (
                <DetailContent
                  onUpdateSuccess={() => {
                    fetchDetail();
                  }}
                  returnDetail={returnDetail}
                />
              ),
            },
            {
              label: "Documents",
              content: !!returnDetail && (
                <Documents source_id={"return_" + returnDetail?.id} />
              ),
            },
          ]}
        ></PageWrapper>
      </div>
    </>
  );
}


import { Box, Button, Typography } from "@mui/joy";
import { unwrapResult } from "@reduxjs/toolkit";
import PageWrapper from "components/PageWrapper";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "redux-store";
import { getUPSToken, getUpsUrl } from "redux-store/reducers/employee";
import ROUTERS from "routers/constants";

export default function UpsAuth() {
  const locationR = useLocation()
  const query = new URLSearchParams(locationR.search);
  const code = query.get('code');

  const [isConnected, setIsConnected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleGetUrlAuth = () => {
    dispatch(getUpsUrl({}))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          if (response.data.url) {
            window.location.href = response.data.url
          }
        }
      });;
  };

  const handleGetStatus = (code: string | null) => {
    setIsLoading(true)
    dispatch(getUPSToken({ code }))
      .then(unwrapResult)
      .then(({ response }) => {
        if (response.apiStatus) {
          if (code) {
            window.history.replaceState({}, '', `${locationR.pathname}`);
          }
          setIsConnected(!!response?.data?.connected)
        }
        setIsLoading(false)
      });;
  };

  useEffect(() => {
    handleGetStatus(code);
  }, []);

  return <PageWrapper
    pageAction={
      <Button
        color="primary"
        size="sm"
        onClick={() => navigate(ROUTERS.DASHBOARD)}
      >
        Close
      </Button>
    }
    pageName="UPS Shipping Account Api"
    breadcrumb={[
      {
        label: "UPS",
      },
    ]}
  >

    <Box sx={{ textAlign: "center" }} py={5}>
      <img width={120} style={{ marginBottom: "30px" }} src="https://developer.ups.com/assets/resources/webcontent/images/ups-logo.svg" alt="" />

      <Typography>
        UPS Connection: {isConnected ? <b style={{ color: "green" }}>Connected</b> : "Not Connected"}
      </Typography>
      <br />
      <Typography>
        <Button variant="outlined"
          loading={isLoading}
          disabled={isConnected}
          onClick={() => handleGetUrlAuth()}
        >
          {isLoading ? "Loading..." : "Click to connect now"}
        </Button>
      </Typography>

    </Box>
  </PageWrapper>
}
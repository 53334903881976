import {
  CustomerPrivateRoute,
  PrivateRoute,
  PublicRouteOnly,
} from "components/PrivateRoute";
import PrivateLayout from "layout/PrivateLayout";
import PublicLayout from "layout/PublicLayout";
import LoginPage from "pages/login";
import ProductDetailPage from "pages/product/detail";
import ProductListPage from "pages/product/list";
import ProductNewPage from "pages/product/new";
import PurchaseDetailPage from "pages/purchase/detail";
import IPurchaseOrderList from "pages/purchase/list";
import PurchaseNewPage from "pages/purchase/new";
import { createBrowserRouter } from "react-router-dom";
import ROUTERS, { ROUTERS_CUSTOMER, screensEnum } from "routers/constants";

// customer
import CustomerLayout from "layout/CustomerPrivateLayout";
import CustomerLoginPage from "pages/customer-portal/login";
import CustomerMyProfile from "pages/customer-portal/my-profile";

import CustomerPurchaseDetail from "pages/customer-portal/purchase/detail";
import CustomerPurchaseList from "pages/customer-portal/purchase/list";
import CustomerPurchaseNew from "pages/customer-portal/purchase/new";

import CustomerFreightQuoteDetail from "pages/customer-portal/freight-quote/detail";
import CustomerPFreightQuoteList from "pages/customer-portal/freight-quote/list";
import CustomerFreightQuoteNew from "pages/customer-portal/freight-quote/new";

import CustomerDetailPage from "pages/customer-admin/detail";
import CustomerListPage from "pages/customer-admin/list";
import CustomerNewPage from "pages/customer-admin/new";
import CustomerInvoiceDetail from "pages/customer-portal/invoice/detail";
import CustomerInvoice from "pages/customer-portal/invoice/list";
import CustomerPolicyPage from "pages/customer-portal/policy";
import CustomerSupportCase from "pages/customer-portal/support-case";
import DashboardPage from "pages/dashboard/dashboard";
import EmployeeDetailPage from "pages/employee/detail";
import EmployeeListPage from "pages/employee/list";
import EmployeeNewPage from "pages/employee/new";
import ProfilePage from "pages/employee/profile";
import FreightQuoteDetailPage from "pages/freight-quote/detail";
import FreightQuoteListPage from "pages/freight-quote/list";
import InventoryItemDetailPage from "pages/inventory/detail";
import InventoryGrid from "pages/inventory/grid";
import InventoryItemListPage from "pages/inventory/list";
import InvoiceDetailPage from "pages/invoice/detail";
import InvoiceListPage from "pages/invoice/list";
import ItemScanPage from "pages/item-scan";
import ManufacturingDetailPage from "pages/manufacturing/detail";
import ManufacturingListPage from "pages/manufacturing/list";
import PalletListPage from "pages/pallet/list";
import ProductImportPage from "pages/product/import";
import PromotionDetailPage from "pages/promotion/detail";
import PromotionListPage from "pages/promotion/list";
import PromotionNewPage from "pages/promotion/new";
import AuditPage from "pages/purchase/item-audit";
import PalletPackingPage from "pages/purchase/pallet-item";
import ReceivePage from "pages/purchase/receive";
import QuickBookAuth from "pages/quickbook";
import ReturnDetailPage from "pages/return/detail";
import ReturnListPage from "pages/return/list";
import ReturnNewPage from "pages/return/new";
import ContainerListPage from "pages/sales-order/container-list";
import SalesOrderDetailPage from "pages/sales-order/detail";
import SalesOrderDetailPickPage from "pages/sales-order/detail-pick";
import SalesOrderListPage from "pages/sales-order/list";
import SalesOrderListBackorderPage from "pages/sales-order/list-backorder";
import SalesOrderPickListPage from "pages/sales-order/list-pick";
import SalesOrderNewPage from "pages/sales-order/new";
import SettingsPage from "pages/settings/settings";
import UpsAuth from "pages/ups";
import WarehouseDetailPage from "pages/warehouse/detail";
import WarehouseListPage from "pages/warehouse/list";

export async function rootLoader(data: any) {
  console.log("rootloader", data);

  return {};
}

const router = createBrowserRouter([
  // public routes
  {
    element: <PublicLayout />,
    errorElement: <h1>Not Found</h1>,
    children: [
      {
        path: ROUTERS.LOGIN,
        element: (
          <PublicRouteOnly Component={LoginPage} to={ROUTERS.DASHBOARD} />
        ),
      },
      {
        path: ROUTERS_CUSTOMER.LOGIN,
        element: (
          <PublicRouteOnly
            Component={CustomerLoginPage}
            to={ROUTERS_CUSTOMER.MY_PROFILE}
          />
        ),
      },
      {
        path: ROUTERS.NOT_FOUND,
        element: (
          <h3>
            Page Not Found -{" "}
            <a href="/" target="_self">
              Back to Home
            </a>
          </h3>
        ),
      },
    ],
  },
  // private routes
  {
    element: <PrivateRoute Component={PrivateLayout} />,
    errorElement: <h1>Not Found</h1>,
    loader: rootLoader,
    children: [

      {
        path: ROUTERS.QUICKBOOKAUTH,
        element: <QuickBookAuth />,
        id: screensEnum.QUICKBOOKAUTH,
      },

      {
        path: ROUTERS.UPSAUTH,
        element: <UpsAuth />,
        id: screensEnum.UPSAUTH,
      },

      {
        path: ROUTERS.DASHBOARD,
        element: <DashboardPage />,
        id: screensEnum.DASHBOARD,
      },



      // PROFILE
      {
        path: ROUTERS.PROFILE,
        element: <ProfilePage />,
        id: screensEnum.PROFILE,
      },

      // PRODUCT
      {
        path: ROUTERS.PRODUCT,
        element: <ProductListPage />,
        id: screensEnum.PRODUCT,
      },
      {
        path: ROUTERS.PRODUCT_NEW,
        element: <ProductNewPage />,
        id: screensEnum.PRODUCT_NEW,
      },
      {
        path: ROUTERS.PRODUCT_DETAIL,
        element: <ProductDetailPage />,
        id: screensEnum.PRODUCT_DETAIL,
      },
      {
        path: ROUTERS.PRODUCT_IMPORT,
        element: <ProductImportPage />,
        id: screensEnum.PRODUCT_IMPORT,
      },

      // INVENTORY
      {
        path: ROUTERS.INVENTORY,
        element: <InventoryItemListPage />,
        id: screensEnum.INVENTORY,
      },
      {
        path: ROUTERS.INVENTORY_DETAIL,
        element: <InventoryItemDetailPage />,
        id: screensEnum.INVENTORY_DETAIL,
      },

      {
        path: ROUTERS.INVENTORY_GRID,
        element: <InventoryGrid />,
        id: screensEnum.INVENTORY_GRID,
      },


      // CUSTOMER
      {
        path: ROUTERS.CUSTOMER,
        element: <CustomerListPage />,
        id: screensEnum.CUSTOMER,
      },
      {
        path: ROUTERS.CUSTOMER_NEW,
        element: <CustomerNewPage />,
        id: screensEnum.CUSTOMER_NEW,
      },
      {
        path: ROUTERS.CUSTOMER_DETAIL,
        element: <CustomerDetailPage />,
        id: screensEnum.CUSTOMER_DETAIL,
      },

      // EMPLOYEE
      {
        path: ROUTERS.EMPLOYEE,
        element: <EmployeeListPage />,
        id: screensEnum.EMPLOYEE,
      },
      {
        path: ROUTERS.EMPLOYEE_NEW,
        element: <EmployeeNewPage />,
        id: screensEnum.EMPLOYEE_NEW,
      },
      {
        path: ROUTERS.EMPLOYEE_DETAIL,
        element: <EmployeeDetailPage />,
        id: screensEnum.EMPLOYEE_DETAIL,
      },

      // PALLET
      {
        path: ROUTERS.PALLET,
        element: <PalletListPage />,
        id: screensEnum.PALLET,
      },

      // RM Serial
      // {
      //   path: ROUTERS.RM_SERIAL,
      //   element: <RmSerialPage />,
      //   id: screensEnum.RM_SERIAL,
      // },

      // MANUFACTURING
      {
        path: ROUTERS.MANUFACTURING,
        element: <ManufacturingListPage />,
        id: screensEnum.MANUFACTURING,
      },
      {
        path: ROUTERS.MANUFACTURING_DETAIL,
        element: <ManufacturingDetailPage />,
        id: screensEnum.MANUFACTURING_DETAIL,
      },

      // PURCHASE
      {
        path: ROUTERS.PURCHASE,
        element: <IPurchaseOrderList />,
        id: screensEnum.PURCHASE,
      },
      {
        path: ROUTERS.PURCHASE_NEW,
        element: <PurchaseNewPage />,
        id: screensEnum.PURCHASE_NEW,
      },
      {
        path: ROUTERS.PURCHASE_DETAIL,
        element: <PurchaseDetailPage />,
        id: screensEnum.PURCHASE_DETAIL,
      },

      // RECEIVE
      {
        path: ROUTERS.RECEIVE,
        element: <ReceivePage />,
        id: screensEnum.RECEIVE,
      },

      // RECEIVE
      {
        path: ROUTERS.AUDIT,
        element: <AuditPage />,
        id: screensEnum.AUDIT,
      },

      {
        path: ROUTERS.ITEM_SCAN,
        element: <ItemScanPage />,
        id: screensEnum.ITEM_SCAN,
      },



      {
        path: ROUTERS.PALLET_PACKING,
        element: <PalletPackingPage />,
        id: screensEnum.PALLET_PACKING,
      },

      // SALES ORDER
      {
        path: ROUTERS.SALES_ORDER,
        element: <SalesOrderListPage />,
        id: screensEnum.SALES_ORDER,
      },
      {
        path: ROUTERS.SALES_ORDER_BACKORDER,
        element: <SalesOrderListBackorderPage />,
        id: screensEnum.SALES_ORDER_BACKORDER,
      },
      {
        path: ROUTERS.SALES_ORDER_PICK,
        element: <SalesOrderPickListPage />,
        id: screensEnum.SALES_ORDER_PICK,
      },
      {
        path: ROUTERS.SALES_ORDER_NEW,
        element: <SalesOrderNewPage />,
        id: screensEnum.SALES_ORDER_NEW,
      },
      {
        path: ROUTERS.SALES_ORDER_DETAIL,
        element: <SalesOrderDetailPage />,
        id: screensEnum.SALES_ORDER_DETAIL,
      },

      {
        path: ROUTERS.SALES_ORDER_PICK_DETAIL,
        element: <SalesOrderDetailPickPage />,
        id: screensEnum.SALES_ORDER_PICK_DETAIL,
      },

      {
        path: ROUTERS.CONTAINER,
        element: <ContainerListPage />,
        id: screensEnum.CONTAINER,
      },

      // PROMOTION
      {
        path: ROUTERS.PROMOTION,
        element: <PromotionListPage />,
        id: screensEnum.PROMOTION,
      },
      {
        path: ROUTERS.PROMOTION_NEW,
        element: <PromotionNewPage />,
        id: screensEnum.PROMOTION_NEW,
      },
      {
        path: ROUTERS.PROMOTION_DETAIL,
        element: <PromotionDetailPage />,
        id: screensEnum.PROMOTION_DETAIL,
      },

      // Return
      {
        path: ROUTERS.RETURN,
        element: <ReturnListPage />,
        id: screensEnum.RETURN,
      },
      {
        path: ROUTERS.RETURN_NEW,
        element: <ReturnNewPage />,
        id: screensEnum.RETURN_NEW,
      },
      {
        path: ROUTERS.RETURN_DETAIL,
        element: <ReturnDetailPage />,
        id: screensEnum.RETURN_DETAIL,
      },

      // Invoice
      {
        path: ROUTERS.INVOICE,
        element: <InvoiceListPage />,
        id: screensEnum.INVOICE,
      },
      {
        path: ROUTERS.INVOICE_DETAIL,
        element: <InvoiceDetailPage />,
        id: screensEnum.INVOICE_DETAIL,
      },

      // Freight Quote
      {
        path: ROUTERS.FREIGHT_QUOTE,
        element: <FreightQuoteListPage />,
        id: screensEnum.FREIGHT_QUOTE,
      },
      {
        path: ROUTERS.FREIGHT_QUOTE_DETAIL,
        element: <FreightQuoteDetailPage />,
        id: screensEnum.FREIGHT_QUOTE_DETAIL,
      },

      // Accounting
      {
        path: ROUTERS.ACCOUNTING,
        element: <h1>Accounting order</h1>,
        id: screensEnum.ACCOUNTING,
      },
      // WAREHOUSE
      {
        path: ROUTERS.WAREHOUSE,
        element: <WarehouseListPage />,
        id: screensEnum.WAREHOUSE,
      },
      {
        path: ROUTERS.WAREHOUSE_DETAIL,
        element: <WarehouseDetailPage />,
        id: screensEnum.WAREHOUSE_DETAIL,
      },

      // SETTINGS
      {
        path: ROUTERS.SETTINGS,
        element: <SettingsPage />,
        id: screensEnum.SETTINGS,
      },

      // Report
      {
        path: ROUTERS.REPORT,
        element: <h1>Report</h1>,
        id: screensEnum.REPORT,
      },
    ],
  },
  // customer private routes
  {
    element: <CustomerPrivateRoute Component={CustomerLayout} />,
    errorElement: <h1>Not Found</h1>,
    loader: rootLoader,
    children: [
      {
        path: ROUTERS_CUSTOMER.CUSTOMER_HOME,
        element: <CustomerMyProfile />,
      },
      {
        path: ROUTERS_CUSTOMER.MY_PROFILE,
        element: <CustomerMyProfile />,
      },

      {
        path: ROUTERS_CUSTOMER.PURCHASE_ORDER,
        element: <CustomerPurchaseList />,
      },
      {
        path: ROUTERS_CUSTOMER.PURCHASE_ORDER_NEW,
        element: <CustomerPurchaseNew />,
      },
      {
        path: ROUTERS_CUSTOMER.PURCHASE_ORDER_DETAIL,
        element: <CustomerPurchaseDetail />,
      },

      {
        path: ROUTERS_CUSTOMER.FREIGHT_QUOTE,
        element: <CustomerPFreightQuoteList />,
      },
      {
        path: ROUTERS_CUSTOMER.FREIGHT_QUOTE_NEW,
        element: <CustomerFreightQuoteNew />,
      },
      {
        path: ROUTERS_CUSTOMER.FREIGHT_QUOTE_DETAIL,
        element: <CustomerFreightQuoteDetail />,
      },

      {
        path: ROUTERS_CUSTOMER.INVOICE,
        element: <CustomerInvoice />,
      },

      {
        path: ROUTERS_CUSTOMER.INVOICE_DETAIL,
        element: <CustomerInvoiceDetail />,
      },
      {
        path: ROUTERS_CUSTOMER.SUPPORT_CASE,
        element: <CustomerSupportCase />,
      },
      {
        path: ROUTERS_CUSTOMER.POLICY,
        element: <CustomerPolicyPage />,
      },
    ],
  },
]);

export default router;

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import * as React from 'react';
import BaseInput from './BaseInput';

const filter = createFilterOptions<OptionType>();

export default function SelectOrAddNew({
  options,
  value,
  onChange,
  label,
}: {
  label?: string
  options?: OptionType[]
  value?: string
  onChange?: (value: any) => void
}) {
  const [valueRaw, setValueRaw] = React.useState<OptionType | null>(null);

  React.useEffect(() => {
    if (value) {
      setValueRaw({
        title: value,
      });
    }
  }, [value]);

  React.useEffect(() => {
    if (onChange) {
      onChange(valueRaw?.title);
    }
  }, [valueRaw]);

  return (
    <Autocomplete
      value={valueRaw}
      onChange={(event, newValue) => {
        if (typeof newValue === 'string') {
          setValueRaw({
            title: newValue,
          });
        } else if (newValue && newValue.inputValue) {
          // Create a new value from the user input
          setValueRaw({
            title: newValue.inputValue,
          });
        } else {
          setValueRaw(newValue);
        }
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);

        const { inputValue } = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        if (inputValue !== '' && !isExisting) {
          filtered.push({
            inputValue,
            title: `Add "${inputValue}"`,
          });
        }

        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id="free-solo-with-text-demo"
      options={options || []}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      renderOption={(props, option) => {
        const { ...optionProps } = props;
        return (
          <li {...optionProps}>
            {option.title}
          </li>
        );
      }}
      freeSolo
      renderInput={(params) => (
        <BaseInput {...params} label={label} />
      )}
    />
  );
}

export interface OptionType {
  inputValue?: string;
  title: string;
  value?: any;
}

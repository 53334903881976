import { NumericFormat } from "react-number-format";
import BaseInput from "./BaseInput";

export default function BaseCurrencyInput({
  label,
  value,
  onChange,
  is_number,
  max,
  min,
  prefix = '',
  className = '',
  placeholder = ''
}: {
  label?: string;
  value?: string | number;
  onChange: (value: any) => void;
  is_number?: boolean;
  prefix?: string;
  max?: number;
  min?: number;
  className?: string;
  placeholder?: string;
}) {
  return (
    <NumericFormat
      className={className}
      label={label}
      customInput={BaseInput}
      decimalScale={2}
      placeholder={placeholder}
      inputMode="decimal"
      thousandSeparator
      valueIsNumericString
      prefix={prefix || (is_number ? "" : "$")}
      onValueChange={(values) => {
        onChange(is_number ? Number(values.value) : values.value);
      }}
      isAllowed={(values) => {
        const value = Number(values.value);
        if (value === undefined) return true;
        if (min !== undefined && value < min) return false;
        if (max !== undefined && value > max) return false;
        return true;
      }}
      value={value}
    />
  );
}

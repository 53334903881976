import Box from '@mui/joy/Box'
import { Outlet } from 'react-router-dom'

export default function PublicLayout() {
  return (
    <Box>
      <Outlet />
    </Box>
  )
}
